<template>

    <div style="overflow-x: clip;">
        <div class="row sticky-top bg-gray-100 shadow" style="padding-top: 10px;">
            <div class="col-sm-12">
                <h2>
                    <button style="border: none; background: none;" @click="goBack"><i
                        class="p-2 fas fa-arrow-left"></i></button>
                    Search Results
                </h2>
            </div>
        </div>

        <Vue3Lottie v-if="searchingRides" :animationData="animationJson" style="position: fixed; bottom: 0;"></Vue3Lottie>


        <div class="container-fluid">

            <div class="row">
                <div v-if="list.rides.length===0" class="col-12 text-center" style="margin-top: 20%;">
                    <h4>{{ getLabel }}</h4>
                    <div v-if="getLabel==='No rides found near you...'">
                        <soft-button color="primary" variant="gradient" @click="publishCustomersRide">Request Ride</soft-button>
                    </div>
                </div>
            </div>
            <br>
            <div class="row" v-if="list.rides.length>0">
                <div class="col-sm-12" v-for="(ride, index) in list.rides" :key="ride.id">
                    <div class="card" :id="index">
                        <div class="card-body" style="margin-top: 0; padding-top: 0;">
                            <br>
                            <div class="row">

                                <div class="col-6">
                                    <label>From</label>
                                    <p>{{ ride.from }}</p>
                                </div>

                                <div class="col-6" style="text-align: right">
                                    <label>To</label>
                                    <p>{{ ride.to }}</p>
                                </div>

                                <div class="col-6">
                                    <br>
                                    <label>{{
                                            (ride.date === null ? "Daily" : convertDate(ride.date)) + " " + convertTime(ride.time)
                                        }}</label>
                                    <br>
                                    <p>₹{{ ride.fair }}/-
                                        <span v-if="ride.fair_per_km" style="font-size: 12px;"> (₹{{
                                                ride.fair_per_km
                                            }}/km)</span></p>
                                </div>
                                <div class="col-6" style="text-align: right;">
                                    <br>
                                    <br>
                                    <soft-button  size="sm" color="dark" full-width
                                                 @click="showBookingDialogV2($event,ride)">Book Now
                                    </soft-button>
                                </div>

                            </div>
                        </div>
                    </div>
                    <br>
                </div>

                <div class="col-12">
                    <div>
                        <p>Dont find a suitable/appropriate ride?</p>
                        <soft-button color="primary" variant="gradient" full-width @click="publishCustomersRide">Request Ride</soft-button>
                    </div>
                </div>

            </div>
            <br>
        </div>

        <div
            v-if="showDatePicker"
            style="z-index: 10000; position: fixed; top: 0; bottom: 0; left: 0; right: 0; background-image: linear-gradient(310deg, #17ad37ee 0%, #98ec2dee 100%);">
            <div style="padding: 20%;">
                <h1 style="color: white;">On which date you want to ride?</h1>
                <input style="font-size: 24px;" type="date" class="form-control" v-model="selectedDate"/>
                <br>

                <div class="row">
                    <div class="col-6">
                        <soft-button @click="showDatePicker=false" color="white" size="sm">Cancel</soft-button>
                    </div>
                    <div class="col-6">
                        <soft-button @click="showBookingDialog($event)" size="sm">Done</soft-button>
                    </div>
                </div>
            </div>
        </div>

    </div>

</template>

<script>


import SoftButton from "../../components/SoftButton";
import swal from "sweetalert";
import {Vue3Lottie} from 'vue3-lottie'

import animationJson from '../../assets/anim/ride-anim.json'


export default {
    components: {
        SoftButton,
        Vue3Lottie,
    },
    props: {
        from: {
            type: String,
            required: true
        },
        to: {
            type: String,
            required: true
        },
        from_pin: {
            type: String,
        },
        to_pin: {
            type: String,
        },
        from_lat_lng: {
            type: String,
        },
        to_lat_lng: {
            type: String,
        }
    },
    data() {
        return {
            animationJson,

            showDatePicker: false,

            selectedDate: '',

            currentlySelectedRide: null,

            isRidePublished: false,

            mobileNumber: '',
            list: {
                rides: [],
            },

            searchingRides: true,

            noOfPassengers: null,

        }
    },
    methods: {
        publishCustomersRide(event) {

            const button = event.target
            console.log(button)

            const vm = this;
            console.log(vm)

            swal("Are you sure want to ride now?", {
                buttons: ["Cancel", "Ride Now"],
            })
                .then((value) => {
                    if (value) {

                        swal("Enter Number of Passengers", {
                            content: "input",
                        })
                            .then((value) => {

                                if (!value) {
                                    return
                                }

                                try {
                                    value = parseInt(value)
                                } catch {
                                    return
                                }

                                if (value < 1 || isNaN(value)) {
                                    return
                                }

                                button.disabled = true

                                swal({
                                    title: `Are you sure you want to request this ride for ${value} passengers?`,
                                    text: "On requesting this ride, All nearby rides will be notified and would be able to see your ride request, they may approve or reject you request",
                                    icon: "info",
                                    buttons: ['Cancel', 'Publish Ride'],
                                    dangerMode: false,
                                }).then((book) => {
                                    if (book) {
                                        vm.noOfPassengers = value
                                        vm.publishThisRide()
                                    } else {
                                        button.disabled = false
                                    }
                                });
                            })

                    }
                });


        },
        publishThisRide() {
            let vm = this
            window.axios.post('/ride/customer/publish/request', {
                mobile_number: vm.mobileNumber,
                no_of_passengers: vm.noOfPassengers,
                from: vm.$props.from,
                to: vm.$props.to,
                from_pin: vm.$props.from_pin,
                to_pin: vm.$props.to_pin,
                from_lat_lng: vm.$props.from_lat_lng,
                to_lat_lng: vm.$props.to_lat_lng,
            }).then(response => {
                if (response.data.response.status.error_code === 0) {
                    vm.isRidePublished = true
                    vm.$toast.open({
                        message: response.data.response.status.msg,
                        type: 'success',
                        position: 'top',
                        duration: 2000,
                        dismissible: false,
                    })
                }
            })
        },
        searchRides() {
            let vm = this
            this.searchingRides = true
            window.axios.post('/ride/search', {
                from: vm.$props.from,
                to: vm.$props.to,
                from_pin: vm.$props.from_pin,
                to_pin: vm.$props.to_pin,
            }).then(response => {
                this.searchingRides = false
                if (response.data.response.status.error_code === 0) {
                    vm.list.rides = response.data.response.data.rides
                }
            })
        },
        goBack() {
            this.$router.replace({name: "ride-search"})
        },
        showBookingDialogV2(event, ride) {
            this.currentlySelectedRide = ride

            if (ride.date == null) {
                this.selectedDate = ''
                this.showDatePicker = true
            } else {
                this.selectedDate = ''
                this.showDatePicker = false
                this.showBookingDialog(event, ride.id)
            }
        },
        showBookingDialog(event) {

            if (this.showDatePicker) {
                if (this.selectedDate === '') {
                    return
                }
            }

            const rideId = this.currentlySelectedRide.id

            const button = event.target

            const vm = this;


            swal("Enter Number of Passengers", {
                content: "input",
            })
                .then((value) => {

                    if (!value) {
                        return
                    }

                    try {
                        value = parseInt(value)
                    } catch {
                        return
                    }

                    if (value < 1 || isNaN(value)) {
                        return
                    }

                    if (value > 4) {
                        vm.$toast.open({
                            message: 'Max 4 Passengers Allowed',
                            type: 'error',
                            position: 'top',
                            duration: 5000,
                            dismissible: false,
                        })
                        return
                    }

                    button.disabled = true

                    let txt = ''
                    if (vm.selectedDate !== '') {
                        //txt = ' on ' + this.selectedDate
                    }

                    swal({
                        title: `Are you sure you want to book this ride${txt} for ${value} persons?`,
                        text: "On booking this ride, rider will receive a notification and they may approve or reject you request",
                        icon: "info",
                        buttons: ['Cancel', 'Send Booking Request'],
                        dangerMode: false,
                    }).then((book) => {
                        if (book) {
                            vm.noOfPassengers = value
                            vm.sendBookingRequest(button, rideId)
                        } else {
                            button.disabled = false
                        }
                    });
                })


        },
        sendBookingRequest(button, rideId) {
            const vm = this
            window.axios.post('/ride/request/booking', {
                mobile_number: this.mobileNumber,
                ride_id: rideId,
                no_of_passengers: vm.noOfPassengers,
                from: vm.$props.from,
                to: vm.$props.to,
                from_lat_lng: vm.$props.from_lat_lng,
                to_lat_lng: vm.$props.to_lat_lng,
                selected_date: vm.selectedDate,
            }).then(response => {
                if (response.data.response.status.error_code === 0) {
                    button.disabled = false
                    vm.selectedDate = ''
                    vm.showDatePicker = false
                    vm.$toast.open({
                        message: response.data.response.status.msg,
                        type: 'success',
                        position: 'top',
                        duration: 2000,
                        dismissible: false,
                    })
                }
            })
        }
    },
    computed: {
        getLabel() {
            if (this.isRidePublished) {
                return 'Your ride has been published. You will be notified when a rider nearby will accept your ride.'
            } else {
                if (this.searchingRides) {
                    return 'Please wait, finding rides near you...'
                } else {
                    if (this.list.rides.length === 0) {
                        return 'No rides found near you...'
                    } else {
                        return ':('
                    }
                }
            }

        }
    },
    created() {
        let vm = this
        vm.mobileNumber = this.$route.params.mobileNumber
        vm.searchRides()
    }
}

</script>

<style scoped>

label {
    margin: 0;
    padding: 0;
}

p {
    font-size: 14px;
}

.Absolute-Center {
    display: flex;
}

</style>
