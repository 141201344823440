<template>
    <div class="register" style="overflow-x: clip;">
        <div class="row">
            <div class="col-sm-12 ms-2 mt-5">
                <h3>
                    <img height="45" width="45" :src="logo">
                    TRANSALL
                </h3>
            </div>
        </div>

        <div class="card h-100 mt-5">
            <div class="card-header p-0 mx-3 mt-3 position-relative z-index-1 text-center">
                <h4>Create Your Profile</h4>
            </div>

            <div class="card-body pt-2">
                <form @submit.prevent>
                    <div class="form-group">
                        <soft-input :isRequired="true" v-model="form.fullName" type="text" placeholder="Enter Full Name"></soft-input>
                    </div>
                    <div class="form-group">
                        <soft-input :isRequired="true" v-model="form.email" type="text" placeholder="Enter Email"></soft-input>
                    </div>
                    <div class="row">
                        <label>Gender</label>
                        <div class="col-12">
                            <soft-radio v-model="form.gender" name="radio" value="male" id="radio1">
                                <slot>Male</slot>
                            </soft-radio>
                        </div>
                        <div class="col-12">
                            <soft-radio v-model="form.gender" value="female" id="radio2" name="radio">
                                <slot>Female</slot>
                            </soft-radio>
                        </div>
                        <div class="col-12">
                            <soft-radio v-model="form.gender" value="other" id="radio3" name="radio">
                                <slot>Other</slot>
                            </soft-radio>
                        </div>
                    </div>
                </form>
                <soft-button v-if="!isLoading" class="btn-primary btn-lg w-100" @click="submit">
                    Submit
                </soft-button>
                
                <div class="text-center" v-if="isLoading">
                    <span class="spinner-border" role="status" aria-hidden="true"></span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>


import { mapGetters } from "vuex";
import logo from '../../assets/img/TA_LOGO_2.png';

import SoftButton from "../../components/SoftButton";
import SoftInput from "../../components/SoftInput";
import SoftRadio from "../../components/SoftRadio";
import authService from "../../services/auth.service";

export default {

    data() {
        return {
            logo,
            form: {
                fullName: '',
                email: '',
                gender: ''
            },
            isLoading: false,
            user: this.getUser()
        }
    },

    components: {
        SoftButton,
        SoftInput,
        SoftRadio
    },


    methods: {
        ...mapGetters(['getUser']),
        submit() {
            const vm = this

            let message = '';

            if (!vm.form.fullName) {
                message = 'Please enter full name';
            } else if (!vm.form.email) {
                message = 'Please enter email';
            } else if (!vm.validateEmail(vm.form.email)) {
                message = 'Please enter valid email';
            } else if (!vm.form.gender) {
                message = 'Please select gender';
            }

            if (message) {
                vm.$toast.open({
                    message: message,
                    type: 'error',
                    position: 'top',
                    duration: 2000,
                    dismissible: true,
                });  
            } else {
                vm.isLoading = true;

                const payload = {
                    uid: vm.user.fId,
                    mobile_number: vm.user.mobileNumber,
                    full_name: vm.form.fullName,
                    gender: vm.form.gender,
                    email: vm.form.email
                };

                authService.registerUser(payload)
                .then(async (apiResponse) => {
                    vm.isLoading = false;
                    if (apiResponse && !apiResponse.response.status.error_code) {
                        vm.$toast.open({
                            message: 'Profile created',
                            type: 'success',
                            position: 'top',
                            duration: 3000,
                            dismissible: true,
                        });
                        await vm.$store.dispatch('setJwtToken', apiResponse.response.data.token);
                        vm.$router.push({
                            name: 'home'
                        });
                    } else {
                        vm.$toast.open({
                            message: 'Something went wrong, please try again!',
                            type: 'error',
                            position: 'top',
                            duration: 3000,
                            dismissible: true,
                        });
                    }
                });
            }
        },
        validateEmail(email) {
            const  re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
            return re.test(email);
        }

    },

    created() {
        
    },

    mounted() {
    }


}

</script>

<style>
.register {
    height: 100vh;
    background: linear-gradient(to top, #103783 0%, #9BAFD9 100%);
}

</style>
