<template>

    <div v-if="isApiCalling" class="row"
         style="position: absolute; top:0; bottom: 0; left: 0; right: 0; background-color: black; opacity: 0.8; z-index: 1">
        <div class="col-12">
            <p class="text-white text-center" style="margin-top: 40vh;">Please wait adding new driver...</p>
        </div>
    </div>

    <div>

        <div class="container-fluid">

            <div class="row">
                <div class="col-sm-12">
                    <br>
                    <h4>{{ getTitle }}</h4>
                    <hr>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12">
                    <label>Driver Name</label>
                    <soft-input v-model="driver.name"
                                placeholder=""></soft-input>
                </div>

                <div class="col-sm-12">
                    <label>Driver Contact</label>
                    <soft-input v-model="driver.contact"
                                placeholder=""></soft-input>
                </div>

                <div v-if="driverData!=null" class="row">
                    <div class="col">
                        <img class="img-fluid" :src="driver.photoUrl"/>
                    </div>
                    <div class="col">
                        <img class="img-fluid" :src="driver.licencePhoto"/>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <label>Driver Photo</label>
                        <br>
                        <input accept="image/*" type="file" id="driver-photo">
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <label>Licence Photo</label>
                        <br>
                        <input accept="image/*" type="file" id="licence-photo">
                    </div>
                </div>

                <div class="col-12">
                    <br>
                    <soft-button btn-type="submit" @click="close()"
                                 full-width color="dark" size="md"
                                 variant="gradient">
                        Close
                    </soft-button>
                </div>

                <div class="col-12">
                    <br>
                    <soft-button btn-type="submit" @click="addDriver" full-width color="success" size="md"
                                 variant="gradient">
                        {{ getButtonLabel }}
                    </soft-button>
                </div>


                <div class="col-12" v-if="driverData">
                    <br>
                    <soft-button btn-type="submit" @click="deleteDriver" full-width color="danger" size="md"
                                 variant="gradient">
                        Delete
                    </soft-button>
                    <br>
                    <br>
                </div>


            </div>

        </div>

    </div>

</template>
<script>

import SoftInput from "../SoftInput";
import SoftButton from "../SoftButton";

export default {

    components: {
        SoftInput,
        SoftButton
    },
    props: {
        modal: {
            type: Object,
        },
        mobileNumber: {
            type: String,
            required: true
        }
    },
    data() {
        return {

            driverData: null,

            isApiCalling: false,

            driver: {
                id: null,
                name: '',
                contact: '',
                photoUrl: '',
                licencePhoto: '',
            },
        }
    },
    methods: {
        close() {
            this.modal.$emit('close');
        },
        setDriverData(selectedDriver) {
            this.driverData = selectedDriver
            if (this.driverData != null) {
                this.driver.id = this.driverData.id
                this.driver.name = this.driverData.name
                this.driver.contact = this.driverData.contact_no

                this.driver.photoUrl = this.driverData.photo_url
                this.driver.licencePhoto = this.driverData.licence_image_url
            } else {
                this.driver.name = ''
                this.driver.contact = ''

                this.driver.photoUrl = ''
                this.driver.licencePhoto = ''
            }
        },
        deleteDriver() {
            const vm = this
            window.axios.delete('/driver/' + vm.$props.mobileNumber + '/delete/' + vm.driverData.id)
                .then(resp => {
                    if (resp.data.response.status.error_code === 0) {
                        vm.close()
                    }
                })
        },
        uploadDriverPhoto() {

            const vm = this

            return new Promise((resolve) => {

                let formData = new FormData()
                let imagefile = document.getElementById('driver-photo')
                if (imagefile.files[0]) {
                    formData.append("image", imagefile.files[0])
                    window.axios.post('/do/upload/image/driver/photo', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(resp => {
                        if (resp.data.response.status.error_code === 0) {
                            vm.driver.frontPhotoUrl = resp.data.response.data.image_url
                        }
                        resolve()
                    }).catch(() => resolve())
                } else {
                    resolve()
                }

            })


        },
        uploadDriverLicencePhoto() {

            const vm = this

            return new Promise((resolve) => {

                let formData = new FormData()
                let imagefile = document.getElementById('licence-photo')
                if (imagefile.files[0]) {
                    formData.append("image", imagefile.files[0])
                    window.axios.post('/do/upload/driver/licence/photo', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(resp => {
                        if (resp.data.response.status.error_code === 0) {
                            vm.driver.backPhotoUrl = resp.data.response.data.image_url
                        }
                        resolve()
                    }).catch(() => resolve())
                } else {
                    resolve()
                }

            })


        },

        async uploadAllImages() {
            const vm = this

            await vm.uploadDriverPhoto()
            await vm.uploadDriverLicencePhoto()


        },

        async addDriver() {
            const vm = this

            let imagefile1 = document.getElementById('driver-photo')

            if (imagefile1.files[0] == null && vm.driver.photoUrl === '') {
                vm.$toast.open({
                    message: 'Driver photo is required',
                    type: 'error',
                    position: 'top',
                    duration: 2000,
                    dismissible: false,
                })
                return
            }

            let imagefile2 = document.getElementById('licence-photo')
            if (imagefile2.files[0] == null && vm.driver.licencePhoto === '') {
                vm.$toast.open({
                    message: 'Licence photo is required',
                    type: 'error',
                    position: 'top',
                    duration: 2000,
                    dismissible: false,
                })
                return
            }


            vm.isApiCalling = true

            await vm.uploadAllImages()

            window.axios.post('/driver/' + vm.$props.mobileNumber + '/add', {
                driver_id: vm.driver.id,
                driver_name: vm.driver.name,
                photo_url: vm.driver.photoUrl,
                contact_no: vm.driver.contact,
                licence_image_url: vm.driver.licencePhoto,
            })
                .then(resp => {
                    if (resp.data.response.status.error_code == 0) {
                        vm.isApiCalling = false
                        vm.close()
                    }
                })
        }
    },
    computed: {
        getTitle() {
            if (this.driverData != null) {
                return 'Driver Details'
            } else {
                return 'Add Driver'

            }
        },
        getButtonLabel() {
            if (this.driverData != null) {
                return 'Save'
            } else {
                return 'Add'

            }
        }
    }

}

</script>
