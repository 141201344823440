<template>
    <div style="overflow-x: clip;">
        <div class="row sticky-top bg-gray-100 shadow" style="padding-top: 10px;">
            <div class="col-sm-12">
                <h3>
                    <button style="border: none; background: none;" @click="goBack"><i
                        class="p-2 fas fa-arrow-left"></i></button>
                    Book Ride
                </h3>
            </div>
        </div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <br>
                    <h5>Ride Details</h5>
                    <hr>
                    <span class="text-bolder" style="font-size: 14px;">From</span>
                    <br>
                    <span>{{ from }}</span>
                    <br>
                    <br>
                    <span class="text-bolder" style="font-size: 14px;">To</span>
                    <br>
                    <span>{{ to }}</span>
                    <br>
                    <br>
                    <span class="text-bolder" style="font-size: 14px;">Schedule</span>
                    <br>
                    <span>N/A</span>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <br>
                    <h5>Fare Details</h5>
                    <hr>
                    <table style="width: 100%;">
<!--                        <tr>-->
<!--                            <td>Per Km Charges</td>-->
<!--                            <td style="text-align: right;">₹10.00/-</td>-->
<!--                        </tr>-->
                        <tr>
                            <td>Convenience Fees</td>
                            <td style="text-align: right;">₹50.00/-</td>
                        </tr>
                        <tr>
                            <td>Ride Charges</td>
                            <td style="text-align: right;">₹{{ amount }}/-</td>
                        </tr>
                    </table>
                    <br>
                    <span>* Terms and Conditions apply</span>

                </div>

                <div class="col-12">
                    <br>
                    <soft-button full-width size="lg" color="dark" @click="makePaymentRequest">PAY NOW
                    </soft-button>
                </div>

            </div>

        </div>
    </div>
</template>
<script>

import SoftButton from "../../components/SoftButton";

export default {
    components: {SoftButton},
    props: {
        from: {
            type: String,
            required: true,
        },
        to: {
            type: String,
            required: true,
        },
        amount: {
            type: String,
            required: true,
        },
        contact: {
            type: String,
            required: true,
        },
        requestType: {
            type: String,
            required: true,
        },
        requestId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {}
    },
    methods: {
        goBack() {
            this.$router.replace({name: "ride-search-results"})
        },
        makePaymentRequest(e) {

            e.target.disabled = true

            window.axios.post('/payment/create/order', {
                amount: this.$props.amount,
                contact: this.$props.contact,
                requestType: this.$props.requestType,
                requestId: this.$props.requestId,
            }).then(response => {
                e.target.disabled = false
                if (response.data.response.data.id) {
                    let orderId = response.data.response.data.id
                    let amount = response.data.response.data.amount
                    this.$router.replace({
                        name: "payment-checkout", params: {
                            orderId: orderId,
                            amount: amount,
                            typeOfService: 'ride',
                            user: {},
                            description: 'Ride Booking'
                        }
                    })
                }
            })
        }
    },
    created() {

        //get price fair distribution

    }

}

</script>
