<template>
    <div style="overflow-x: clip;">
        <div class="row sticky-top bg-gray-100 shadow" style="padding-top: 10px;">
            <div class="col-10 col-sm-10">
                <h3>
                    <button style="border: none; background: none;" @click="goBack"><i
                        class="p-2 fas fa-arrow-left"></i></button>
                    Manage Account
                </h3>
            </div>
            <div class="col-2 col-sm-2" @click="logout">
                <i class="fa fa-power-off fa-2x p-2"></i>
            </div>
        </div>
        <div class="container-fluid pb-5">
            <div class="row pt-4">
                <div class="col text-center">
                    <div class="card card-user-image">
                        <img class="user-image" :src="$store.state.user.profile_picture_image_url || userDemoImg">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-6 pt-3">
                    <div class="card" @click="$router.push({name:'account-manage-personal-details',params:{mobileNumber:mobileNumber}})">
                        <i class="card-icon fa fa-solid fa-user fa-2x"></i>
                        <span class="card-title">Personal Details</span>
                    </div>
                </div>
                <div class="col-6 pt-3">
                    <div class="card" @click="$router.push({name:'user-wallet',params:{mobileNumber:mobileNumber}})">
                        <i class="card-icon fa fa-solid fa-wallet fa-2x"></i>
                        <span class="card-title">Wallet</span>
                    </div>
                </div>
                <div class="col-6 pt-3">
                    <div class="card" @click="$router.push({name:'account-manage-vehicles',params:{mobileNumber:mobileNumber}})">
                        <i class="card-icon fa fa-solid fa-car fa-2x"></i>
                        <span class="card-title">Manage Vehicles</span>
                    </div>
                </div>
                <div class="col-6 pt-3">
                    <div class="card" @click="$router.push({name:'account-manage-drivers',params:{mobileNumber:mobileNumber}})">
                        <i class="card-icon fa fa-solid fa-id-card fa-2x"></i>
                        <span class="card-title">Manage Drivers</span>
                    </div>
                </div>
                <div class="col-6 pt-3">
                    <div class="card card-red" @click="deleteAccount">
                        <i class="card-icon fa fa-solid fa-user-slash fa-2x"></i>
                        <span class="card-title">Delete Account</span>
                    </div>
                </div>
            </div>
            <div class="row pt-5">
                <div class="col">
                    <span>User Type</span>
                    <br>
                    <b>{{ $store.state.user.user_type === '2' ? 'General User' : 'Service Provider' }}</b>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import swal from "sweetalert";
import userDemoImg from "../../assets/img/user.png";
export default {
    components: {},
    data() {
        return {
            userDemoImg,
            mobileNumber: null,
            walletBalance: 0.0
        }
    },
    methods: {
        deleteAccount(){
            swal({
                title: "Delete account?",
                text: "Are you sure you want to delete this account? You cannot undo this.",
                icon: "warning",
                buttons: ['Cancel', 'Delete Account'],
                dangerMode: false,
            }).then((deleteAcc) => {
                if(deleteAcc){
                    alert('Account deleted successfully')
                    window.ReactNativeWebView.postMessage("close-screen")
                }
            })
        },
        goBack() {
            window.ReactNativeWebView.postMessage("close-screen")
        },
        getUserWalletBalance() {
            const vm = this
            window.axios.get('/user/wallet/balance/' + vm.mobileNumber)
                .then(resp => {
                    if (resp.data.response.status.error_code === 0) {
                        vm.walletBalance = (resp.data.response.data.wallet_balance / 100)
                    }
                })
                .catch(e => console.log(e))
        },
        requestWithdrawal() {
            const vm = this
            swal("Enter Amount", {
                content: "input",
            })
                .then((value) => {
                    let val = parseFloat(value)
                    let currentBalance = parseFloat(vm.walletBalance)

                    if (isNaN(val)) {
                        vm.$toast.open({
                            message: 'Invalid Input',
                            type: 'error',
                            position: 'top',
                            duration: 2000,
                            dismissible: false,
                        })
                        return
                    }
                    if (currentBalance - val < 100) {
                        vm.$toast.open({
                            message: 'Value exceeds min balance',
                            type: 'error',
                            position: 'top',
                            duration: 2000,
                            dismissible: false,
                        })
                        return
                    }
                    if (val > currentBalance) {
                        vm.$toast.open({
                            message: 'Value exceeds current balance',
                            type: 'error',
                            position: 'top',
                            duration: 2000,
                            dismissible: false,
                        })
                        return
                    }

                    vm.$toast.open({
                        message: "Request sent",
                        type: 'success',
                        position: 'top',
                        duration: 5000,
                        dismissible: false,
                    })

                });
        },
        logout() {
            this.$store.dispatch('Logout');
            this.$router.push({
                name: 'sign-in'
            });
        }
    },
    mounted() {
        this.mobileNumber = this.$route.params.mobileNumber
        //this.getUserWalletBalance()
    }
}

</script>
<style scoped>

.card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Adjust spacing between cards */
}
.card {
    display: flex;
    align-items: center;
    background-color: #2f194d;
    color: white;
    border-radius: 8px;
    padding: 1rem;
    width: 100%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    flex-direction: row;
    height: 70px;
}

.card-icon {
    margin-right: 1rem;
}

.card-title {
    margin: 0;
    font-weight: bold;
}

.card-red {
    background-color: #a20d29;   
}

.user-image {
    width: 100px;
    height: 100px;
    filter: invert(1);
    max-width: 100px;
}

.card-user-image {
    width: 100px;
    height: 100px;
    padding: 0;
    display: block;
    margin: 0 auto;
    color: white;
}
</style>
