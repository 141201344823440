<template>

    <div style="overflow-x: clip;">

        <div class="row sticky-top bg-gray-100 shadow" style="padding-top: 10px;">
            <div class="col-sm-12">
                <h2>
                    <button style="border: none; background: none;" @click="goBack"><i
                        class="p-2 fas fa-arrow-left"></i></button>
                    Search Results
                </h2>
            </div>
        </div>

        <Vue3Lottie v-if="isCallingApi" :animationData="animationJson" style="position: fixed; bottom: 0;"></Vue3Lottie>

        <div class="container-fluid">
            <br>
            <div class="row">
                <div class="col-12" v-if="travels.length===0 && !isCallingApi">
                    <h4 class="text-center" style="margin: 10%;">No matching travels
                        found.</h4>
                </div>
                <div class="col-12" v-for="(travel, index) in travels" :key="travel.id">
                    <div class="card" :id="index">
                        <div class="card-body">
                            <h5>{{ travel.title }}</h5>
                            <hr>
                            <p>Max {{ travel.max_persons }} Persons</p>

                            <div class="row">
                                <div class="col-6">
                                    <label>Date</label>
                                    <p>{{ convertDate(travel.date) }}</p>
                                </div>
                                <div class="col-6" style="text-align: right;">
                                    <label>Time</label>
                                    <p>{{ convertTime(travel.time) }}</p>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-6">
                                    <label>Price Per Person</label>
                                    <p>{{ stringToINR(travel.price_per_person) }}</p>
                                </div>
                                <div class="col-6" style="text-align: end;">
                                    <p></p>
                                    <SoftButton :ref="'tour-'+travel.id" @click="viewDetails(travel)"
                                                color="dark"
                                                size="sm">View Details
                                    </SoftButton>
                                </div>
                            </div>


                            <div class="row">

                            </div>
                        </div>
                    </div>
                    <br>
                </div>
            </div>
            <br>
        </div>

        <Modal
            ref="modal"
            v-show="modalOpen"
            @close="closeModal">
            <template v-slot:body>
                <TravelDetails ref="travelDetails" :modal="$refs.modal" :mobile-number="mobileNumber"></TravelDetails>
            </template>
        </Modal>

    </div>

</template>

<script>

import SoftButton from "../../components/SoftButton";
import Modal from "../../components/Modal";
import TravelDetails from "../../components/modals/TravelDetails";
import animationJson from "../../assets/anim/travels-anim.json"
import {Vue3Lottie} from 'vue3-lottie'

export default {
    components: {TravelDetails, Modal, SoftButton,Vue3Lottie},
    props: {
        from: {
            type: String,
            required: true
        },
        to: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            animationJson,
            isCallingApi: true,
            modalOpen: false,
            mobileNumber: null,
            travels: []
        }
    },
    methods: {
        closeModal() {
            this.modalOpen = false;
        },
        goBack() {
            this.$router.go(-1)
        },
        viewDetails(travel) {
            const vm = this;
            vm.$refs.travelDetails.setTravelDetails(travel)
            vm.modalOpen = true
        },
        searchTravels() {
            const vm = this
            window.axios.get('/travels/search', {
                params: {
                    from: vm.$props.from,
                    to: vm.$props.to
                }
            }).then(resp => {
                vm.isCallingApi = false
                if (resp.data.response.status.error_code === 0) {
                    vm.travels = resp.data.response.data.travels
                    console.log(vm.travels)
                }
            })
        }

    },
    mounted() {

        const vm = this
        vm.mobileNumber = this.$route.params.mobileNumber
        vm.searchTravels()

    }

}

</script>

<style scoped>

label {
    margin: 0;
    padding: 0;
}

</style>
