<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12" style="text-align: center; margin-top: 40%">
                <h5>Redirecting...</h5>
            </div>
        </div>
    </div>
</template>
<script>

export default {

    props: {
        amount: {
            type: String,
            required: true,
        },
        orderId: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        email: {
            type: String,
            required: true,
        },
        mobileNumber: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: true,
        },
        typeOfService: {
            type: String,
            required: true,
        },
        bookingId: {
            type: String,
        },
        customerRequestId: {
            type: String,
        }
    },

    data() {
        return {
            script: "https://checkout.razorpay.com/v1/checkout.js",
            byPassPayment: true
        }
    },

    methods: {
        makePayment() {
            alert("Payment");
        },
        async loadRazorPay() {
            return new Promise(resolve => {
                const script = document.createElement('script')
                script.src = this.script
                script.onload = () => {
                    resolve(true)
                }
                script.onerror = () => {
                    resolve(false)
                }
                document.body.appendChild(script)
            })
        }
    },

    async created() {

        const vm = this

        console.log(vm.$props)

        if (!vm.$props.amount) {
            vm.$router.go(-1)
        }

        if (vm.byPassPayment) {
            vm.$router.replace({
                name: "payment-success", params: {
                    mobileNumber: vm.$props.mobileNumber,
                    typeOfService: vm.$props.typeOfService,
                    paymentId: "payment-bypass",
                    orderId: "payment-bypass",
                    signature: "payment-bypass",
                    bookingId: vm.$props.bookingId,
                    customerRequestId: vm.$props.customerRequestId,
                    amount: vm.amount
                }
            })
        } else {


            // vm.$router.replace({
            //     name: "payment-success", params: {
            //         mobileNumber: vm.$props.mobileNumber,
            //         typeOfService: vm.$props.typeOfService,
            //         paymentId: require('crypto').randomBytes(20).toString('hex'),
            //         orderId: require('crypto').randomBytes(20).toString('hex'),
            //         signature: require('crypto').randomBytes(20).toString('hex'),
            //         bookingId: vm.$props.bookingId,
            //         amount: vm.amount
            //     }
            // })

            const result = await this.loadRazorPay()
            if (!result) {
                alert('Failed to load razorpay script')
                return
            }

            const options = {
                key: process.env.VUE_APP_RAZORPAY_LIVE_ID,
                amount: vm.amount,
                currency: 'INR',
                name: 'TransAll',
                description: this.description,
                order_id: this.orderId,
                image: '',
                handler: function (response) {
                    console.log(response)
                    vm.$router.replace({
                        name: "payment-success", params: {
                            mobileNumber: vm.$props.mobileNumber,
                            typeOfService: vm.$props.typeOfService,
                            paymentId: response.razorpay_payment_id,
                            orderId: response.razorpay_order_id,
                            signature: response.razorpay_signature,
                            bookingId: vm.$props.bookingId,
                            customerRequestId: vm.$props.customerRequestId,
                            amount: vm.amount
                        }
                    })
                },
                modal: {
                    ondismiss: function () {
                        alert("You closed the payment page")
                        vm.$router.go(-1)
                    }
                },
                prefill: {
                    name: vm.$props.name,
                    email: vm.$props.email,
                    contact: vm.$props.mobileNumber
                }
            };
            const paymentObject = new window.Razorpay(options);
            paymentObject.open();

        }

    }

}

</script>
