<template>

    <div>

        <div class="container-fluid">

            <div class="row">
                <div class="col-sm-12">
                    <h4>Add/Select Branch Details</h4>
                    <hr>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <label>Select Branch</label>
                    <select class="custom-select form-control">
                        <option selected>Click here to select</option>
                        <option value="1">Ahmedabad 1</option>
                        <option value="2">Mehsana 2</option>
                        <option value="3">Gandhinagar 3</option>
                    </select>
                </div>
                <div class="col-sm-12">
                    <br>
                    <soft-button btn-type="submit" @click="addTour" full-width color="success" size="md"
                                 variant="gradient">
                        Confirm
                    </soft-button>
                    <hr>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12">
                    <label>Branch Name</label>
                    <soft-input v-model="form.fromPinCode"
                                placeholder=""></soft-input>
                </div>

                <div class="col-sm-12">
                    <label>GSTIN</label>
                    <soft-input v-model="form.fromPinCode"
                                placeholder=""></soft-input>
                </div>

                <div class="col-sm-12">
                    <label>Franchise Name</label>
                    <soft-input v-model="form.fromPinCode"
                                placeholder=""></soft-input>
                </div>

                <div class="col-sm-12">
                    <label>First Name</label>
                    <soft-input v-model="form.fromPinCode"
                                placeholder=""></soft-input>
                </div>

                <div class="col-sm-12">
                    <label>Last Name</label>
                    <soft-input v-model="form.fromPinCode"
                                placeholder=""></soft-input>
                </div>

                <div class="col-sm-12">
                    <label>Contact Number</label>
                    <soft-input v-model="form.fromPinCode"
                                placeholder=""></soft-input>
                </div>

                <div class="col-sm-12">
                    <label>Email</label>
                    <soft-input v-model="form.fromPinCode"
                                placeholder=""></soft-input>
                </div>

            </div>

            <div class="row">

                <label>Role of Person</label>
                <div class="col-sm-4">
                    <soft-radio v-model="form.acNonAc" value="AC-Seating" id="Non-AC-Seating">Owner
                    </soft-radio>
                </div>

                <div class="col-sm-4">
                    <soft-radio v-model="form.acNonAc" value="Non-AC-Seating" id="Non-AC-Seating"
                                name="Non-AC-Seating">
                        Manager
                    </soft-radio>
                </div>

                <div class="col-sm-4">
                    <soft-radio v-model="form.acNonAc" value="Non-AC-Sleeper" id="Non-AC-Sleeper"
                                name="Non-AC-Sleeper">
                        Assistant
                    </soft-radio>
                </div>

            </div>

            <div class="row">
                <div class="col-sm-6">
                    <br>
                    <soft-button btn-type="submit" @click="close()"
                                 full-width color="dark" size="md"
                                 variant="gradient">
                        Close
                    </soft-button>
                </div>

                <div class="col-sm-6">
                    <br>
                    <soft-button btn-type="submit" @click="addTour" full-width color="success" size="md"
                                 variant="gradient">
                        Add
                    </soft-button>
                </div>
            </div>

        </div>

    </div>

</template>
<script>

import SoftInput from "../SoftInput";
import SoftButton from "../SoftButton";
import SoftRadio from "../SoftRadio";

export default {

    components: {
        SoftInput,
        SoftButton,
        SoftRadio
    },
    props: {
        modal: {
            type: Object,
        }
    },
    data()
    {
        return {
            form: {
                fromPinCode: ''
            }
        }
    },
    methods: {
        close()
        {
            this.modal.$emit('close');
        },
    }

}

</script>
