function requestGeolocation() {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            handleGeolocation
            //, handleGeolocationError
        );
    } else {
        console.error('Geolocation is not supported by this browser');
        // Handle unsupported geolocation if needed
    }
}

function handleGeolocation(position) {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    console.log('User latitude = ' + latitude);
    console.log('User longitude = ' + longitude);
}

function watchGeolocationPosition() {
    const options = {
        enableHighAccuracy: false,
        timeout: 5000,
        maximumAge: 0,
    };
    navigator.geolocation.watchPosition(
        handleGeolocation,
        error,
        options
    );
}

function error(err) {
    console.error(JSON.stringify(err));
}
  

export default {
    requestGeolocation,
    watchGeolocationPosition
}