<template>
    <div class="home-header sticky-header bg-gray-100 shadow py-2">
        <div class="head-left">
            <div class="logo-box">
                <img height="45" width="45" :src="logo">
                <h3>TRANSALL
                    <span>Hi, {{$store.state.user.first_name}}</span>
                </h3>
            </div>
        </div>
        <div class="icon-col">
            <router-link :to="{name: 'notifications'}">
                <i class="fa fa-bell fa-2x"></i>
            </router-link>
        </div>
    </div>
</template>
<script>
import logo from '../../assets/img/TA_LOGO_2.png';

export default {
    data() {
        return {
            logo
        }
    }
}
</script>

<style lang="scss" scoped>
.home-header{
    justify-content: space-between;
    padding: 8px 15px;
    align-items: center;
    .head-left{
        .logo-box{
            display: flex;
            align-items: center;
            gap: 8px;
            h3{
                margin-bottom: 0;
                line-height: 1.1;
                font-size: 1.2rem;
                color: var(--bs-gray-900);
                span{
                    display: flex;
                    font-size: 0.9rem;
                    display: block;
                    font-weight: 600;
                    color: var(--bs-gray-900);
                    margin-top: 3px;
                }
            }
        }
    }
    .icon-col{
        .fa{
            font-size: 1.5rem;
            color: var(--bs-gray-900);
        } 
    }
}

</style>