<template>
    <div style="overflow-x: clip;">
        <div class="row sticky-top bg-gray-100 shadow" style="padding-top: 10px;">
            <div class="col-sm-12">
                <h3>
                    <button style="border: none; background: none;" @click="goBack"><i
                        class="p-2 fas fa-arrow-left"></i></button>
                    Drop Location
                </h3>
            </div>
        </div>


        <div class="row">
            <div class="col-12">
                <vue-google-autocomplete
                    ref="googleAutocomplete"
                    enable-geolocation
                    types=""
                    style="position:fixed; top:80px; right: 30px; left: 30px; padding: 5px; border-radius: 10px;"
                    id="map-to"
                    country="in"
                    :placeholder="getPlaceholderText"
                    v-on:placechanged="getAddressData">
                </vue-google-autocomplete>

            </div>
        </div>

        <div style="position: fixed; bottom: 0; right: 0; left: 0;">
            <soft-button @click="openLocationPicker" full-width size="lg" color="dark">Select From Map</soft-button>
        </div>

    </div>
</template>

<script>
import {ref, watch} from 'vue'
import VueGoogleAutocomplete from "vue-google-autocomplete"
import dropLocationClipArt from "../assets/img/drop_location_clipart.jpg"
import SoftButton from "../components/SoftButton";


export default {
    components: {
        SoftButton,
        VueGoogleAutocomplete,
    },

    data() {
        return {
            dropLocationClipArt: dropLocationClipArt,

            center: {lat: 23.0208366, lng: 72.5086508},
            markerPosition: null,
            autocompleteText: '',
            pinCode: '',
            city: '',
            locality: '',
            isFetchingLocation: false,
            address: null,
            isFetchingLocationCount: 0
        }
    },

    created() {
        let vm = this
        vm.isFetchingLocation = true
        navigator.geolocation.getCurrentPosition(
            position => {
                vm.isFetchingLocation = false
                console.log(position.coords.latitude);
                console.log(position.coords.longitude);
                vm.center = {lat: position.coords.latitude, lng: position.coords.longitude}
                vm.markerPosition = {
                    position: {lat: position.coords.latitude, lng: position.coords.longitude},
                }
                vm.reverseGeoencode()
            },
            error => {
                vm.isFetchingLocation = false
                console.log(error.message);
            },
        )
    },
    methods: {
        openLocationPicker(){
            this.$router.push({
                name: 'map-location-picker', params: {
                    locationType: 'select-drop-location'
                }
            })
        },
        reverseGeoencode() {
            const vm = this
            vm.isFetchingLocation = true
            vm.isFetchingLocationCount++
            window.axios.get('/google/reverse/geoencode', {
                params: {
                    lat_lng: vm.markerPosition.position.lat + ',' + vm.markerPosition.position.lng
                }
            }).then(resp => {
                vm.isFetchingLocation = false
                vm.$refs.googleAutocomplete.autocompleteText = ''
                vm.autocompleteText = resp.data.response.data.formatted_address
                vm.city = resp.data.response.data.city
                vm.state = resp.data.response.data.state
                vm.locality = resp.data.response.data.locality
                vm.pinCode = resp.data.response.data.pin_code
            }).catch(error => {
                vm.isFetchingLocation = false
                console.log(error)
            })
        },
        getAddressData: function (addressData) {
            this.address = addressData;
            console.log(addressData)

            this.city = addressData.administrative_area_level_2
            this.locality = addressData.locality
            this.pinCode = addressData.postal_code

            this.center = {lat: addressData.latitude, lng: addressData.longitude}

            this.markerPosition = null
        },
        placeMarker(e) {
            let vm = this
            if (vm.isFetchingLocation) {
                return
            }
            console.log(e.latLng.lat())
            vm.center.lat = e.latLng.lat()
            vm.center.lng = e.latLng.lng()
            vm.markerPosition = {
                position: {lat: e.latLng.lat(), lng: e.latLng.lng()},
            }
            vm.reverseGeoencode()
        },
        goBack() {
            this.$router.back()
        }
    },
    setup() {
        const mapRef = ref(null)

        // Third pattern: watch for "ready" then do something with the API or map instance
        watch(() => mapRef.value?.ready, (ready) => {

            //alert("map ready")

            if (!ready) return

            // do something with the api using `mapRef.value.api`
            // or with the map instance using `mapRef.value.map`
        })

        return {mapRef}
    },
    computed: {
        getPlaceholderText() {
            if (this.isFetchingLocation) {
                return 'Search Drop Location'
            } else if (this.autocompleteText != '') {
                if (this.isFetchingLocationCount > 1) {
                    return this.autocompleteText
                }
                return 'Search Drop Location'
            } else {
                return 'Type location name here...'
            }
        },
    }
}
</script>
