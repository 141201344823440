<template>
    <div class="container-fluid"
         style="background-color: #000000AA; z-index: 10000; display: flex; align-items: center;">

        <div class="fusion-dialog">

            <div class="row">

                <div class="col-12">

                    <div class="fusion-dialog-header">
                        <h5>Refer & Earn</h5>
                        <hr>
                    </div>

                    <div class="fusion-dialog-body">

                        <div class="fusion-dialog-icon">
                            <Vue3Lottie :animationData="animationJson" :height="200"></Vue3Lottie>
                        </div>

                        <div class="fusion-dialog-text">
                            <p>Now Refer & Earn, TransAll gives you <span style="font-size: 18px;"><b>₹50/-</b></span>
                                on referring your friend.</p>
                        </div>

                    </div>

                    <div class="fusion-dialog-footer">

                        <div class="row">
                            <div class="col-12">
                                <soft-checkbox @change="handleCheckEvent" id="dont-show" value="dont-show"
                                               name="dont-show">Dont show again
                                </soft-checkbox>
                            </div>
                            <div class="col-12" style="text-align: right;">
                                <soft-button @click="closeDialog" color="white" variant="gradient"
                                             style="margin-right: 10px;">Ok
                                </soft-button>
                                <soft-button color="primary" @click="positiveButtonClicked" variant="gradient">Refer
                                    Now
                                </soft-button>
                            </div>
                        </div>

                    </div>

                </div>

            </div>

        </div>

    </div>
</template>
<script>


import SoftButton from "./SoftButton";

import {Vue3Lottie} from 'vue3-lottie'

import animationJson from '../assets/anim/refer-and-earn.json'
import SoftCheckbox from "./SoftCheckbox";

export default {
    components: {SoftCheckbox, SoftButton, Vue3Lottie},

    data() {
        return {
            animationJson
        }
    },

    methods: {
        handleCheckEvent(e) {
            this.$emit('fusion-dialog-check-box-clicked', e.target.checked);
        },
        closeDialog() {
            this.$emit('close-refer-earn-dialog');
        },

        positiveButtonClicked() {
            this.$emit('fusion-dialog-positive-button-clicked');
        }

    }

}


</script>
<style>

.fusion-dialog {
    margin: 20px auto;
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    max-width: 350px;
}

.fusion-dialog-header {
    text-align: center;
}

</style>
