import axios from "axios";

class ApiService {
    getNotifications(mobileNumber) {
        return axios.get(`/user/get/notifications/${mobileNumber}`)
        .then((response) => response.data, (error) => error)
        .catch((error) => {
            return error;
        });
    }
    getRecentActivities(mobileNumber) {
        return axios.get(`/user/get/recent/activities/${mobileNumber}`)
        .then((response) => response.data)
        .catch((error) => error);
    }
}

export default new ApiService();