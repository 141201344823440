<template>

    <div style="overflow-x: clip;">
        <div class="row sticky-top bg-gray-100 shadow" style="padding-top: 10px;">
            <div class="col-sm-12">
                <h2>
                    <button style="border: none; background: none;" @click="goBack"><i
                        class="p-2 fas fa-arrow-left"></i></button>
                    Manage Rides
                </h2>
            </div>
            <div class="col-12">

                <div class="row text-center" style="margin: 5px;">
                    <div class="col-6">
                        <soft-button color="success" style="font-size: 10px"
                                     :variant="selectedTab==='ongoing'?'gradient':'outline'" full-width name="ongoing"
                                     id="ongoing" value="ongoing" @click="selectedTab='ongoing'">Active
                        </soft-button>
                    </div>
<!--                    <div class="col-4">-->
<!--                        <soft-button color="info" style="font-size: 8px"-->
<!--                                     :variant="selectedTab==='upcoming'?'gradient':'outline'" full-width name="upcoming"-->
<!--                                     id="upcoming" value="upcoming" @click="selectedTab='upcoming'">Up Coming-->
<!--                        </soft-button>-->
<!--                    </div>-->

                    <div class="col-6">
                        <soft-button color="warning" style="font-size: 10px"
                                     :variant="selectedTab==='completed'?'gradient':'outline'" full-width
                                     name="completed" id="completed" value="completed" @click="selectedTab='completed'">
                            Expired
                        </soft-button>
                    </div>
                </div>

            </div>
        </div>

        <div class="container-fluid">

            <template v-if="list.rides.length===0">
                <div class="row">
                    <div class="col-12" style="text-align: center; margin-top: 20%">

                        <p>You dont have any rides to mange<br>You can add new ride request by tapping on <b>"+"</b>
                            button bellow</p>

                    </div>
                </div>
            </template>

            <br>
            <div class="row">
                <div class="col-sm-12" v-for="(ride, index) in getFilteredRideList" :key="ride.id">
                    <div class="card" :id="index">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12">
                                    <label>Ride No.</label>
                                    <p>#{{ ride.ride_number }}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <div class="row">
                                        <div class="col-12">
                                            <label>From</label>
                                            <p>{{ ride.from }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="row">
                                        <div class="col-12" style="text-align: right;">
                                            <label>To</label>
                                            <p>{{ ride.to }}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-6">
                                    <label>{{
                                            (ride.date == null ? 'Daily' : convertDate(ride.date)) + " " + convertTime(ride.time)
                                        }}</label>
                                    <p>{{ stringToINR(ride.fair) }}
                                        <span v-if="ride.fair_per_km" style="font-size: 12px;"> ({{
                                                stringToINR(ride.fair_per_km)
                                            }} per km)</span></p>
                                </div>

                                <div class="col-6" style="text-align: right;">
                                    <label>Booking Requests</label>
                                    <p>{{ ride.booking_requests_count }}</p>
                                </div>


                            </div>

                            <div class="row">

                                <div class="col-6">
                                    <soft-button @click="deleteRide(ride.id)" size="sm" full-width color="danger"
                                                 style="float: right;">Delete
                                    </soft-button>
                                </div>

                                <div class="col-6">
                                    <soft-button size="sm" full-width color="dark"
                                                 @click="viewBookingRequests(ride.id)"
                                                 style="float: right;">View Requests
                                    </soft-button>

                                </div>

                            </div>

                        </div>
                    </div>
                    <br>
                </div>
            </div>
            <br>

            <soft-button @click="addNewRide" style="position: fixed; bottom: 20px; right: 20px; padding: 5px 20px;"
                         class="text-2xl">+
            </soft-button>

        </div>

        <Modal
            ref="modal"
            v-show="modalOpen"
            @close="closeModal">
            <template v-slot:body>
                <ViewBookingRequests ref="bookingRequestsView" :modal="$refs.modal" :mobile-number="mobileNumber"
                                     view-for="ride"></ViewBookingRequests>
            </template>
        </Modal>

    </div>

</template>

<script>


import SoftButton from "../../components/SoftButton";
import swal from "sweetalert";
import Modal from "../../components/Modal";
import ViewBookingRequests from "../../components/modals/ViewBookingRequests";

export default {
    components: {ViewBookingRequests, Modal, SoftButton},
    data() {
        return {
            modalOpen: false,

            mobileNumber: null,

            list: {
                rides: [],
            },

            selectedTab: 'ongoing',

        }
    },
    methods: {
        addNewRide() {
            this.$router.replace({name: 'publish-ride'})
        },
        viewBookingRequests(id) {
            const vm = this;
            vm.$refs.bookingRequestsView.setDetails(id)
            vm.modalOpen = true
        },
        closeModal() {
            this.modalOpen = false;
        },
        getRideStatus(status) {
            switch (status) {
                case 0:
                    return 'Pending'
                case 1:
                    return 'Booking Requested'
                case 2:
                    return 'Booking Confirmed'
            }
        },
        startRide(rideId) {
            const vm = this
            swal("Enter Ride PIN", {
                content: "input",
            })
                .then((value) => {
                    window.axios.post('ride/start/' + rideId, {
                        ride_pin: value
                    }).then(resp => {
                        if (resp.data.response.status.error_code === 0) {
                            vm.$toast.open({
                                message: resp.data.response.status.msg,
                                type: 'success',
                                position: 'top',
                                duration: 5000,
                                dismissible: false,
                            })
                        } else {
                            vm.$toast.open({
                                message: resp.data.response.status.error_msg,
                                type: 'error',
                                position: 'top',
                                duration: 2000,
                                dismissible: false,
                            })
                        }
                    })
                });
        },
        deleteRide(rideId) {
            let vm = this
            window.axios.delete(`ride/delete/${rideId}`, {
                params: {
                    mobile_number: vm.mobileNumber,
                }
            }).then(response => {
                if (response.data.response.status.error_code == 0) {
                    vm.getRides()
                }
            })
        },
        goBack() {
            window.ReactNativeWebView.postMessage("close-screen")
        },
        getRides() {
            let vm = this
            window.axios.get('ride/manage', {
                params: {
                    mobile_number: vm.mobileNumber,
                }
            }).then(response => {
                if (response.data.response.status.error_code == 0) {
                    vm.list.rides = []
                    vm.list.rides = response.data.response.data.rides
                }
            })
        },
        checkUserServices() {
            const vm = this
            window.axios.get('user/get/services/offered/' + vm.mobileNumber)
                .then(resp => {
                    if (resp.data.response.status.error_code === 0) {
                        const user = resp.data.response.data.user
                        if (user.register_for_ride === 0) {
                            swal({
                                title: "Not Registered",
                                text: "You are not registered for providing this service, Please register first.",
                                icon: "error",
                            }).then(() => {
                                vm.$router.replace({
                                    name: 'ride-register-individual',
                                    params: {
                                        mobileNumber: vm.mobileNumber
                                    }
                                })
                            });
                        } else {
                            if (user.is_verified_by_admin === 0) {
                                swal({
                                    title: "Pending KYC",
                                    text: "You KYC is still in process and pending approval, Once done you would be able to use this service",
                                    icon: "error",
                                }).then(() => {
                                    vm.$router.replace({
                                        name: 'ride-register-individual',
                                        params: {
                                            mobileNumber: vm.mobileNumber
                                        }
                                    })
                                });
                            } else {
                                vm.getRides()
                            }
                        }
                    }
                })
        }
    },
    computed: {

        getFilteredRideList() {
            if (this.selectedTab === 'ongoing') {
                return this.list.rides.filter(function (ride) {
                    return ride.date==null
                })
            } else if (this.selectedTab === 'upcoming') {
                return this.list.rides
            } else if (this.selectedTab === 'completed') {
                return this.list.rides.filter(function (ride) {
                    return ride.expired
                })
            } else {
                return this.list.rides
            }
        }

    },
    created() {
        let vm = this
        vm.mobileNumber = this.$route.params.mobileNumber
        vm.checkUserServices()
    }
}

</script>

<style scoped>

label {
    margin: 0;
    padding: 0;
}

p {
    font-size: 14px;
}

</style>
