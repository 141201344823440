<template>
    <div>

        <div class="row">

            <div class="col-12 text-center">
                <span class="text-bold text-2xl">{{ tour.title }}</span>
                <br>
            </div>

            <hr>

            <div class="col-12">
                <label>Type</label>
                <p class="text-bold">{{ getTourType(tour) }}</p>
                <template v-if="tour.description">
                    <label>Description</label>
                    <p>{{ tour.description }}</p>
                </template>
                <label>Max Persons Allowed</label>
                <p>{{ tour.max_persons }}</p>
            </div>

            <div class="col-12">
                <label>Places you will visit</label>
                <br>
                <template v-for="(place,i) in getJSON(tour.other_places)" v-bind:key="i">
                    <span>{{ place.selectedPlace }}</span>
                    <span v-if="getJSON(tour.other_places).length-2>i">, </span>
                    <span v-if="getJSON(tour.other_places).length-2===i"> & </span>
                </template>
            </div>

            <div class="col-12">
                <br>
                <table style="width: 100%;">
                    <tr>
                        <th>
                            <label>Tour dates</label>
                        </th>
                        <th>

                        </th>
                    </tr>
                    <template v-for="(date,i) in getJSON(tour.other_dates)" v-bind:key="i">
                        <tr>
                            <td>
                                {{ convertDate(date.selectedDate) }}
                            </td>
                            <td style="text-align: right;">
                                <soft-button color="success" size="sm" style="margin-top: 5px; margin-bottom: 5px"
                                             @click="showBookingDialog($event,date.selectedDate)">BOOK NOW
                                </soft-button>
                            </td>
                        </tr>
                    </template>
                </table>
            </div>

            <div class="row" v-if="tour.tour_pdf_file_url!==''">
                <div class="col-6">
                    <br>
                    <span class="btn btn-warning" @click="openLink(tour.tour_pdf_file_url)">
                        <img class="img-fluid" height="30" width="30"
                             src="https://icones.pro/wp-content/uploads/2021/03/icone-pdf-symbole-png-rouge.png">
                        <a :href="tour.tour_pdf_file_url" target="_blank"><br>Download</a></span>
                </div>
                <div class="col-6" style="text-align: right">
                    <br>
                    <span class="text-bold text-2xl">{{ stringToINR(tour.package_price) }}</span>
                    <p class="text-xs">*per person</p>
                </div>
            </div>


            <div class="col-12">
                <soft-button full-width size="lg" color="dark" @click="close">CLOSE</soft-button>
            </div>
        </div>

    </div>
</template>

<script>

import SoftButton from "../SoftButton";
import swal from "sweetalert";

export default {
    components: {SoftButton},
    props: {

        modal: {
            type: Object
        },

        mobileNumber: {
            type: String,
            required: true
        }

    },

    data() {
        return {

            tour: {
                title: '',
                days: '',
                type: '',
                max_persons: '',
                description: '',
                tour_pdf_file_url: '',
                package_price: '',
                other_places: [],
                other_dates: [],
            },

        }
    },

    methods: {
        openLink(url) {
            window.location.href = url
        },
        getJSON(data) {
            try {
                return JSON.parse(data)
            } catch {
                return []
            }
        },
        setTourDetails(tour) {
            console.log(tour)
            this.tour = tour
        },
        close() {
            this.modal.$emit('close');
        },
        getTourType(tour) {

            let tourType = ''
            let count = 0

            if (tour.adventure === 1) {
                count++
                tourType += 'Adventure'
            }

            if (tour.honeymoon === 1) {
                if (count > 0) {
                    tourType = tourType + ' | '
                }
                count++
                tourType += 'Honeymoon'
            }

            if (tour.spiritual === 1) {
                if (count > 0) {
                    tourType = tourType + ' | '
                }
                count++
                tourType += 'Spiritual'
            }

            if (tour.family === 1) {
                if (count > 0) {
                    tourType = tourType + ' | '
                }
                count++
                tourType += 'Family'
            }

            if (tour.picnic === 1) {
                if (count > 0) {
                    tourType = tourType + ' | '
                }
                count++
                tourType += 'Picnic'
            }


            return tourType

        },
        showBookingDialog(event, selectedDate) {
            const vm = this;
            const button = event.target

            const formattedDate = vm.convertDate(selectedDate)

            swal(`Book For ${formattedDate}. Enter Number of Persons`, {
                content: "input",
            })
                .then((value) => {

                    if (!value) {
                        return
                    }

                    try {
                        value = parseInt(value)
                    } catch {
                        return
                    }

                    if (value < 1 || isNaN(value)) {
                        return
                    }

                    button.disabled = true

                    vm.modalOpen = true
                    swal({
                        title: "Book this tour?",
                        text: "On booking, this tour company will receive a notification and they may approve your request",
                        icon: "info",
                        buttons: ['Cancel', 'Send Request'],
                        dangerMode: false,
                    }).then((book) => {
                        if (book) {
                            window.axios.post('/tour/request/booking/' + vm.$props.mobileNumber + '/' + vm.tour.id, {
                                no_of_persons: value,
                                selected_date: selectedDate
                            }).then(resp => {
                                console.log(resp)
                                button.disabled = false

                                if (resp.data.response.status.error_code === 0) {
                                    vm.$toast.open({
                                        message: 'Booking Request Sent',
                                        type: 'success',
                                        position: 'top',
                                        duration: 5000,
                                        dismissible: false,
                                    })
                                }
                            }).catch(e => {
                                console.log(e)
                                button.disabled = false

                            })
                        } else {
                            button.disabled = false
                        }
                    }).catch(e => {
                        console.log(e)
                        button.disabled = false
                    })

                })


        },
    }

}

</script>

<style scoped>

label {
    margin: 0;
    padding: 0;
}

</style>
