export default {


    methods: {

        convertDate(date) {
            if(date===null){
                return 'N/A'
            }if(date===''){
                return 'N/A'
            }
            return window.moment(date).format('Do MMM YYYY')
        },

        convertTime(time) {
            if(time===null){
                return ''
            }if(time===''){
                return ''
            }
            return window.moment(time, ["HH:mm"]).format('h:mm a')
        },
        getUserTimeZone() {
            return Intl.DateTimeFormat().resolvedOptions().timeZone;
        },
        convertUtcToLocal(utcTimestamp) {
            // Append 'Z' to the timestamp to interpret it as UTC
            const date = new Date(`${utcTimestamp}Z`);
            const userTimeZone = this.getUserTimeZone();
            return date.toLocaleString('en-US', { timeZone: userTimeZone });
        },
        covertTimeStamp(timestamp) {
            return window.moment(this.convertUtcToLocal(timestamp)).local().format('Do MMM YYYY, h:mm a')
        },
        stringToINR(x) {
            if(x===null) return 'N/A'
            let val = x.toString().split('.')[0].length > 3 ? x.toString().substring(0, x.toString().split('.')[0].length - 3).replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + x.toString().substring(x.toString().split('.')[0].length - 3) : x.toString()
            return '₹' + val + '/-'
        },
        stringToComma(x) {
            return x.toString().split('.')[0].length > 3 ? x.toString().substring(0, x.toString().split('.')[0].length - 3).replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + x.toString().substring(x.toString().split('.')[0].length - 3) : x.toString()
        }


    }


}
