<template>
    <div style="overflow-x: clip;">
        <div class="row sticky-top bg-gray-100 shadow" style="padding-top: 10px;">
            <div class="col-sm-12">
                <h3>
                    <button style="border: none; background: none;" @click="goBack"><i
                        class="p-2 fas fa-arrow-left"></i></button>
                    Manage Vehicles
                </h3>
            </div>
        </div>
        <div class="container-fluid">
            <br>
            <div class="row">
                <template v-if="vehicles.length===0">
                    <div class="col-12 text-center">
                        <br>
                        <br>
                        <br>
                        <br>
                        <br>
                        <p>No vehicles found, You can add new vehicle by tapping <br> <span
                            class="text-2xl"><b>+</b></span> <br>button at the bottom.</p>
                    </div>
                </template>
                <template v-for="vehicle in vehicles" :key="vehicle.id">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">

                                <div class="row">
                                    <div class="col-4">
                                        <img class="img-fluid"
                                             style="border-radius: 5px; aspect-ratio: 1; object-fit: cover; height: 100%; width: 100%;"
                                             :src="vehicle.front_image">
                                    </div>
                                    <div class="col-8">
                                        <div class="text-xs text-bold">Type</div>
                                        <div class="text-xs">
                                            {{ vehicle.vehicle_type }}
                                        </div>
                                        <br>
                                        <div class="text-xs text-bold">Model</div>
                                        <div class="text-xs">
                                            {{ vehicle.model_name }}
                                        </div>
                                        <br>
                                        <div class="text-xs text-bold">Vehicle Number
                                        </div>
                                        <div class="text-xs">
                                            {{ vehicle.registered_number.toUpperCase() }}
                                        </div>

                                    </div>
                                </div>

                                <div class="row mt-2">
                                    <div class="col-6">
                                        <soft-button full-width color="dark" size="sm"
                                                     @click="showVehicleDetails(vehicle)">Edit
                                        </soft-button>
                                    </div>
                                    <div class="col-6">
                                        <template v-if="vehicle.is_active">
                                            <soft-button @click="vehicle.is_active=false" full-width color="success"
                                                         size="sm">Active
                                            </soft-button>
                                        </template>
                                        <template v-else>
                                            <soft-button full-width color="dark" size="sm"
                                                         @click="setAsActiveVehicle(vehicle)">Set as Active
                                            </soft-button>
                                        </template>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <br>
                    </div>
                </template>
            </div>
            <soft-button @click="addVehicle" style="position: fixed; bottom: 20px; right: 20px; padding: 5px 20px;"
                         class="text-2xl">+
            </soft-button>

        </div>


        <Modal
            ref="modal"
            v-show="addingVehicle"
            @close="closeModal">
            <template v-slot:body>
                <AddVehicle ref="vehicleModal" :modal="$refs.modal" :mobile-number="mobileNumber"
                            :is="addVehicleComponent"></AddVehicle>
            </template>
        </Modal>


    </div>
</template>
<script>

import Modal from "../../components/Modal"
import SoftButton from "../../components/SoftButton";
import AddVehicle from "../../components/modals/AddVehicle"

export default {
    components: {SoftButton, AddVehicle, Modal},
    data() {
        return {
            addingVehicle: false,

            addVehicleComponent: AddVehicle,
            mobileNumber: null,
            vehicles: []
        }
    },
    methods: {
        goBack() {
            //this.$router.go(-1)
            window.ReactNativeWebView.postMessage("close-screen")
        },
        addVehicle() {
            this.addingVehicle = true
        },
        closeModal() {
            this.$refs.vehicleModal.setVehicleData(null)
            this.addingVehicle = false;
            this.getVehicleList()
        },
        showVehicleDetails(vehicle) {
            this.$refs.vehicleModal.setVehicleData(vehicle)
            this.addVehicle()
        },
        getVehicleList() {
            const vm = this
            window.axios.get('/vehicle/' + vm.mobileNumber + '/list')
                .then(resp => {
                    if (resp.data.response.status.error_code == 0) {
                        vm.vehicles = resp.data.response.data.vehicles
                    }
                })
        },
        setAsActiveVehicle(vehicle) {
            const vm = this
            window.axios.put('/vehicle/' + vm.mobileNumber + '/set/active/' + vehicle.id)
                .then(resp => {
                    vm.vehicles?.map(vehicle => vehicle.is_active = false)
                    if (resp.data.response.status.error_code == 0) {
                        vehicle.is_active = true
                    }
                })
        }
    },
    mounted() {
        this.mobileNumber = this.$route.params.mobileNumber
        this.getVehicleList()
    }
}

</script>
