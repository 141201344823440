<template>

    <div>

        <div class="row sticky-top shadow bg-gray-100">
            <div class="col-sm-12">
                <h2>
                    <button style="border: none; background: none;" @click="close"><i
                        class="p-2 fas fa-arrow-left"></i></button>
                    Booking Requests
                </h2>
            </div>
        </div>

        <br>

        <div class="row">

            <template v-if="bookingRequests.length>0">

                <div class="col-12" v-for="(request, index) in bookingRequests" :key="request.id">
                    <div class="card" :id="index">
                        <div class="card-body">
                            <h5>{{ request.user.first_name + " " + request.user.last_name }}</h5>

                            <template
                                v-if="$props.viewFor==='spv'|| $props.viewFor==='tours'||$props.viewFor==='travels'">
                                <div class="col-12" v-if="request.status===2">
                                    <label>Contact</label>
                                    <p>{{ request.user.mobile_number }}</p>
                                </div>
                            </template>


                            <template v-if="$props.viewFor==='parcel'">
                                <label>Parcel Type</label>
                                <p>{{ request.is_material === 1 ? 'Material' : 'Document' }}</p>
                            </template>
                            <template
                                v-if="$props.viewFor==='ride'|| $props.viewFor==='tours'||$props.viewFor==='travels'">
                                <div class="row">
                                    <div class="col-6">
                                        <label>No of Persons</label>
                                        <p>{{
                                                request.no_of_persons ? request.no_of_persons : request.no_of_passengers
                                            }}</p>
                                    </div>
                                    <template v-if="$props.viewFor==='tours'">
                                        <div class="col-6" style="text-align: right">
                                            <label>Date</label>
                                            <p>{{ convertDate(request.selected_date) }}</p>
                                        </div>
                                    </template>
                                </div>
                            </template>

                            <div class="row" v-if="$props.viewFor==='tours'||$props.viewFor==='travels'">

                                <div class="col-4">
                                    <label>Price</label>
                                    <p>{{ stringToINR(price) }} x {{ request.no_of_persons }}</p>
                                </div>

                                <div class="col-4">
                                    <label>Conv Fees</label>
                                    <p>7%</p>
                                </div>

                                <div class="col-4" style="text-align: right;">
                                    <label>You Get</label>
                                    <p>{{ stringToINR((request.publisher_amount / 100)) }}</p>
                                </div>

                                <div class="col-12">
                                    <br>
                                </div>

                                <div class="col-6" v-if="request.status!==2">
                                    <soft-button full-width color="danger" @click="rejectRequest(request.id)">Reject
                                    </soft-button>
                                </div>
                                <div class="col-6" v-if="request.status===0">
                                    <soft-button full-width color="success" @click="approveRequest(request.id)">
                                        Accept
                                    </soft-button>
                                </div>
                                <div class="col-6" style="text-align: center;" v-if="request.status===1">
                                    <label class="text-sm text-bold">APPROVED</label>
                                    <p class="text-xs">(Payment Pending)</p>
                                </div>
                                <div class="col-12" style="text-align: center;" v-if="request.status===2">
                                    <hr>
                                    <label class="text-sm text-bold">BOOKING CONFIRMED</label>
                                    <p class="text-xs">({{ stringToINR((request.publisher_amount / 100)) }}
                                        Received)</p>
                                </div>
                            </div>

                            <div class="row" v-else-if="$props.viewFor==='spv'">

                                <div class="col-12">
                                    <label>Required at Location</label>
                                    <p>{{ request.address }}</p>
                                </div>

                                <div class="col-4">
                                    <label>Rent per Day</label>
                                    <p>{{ stringToINR(price) }} x {{ request.no_of_days }} Day</p>
                                </div>

                                <div class="col-4">
                                    <label>Conv Fees</label>
                                    <p>7%</p>
                                </div>

                                <div class="col-4" style="text-align: right;">
                                    <label>You Get</label>
                                    <p>{{ stringToINR((request.service_provider_amount / 100)) }}</p>
                                </div>

                                <div class="col-12">
                                    <br>
                                </div>

                                <div class="col-6" v-if="request.status!=2">
                                    <soft-button full-width color="danger" @click="rejectRequest(request.id)">Reject
                                    </soft-button>
                                </div>
                                <div class="col-6" v-if="request.status==0">
                                    <soft-button full-width color="success" @click="approveRequest(request.id)">
                                        Accept
                                    </soft-button>
                                </div>
                                <div class="col-6" style="text-align: center;" v-if="request.status==1">
                                    <label class="text-sm text-bold">APPROVED</label>
                                    <p class="text-xs">(Payment Pending)</p>
                                </div>
                                <div class="col-12" v-if="request.status==2">
                                    <label>Contact</label>
                                    <p>{{request.user.mobile_number}}</p>
                                </div>
                                <div class="col-12" style="text-align: center;" v-if="request.status==2">
                                    <hr>
                                    <label class="text-sm text-bold">BOOKING CONFIRMED</label>
                                    <p class="text-xs">({{ stringToINR((request.service_provider_amount / 100)) }}
                                        Received)</p>
                                </div>
                            </div>
                            <div v-else>
                                <div class="col-12">
                                    <br>
                                    <soft-button full-width color="dark" @click="viewDetails(request.id)">
                                        View Details
                                    </soft-button>
                                </div>
                            </div>

                        </div>
                    </div>
                    <br>
                </div>
            </template>

            <template v-else>
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <p>No booking requests yet...</p>
                        </div>
                    </div>
                    <br>
                </div>
            </template>

            <div class="col-12">
                <soft-button full-width size="lg" color="dark" @click="close">CLOSE</soft-button>
                <br>
                <br>
            </div>

        </div>

    </div>

</template>

<script>

import swal from "sweetalert";
import SoftButton from "../SoftButton";

export default {
    components: {SoftButton},
    props: {

        modal: {
            type: Object
        },

        mobileNumber: {
            type: String,
            required: true
        },

        viewFor: {
            type: String,
            required: true
        }

    },

    data() {
        return {

            entityId: null,
            price: 0,

            bookingRequests: []

        }
    },

    methods: {
        rejectRequest(id) {
            const vm = this
            if (vm.$props.viewFor === 'travels') {
                window.axios.put('travels/reject/request/' + id)
                    .then(resp => {
                        if (resp.data.response.status.error_code === 0) {
                            vm.setDetails(vm.entityId)
                            swal({
                                title: resp.data.response.status.msg,
                                icon: "info",
                                dangerMode: false,
                            })
                        } else {
                            swal({
                                title: resp.data.response.status.error_msg,
                                icon: "warning",
                                dangerMode: false,
                            })
                        }
                    })
            } else if (vm.$props.viewFor === 'tours') {
                window.axios.put('tour/reject/request/' + id)
                    .then(resp => {
                        if (resp.data.response.status.error_code === 0) {
                            vm.setDetails(vm.entityId)
                            swal({
                                title: resp.data.response.status.msg,
                                icon: "info",
                                dangerMode: false,
                            })
                        } else {
                            swal({
                                title: resp.data.response.status.error_msg,
                                icon: "warning",
                                dangerMode: false,
                            })
                        }
                    })
            }else if (vm.$props.viewFor === 'spv') {
                window.axios.put('spv/reject/request/' + id)
                    .then(resp => {
                        if (resp.data.response.status.error_code === 0) {
                            vm.setDetails(vm.entityId)
                            swal({
                                title: resp.data.response.status.msg,
                                icon: "info",
                                dangerMode: false,
                            })
                        } else {
                            swal({
                                title: resp.data.response.status.error_msg,
                                icon: "warning",
                                dangerMode: false,
                            })
                        }
                    })
            }
        },
        approveRequest(id) {
            const vm = this
            let viewType = null
            if (vm.$props.viewFor === 'travels') {
                viewType = 'travels'
            } else if (vm.$props.viewFor === 'tours') {
                viewType = 'tour'
            } else if (vm.$props.viewFor === 'ride') {
                viewType = 'ride'
            } else if (vm.$props.viewFor === 'parcel') {
                viewType = 'parcel'
            } else if (vm.$props.viewFor === 'spv') {
                viewType = 'spv'
            }
            window.axios.put(viewType + '/approve/request/' + id)
                .then(resp => {
                    if (resp.data.response.status.error_code === 0) {
                        vm.setDetails(vm.entityId)
                        swal({
                            title: resp.data.response.status.msg,
                            icon: "info",
                            dangerMode: false,
                        })
                    } else {
                        swal({
                            title: resp.data.response.status.error_msg,
                            icon: "warning",
                            dangerMode: false,
                        })
                    }
                })
        },
        setDetails(id) {
            const vm = this
            let viewType = null
            if (vm.$props.viewFor === 'travels') {
                viewType = 'travels'
            } else if (vm.$props.viewFor === 'tours') {
                viewType = 'tour'
            } else if (vm.$props.viewFor === 'ride') {
                viewType = 'ride'
            } else if (vm.$props.viewFor === 'parcel') {
                viewType = 'parcel'
            } else if (vm.$props.viewFor === 'spv') {
                viewType = 'spv'
            }
            vm.entityId = id
            window.axios.get('/' + viewType + '/get/booking/requests/' + vm.$props.mobileNumber + '/' + id)
                .then(resp => {
                    if (resp.data.response.status.error_code === 0) {
                        vm.price = resp.data.response.data.booking_requests[0].price_per_person ?
                            resp.data.response.data.booking_requests[0].price_per_person :
                            vm.price = resp.data.response.data.booking_requests[0].package_price
                        if (viewType === 'spv') {
                            vm.price = resp.data.response.data.booking_requests[0].rent_per_day
                        }
                        vm.bookingRequests = resp.data.response.data.booking_requests[0].booking_requests
                    }
                })
        },
        close() {
            this.bookingRequests = []
            this.modal.$emit('close');
        },
        showBookingDialog() {
            const vm = this;
            vm.modalOpen = true
            swal({
                title: "Book this travels?",
                text: "On booking, this travels company will receive a notification and they may approve your request",
                icon: "info",
                buttons: ['Cancel', 'Send Request'],
                dangerMode: false,
            }).then((book) => {
                if (book) {
                    window.axios.post('/travels/request/booking/' + vm.$props.mobileNumber + '/' + vm.travel.id, {
                        no_of_persons: 5
                    })
                        .then(resp => {
                            console.log(resp)
                            if (resp.data.response.status.error_code === 0) {
                                vm.$toast.open({
                                    message: 'Booking Request Sent',
                                    type: 'success',
                                    position: 'top',
                                    duration: 5000,
                                    dismissible: false,
                                })
                            }
                        })
                }
            });
        },
        viewDetails(bookingId) {
            const vm = this
            if (vm.$props.viewFor === 'parcel') {
                vm.$router.push({
                    name: 'handle-parcel-notification', params: {
                        notificationType: 'PARCEL_BOOKING_REQUEST',
                        bookingId: bookingId
                    }
                })
            } else if (vm.$props.viewFor === 'ride') {
                vm.$router.push({
                    name: 'handle-ride-notification', params: {
                        notificationType: 'RIDE_BOOKING_REQUEST',
                        bookingId: bookingId
                    }
                })
            }

        }
    }

}

</script>

<style scoped>

label {
    margin: 0;
    padding: 0;
}

p {
    font-size: 14px;
    margin: 0;
    padding: 0;
}

</style>
