<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 text-center" style="margin-top: 25%">
                <img class="img-fluid" :src="kycApprovedImage">
                <br>
                <br>
                <h5>Congratulations!</h5>
                <p>
                    KYC Verified and Approved by TransAll.
                </p>
            </div>
        </div>
    </div>
</template>
<script>

import kycApprovedImage from '@/assets/img/kyc_approved.png'

export default {

    data() {
        return {
            kycApprovedImage
        }
    },

}

</script>
