<template>
    <div style="overflow-x: clip;">
        <div class="row sticky-top bg-gray-100 shadow" style="padding-top: 10px;">
            <div class="col-sm-12">
                <h3>
                    <button style="border: none; background: none;" @click="goBack"><i
                        class="p-2 fas fa-arrow-left"></i></button>
                    Transactions
                </h3>
            </div>
        </div>
        <div class="container-fluid">
            <br>
            <div class="row">
                <template v-for="transaction in transactions" v-bind:key="transaction.id">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">

                                    <div class="col-12">
                                        <label>Date</label>
                                        <p>{{ covertTimeStamp(transaction.created_at) }}</p>
                                    </div>

                                    <div class="col-6">
                                        <label>Service Type</label>
                                        <p>{{ transaction.type_of_service }}</p>
                                    </div>

                                    <div class="col-6">
                                        <label>Amount</label>
                                        <p>{{ stringToINR(parseInt(transaction.amount)/100) }}</p>
                                    </div>

                                    <div class="col-6">
                                        <label>Payment ID</label>
                                        <p>{{ transaction.payment_id }}</p>
                                    </div>

                                    <div class="col-6">
                                        <label>Order ID</label>
                                        <p>{{ transaction.order_id }}</p>
                                    </div>


                                </div>
                            </div>
                        </div>
                        <br>
                    </div>
                </template>
            </div>

        </div>

    </div>
</template>
<script>


export default {
    components: {},
    data() {
        return {
            mobileNumber: null,

            transactions: [],

        }
    },
    methods: {
        goBack() {
            this.$router.go(-1)
        },
        getUserTransactions() {
            const vm = this
            window.axios.get('/user/transaction/history/' + vm.mobileNumber)
                .then(resp => {
                    if (resp.data.response.status.error_code === 0) {
                        vm.transactions = resp.data.response.data.transactions
                    }
                })
                .catch(e => console.log(e))
        },
    },
    mounted() {
        this.mobileNumber = this.$route.params.mobileNumber
        this.getUserTransactions()
    }
}

</script>

<style scoped>

label {
    margin: 0;
    padding: 0;
}

</style>