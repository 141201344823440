<template>

    <div style="overflow-x: clip;">
        <div class="row sticky-top bg-gray-100 shadow" style="padding-top: 10px;">
            <div class="col-sm-12">
                <h2>
                    <button style="border: none; background: none;" @click="goBack"><i
                        class="p-2 fas fa-arrow-left"></i></button>
                    Search Results
                </h2>
            </div>
        </div>

        <Vue3Lottie v-if="isCallingApi" :animationData="animationJson" style="position: fixed; bottom: 0;"></Vue3Lottie>

        <div class="container-fluid">
            <br>
            <div class="row">
                <div class="col-sm-12" v-for="(tour, index) in tours" :key="tour.id">
                    <div class="card" :id="index">
                        <div class="card-body">
                            <h5>{{ tour.title }}</h5>
                            <hr>
                            <label>{{ getTourType(tour) }}</label>
                            <p></p>
                            <p>Max {{ tour.max_persons }} Persons</p>

                            <div class="row">
                                <div class="col-6">
                                    <label>Price Per Person</label>
                                    <p>{{ stringToINR(tour.package_price) }}</p>
                                </div>
                                <div class="col-6">
                                    <p></p>
                                    <div style="text-align: end; font-size: 18px; font-weight: bold;">
                                        <SoftButton :ref="'tour-'+tour.id" @click="viewDetails(tour)"
                                                    color="dark"
                                                    size="sm">View Details
                                        </SoftButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                </div>
            </div>
            <br>
        </div>

        <Modal
            ref="modal"
            v-show="modalOpen"
            @close="closeModal">
            <template v-slot:body>
                <TourDetails ref="tourDetails" :modal="$refs.modal" :mobile-number="mobileNumber"></TourDetails>
            </template>
        </Modal>

    </div>

</template>

<script>


import SoftButton from "../../components/SoftButton";
import Modal from "../../components/Modal";
import TourDetails from "../../components/modals/TourDetails";

import {Vue3Lottie} from 'vue3-lottie'
import animationJson from "../../assets/anim/tours-anim.json"

export default {
    components: {TourDetails, Modal, SoftButton,Vue3Lottie},
    props: {

        fromPlace: {
            type: String,
            require: true,
        }

    },
    data() {
        return {
            animationJson,

            isCallingApi:true,

            modalOpen: false,

            mobileNumber: null,


            tours: [],

        }
    },
    methods: {
        closeModal() {
            this.modalOpen = false;
        },
        goBack() {
            this.$router.go(-1)
        },
        viewDetails(tour) {
            const vm = this;
            vm.$refs.tourDetails.setTourDetails(tour)
            vm.modalOpen = true
        },
        searchTours() {
            const vm = this

            window.axios.get('/tour/search', {
                params: {
                    keyword: vm.$props.fromPlace
                }
            })
                .then(resp => {
                    vm.isCallingApi=false
                    if (resp.data.response.status.error_code === 0) {
                        vm.tours = resp.data.response.data.tours
                    }
                })
                .catch(e => {
                    vm.isCallingApi=false
                    console.log(e)
                })

        },
        getTourType(tour) {

            let tourType = ''
            let count = 0

            if (tour.adventure === 1) {
                count++
                tourType += 'Adventure'
            }

            if (tour.honeymoon === 1) {
                if (count > 0) {
                    tourType = tourType + ' | '
                }
                count++
                tourType += 'Honeymoon'
            }

            if (tour.spiritual === 1) {
                if (count > 0) {
                    tourType = tourType + ' | '
                }
                count++
                tourType += 'Spiritual'
            }

            if (tour.family === 1) {
                if (count > 0) {
                    tourType = tourType + ' | '
                }
                count++
                tourType += 'Family'
            }

            if (tour.picnic === 1) {
                if (count > 0) {
                    tourType = tourType + ' | '
                }
                count++
                tourType += 'Picnic'
            }


            return tourType

        },
    },
    created() {
        let vm = this
        vm.mobileNumber = this.$route.params.mobileNumber
        setTimeout(()=>{
            vm.searchTours()
        },2000)
    }
}

</script>

<style scoped>

label {
    margin: 0;
    padding: 0;
}

</style>
