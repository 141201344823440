<template>

    <div>

    </div>

</template>
<script>

export default {

    props: {
        webCheckoutURL:{
            type: String,
            required: true,
        },
        amount: {
            type: String,
            required: true,
        },
        orderId: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        email: {
            type: String,
            required: true,
        },
        mobileNumber: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: true,
        },
        typeOfService: {
            type: String,
            required: true,
        },
        bookingId: {
            type: String,
        }
    },

    methods: {
        makePayment() {
            alert("Payment");
        },
        async loadRazorPay() {
            return new Promise(resolve => {
                const script = document.createElement('script')
                script.src = this.script
                script.onload = () => {
                    resolve(true)
                }
                script.onerror = () => {
                    resolve(false)
                }
                document.body.appendChild(script)
            })
        }
    },

    async created() {

        const vm = this

        console.log(vm.$props)

        if (!vm.$props.amount) {
            vm.$router.go(-1)
        }

        const redirectionUrl = this.$props.webCheckoutURL;

        window.location.href = redirectionUrl;


    }

}

</script>
