<template>

    <div style="overflow-x: clip;">
        <div class="row sticky-top bg-gray-100 shadow" style="padding-top: 10px;">
            <div class="col-sm-12">
                <h2>
                    <button style="border: none; background: none;" @click="goBack"><i
                        class="p-2 fas fa-arrow-left"></i></button>
                    Material Details
                </h2>
            </div>
        </div>

        <div class="container-fluid">
            <br>

            <form @submit.prevent>
                <div class="row">

                    <section>

                        <div class="row">

                            <div class="col-12">
                                <label>Item Name</label>
                                <soft-input :disabled="$props.parcel_id==='read-only'" v-model="parcel.itemDetails.name"
                                            type="text"
                                            placeholder=""></soft-input>
                            </div>

                            <div class="col-12">
                                <soft-checkbox
                                    :checked="isProvidingMoreDetails"
                                    @change="isProvidingMoreDetails=!isProvidingMoreDetails"
                                    id="isProvidingMoreDetails"
                                    name="isProvidingMoreDetails">
                                    Provide More Details
                                </soft-checkbox>
                            </div>

                            <template v-if="isProvidingMoreDetails">

                                <div class="col-sm-12">

                                    <label>Item Category</label>
                                    <soft-checkbox
                                        :disabled="$props.parcel_id==='read-only'"
                                        :checked="parcel.itemDetails.plantAndMachinery==1"
                                        v-model="parcel.itemDetails.plantAndMachinery"
                                        value="1"
                                        id="flexCheckDefault1"
                                        name="flexCheckDefault">
                                        Plant & Machinery
                                    </soft-checkbox>

                                    <soft-checkbox
                                        :disabled="$props.parcel_id==='read-only'"
                                        :checked="parcel.itemDetails.furnitureAndHousehold==1"
                                        v-model="parcel.itemDetails.furnitureAndHousehold"
                                        value="1"
                                        id="flexCheckDefault2"
                                        name="flexCheckDefault">
                                        Furniture & Household
                                    </soft-checkbox>

                                    <soft-checkbox
                                        :disabled="$props.parcel_id==='read-only'"
                                        :checked="parcel.itemDetails.electricItem==1"
                                        v-model="parcel.itemDetails.electricItem"
                                        value="1"
                                        id="flexCheckDefault3"
                                        name="flexCheckDefault">
                                        Electric Item
                                    </soft-checkbox>

                                    <soft-checkbox
                                        :disabled="$props.parcel_id==='read-only'"
                                        :checked="parcel.itemDetails.chemicalOrLiquid==1"
                                        v-model="parcel.itemDetails.chemicalOrLiquid"
                                        value="1"
                                        id="flexCheckDefault4"
                                        name="flexCheckDefault">
                                        Chemical or Liquid Item
                                    </soft-checkbox>

                                    <soft-checkbox
                                        :disabled="$props.parcel_id==='read-only'"
                                        :checked="parcel.itemDetails.rawMaterial==1"
                                        v-model="parcel.itemDetails.rawMaterial"
                                        value="1"
                                        id="flexCheckDefault5"
                                        name="flexCheckDefault">
                                        Raw Material
                                    </soft-checkbox>

                                    <soft-checkbox
                                        :disabled="$props.parcel_id==='read-only'"
                                        :checked="parcel.itemDetails.electricalItem==1"
                                        v-model="parcel.itemDetails.electricalItem"
                                        value="1"
                                        id="flexCheckDefault6"
                                        name="flexCheckDefault">
                                        Electrical item
                                    </soft-checkbox>

                                    <soft-checkbox
                                        :disabled="$props.parcel_id==='read-only'"
                                        :checked="parcel.itemDetails.gasItem==1"
                                        v-model="parcel.itemDetails.gasItem"
                                        value="1"
                                        id="flexCheckDefault7"
                                        name="flexCheckDefault">
                                        Glass item
                                    </soft-checkbox>

                                </div>

                                <div class="col-12">
                                    <label>Fragile Item</label>
                                    <soft-radio :checked="parcel.itemDetails.fragileItem==='yes'"
                                                :disabled="$props.parcel_id==='read-only'"
                                                v-model="parcel.itemDetails.fragileItem" value="yes" id="fragileItem"
                                                name="fragileItem">
                                        <slot>Yes</slot>
                                    </soft-radio>
                                    <soft-radio :checked="parcel.itemDetails.fragileItem==='no'"
                                                :disabled="$props.parcel_id==='read-only'"
                                                v-model="parcel.itemDetails.fragileItem" value="no" id="fragileItem2"
                                                name="fragileItem">
                                        <slot>No</slot>
                                    </soft-radio>
                                </div>

                                <div class="col-12">
                                    <label>Special Care Required</label>
                                    <soft-radio :checked="parcel.itemDetails.specialCareRequired==='yes'"
                                                :disabled="$props.parcel_id==='read-only'"
                                                v-model="parcel.itemDetails.specialCareRequired" value="yes"
                                                id="specialCareRequired"
                                                name="specialCareRequired">
                                        <slot>Yes</slot>
                                    </soft-radio>
                                    <soft-radio :checked="parcel.itemDetails.specialCareRequired==='no'"
                                                :disabled="$props.parcel_id==='read-only'"
                                                v-model="parcel.itemDetails.specialCareRequired" value="no"
                                                id="specialCareRequired2"
                                                name="specialCareRequired">
                                        <slot>No</slot>
                                    </soft-radio>
                                </div>

                                <div class="col-12">
                                    <label>Hazardous Item</label>
                                    <soft-radio :checked="parcel.itemDetails.hazardousItem==='yes'"
                                                :disabled="$props.parcel_id==='read-only'"
                                                v-model="parcel.itemDetails.hazardousItem" value="yes"
                                                id="hazardousItem"
                                                name="hazardousItem">
                                        <slot>Yes</slot>
                                    </soft-radio>
                                    <soft-radio :checked="parcel.itemDetails.hazardousItem==='no'"
                                                :disabled="$props.parcel_id==='read-only'"
                                                v-model="parcel.itemDetails.hazardousItem" value="no"
                                                id="hazardousItem2"
                                                name="hazardousItem">
                                        <slot>No</slot>
                                    </soft-radio>
                                </div>

                                <div class="col-12">
                                    <label>Inflammable Item</label>
                                    <soft-radio :checked="parcel.itemDetails.inflamableItem==='yes'"
                                                :disabled="$props.parcel_id==='read-only'"
                                                v-model="parcel.itemDetails.inflamableItem" value="yes"
                                                id="inflamableItem"
                                                name="inflamableItem">
                                        <slot>Yes</slot>
                                    </soft-radio>
                                    <soft-radio :checked="parcel.itemDetails.inflamableItem==='no'"
                                                :disabled="$props.parcel_id==='read-only'"
                                                v-model="parcel.itemDetails.inflamableItem" value="no"
                                                id="inflamableItem2"
                                                name="inflamableItem">
                                        <slot>No</slot>
                                    </soft-radio>
                                </div>

                                <div class="col-12">
                                    <label>Item Value</label>
                                    <soft-radio :checked="parcel.itemDetails.price==='Less Than 50k'"
                                                :disabled="$props.parcel_id==='read-only'"
                                                v-model="parcel.itemDetails.price" value="Less Than 50k" id="mtk"
                                                name="mtk">
                                        <slot>Less than ₹50,000/-</slot>
                                    </soft-radio>
                                    <soft-radio :checked="parcel.itemDetails.price==='More Than 50k'"
                                                :disabled="$props.parcel_id==='read-only'"
                                                v-model="parcel.itemDetails.price" value="More Than 50k" id="mtk2"
                                                name="mtk">
                                        <slot>More than ₹50,000/-</slot>
                                    </soft-radio>
                                </div>

                                <div class="col-12">

                                    <br>

                                    <h6>Package Type</h6>

                                    <soft-checkbox
                                        :checked="parcel.itemDetails.packageTypes.includes('box')"
                                        :disabled="$props.parcel_id==='read-only'"
                                        @click="addPackageType($event,'box')"
                                        value="box"
                                        id="box"
                                        name="box">
                                        <slot>
                                            Box{{
                                                ' (Weight: ' + calculateTotalWeight + ' Kg | Volume: ' + calculateTotalVolume + ' cubic centimeters)'
                                            }}
                                        </slot>
                                    </soft-checkbox>

                                    <div v-if="parcel.itemDetails.packageTypes.includes('box')">
                                        <label>Volume of Box</label>
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Length (cm)</label>
                                                <soft-input v-model="parcel.itemDetails.box.length"
                                                            :disabled="$props.parcel_id==='read-only'"
                                                            type="number"
                                                            placeholder=""></soft-input>
                                            </div>
                                            <div class="col-4">
                                                <label>Breath (cm)</label>
                                                <soft-input v-model="parcel.itemDetails.box.breath"
                                                            :disabled="$props.parcel_id==='read-only'"
                                                            type="number"
                                                            placeholder=""></soft-input>
                                            </div>
                                            <div class="col-4">
                                                <label>Height (cm)</label>
                                                <soft-input v-model="parcel.itemDetails.box.height"
                                                            :disabled="$props.parcel_id==='read-only'"
                                                            type="number"
                                                            placeholder=""></soft-input>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6">
                                                <label>Number of Boxes</label>
                                                <soft-input v-model="parcel.itemDetails.box.totalCount"
                                                            :disabled="$props.parcel_id==='read-only'"
                                                            type="number"
                                                            placeholder=""></soft-input>
                                            </div>
                                            <div class="col-6">
                                                <label>Weight of Each Box (Kg)</label>
                                                <soft-input v-model="parcel.itemDetails.box.weight"
                                                            :disabled="$props.parcel_id==='read-only'"
                                                            type="number"
                                                            placeholder=""></soft-input>
                                            </div>
                                        </div>
                                    </div>

                                    <soft-checkbox
                                        :checked="parcel.itemDetails.packageTypes.includes('pallet')"
                                        :disabled="$props.parcel_id==='read-only'"
                                        @click="addPackageType($event,'pallet')"
                                        value="pallet"
                                        id="pallet"
                                        name="pallet">
                                        Pallet
                                    </soft-checkbox>

                                    <div v-if="parcel.itemDetails.packageTypes.includes('pallet')">
                                        <label>Volume of Pallet</label>
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Length (cm)</label>
                                                <soft-input v-model="parcel.itemDetails.pallet.length"
                                                            :disabled="$props.parcel_id==='read-only'"
                                                            type="number"
                                                            placeholder=""></soft-input>
                                            </div>
                                            <div class="col-4">
                                                <label>Breath (cm)</label>
                                                <soft-input v-model="parcel.itemDetails.pallet.breath"
                                                            :disabled="$props.parcel_id==='read-only'"
                                                            type="number"
                                                            placeholder=""></soft-input>
                                            </div>
                                            <div class="col-4">
                                                <label>Height (cm)</label>
                                                <soft-input v-model="parcel.itemDetails.pallet.height"
                                                            :disabled="$props.parcel_id==='read-only'"
                                                            type="number"
                                                            placeholder=""></soft-input>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6">
                                                <label>Number of Pallets</label>
                                                <soft-input v-model="parcel.itemDetails.pallet.totalCount"
                                                            :disabled="$props.parcel_id==='read-only'"
                                                            type="number"
                                                            placeholder=""></soft-input>
                                            </div>
                                            <div class="col-6">
                                                <label>Weight of Each Pallet (Kg)</label>
                                                <soft-input v-model="parcel.itemDetails.pallet.weight"
                                                            :disabled="$props.parcel_id==='read-only'"
                                                            type="number"
                                                            placeholder=""></soft-input>
                                            </div>
                                        </div>
                                    </div>

                                    <soft-checkbox
                                        :checked="parcel.itemDetails.packageTypes.includes('item')"
                                        :disabled="$props.parcel_id==='read-only'"
                                        @click="addPackageType($event,'item')"
                                        value="item"
                                        id="item"
                                        name="item">
                                        Item
                                    </soft-checkbox>

                                    <div v-if="parcel.itemDetails.packageTypes.includes('item')">
                                        <label>Volume of Item</label>
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Length (cm)</label>
                                                <soft-input v-model="parcel.itemDetails.item.length"
                                                            :disabled="$props.parcel_id==='read-only'"
                                                            type="number"
                                                            placeholder=""></soft-input>
                                            </div>
                                            <div class="col-4">
                                                <label>Breath (cm)</label>
                                                <soft-input v-model="parcel.itemDetails.item.breath"
                                                            :disabled="$props.parcel_id==='read-only'"
                                                            type="number"
                                                            placeholder=""></soft-input>
                                            </div>
                                            <div class="col-4">
                                                <label>Height (cm)</label>
                                                <soft-input v-model="parcel.itemDetails.item.height"
                                                            :disabled="$props.parcel_id==='read-only'"
                                                            type="number"
                                                            placeholder=""></soft-input>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6">
                                                <label>Number of Items</label>
                                                <soft-input v-model="parcel.itemDetails.item.totalCount"
                                                            :disabled="$props.parcel_id==='read-only'"
                                                            type="number"
                                                            placeholder=""></soft-input>
                                            </div>
                                            <div class="col-6">
                                                <label>Weight of Each Item (Kg)</label>
                                                <soft-input v-model="parcel.itemDetails.item.weight"
                                                            :disabled="$props.parcel_id==='read-only'"
                                                            type="number"
                                                            placeholder=""></soft-input>
                                            </div>
                                        </div>
                                    </div>

                                    <soft-checkbox
                                        :checked="parcel.itemDetails.packageTypes.includes('other')"
                                        :disabled="$props.parcel_id==='read-only'"
                                        @click="addPackageType($event,'other')"
                                        value="other"
                                        id="other"
                                        name="other">
                                        Other
                                    </soft-checkbox>

                                    <div v-if="parcel.itemDetails.packageTypes.includes('other')">
                                        <label>Volume of Other</label>
                                        <div class="row">
                                            <div class="col-4">
                                                <label>Length (cm)</label>
                                                <soft-input v-model="parcel.itemDetails.other.length"
                                                            :disabled="$props.parcel_id==='read-only'"
                                                            type="number"
                                                            placeholder=""></soft-input>
                                            </div>
                                            <div class="col-4">
                                                <label>Breath (cm)</label>
                                                <soft-input v-model="parcel.itemDetails.other.breath"
                                                            :disabled="$props.parcel_id==='read-only'"
                                                            type="number"
                                                            placeholder=""></soft-input>
                                            </div>
                                            <div class="col-4">
                                                <label>Height (cm)</label>
                                                <soft-input v-model="parcel.itemDetails.other.height"
                                                            :disabled="$props.parcel_id==='read-only'"
                                                            type="number"
                                                            placeholder=""></soft-input>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6">
                                                <label>Number of Other</label>
                                                <soft-input v-model="parcel.itemDetails.other.totalCount"
                                                            :disabled="$props.parcel_id==='read-only'"
                                                            type="number"
                                                            placeholder=""></soft-input>
                                            </div>
                                            <div class="col-6">
                                                <label>Weight of Other (Kg)</label>
                                                <soft-input v-model="parcel.itemDetails.other.weight"
                                                            :disabled="$props.parcel_id==='read-only'"
                                                            type="number"
                                                            placeholder=""></soft-input>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </template>

                        </div>

                    </section>

                    <div class="col-12" v-if="$props.parcel_id!=='read-only'">
                        <br>
                        <soft-button btn-type="submit" @click="storeMaterialDetailsAndBook" full-width color="success"
                                     variant="gradient"
                                     size="lg">
                            Request Quote
                        </soft-button>
                    </div>


                </div>
            </form>

            <br>
        </div>

    </div>

</template>

<script>

import SoftInput from "../../components/SoftInput"
import SoftButton from "../../components/SoftButton"
import SoftRadio from "../../components/SoftRadio"
import SoftCheckbox from "../../components/SoftCheckbox"
import {mapGetters} from "vuex";
import swal from "sweetalert";


export default {
    components: {
        SoftInput,
        SoftButton,
        SoftRadio,
        SoftCheckbox
    },
    props: {
        parcel_id: {
            type: String,
            required: true
        },
        from: {
            type: String,
            required: true
        },
        to: {
            type: String,
            required: true
        },
        from_pin: {
            type: String,
        },
        to_pin: {
            type: String,
        },
        from_lat_lng: {
            type: String,
        },
        to_lat_lng: {
            type: String,
        }
    },
    data() {
        return {

            isProvidingMoreDetails: false,

            mobileNumber: '',

            parcel: this.getParcelDetails()
        }
    },
    methods: {
        ...mapGetters(['getParcelDetails']),

        addPackageType(e, item) {
            if (e.target.checked) {
                this.parcel.itemDetails.packageTypes.push(item)
            } else {
                const index = this.parcel.itemDetails.packageTypes.indexOf(item);
                if (index > -1) { // only splice array when item is found
                    this.parcel.itemDetails.packageTypes.splice(index, 1); // 2nd parameter means remove one item only
                }
            }
        },

        goBack() {
            const vm = this
            vm.$router.replace({
                name: 'parcel-search-results', params: {
                    parcel_type: 'material',
                }
            })
        },

        storeMaterialDetailsAndBook() {
            const vm = this

            swal({
                title: `Are you sure you want to book this vehicle for Material?`,
                text: "On booking this vehicle, service provider will receive a notification and they may approve or reject you request",
                icon: "info",
                buttons: ['Cancel', 'Send Request'],
                dangerMode: false,
            }).then((book) => {
                if (book) {
                    vm.$store.dispatch('setParcelDetails', this.parcel)

                    let url = '/parcel/request/booking'
                    if (vm.$props.parcel_id === 'customer-request') {
                        url = '/parcel/customer/publish/request'
                    }

                    window.axios.post(url, {
                        mobile_number: vm.mobileNumber,
                        is_document: 0,
                        is_material: 1,
                        material_details_json: JSON.stringify(vm.parcel),
                        parcel_id: vm.$props.parcel_id,
                        from: vm.$props.from,
                        to: vm.$props.to,
                        from_lat_lng: vm.$props.from_lat_lng,
                        to_lat_lng: vm.$props.to_lat_lng,
                    }).then(response => {
                        if (response.data.response.status.error_code === 0) {

                            vm.$store.dispatch('clearParcelDetails')

                            vm.$toast.open({
                                message: response.data.response.status.msg,
                                type: 'success',
                                position: 'top',
                                duration: 2000,
                                dismissible: false,
                            })

                            window.ReactNativeWebView.postMessage("close-screen")

                        }
                    })
                }
            });


        },

    },
    computed: {
        calculateTotalWeight() {
            let val = this.parcel.itemDetails.box.totalCount * this.parcel.itemDetails.box.weight
            return this.stringToComma(val)
        },
        calculateTotalVolume() {
            let val = this.parcel.itemDetails.box.totalCount * (this.parcel.itemDetails.box.length * this.parcel.itemDetails.box.height * this.parcel.itemDetails.box.breath)
            return this.stringToComma(val)
        }
    },
    created() {
        let vm = this
        vm.mobileNumber = vm.$route.params.mobileNumber
    },
}

</script>

<style scoped>

</style>
