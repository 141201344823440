<template>
    <div>
        <div class="sticky-top inner-header sticky-header bg-gray-100 shadow py-2">
            <div class="col-sm-12">
                <h3>
                    <router-link :to="{name: 'home'}">
                        <button style="border: none; background: none;">
                            <i class="p-2 fas fa-arrow-left"></i>
                        </button>
                    </router-link>
                    Recents
                </h3>
            </div>
        </div>
        <div class="container-fluid p-3 have-sticky-header">
            <div class="text-center pt-5" v-if="isLoading">
                <span class="spinner-border" role="status" aria-hidden="true"></span>
            </div>
            <div class="mt-1 p-0" v-if="!isLoading">
                <p v-if="errorMessage">{{ errorMessage }}</p>
                <div class="recent-activity-card card mb-2" v-for="recentActivity of recentActivities" v-bind:key="recentActivity.id">
                    <div class="card-body p-3" @click="redirectUser(recentActivity.url)">
                        <h5 class="card-title ">{{ recentActivity.title }}</h5>
                        <p class="text-dark p-0 m-0">{{ recentActivity.body }}</p>
                        <p class="text-end p-0 m-0"> {{ recentActivity.time_elapsed }} </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import apiService from "../services/api.service";
export default {
    name: "RecentActivities",
    data() {
        return {
            mobileNumber: this.getUserMobileNumber(),
            recentActivities: [],
            isLoading: true,
            errorMessage: ''
        }
    },
    components: {
    },
    methods: {
        ...mapGetters(['getUserMobileNumber']),
        async getRecentActivities() {
            this.isLoading = true;
            apiService.getRecentActivities(this.mobileNumber)
            .then((apiResponse) => {
                this.isLoading = false;
                if (apiResponse && !apiResponse.response.status.error_code) {
                    this.recentActivities = apiResponse.response.data.recent_activities;
                    this.errorMessage = this.recentActivities.length ? '' : 'No recent activities';
                } else {
                    this.errorMessage = apiResponse && apiResponse.response.status.error_code ? apiResponse.response.status.error_msg : 'No recent activities';
                }
            })
            .catch(() => {
                this.errorMessage = 'No recent activities';
            });
        },

        redirectUser (url) {
            url = new URL(url).pathname;
            this.$router.push(url);
        }
    },
    created() {
        this.getRecentActivities();
    }
};
</script>
<style scoped lang="scss">
.recent-activity-top-bar{
    h3{
        color: var(--bs-gray-900);
        margin-bottom: 0;
        display: flex;
        align-items: center;
    }
}

.recent-activity-card {
    box-shadow: 1px 4px 16px 0 rgba(0, 0, 0, 0.05);
    margin-bottom: 1rem;
    h5{
        font-size: 18px;
        color: var(--bs-gray-900);
    }
    p{
        font-size: 15px;
        line-height: 1.4;
        color: var(--bs-gray-900);
    }
}
</style>