<template>

    <div style="overflow-x: clip;">
        <div class="row sticky-top bg-gray-100 shadow" style="padding-top: 10px;">
            <div class="col-sm-12">
                <h2>
                    <button style="border: none; background: none;" @click="goBack"><i
                        class="p-2 fas fa-arrow-left"></i></button>
                    Publish Tour
                </h2>
            </div>
        </div>

        <div class="container-fluid">
            <br>

            <form @submit.prevent>
                <div class="row">

                    <div class="col-sm-12">
                        <label>Tour Name</label>
                        <soft-input v-model="form.title"
                                    placeholder="Enter your tour name here"></soft-input>
                    </div>

                    <div class="col-sm-12">
                        <label>Start date of Tour</label>
                        <template v-for="(date,index) in form.dates" :key="index">
                            <soft-input type="date" v-model="date.selectedDate"
                                        placeholder="Select start date"></soft-input>
                        </template>
                        <div class="row">
                            <div class="col-6">
                                <soft-button color="dark" size="sm" @click="removeDate">- Remove</soft-button>
                            </div>
                            <div class="col-6" style="text-align: right">
                                <soft-button color="dark" size="sm" @click="addMoreDate">+ Add</soft-button>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-12">
                        <label>Tour Places</label>
                        <template v-for="(date,index) in form.places" :key="index">
                            <soft-input type="text" v-model="date.selectedPlace"
                                        placeholder="Enter place name here"></soft-input>
                        </template>
                        <div class="row">

                            <div class="col-6 text-right">
                                <soft-button color="dark" size="sm" @click="removePlace">- Remove</soft-button>
                            </div>
                            <div class="col-6" style="text-align: right">
                                <soft-button color="dark" size="sm" @click="addMorePlace">+ Add</soft-button>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <br>
                        <label>Type of Tour</label>
                        <div class="col-sm-4">
                            <soft-checkbox :checked="form.adventure" @change="form.adventure=!form.adventure"
                                           id="adventure" name="adventure">
                                Adventure
                            </soft-checkbox>
                        </div>
                        <div class="col-sm-4">
                            <soft-checkbox :checked="form.honeymoon" @change="form.honeymoon=!form.honeymoon"
                                           id="honeymoon" name="honeymoon">Honeymoon
                            </soft-checkbox>
                        </div>
                        <div class="col-sm-4">
                            <soft-checkbox :checked="form.spiritual" @change="form.spiritual=!form.spiritual"
                                           id="spiritual" name="spiritual">Spiritual
                            </soft-checkbox>
                        </div>
                        <div class="col-sm-4">
                            <soft-checkbox :checked="form.family" @change="form.family=!form.family"
                                           id="family" name="family">Family
                            </soft-checkbox>
                        </div>
                        <div class="col-sm-4">
                            <soft-checkbox :checked="form.picnic" @change="form.picnic=!form.picnic"
                                           id="picnic" name="picnic">Picnic
                            </soft-checkbox>
                        </div>
                    </div>

                    <div class="col-sm-12">
                        <br>
                        <label>Tour Detailed Description</label>
                        <soft-textarea id="tour-description" v-model="form.description"
                                       placeholder="Enter tour details and description here"></soft-textarea>
                    </div>

                    <div class="col-sm-12">
                        <label>Maximum Persons Allowed</label>
                        <soft-input type="number" v-model="form.maxNoOfPersons"
                                    placeholder="Enter max number of allowed persons in this tour"></soft-input>
                    </div>

                    <div class="row">
                        <label>Seating / Sleeper</label>
                        <div class="col-sm-4">
                            <soft-radio v-model="form.acNonAc" name="radio" value="AC-Seating" id="radio1">
                                <slot>AC-Seating</slot>
                            </soft-radio>
                        </div>
                        <div class="col-sm-4">
                            <soft-radio v-model="form.acNonAc" value="Non-AC-Seating" id="radio2"
                                        name="radio">
                                <slot>Non-AC-Seating</slot>
                            </soft-radio>
                        </div>
                        <div class="col-sm-4">
                            <soft-radio v-model="form.acNonAc" value="Non-AC-Sleeper" id="radio3"
                                        name="radio">
                                <slot>Non-AC-Sleeper</slot>
                            </soft-radio>
                        </div>
                        <div class="col-sm-4">
                            <soft-radio v-model="form.acNonAc" value="AC-Sleeper" id="radio4" name="radio">
                                <slot>AC-Sleeper</slot>
                            </soft-radio>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12">
                            <label>Upload Tour Catalogue PDF</label>
                            <br>
                            <input type="file" name="tour-pdf" id="tour-pdf">
                        </div>
                    </div>


                    <div class="col-sm-12">
                        <label>Tour Price/Person</label>
                        <soft-input v-model="form.price"
                                    type="number"
                                    placeholder="Enter tour price"></soft-input>
                    </div>

                    <div class="col-12">
                        <br>
                        <soft-checkbox id="terms-conditions"
                                       @change="notAcceptedTermsAndConditions=!notAcceptedTermsAndConditions">
                            <slot>
                                I Accept TransAll Terms & Conditions (<a style="color: #0d6efd;"
                                                                         href="https://transall.in/terms_conditions.html"
                                                                         target="_blank">Read More</a>)
                            </slot>
                        </soft-checkbox>
                    </div>

                    <div class="col-sm-12">
                        <br>
                        <soft-button :disabled="notAcceptedTermsAndConditions || isCallingApi" btn-type="submit"
                                     @click="addTour"
                                     full-width color="success" size="lg"
                                     variant="gradient">
                            Publish Tour
                        </soft-button>
                    </div>


                </div>
            </form>

            <br>
        </div>

    </div>

</template>

<script>

import SoftInput from "../../components/SoftInput"
import SoftButton from "../../components/SoftButton"
import SoftTextarea from "../../components/SoftTextarea"
import SoftCheckbox from "../../components/SoftCheckbox"
import SoftRadio from "../../components/SoftRadio"
import swal from "sweetalert"


export default {
    components: {
        SoftInput,
        SoftButton,
        SoftCheckbox,
        SoftTextarea,
        SoftRadio,

    },
    data() {
        return {

            mobileNumber: null,

            notAcceptedTermsAndConditions: true,

            isCallingApi: false,

            form: {
                title: '',
                dates: [
                    {selectedDate: ''},
                ],
                places: [
                    {selectedPlace: ''},
                ],
                from: '',
                to: '',
                startDate: '',
                endDate: '',
                maxNoOfPersons: '',
                acNonAc: 'AC',
                description: '',

                adventure: false,
                honeymoon: false,
                spiritual: false,
                family: false,
                picnic: false,

                tourPdfUrl: '',

                price: '',
            }

        }
    },
    methods: {
        checkUserServices() {
            const vm = this
            window.axios.get('user/get/services/offered/' + vm.mobileNumber)
                .then(resp => {
                    if (resp.data.response.status.error_code === 0) {
                        const user = resp.data.response.data.user
                        if (user.register_for_tours === 0) {
                            swal({
                                title: "Not Registered",
                                text: "You are not registered/allowed for providing this service, Please register first or contact customer support",
                                icon: "error",
                            }).then(() => {
                                vm.$router.replace({
                                    name: 'ride-register-individual',
                                    params: {
                                        mobileNumber: vm.mobileNumber
                                    }
                                })
                            });
                        } else {
                            if (user.is_verified_by_admin === 0) {
                                swal({
                                    title: "Pending KYC",
                                    text: "You KYC is still in process and pending approval, Once done you would be able to use this service",
                                    icon: "error",
                                }).then(() => {
                                    vm.$router.replace({
                                        name: 'ride-register-individual',
                                        params: {
                                            mobileNumber: vm.mobileNumber
                                        }
                                    })
                                });
                            }
                        }
                    }

                })
        },
        async uploadAllImages() {
            const vm = this
            await vm.uploadTourPdf()
        },
        uploadTourPdf() {

            const vm = this

            return new Promise((resolve) => {

                let formData = new FormData()
                let imagefile = document.getElementById('tour-pdf')
                if (imagefile.files[0]) {
                    formData.append("image", imagefile.files[0])
                    window.axios.post('/do/upload/document/tours/pdf', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(resp => {
                        if (resp.data.response.status.error_code === 0) {
                            vm.form.tourPdfUrl = resp.data.response.data.image_url
                        }
                        resolve()
                    }).catch(() => resolve())
                } else {
                    resolve()
                }

            })


        },
        goBack() {
            const vm = this
            swal({
                title: "Are you sure you want to go back?",
                text: "Once back, you will not be able to recover this filled form!",
                icon: "warning",
                buttons: ['Cancel', 'GoBack'],
                dangerMode: true,
            }).then((goBack) => {
                if (goBack) {
                    vm.$store.dispatch('clearAllLocations')
                    window.ReactNativeWebView.postMessage("close-screen")
                }
            });
        },
        async addTour() {
            let vm = this

            vm.isCallingApi = true

            await vm.uploadAllImages()

            window.axios.post('/tour/add/new', {

                mobile_number: vm.mobileNumber,

                title: vm.form.title,
                start_date: vm.form.dates[0].selectedDate,

                other_dates: JSON.stringify(vm.form.dates),
                other_places: JSON.stringify(vm.form.places),

                package_price: vm.form.price,
                max_persons: vm.form.maxNoOfPersons,

                description: vm.form.description,

                adventure: vm.form.adventure ? 1 : 0,
                honeymoon: vm.form.honeymoon ? 1 : 0,
                spiritual: vm.form.spiritual ? 1 : 0,
                family: vm.form.family ? 1 : 0,
                picnic: vm.form.picnic ? 1 : 0,

                tour_pdf_file_url: vm.form.tourPdfUrl

            }).then(resp => {

                if (resp.data.response.status.error_code === 0) {
                    swal({
                        title: "Success",
                        text: "Tour published successfully!",
                        icon: "info",
                    }).then(() => {
                        window.ReactNativeWebView.postMessage("close-screen")
                    });
                } else {
                    vm.isCallingApi = false

                    vm.$toast.open({
                        message: "Failed to add",
                        type: 'error',
                        position: 'top',
                        duration: 5000,
                        dismissible: false,
                    })
                }

            }).catch(e => {
                vm.isCallingApi = false
                console.log(e)
                vm.$toast.open({
                    message: "Something went wrong",
                    type: 'error',
                    position: 'top',
                    duration: 5000,
                    dismissible: false,
                })
            })
        },
        addMoreDate() {
            this.form.dates.push({selectedDate: ''})
        },
        removeDate() {
            if (this.form.dates.length > 1) {
                this.form.dates.pop()
            }
        },
        addMorePlace() {
            this.form.places.push({selectedPlace: ''})
        },
        removePlace() {
            if (this.form.places.length > 1) {
                this.form.places.pop()
            }
        },
        showModal() {
            this.addingVehicleOrManager = true;
        },
        closeModal() {
            this.addingVehicleOrManager = false;
        }
    },
    computed: {

        getTripDays() {
            if (this.form.startDate === '' && this.form.endDate === '') {
                return 'Please select start and end date';
            } else {
                const date1 = new Date(this.form.startDate);
                const date2 = new Date(this.form.endDate);
                const diffTime = Math.abs(date2 - date1);
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                return (diffDays + 1) + ' Days & ' + (diffDays) + ' Nights.'
            }
        }

    },
    created() {
        let vm = this
        vm.mobileNumber = this.$route.params.mobileNumber
        vm.checkUserServices();
    }
}

</script>

<style scoped>

</style>
