<template>

    <div style="overflow-x: clip;">
        <div class="row sticky-top bg-gray-100 shadow" style="padding-top: 10px;">
            <div class="col-sm-12">
                <h2>
                    <button style="border: none; background: none;" @click="goBack"><i
                        class="p-2 fas fa-arrow-left"></i></button>
                    Transport & Couriers
                </h2>
            </div>
        </div>

        <div class="container-fluid">
            <br>

            <form @submit.prevent>
                <div class="row">

                    <div class="col-sm-12">
                        <label>From Location PIN Code</label>
                        <soft-input v-model="transport.fromPinCode"
                                    type="number"
                                    placeholder="Enter PIN Code"></soft-input>
                    </div>

                    <div class="col-sm-12">
                        <label>To Location PIN Code</label>
                        <soft-input v-model="transport.toPinCode"
                                    type="number"
                                    placeholder="Enter PIN Code"></soft-input>
                    </div>

                    <div class="col-12">
                        <label>Select Service Type</label>
                        <soft-radio v-model="transport.serviceType" value="Transportation" id="s"
                                    name="radio">
                            <slot>Transportation</slot>
                        </soft-radio>
                        <soft-radio v-model="transport.serviceType" value="Courier" id="s2"
                                    name="radio">
                            <slot>Courier</slot>
                        </soft-radio>
                    </div>

                    <div class="col-sm-12">
                        <br>
                        <soft-button @click="searchRides" full-width
                                     color="success" size="lg"
                                     variant="gradient">
                            Search
                        </soft-button>
                    </div>


                </div>
            </form>

            <br>
        </div>

    </div>

</template>

<script>

import SoftButton from "../../components/SoftButton"
import SoftRadio from "../../components/SoftRadio"
//import swal from "sweetalert"
import {mapGetters} from "vuex";
import SoftInput from "../../components/SoftInput";


export default {
    components: {
        SoftInput,
        SoftButton,
        SoftRadio
    },
    data() {
        return {

            mobileNumber: null,

            transport: {
                fromPinCode: '',
                toPinCode: '',
                serviceType: '',
            }

        }
    },
    methods: {
        ...mapGetters(['getSearchFromLocation', 'getSearchToLocation']),
        goBack() {
            this.$store.dispatch('clearAllLocations')
            window.ReactNativeWebView.postMessage("close-screen")
        },
        searchRides() {
            let vm = this
                vm.$router.push({
                    name: 'map-nearby-transportations', params: {
                        fromPinCode: vm.transport.fromPinCode,
                        toPinCode: vm.transport.toPinCode,
                        serviceType: vm.transport.serviceType
                    }
                })

        },
        showModal() {
            this.addingVehicleOrManager = true;
        },
        closeModal() {
            this.addingVehicleOrManager = false;
        },
    },
    computed: {},
    created() {
        let vm = this
        vm.mobileNumber = this.$route.params.mobileNumber

    },
}

</script>

<style scoped>

</style>
