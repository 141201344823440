<template>
    <div style="overflow-x: clip;">

        <div class="row sticky-top bg-gray-100 shadow" style="padding-top: 10px;">
            <div class="col-sm-12">
                <h2 v-if="!ride.ended && !ride.onGoing">
                    <button style="border: none; background: none;" @click="goBack"><i
                        class="p-2 fas fa-arrow-left"></i></button>
                    {{ getTitle }}
                </h2>
                <h2 v-if="ride.onGoing">
                    <button style="border: none; background: none;" @click="goBack"><i
                        class="p-2 fas fa-arrow-left"></i></button>
                    Ongoing Ride
                </h2>
                <h2 v-if="ride.ended">
                    <button style="border: none; background: none;" @click="goBack"><i
                        class="p-2 fas fa-arrow-left"></i></button>
                    Ride Ended
                </h2>

            </div>
        </div>

        <div class="row" v-if="socketErrorMsg">
            <div class="col-12">
                <p class="text-center">{{ socketErrorMsg }}</p>
            </div>
        </div>

        <div class="row" v-if="isCustomer && socketConnected">
            <div class="col-12" v-if="locationTimestamp!==''">
                <GoogleMap
                    ref="mapRef"
                    disable-default-ui
                    :styles=mapStyle
                    api-key="AIzaSyDk4e0NV-K0_uVSlCqJNtCnKHP5qeYWzLY"
                    style="height: 200px; width: 100%;"
                    :center="center"
                    :zoom="15">

                    <Marker :options="{
                                position: center,
                            }">
                        <InfoWindow :options="{ position: center }">Rider Location <br> {{ locationTimestamp }}
                        </InfoWindow>
                    </Marker>

                </GoogleMap>
            </div>
            <div v-else>
                <div class="col-12"
                     style="background-image: linear-gradient(310deg, rgba(104,32,197,0.96) 0%, rgba(171,134,218,0.96) 100%);">
                    <p style="margin: 0; color: white; font-weight: bold; text-transform: uppercase;"
                       class="text-center blink-text">
                        Waiting for rider location...</p>
                </div>
            </div>
        </div>


        <div class="row" v-if="isRider && socketConnected"
             style="background-image: linear-gradient(310deg, #17ad37ee 0%, #98ec2dee 100%);">
            <div class="col-12">
                <p style="margin: 0; color: white; font-weight: bold; text-transform: uppercase;" class="text-center">
                    Sharing live location</p>
            </div>
        </div>

        <div class="container-fluid" v-if="!ride.ended">

            <br>

            <div v-if="notificationType==='RIDE_BOOKING_REJECTED_BY_CUSTOMER'">

                <div class="row">
                    <div class="col-12">
                        <h5>Ride No. (#{{ ride.rideNumber }})</h5>
                    </div>
                </div>

                <br>

                <div class="row" v-if="isRider">
                    <div class="col-6">
                        <label>Customer Name</label>
                        <p>{{ ride.customer_name }}</p>
                    </div>
                    <div class="col-6" style="text-align: right;">
                        <label>Passengers</label>
                        <p>{{ ride.noOfPassengers }}</p>
                    </div>
                </div>

                <br>

                <div class="row">
                    <div class="col-6">
                        <label>Date</label>
                        <p>{{ ride.date }}</p>
                    </div>
                    <div class="col-6" style="text-align: right;">
                        <label>Time</label>
                        <p>{{ ride.time }}</p>
                    </div>
                </div>

                <br>

                <div class="row">
                    <div class="col-10">
                        <label>From Location</label>
                        <p>{{ ride.from_location }}</p>
                    </div>
                    <div class="col-2" style="text-align: right">
                        <a :href="fromLocationPoint" target="_blank">
                            <img width="30" class="img-fluid" :src="navIcon">
                        </a>
                    </div>
                </div>

                <br>

                <div class="row">
                    <div class="col-10">
                        <label>To Location</label>
                        <p>{{ ride.to_location }}</p>
                    </div>
                    <div class="col-2" style="text-align: right">
                        <a :href="toLocationPoint" target="_blank">
                            <img width="30" class="img-fluid" :src="navIcon">
                        </a>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <p style="color:red;" v-if="isRider">
                            <br>
                            Customer rejected the ride
                        </p>
                        <p style="color:red;" v-if="isCustomer">
                            <br>
                            You rejected this ride
                        </p>
                    </div>
                </div>

                <br>
                <br>
                <br>

            </div>

            <div v-if="notificationType==='RIDE_BOOKING_REJECTED_BY_RIDER'">

                <div class="row">
                    <div class="col-12">
                        <h5>Ride No. (#{{ ride.rideNumber }})</h5>
                    </div>
                </div>

                <br>

                <div class="row" v-if="isRider">
                    <div class="col-6">
                        <label>Customer Name</label>
                        <p>{{ ride.customer_name }}</p>
                    </div>
                    <div class="col-6" style="text-align: right;">
                        <label>Passengers</label>
                        <p>{{ ride.noOfPassengers }}</p>
                    </div>
                </div>

                <br>

                <div class="row">
                    <div class="col-6">
                        <label>Date</label>
                        <p>{{ ride.date }}</p>
                    </div>
                    <div class="col-6" style="text-align: right;">
                        <label>Time</label>
                        <p>{{ ride.time }}</p>
                    </div>
                </div>

                <br>

                <div class="row">
                    <div class="col-10">
                        <label>From Location</label>
                        <p>{{ ride.from_location }}</p>
                    </div>
                    <div class="col-2" style="text-align: right">
                        <a :href="fromLocationPoint" target="_blank">
                            <img width="30" class="img-fluid" :src="navIcon">
                        </a>
                    </div>
                </div>

                <br>

                <div class="row">
                    <div class="col-10">
                        <label>To Location</label>
                        <p>{{ ride.to_location }}</p>
                    </div>
                    <div class="col-2" style="text-align: right">
                        <a :href="toLocationPoint" target="_blank">
                            <img width="30" class="img-fluid" :src="navIcon">
                        </a>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <p style="color:red;" v-if="isRider">
                            <br>
                            You rejected this ride
                        </p>
                        <p style="color:red;" v-if="isCustomer">
                            <br>
                            Rider rejected your ride request
                        </p>
                    </div>
                </div>

                <br>
                <br>
                <br>

            </div>

            <!--            This will be shown to RIDER who published the ride-->
            <div v-if="notificationType==='RIDE_BOOKING_REQUEST' && isRider">

                <div class="row">
                    <div class="col-12">
                        <h5>Ride No. (#{{ ride.rideNumber }})</h5>
                    </div>
                </div>

                <br>

                <div class="row">
                    <div class="col-6">
                        <label>Passengers</label>
                        <p>{{ ride.noOfPassengers }}</p>
                    </div>
                    <div class="col-6" style="text-align: right;" v-if="isRider">
                        <label>Customer Name</label>
                        <p>{{ ride.customer_name }}</p>
                    </div>
                </div>

                <br>

                <div class="row">
                    <div class="col-6">
                        <label>Date</label>
                        <p>{{ ride.date }}</p>
                    </div>
                    <div class="col-6" style="text-align: right;">
                        <label>Time</label>
                        <p>{{ ride.time }}</p>
                    </div>
                </div>

                <br>

                <div class="row">
                    <div class="col-10">
                        <label>From Location</label>
                        <p>{{ ride.from_location }}</p>
                    </div>
                    <div class="col-2" style="text-align: right">
                        <a :href="fromLocationPoint" target="_blank">
                            <img width="30" class="img-fluid" :src="navIcon">
                        </a>
                    </div>
                </div>

                <br>

                <div class="row">
                    <div class="col-10">
                        <label>To Location</label>
                        <p>{{ ride.to_location }}</p>
                    </div>
                    <div class="col-2" style="text-align: right">
                        <a :href="toLocationPoint" target="_blank">
                            <img width="30" class="img-fluid" :src="navIcon">
                        </a>
                    </div>
                </div>

                <hr>

                <template v-if="isRider">
                    <div class="row">
                        <div class="col-6">
                            <span class="text-bold">Per Km Charges</span>
                        </div>
                        <div class="col-6" style="text-align: right;">
                            <span>₹{{ rideFair.perKmCharges }}/-</span>
                        </div>

                    </div>

                    <br>

                    <div class="row">
                        <div class="col-6">
                            <span class="text-bold">Distance</span>
                        </div>
                        <div class="col-6" style="text-align: right;">
                            <span>{{ ride.distance }}</span>
                        </div>

                    </div>

                    <br>

                    <div class="row">
                        <div class="col-6">
                            <span class="text-bold">Convenience Fees</span>
                        </div>
                        <div class="col-6" style="text-align: right;">
                            <span>₹{{ rideFair.convFees }}/-</span>
                        </div>

                    </div>

                    <br>

                    <div class="row">
                        <div class="col-6">
                            <h4 class="text-bold">You Get</h4>
                        </div>
                        <div class="col-6" style="text-align: right;">
                            <h4>₹{{ rideFair.total }}/-</h4>
                        </div>
                        <span class="font-italic">* Terms and Conditions apply</span>
                    </div>

                    <br>

                    <div class="row">

                        <div class="col-4" v-if="!rideAlreadyAcceptedByRider">
                            <soft-button :disabled="isLoading" @click="rideRejectedByRider" full-width color="danger">
                                Reject
                            </soft-button>
                        </div>

                        <div class="col-8">
                            <soft-button v-if="!rideAlreadyAcceptedByRider"
                                         :disabled="isLoading" @click="acceptRideRequest"
                                         full-width color="success">
                                Accept
                            </soft-button>
                        </div>

                        <p style="color: #0d6efd" v-if="rideAlreadyAcceptedByRider">
                            <br>
                            <b>Waiting for Customer to make the Payment...</b>
                        </p>

                        <br>
                        <br>
                        <br>

                    </div>
                </template>

                <div class="row" v-if="isCustomer">

                    <div class="col-12">
                        <p style="color: #0d6efd"><b>Waiting for rider response...</b></p>
                    </div>
                </div>

            </div>

            <!--            This will be shown to CUSTOMER who requested for ride-->
            <div v-if="notificationType==='RIDE_BOOKING_ACCEPTED' && isCustomer">

                <div class="row">
                    <div class="col-12">
                        <h5>Ride No. (#{{ ride.rideNumber }})</h5>
                    </div>
                </div>

                <br>

                <div class="row">
                    <div class="col-6">
                        <span style="position:relative; z-index: 1;">

                            <img class="img-fluid card"
                                 :src="ride.riderImage">

                            <img class="img-fluid"
                                 style="position:absolute; top: -10px; left: -10px; height: 35px; width: 35px;"
                                 src="https://www.pngkey.com/png/full/624-6246436_verified-account-icon-selo-verificao-instagram-png.png">
                            </span>
                    </div>
                    <div class="col-6">
                        <div class="row">
                            <div class="col-12">
                                <span class="text-bold">Rider Name</span>
                                <br>
                                <span>{{ ride.riderName }}</span>
                                <br>
                            </div>
                            <div class="col-12">
                                <br>
                                <span class="text-bold">Rider Rating</span>
                                <br>
                                <span><img class="img-fluid" width="20"
                                           style="padding: 0; margin-right: 5px; margin-top: -5px;"
                                           src="https://iconsplace.com/wp-content/uploads/_icons/ffe500/256/png/rating-star-icon-19-256.png"></span>
                                <span>{{ ride.riderRating }}</span>
                                <br>
                                <br>
                            </div>
                            <div class="col-12">
                                <span class="text-bold">Vehicle Model</span>
                                <br>
                                <span>{{ ride.vehicle.model_name }}</span>
                                <br>
                            </div>

                        </div>


                    </div>
                </div>

                <hr>

                <div class="row">
                    <div class="col-10">
                        <span class="text-bold">From Location</span>
                        <br>
                        <span>{{ ride.from_location }}</span>
                    </div>
                    <div class="col-2" style="text-align: right">
                        <a :href="fromLocationPoint" target="_blank">
                            <img width="30" class="img-fluid" :src="navIcon">
                        </a>
                    </div>
                </div>

                <br>

                <div class="row">
                    <div class="col-10">
                        <span class="text-bold">To Location</span>
                        <br>
                        <span>{{ ride.to_location }}</span>
                    </div>
                    <div class="col-2" style="text-align: right">
                        <a :href="toLocationPoint" target="_blank">
                            <img width="30" class="img-fluid" :src="navIcon">
                        </a>
                    </div>
                </div>

                <hr>

                <div class="row">
                    <div class="col-6">
                        <span class="text-bold">Per Km Charges</span>
                    </div>
                    <div class="col-6" style="text-align: right;">
                        <span>₹{{ rideFair.perKmCharges }}/-</span>
                    </div>

                </div>

                <br>

                <div class="row">
                    <div class="col-6">
                        <span class="text-bold">Distance</span>
                    </div>
                    <div class="col-6" style="text-align: right;">
                        <span>{{ ride.distance }}</span>
                    </div>

                </div>

                <br>

                <div class="row">
                    <div class="col-6">
                        <span class="text-bold">Passengers</span>
                    </div>
                    <div class="col-6" style="text-align: right;">
                        <span>{{ rideFair.no_of_passengers }}</span>
                    </div>

                </div>

                <br>

                <div class="row">

                    <div class="col-6">
                        <span class="text-bold">Convenience Fees</span>
                        <p style="font-size: 12px;">Deducted from Wallet Balance</p>
                    </div>
                    <div class="col-6" style="text-align: right;">
                        <span>₹{{ rideFair.convFees }}/-</span>
                        <p style="font-size: 12px;">₹{{ rideFair.wallet_balance }} - ₹{{
                                rideFair.convFees
                            }} = ₹{{ rideFair.new_wallet_balance }}</p>
                    </div>

                </div>

                <br>

                <div class="row">
                    <div class="col-6">
                        <h4 class="text-bold">You Pay</h4>
                    </div>
                    <div class="col-6" style="text-align: right;">
                        <h4>₹{{ rideFair.total }}/-</h4>
                    </div>
                    <span class="font-italic">* Terms and Conditions apply</span>
                </div>

                <br>

                <div class="row">

                    <div class="col-4">
                        <soft-button :disabled="isLoading" @click="rideRejectedByCustomer" full-width color="danger">
                            Reject
                        </soft-button>
                    </div>

                    <div class="col-8">
                        <soft-button :disabled="rideFair.isLoading" @click="makePaymentRequest" full-width
                                     color="success">
                            Accept & Pay
                        </soft-button>
                    </div>
                </div>

                <br>
            </div>

            <!--            This will be shown to CUSTOMER once the payment is done and ride is confirmed-->
            <div v-if="notificationType==='RIDE_BOOKING_CONFIRMED_CUSTOMER' && isCustomer">

                <div class="row">
                    <div class="col-12">
                        <h5>Ride No. (#{{ ride.rideNumber }})</h5>
                    </div>
                </div>

                <br>

                <div class="row">
                    <div class="col-6">
                        <span style="position:relative; z-index: 1;">

                            <img class="img-fluid card"
                                 :src="ride.riderImage">

                            <img class="img-fluid"
                                 style="position:absolute; top: -10px; left: -10px; height: 35px; width: 35px;"
                                 src="https://www.pngkey.com/png/full/624-6246436_verified-account-icon-selo-verificao-instagram-png.png">
                            </span>
                    </div>
                    <div class="col-6">
                        <div class="row">

                            <div class="col-12">
                                <label>Rider Name</label>
                                <p>{{ ride.riderName }}</p>
                            </div>

                            <div class="col-12">
                                <label>Vehicle Model</label>
                                <p>{{ ride.vehicle.model_name }}</p>
                            </div>

                            <div class="col-12">
                                <label>Rider Rating</label>
                                <br>
                                <span><img class="img-fluid" width="20"
                                           style="padding: 0; margin-right: 5px; margin-top: -5px;"
                                           src="https://iconsplace.com/wp-content/uploads/_icons/ffe500/256/png/rating-star-icon-19-256.png"></span>
                                <span>{{ ride.riderRating }}</span>
                            </div>

                            <div class="col-12">
                                <label>Payment Made</label>
                                <h5>{{ stringToINR(ride.customerPaid) }}</h5>
                            </div>

                        </div>


                    </div>

                </div>

                <hr>

                <div class="row">
                    <div class="col-12">
                        <label>From</label>
                        <p>{{ ride.from_location }}</p>
                    </div>
                </div>

                <br>

                <div class="row">
                    <div class="col-12">
                        <label>To</label>
                        <p>{{ ride.to_location }}</p>
                    </div>
                </div>

                <br>

                <div class="row">
                    <div class="col-12">
                        <label>Vehicle Photos</label>
                        <br>
                        <br>
                    </div>
                    <div class="col">
                        <img class="img-fluid" :src="ride.vehicle.front_image"/>
                    </div>
                    <div class="col">
                        <img class="img-fluid" :src="ride.vehicle.back_image"/>
                    </div>
                    <div class="col">
                        <img class="img-fluid" :src="ride.vehicle.right_image"/>
                    </div>
                    <div class="col">
                        <img class="img-fluid" :src="ride.vehicle.left_image"/>
                    </div>
                </div>

                <br>

                <div class="row">
                    <div class="col-12">
                        <label>Vehicle Number</label>
                        <p>{{ ride.vehicle.registered_number.toUpperCase() }}</p>
                    </div>
                </div>

                <br>

                <div class="row">
                    <div class="col-10">
                        <label>Rider Contact</label>
                        <p>{{ ride.riderContact }}</p>
                    </div>
                    <div class="col-2" style="text-align: right">
                        <a :href="riderNumber" target="_blank">
                            <img width="30" class="img-fluid" :src="callBtn">
                        </a>
                    </div>
                </div>

                <br>

                <div class="row">
                    <div v-if="!ride.onGoing" class="col-12">
                        <span class="text-bold text-2xl">Ride PIN</span>
                        <br>
                        <span class="text-bold"
                              style="border-radius:3px; text-align: center; font-size: 28px; letter-spacing: 10px; background-color: black; color: white; padding: 5px 5px 5px 15px;">{{
                                ride.ridePin
                            }}</span>
                        <br>
                        <span class="font-italic">* Share this PIN when rider arrives at your location</span>
                    </div>
                    <div v-else>
                        <h5>Ride is ongoing...</h5>
                        <a :href="getRideSharingDetails">
                            <img height="100"
                                 src="https://cdn.iconscout.com/icon/free/png-256/free-whatsapp-share-button-3289864-2758562.png">
                        </a>
                    </div>
                </div>

                <br>


            </div>

            <!--            This will be shown to RIDER once the payment is done by customer-->
            <div v-if="notificationType==='RIDE_BOOKING_CONFIRMED_RIDER' && isRider">

                <div class="row">
                    <div class="col-12">
                        <h5>Ride No. (#{{ ride.rideNumber }})</h5>
                    </div>
                </div>

                <br>

                <div class="row">
                    <div class="col-6">
                        <span style="position:relative; z-index: 1;">

                            <img class="img-fluid card"
                                 :src="ride.customerImage">

                            <img class="img-fluid"
                                 style="position:absolute; top: -10px; left: -10px; height: 35px; width: 35px;"
                                 src="https://www.pngkey.com/png/full/624-6246436_verified-account-icon-selo-verificao-instagram-png.png">
                            </span>
                    </div>
                    <div class="col-6">
                        <div class="row">
                            <div class="col-12">
                                <label>Customer Name</label>
                                <p>{{ ride.customer_name }}</p>
                            </div>
                            <div class="col-12">
                                <label>Passengers</label>
                                <p>{{ ride.noOfPassengers }}</p>
                            </div>
                            <div class="col-12">
                                <label>Customer Rating</label>
                                <br>
                                <span><img class="img-fluid" width="20"
                                           style="padding: 0; margin-right: 5px; margin-top: -5px;"
                                           src="https://iconsplace.com/wp-content/uploads/_icons/ffe500/256/png/rating-star-icon-19-256.png"></span>
                                <span>{{ ride.riderRating }}</span>
                            </div>
                            <div class="col-12">
                                <label>Payment Received</label>
                                <h5>{{ stringToINR(ride.riderEarned) }}</h5>
                            </div>
                        </div>
                    </div>
                </div>

                <hr>

                <div class="row">
                    <div class="col-10">
                        <label>Pickup Location</label>
                        <p>{{ ride.from_location }}</p>
                    </div>
                    <div class="col-2" style="text-align: right">
                        <a :href="fromLocationPoint" target="_blank">
                            <img width="30" class="img-fluid" :src="navIcon">
                        </a>
                    </div>
                </div>

                <br>

                <div class="row">
                    <div class="col-10">
                        <label>Drop Location</label>
                        <p>{{ ride.to_location }}</p>
                    </div>
                    <div class="col-2" style="text-align: right">
                        <a :href="toLocationPoint" target="_blank">
                            <img width="30" class="img-fluid" :src="navIcon">
                        </a>
                    </div>
                </div>

                <br>

                <div class="row">
                    <div class="col-10">
                        <label>Customer Contact</label>
                        <p>{{ ride.customerContact }}</p>
                    </div>
                    <div class="col-2" style="text-align: right">
                        <br>
                        <a :href="customerNumber" target="_blank">
                            <img width="30" class="img-fluid" :src="callBtn">
                        </a>
                    </div>
                </div>

                <br>


                <div class="row">
                    <div v-if="!ride.onGoing" class="col-12">
                        <soft-button @click="startRide" full-width color="success">
                            Start Ride
                        </soft-button>
                    </div>

                    <div v-else class="col-12">
                        <soft-button @click="endRide" full-width color="danger">
                            End Ride
                        </soft-button>
                    </div>

                    <br>
                    <br>
                    <br>

                </div>


            </div>


        </div>

        <div class="container-fluid" v-if="ride.ended && isRider">

            <br>

            <div class="row">
                <div class="col-12">
                    <h5>Ride No. (#{{ ride.rideNumber }})</h5>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <label>Customer Name</label>
                    <p>{{ ride.customer_name }}</p>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <label>From Location</label>
                    <p>{{ ride.from_location }}</p>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <label>To Location</label>
                    <p>{{ ride.to_location }}</p>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <label>{{
                            givenCustomerRating != null ? `You provided ${givenCustomerRating} star rating` : 'Please provide your rating'
                        }}</label>
                    <star-rating star-size="30" text-class="star-rating-text" rounded-corners
                                 v-model:rating="rating"></star-rating>
                    <br>
                </div>
            </div>

            <template v-if="!isRatingSubmitted && givenCustomerRating==null">

                <div class="row">
                    <div class="col-12">
                        <soft-button @click="submitRating" full-width color="dark">
                            Submit Rating
                        </soft-button>
                    </div>
                </div>

            </template>

        </div>

        <div class="container-fluid" v-if="ride.ended && isCustomer">

            <br>

            <div class="row">
                <div class="col-12">
                    <h5>Ride No. (#{{ ride.rideNumber }})</h5>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <label>Rider Name</label>
                    <p>{{ ride.riderName }}</p>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <label>From Location</label>
                    <p>{{ ride.from_location }}</p>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <label>To Location</label>
                    <p>{{ ride.to_location }}</p>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <label>{{
                            givenRiderRating != null ? `You provided ${givenRiderRating} star rating` : 'Please provide your rating'
                        }}</label>
                    <star-rating star-size="30" text-class="star-rating-text" rounded-corners
                                 v-model:rating="rating"></star-rating>
                    <br>
                </div>
            </div>

            <template v-if="!isRatingSubmitted && givenRiderRating==null">

                <div class="row">
                    <div class="col-12">
                        <soft-button @click="submitRating" full-width color="dark">
                            Submit Rating
                        </soft-button>
                    </div>
                </div>

            </template>

        </div>

        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <span style="font-size: 10px; color: lightgray;">{{ currentLocation }}</span>
                </div>
            </div>
        </div>

    </div>

</template>

<script>

import callBtn from "@/assets/img/call_btn.png"
import navIcon from "@/assets/img/navigate_icon.png"
import SoftButton from "../../components/SoftButton"
import StarRating from 'vue-star-rating'

import swal from "sweetalert";
import {io} from "socket.io-client";

import {GoogleMap, InfoWindow, Marker} from 'vue3-google-map'


export default {
    components: {
        SoftButton,
        StarRating,
        GoogleMap,
        Marker,
        InfoWindow,
    },
    props: {

        viewer: {
            default: ''
        },

    },
    data() {
        return {

            rideInfo: 'Hi, I am riding using TransAll. And you can track my rides live location via this link https://admin.transall.in/share/live/status/ride/',

            socketErrorMsg: null,

            center: {lat: 23.0208366, lng: 72.5086508},

            locationTimestamp: '',

            mapStyle: [
                {
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "administrative",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "administrative.land_parcel",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "administrative.neighborhood",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "transit",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                }
            ],

            markers: [],

            socket: null,
            socketConnected: false,

            isCustomer: false,
            isRider: false,

            givenCustomerRating: null,
            givenRiderRating: null,

            rating: 0,
            isRatingSubmitted: false,

            isLoading: true,

            callBtn,

            navIcon,

            mobileNumber: null,

            notificationType: null,

            bookingId: null,

            customerBookingId: null,

            currentLocation: '',

            ride: {
                id: '',
                ride_sharing_key: '',

                rideAlreadyAcceptedByRider: false,
                onGoing: false,
                ended: false,
                rated: false,

                customerContact: '',
                riderContact: '',

                distance: '',
                distanceValue: 0,
                duration: '',

                rideNumber: '',
                customer_name: '',
                riderName: '',
                from_location: '',
                to_location: '',
                date: '',
                time: '',
                fair: '',
                from_lat: '',
                from_lng: '',
                to_lat: '',
                to_lng: '',
                fairPerKm: '',

                riderEarned: 0,
                customerPaid: 0,

                noOfPassengers: 0,

                riderRating: '4.2',

                personImage: 'https://www.ccd.edu/sites/default/files/PhotoAvatar.jpg',

                riderImage: 'https://www.ccd.edu/sites/default/files/PhotoAvatar.jpg',
                customerImage: 'https://www.ccd.edu/sites/default/files/PhotoAvatar.jpg',

                ridePin: '',

                status: 0,

                vehicle: {
                    registered_number: '',
                    model_name: '',
                    owner_name: '',
                    front_image: '',
                    back_image: '',
                    right_image: '',
                    left_image: '',
                },


            },

            payee: {
                name: '',
                email: '',
            },

            rideFair: {
                isLoading: true,
                fair: '0.0',
                perKmCharges: '0.0',
                convFees: '0.0',
                total: '0.0',
                new_wallet_balance: '0.0',
                wallet_balance: '0.0',
                no_of_passengers: '',
            }

        }
    },
    methods: {

        goBack() {
            if (window.history.length > 2) {
                this.$router.go(-1)
            } else {
                window.ReactNativeWebView.postMessage("close-screen")
            }
        },

        getRideBookingDetails() {

            const vm = this

            vm.isLoading = true

            window.axios.get('/ride/get/booking/details/' + vm.bookingId)
                .then(resp => {
                    if (resp.data.response.status.error_code === 0) {

                        vm.isLoading = false

                        const bookingDetails = resp.data.response.data.booking_details

                        vm.ride.customerContact = bookingDetails.user.mobile_number
                        vm.ride.riderContact = bookingDetails.ride.rider.mobile_number

                        /*
                            public const STATUS_BOOKING_REQUESTED = 0;
                            public const STATUS_BOOKING_ACCEPTED = 1;
                            public const STATUS_BOOKING_CONFIRMED = 2;
                            public const STATUS_RIDE_STARTED = 3;
                            public const STATUS_RIDE_ENDED = 4;
                            public const STATUS_RIDE_RATED = 5;
                        */
                        vm.ride.status = bookingDetails.status

                        //check if customer or rider
                        if (vm.mobileNumber == vm.ride.customerContact) {

                            console.log('customer')

                            vm.isCustomer = true
                            vm.isRider = false
                            //Customer
                            if (vm.ride.status === 1) {
                                vm.notificationType = 'RIDE_BOOKING_ACCEPTED'
                            } else if (vm.ride.status === 2 || vm.ride.status === 3) {
                                vm.notificationType = 'RIDE_BOOKING_CONFIRMED_CUSTOMER'
                            }

                            vm.ride.ride_sharing_key = bookingDetails.ride_sharing_key

                        } else if (vm.mobileNumber == vm.ride.riderContact) {

                            console.log('rider')


                            vm.isRider = true
                            vm.isCustomer = false
                            //Rider
                            if (vm.ride.status === 0) {
                                vm.notificationType = 'RIDE_BOOKING_REQUEST'
                            } else if (vm.ride.status === 1) {
                                vm.rideAlreadyAcceptedByRider = true
                            } else if (vm.ride.status === 2 || vm.ride.status === 3) {

                                vm.notificationType = 'RIDE_BOOKING_CONFIRMED_RIDER'
                            }
                        }

                        if (bookingDetails.status === 3) {
                            vm.ride.onGoing = true
                        }

                        if (bookingDetails.status === 4) {
                            vm.ride.ended = true
                        }

                        if (bookingDetails.status === 5) {
                            vm.ride.ended = true
                            vm.ride.rated = true

                            if (vm.isRider) {
                                vm.givenCustomerRating = bookingDetails.customer_rating
                                if (vm.givenCustomerRating != null) {
                                    vm.rating = vm.givenCustomerRating
                                }
                            }

                            if (vm.isCustomer) {
                                vm.givenRiderRating = bookingDetails.rider_rating
                                if (vm.givenRiderRating != null) {
                                    vm.rating = vm.givenRiderRating
                                }
                            }

                        }

                        vm.customerBookingId = vm.bookingId

                        //vm.ride.rideNumber = bookingDetails.ride.ride_number
                        vm.ride.rideNumber = bookingDetails.ride.id

                        vm.ride.customer_name = bookingDetails.user.first_name + " " + bookingDetails.user.last_name
                        vm.ride.noOfPassengers = bookingDetails.no_of_passengers

                        vm.payee.name = bookingDetails.user.first_name + ' ' + bookingDetails.user.last_name
                        vm.payee.email = bookingDetails.user.email

                        vm.ride.date = bookingDetails.ride.date ? vm.convertDate(bookingDetails.ride.date) : 'Daily'
                        vm.ride.time = vm.convertTime(bookingDetails.ride.time)

                        vm.ride.from_location = bookingDetails.from
                        vm.ride.to_location = bookingDetails.to

                        vm.rideFair.perKmCharges = bookingDetails.ride.fair_per_km

                        vm.ride.distance = bookingDetails.distance

                        vm.ride.from_lat = bookingDetails.from_lat
                        vm.ride.from_lng = bookingDetails.from_lng

                        vm.ride.to_lat = bookingDetails.to_lat
                        vm.ride.to_lng = bookingDetails.to_lng

                        vm.ride.riderName = bookingDetails.ride.rider.first_name + " " + bookingDetails.ride.rider.last_name
                        vm.ride.vehicle.model_name = bookingDetails.ride.vehicle.model_name
                        vm.ride.vehicle.registered_number = bookingDetails.ride.vehicle.registered_number

                        vm.ride.riderEarned = (bookingDetails.service_provider_amount / 100)

                        vm.ride.customerPaid = (bookingDetails.customer_amount / 100)

                        vm.ride.ridePin = bookingDetails.ride_pin

                        vm.ride.riderImage = bookingDetails.ride.rider.personal_picture_image_url ? bookingDetails.ride.rider.personal_picture_image_url : "https://www.ccd.edu/sites/default/files/PhotoAvatar.jpg"

                        vm.ride.customerImage = bookingDetails.user.personal_picture_image_url ? bookingDetails.user.personal_picture_image_url : "https://www.ccd.edu/sites/default/files/PhotoAvatar.jpg"

                        vm.ride.vehicle.front_image = bookingDetails.ride.vehicle.front_image
                        vm.ride.vehicle.back_image = bookingDetails.ride.vehicle.back_image
                        vm.ride.vehicle.left_image = bookingDetails.ride.vehicle.left_image
                        vm.ride.vehicle.right_image = bookingDetails.ride.vehicle.right_image

                        if (vm.ride.status === -1) {
                            vm.notificationType = 'RIDE_BOOKING_REJECTED_BY_RIDER'
                        } else if (vm.ride.status === -2) {
                            vm.notificationType = 'RIDE_BOOKING_REJECTED_BY_CUSTOMER'
                        }

                        if (vm.notificationType === 'RIDE_BOOKING_ACCEPTED') {
                            vm.getRideFairDetailsForCustomer()
                        } else if (vm.notificationType === 'RIDE_BOOKING_REQUEST') {
                            vm.getRideFairDetailsForServiceProvider()
                        }

                        if (bookingDetails.status === 2 || bookingDetails.status === 3) {

                            if (vm.isCustomer) {
                                vm.receiveLocationUpdates()
                            }

                            if (vm.isRider) {

                                vm.sendLocationUpdates()

                                setInterval(() => {
                                    vm.getCurrentLocation()
                                }, 2000)
                            }

                        }

                    }
                })
                .catch(error => {
                    vm.isLoading = false
                    console.log(error)
                })

        },

        acceptRideRequest() {
            const vm = this
            vm.isLoading = true
            window.axios.post('/ride/accept/booking/v2/' + vm.bookingId, {
                mobile_number: vm.mobileNumber,
            }).then(response => {
                if (response.data.response.status.error_code === 0) {
                    swal({
                        title: "Ride Accepted",
                        text: "You have accepted this ride request",
                        icon: "info",
                        dangerMode: false,
                    }).then(() => {
                        window.ReactNativeWebView.postMessage("close-screen")
                    })
                }
            }).catch(e => console.log(e))
        },

        makePaymentRequest() {
            const vm = this
            vm.rideFair.isLoading = true
            window.axios.post('/payment/create/ride/order/' + vm.customerBookingId, {
                contact: vm.mobileNumber,
                requestId: vm.customerBookingId,
                requestType: 'ride',
            }).then(response => {
                if (response.data.response.status.error_code === 0) {
                    //let orderId = response.data.response.data.id
                    //let amount = response.data.response.data.amount

                    let url = response.data.response.data.data.instrumentResponse.redirectInfo.url

                    this.$router.push({
                        name: "phonepe-payment-checkout", params: {
                            webCheckoutURL: url,
                            typeOfService: 'ride',
                            name: vm.payee.name,
                            email: vm.payee.email,
                            mobileNumber: vm.mobileNumber,
                            description: 'Ride Booking',
                            bookingId: vm.customerBookingId
                        }
                    })
                }
            }).catch(e => console.log(e))
        },

        startRide() {
            const vm = this
            swal("Enter Ride PIN", {
                content: "input",
            })
                .then((value) => {
                    window.axios.post('/ride/start/' + vm.bookingId, {
                        ride_pin: value
                    }).then(resp => {
                        if (resp.data.response.status.error_code === 0) {
                            vm.ride.onGoing = true
                            vm.getRideBookingDetails()
                            vm.$toast.open({
                                message: resp.data.response.status.msg,
                                type: 'success',
                                position: 'top',
                                duration: 5000,
                                dismissible: false,
                            })
                        } else {
                            vm.$toast.open({
                                message: resp.data.response.status.error_msg,
                                type: 'error',
                                position: 'top',
                                duration: 2000,
                                dismissible: false,
                            })
                        }
                    })
                });
        },

        endRide() {
            const vm = this
            window.axios.post('/ride/end/' + vm.bookingId)
                .then(resp => {
                    if (resp.data.response.status.error_code === 0) {
                        vm.ride.onGoing = false
                        vm.ride.ended = true
                        vm.getRideBookingDetails()
                        vm.$toast.open({
                            message: resp.data.response.status.msg,
                            type: 'success',
                            position: 'top',
                            duration: 5000,
                            dismissible: false,
                        })
                    } else {
                        vm.$toast.open({
                            message: resp.data.response.status.error_msg,
                            type: 'error',
                            position: 'top',
                            duration: 2000,
                            dismissible: false,
                        })
                    }
                })
        },

        submitRating() {
            const vm = this

            if (vm.rating === 0) {
                return
            }

            let entity = ''
            if (vm.isRider) {
                entity = 'customer'
            }
            if (vm.isCustomer) {
                entity = 'rider'
            }
            window.axios.put(`/ride/rate/${entity}/${vm.bookingId}`, {
                rating: vm.rating
            })
                .then(resp => {
                    if (resp.data.response.status.error_code === 0) {
                        vm.isRatingSubmitted = true
                        vm.$toast.open({
                            message: resp.data.response.status.msg,
                            type: 'success',
                            position: 'top',
                            duration: 5000,
                            dismissible: false,
                        })
                    }
                }).catch(e => console.log(e))
        },

        rideRejectedByRider() {
            const vm = this
            window.axios.post(`/ride/reject/booking/${vm.bookingId}/rider`)
                .then(resp => {
                    if (resp.data.response.status.error_code === 0) {
                        vm.$toast.open({
                            message: 'Ride rejected by you',
                            type: 'success',
                            position: 'top',
                            duration: 2000,
                            dismissible: false,
                        })
                        vm.getRideBookingDetails()
                    }
                }).catch(e => console.log(e))
        },

        rideRejectedByCustomer() {
            const vm = this
            window.axios.post(`/ride/reject/booking/${vm.bookingId}/customer`)
                .then(resp => {
                    if (resp.data.response.status.error_code === 0) {
                        vm.$toast.open({
                            message: 'Ride rejected by you',
                            type: 'success',
                            position: 'top',
                            duration: 2000,
                            dismissible: false,
                        })
                        vm.getRideBookingDetails()
                    }
                }).catch(e => console.log(e))
        },

        getRideFairDetailsForCustomer() {
            const vm = this
            window.axios.get('ride/calculate/fare/customer/' + vm.customerBookingId)
                .then(resp => {
                    if (resp.data.response.status.error_code === 0) {
                        vm.isLoading = false
                        vm.rideFair.isLoading = false
                        vm.rideFair.fair = resp.data.response.data.fair_details.fair
                        vm.rideFair.perKmCharges = resp.data.response.data.fair_details.fair_per_km
                        vm.rideFair.convFees = resp.data.response.data.fair_details.conv_fees
                        vm.rideFair.total = resp.data.response.data.fair_details.total_fair
                        vm.rideFair.wallet_balance = resp.data.response.data.fair_details.wallet_balance
                        vm.rideFair.new_wallet_balance = resp.data.response.data.fair_details.new_wallet_balance
                        vm.rideFair.no_of_passengers = resp.data.response.data.fair_details.no_of_passengers
                    }
                }).catch(e => console.log(e))
        },

        getRideFairDetailsForServiceProvider() {
            const vm = this
            window.axios.get('ride/calculate/fare/service/provider/' + vm.customerBookingId)
                .then(resp => {
                    if (resp.data.response.status.error_code === 0) {
                        vm.rideFair.isLoading = false
                        vm.rideFair.fair = resp.data.response.data.fair_details.fair
                        vm.rideFair.perKmCharges = resp.data.response.data.fair_details.fair_per_km
                        vm.rideFair.convFees = resp.data.response.data.fair_details.conv_fees
                        vm.rideFair.total = resp.data.response.data.fair_details.total_fair
                    }
                }).catch(e => console.log(e))
        },

        getCurrentLocation() {
            const vm = this
            navigator.geolocation.getCurrentPosition(
                position => {
                    console.log(position.coords.latitude)
                    console.log(position.coords.longitude)

                    vm.currentLocation = position.coords.latitude + ',' + position.coords.longitude

                },
                error => {
                    console.log(error.message);
                },
            )
        },

        //for rider
        sendLocationUpdates() {
            const vm = this

            const broadcastId = vm.mobileNumber + '-' + vm.bookingId

            console.log('sending location')

            vm.socket = io('https://socket.transall.in', {
                query: "broadcast_id=" + broadcastId,
                transports: ['websocket']
            });

            vm.socket.on("connect_error", (err) => {
                vm.socketErrorMsg = `connect_error due to ${err.message}`
                console.log(`connect_error due to ${err.message}`);
            });

            // Listen for connection events
            vm.socket.on('connect', () => {

                console.log('socket connected')

                vm.socketConnected = true

            });

            vm.socket.on('disconnect', () => {
                console.log('socket disconnected')
                vm.socketConnected = false

            });

            setInterval(() => {

                if (vm.currentLocation != '') {
                    console.log('sending location... Socket Broadcast Id:' + vm.ride.customerContact + '-' + vm.bookingId)
                    vm.socket.emit('send-location-updates', {
                        targetUserId: vm.ride.customerContact + '-' + vm.bookingId,
                        message: vm.currentLocation,
                        timestamp: Date.now()
                    });
                }

            }, 4000)
        },


        //for customer
        receiveLocationUpdates() {
            const vm = this

            const broadcastId = vm.mobileNumber + '-' + vm.bookingId

            console.log('receiving location')

            vm.socket = io('https://socket.transall.in', {
                query: "broadcast_id=" + broadcastId,
                transports: ['websocket']
            });

            vm.socket.on("connect_error", (err) => {
                vm.socketErrorMsg = `connect_error due to ${err.message}`
                console.log(`connect_error due to ${err.message}`);
            });

            // Listen for connection events
            vm.socket.on('connect', () => {

                console.log('customer socket connected')

                vm.socketConnected = true

                vm.socket.emit('message', {
                    rideId: vm.bookingId
                });

                vm.socket.on('receive-location-updates', (data) => {
                    console.log(data)

                    //vm.locationTimestamp = window.moment(data.timestamp).format("dddd, MMMM Do YYYY, h:mm:ss a");
                    vm.locationTimestamp = window.moment(data.timestamp).format("hh:mm a");

                    const latLng = data.message.split(',')

                    vm.center = {lat: parseFloat(latLng[0]), lng: parseFloat(latLng[1])}

                })

            });

            vm.socket.on('disconnect', () => {

                console.log('socket disconnected')

                vm.socketConnected = false

            });

        }


    },
    mounted() {

        if (this.notificationType === '') {
            window.ReactNativeWebView.postMessage("close-screen")
        }

    },
    computed: {
        getTitle() {
            if (this.notificationType === 'RIDE_BOOKING_REQUEST') {
                return 'Ride Booking'
            } else if (this.notificationType === 'RIDE_BOOKING_ACCEPTED') {
                return 'Ride Accepted'
            } else if (this.notificationType === 'RIDE_BOOKING_REJECTED_BY_CUSTOMER' || this.notificationType === 'RIDE_BOOKING_REJECTED_BY_RIDER') {
                return 'Ride Rejected'
            } else if (this.notificationType === 'RIDE_BOOKING_CONFIRMED_CUSTOMER' || this.notificationType === 'RIDE_BOOKING_CONFIRMED_RIDER') {
                return 'Ride Confirmed'
            }
            return 'Notification'
        },
        toLocationPoint() {
            return 'geo:' + this.ride.to_lat + ',' + this.ride.to_lng
        },
        fromLocationPoint() {
            return 'geo:' + this.ride.from_lat + ',' + this.ride.from_lng
        },
        riderNumber() {
            return 'tel:' + this.ride.riderContact
        },
        customerNumber() {
            return 'tel:' + this.ride.customerContact
        },
        getRideSharingDetails() {
            return 'https://api.whatsapp.com/send/?text=' + this.rideInfo + this.ride.customerContact + '-' + this.bookingId + '/' + this.ride.ride_sharing_key
        }
    },
    created() {
        let vm = this
        vm.mobileNumber = vm.$route.params.mobileNumber
        vm.notificationType = vm.$route.params.notificationType
        vm.bookingId = vm.$route.params.bookingId

        vm.getRideBookingDetails()

    }

}

</script>

<style>
.star-rating-text {
    color: transparent;
}

.blink-text {
    animation: text-blinker 1s linear infinite;
}

@keyframes text-blinker {
    50% {
        opacity: 0;
    }
}

</style>

<style scoped>

label {
    margin: 0;
    padding: 0;
}

p {
    margin-bottom: 0;
}

</style>
