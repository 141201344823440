<template>

    <div style="overflow-x: clip;">
        <div class="row sticky-top bg-gray-100 shadow" style="padding-top: 10px;">
            <div class="col-sm-12">
                <h2>
                    <button style="border: none; background: none;" @click="goBack"><i
                        class="p-2 fas fa-arrow-left"></i></button>
                    Search Results
                </h2>
            </div>
        </div>

        <Vue3Lottie v-if="isCallingApi" :animationData="animationJson" style="position: fixed; bottom: 0;"></Vue3Lottie>

        <div class="container-fluid">
            <div class="row">
                <div v-if="list.spv.length===0 && !isCallingApi" class="col-12 text-center" style="margin-top: 20%;">
                    <h4>{{ getLabel }}</h4>
                </div>
            </div>
            <br>
            <div class="row" v-if="list.spv.length>0">
                <div class="col-sm-12" v-for="(spv, index) in list.spv" :key="spv.id">
                    <div class="card" :id="index">
                        <div class="card-body" style="margin-top: 0; padding-top: 0;">
                            <br>
                            <div class="row">

                                <div class="col-12">
                                    <label>Vehicle Type</label>
                                    <p>{{ spv.vehicle_type }}</p>
                                </div>

                                <div class="col-6">
                                    <label>Location</label>
                                    <p>{{ spv.address }}</p>
                                </div>

                                <div class="col-6" style="text-align: right">
                                    <label>PIN Code</label>
                                    <p>{{ spv.pin_code }}</p>
                                </div>

                                <!--                                <div class="col-6">-->
                                <!--                                    <label>Rent per Hr</label>-->
                                <!--                                    <p>{{ stringToINR(spv.rent_per_hour) }}</p>-->
                                <!--                                </div>-->
                                <div class="col-12" style="text-align: right;">
                                    <label>Rent per Day</label>
                                    <p>{{ stringToINR(spv.rent_per_day) }}</p>
                                </div>

                                <div class="col-12">
                                    <soft-button size="sm" color="dark" full-width style="float: right;"
                                                 @click="showBookingDialog($event,spv.id)">Book Now
                                    </soft-button>
                                </div>

                            </div>
                        </div>
                    </div>
                    <br>
                </div>

            </div>
            <br>
        </div>

    </div>

</template>

<script>


import SoftButton from "../../components/SoftButton"
import swal from "sweetalert"
import {mapGetters} from "vuex"

import {Vue3Lottie} from "vue3-lottie"
import animationJson from "../../assets/anim/spv-anim.json"

export default {
    components: {SoftButton, Vue3Lottie},
    props: {
        vehicleType: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            animationJson,
            isCallingApi: true,

            isSpvPublished: false,

            mobileNumber: '',
            list: {
                spv: [],
            },

            searchingSpv: true,

            address: this.getFromLocation().address,
            latLng: this.getFromLocation().latLng,
            pinCode: this.getFromLocation().pinCode,

        }
    },
    methods: {
        ...mapGetters(['getFromLocation']),
        searchSpv() {
            let vm = this
            this.searchingSpv = true
            window.axios.get('/spv/search', {
                params: {
                    pin_code: vm.$props.pinCode,
                    vehicle_type: vm.$props.vehicleType,
                }
            }).then(response => {
                vm.isCallingApi = false
                this.searchingSpv = false
                if (response.data.response.status.error_code === 0) {
                    vm.list.spv = response.data.response.data.spv
                }
            }).catch(e => {
                console.log(e)
                vm.isCallingApi = false
            })
        },
        goBack() {
            this.$router.replace({name: "search-spv"})
        },
        showBookingDialog(event, spvId) {

            const button = event.target

            const vm = this

            swal("Enter Number of Days", {
                content: "input",
            })
                .then((value) => {

                    if (!value) {
                        return
                    }

                    try {
                        value = parseInt(value)
                    } catch {
                        return
                    }

                    if (value < 1 || isNaN(value)) {
                        return
                    }

                    if (value > 99) {
                        vm.$toast.open({
                            message: 'Max 99 Days Allowed',
                            type: 'error',
                            position: 'top',
                            duration: 5000,
                            dismissible: false,
                        })
                        return
                    }

                    button.disabled = true

                    swal({
                        title: `Are you sure you want to rent this vehicle for ${value} days?`,
                        text: "On booking this vehicle, vehicle publisher will receive a notification and they may approve or reject you request",
                        icon: "info",
                        buttons: ['Cancel', 'Send Booking Request'],
                        dangerMode: false,
                    }).then((book) => {
                        if (book) {
                            vm.sendBookingRequest(button, spvId, value)
                        } else {
                            button.disabled = false
                        }
                    })

                })


        },
        sendBookingRequest(button, spvId, days) {
            const vm = this
            window.axios.post('/spv/request/booking', {

                mobile_number: vm.mobileNumber,
                spv_id: spvId,
                vehicle_type: vm.$props.vehicleType,

                no_of_days: days,
                no_of_hours: 0,

                lat_lng: vm.latLng,
                address: vm.address,
                pinCode: vm.pinCode,

            }).then(response => {
                if (response.data.response.status.error_code === 0) {
                    button.disabled = false
                    vm.$toast.open({
                        message: response.data.response.status.msg,
                        type: 'success',
                        position: 'top',
                        duration: 2000,
                        dismissible: false,
                    })
                }
            })
        }
    },
    computed: {
        getLabel() {

            if (this.list.spv.length === 0) {
                return 'No SPV found near you...'
            } else {
                return ':('
            }

        }
    },
    created() {
        let vm = this
        vm.mobileNumber = this.$route.params.mobileNumber
        setTimeout(() => {
            vm.searchSpv()
        }, 2000)
    }
}

</script>

<style scoped>

label {
    margin: 0;
    padding: 0;
}

p {
    font-size: 14px;
}

</style>
