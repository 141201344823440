<template>

    <div style="overflow-x: clip;">
        <div class="row sticky-top bg-gray-100 shadow" style="padding-top: 10px;">
            <div class="col-sm-12">
                <h2>
                    <button style="border: none; background: none;" @click="goBack"><i
                        class="p-2 fas fa-arrow-left"></i></button>
                    Manage Parcels
                </h2>
            </div>
        </div>

        <div class="container-fluid">

            <template v-if="list.parcels.length===0">
                <div class="row">
                    <div class="col-12" style="text-align: center; margin-top: 20%">

                        <p>You dont have any parcels to mange<br>You can add new parcel request by tapping on <b>"+"</b>
                            button bellow</p>

                    </div>
                </div>
            </template>

            <br>
            <div class="row">
                <div class="col-sm-12" v-for="(parcel, index) in list.parcels" :key="parcel.id">
                    <div class="card" :id="index">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-6">
                                    <label>Parcel No.</label>
                                    <p>#{{ parcel.ride_number }}</p>
                                </div>
                                <div class="col-6" style="text-align: right">
                                    <label>Parcel Type</label>
                                    <p>{{ getParcelType(parcel) }}</p>
                                </div>
                                <div class="col-6">
                                    <label>From</label>
                                    <p>{{ parcel.from }}</p>
                                </div>
                                <div class="col-6" style="text-align: right">
                                    <label>To</label>
                                    <p>{{ parcel.to }}</p>
                                </div>

                                <div class="col-6">
                                    <label>{{
                                            (parcel.date == null ? 'Daily' : convertDate(parcel.date)) + " " + convertTime(parcel.time)
                                        }}</label>
                                    <p>{{
                                            stringToINR(parcel.fare_per_km)
                                        }} per Km ({{ stringToINR(parcel.fare_per_kg) }} per Kg)</p>
                                </div>

                                <div class="col-6" style="text-align: right;">
                                    <label>Booking Requests</label>
                                    <p>{{ parcel.booking_requests_count }}</p>
                                </div>


                                <div class="col-6">
                                    <soft-button @click="deleteParcel(parcel.id)" size="sm" full-width color="danger"
                                                 style="float: right;">Delete
                                    </soft-button>
                                </div>

                                <div class="col-6">
                                    <soft-button size="sm" full-width color="dark"
                                                 @click="viewBookingRequests(parcel.id)"
                                                 style="float: right;">View Requests
                                    </soft-button>

                                </div>


                            </div>
                        </div>
                    </div>
                    <br>
                </div>
            </div>
            <br>

            <soft-button @click="addNewParcel" style="position: fixed; bottom: 20px; right: 20px; padding: 5px 20px;"
                         class="text-2xl">+
            </soft-button>

        </div>

        <Modal
            ref="modal"
            v-show="modalOpen"
            @close="closeModal">
            <template v-slot:body>
                <ViewBookingRequests ref="bookingRequestsView" :modal="$refs.modal" :mobile-number="mobileNumber"
                                     view-for="parcel"></ViewBookingRequests>
            </template>
        </Modal>


    </div>

</template>

<script>


import SoftButton from "../../components/SoftButton";
import Modal from "../../components/Modal";
import ViewBookingRequests from "../../components/modals/ViewBookingRequests";
import swal from "sweetalert";

export default {
    components: {SoftButton, ViewBookingRequests, Modal},
    data() {
        return {

            modalOpen: false,

            mobileNumber: null,

            list: {
                parcels: [],
            }

        }
    },
    methods: {
        viewBookingRequests(id) {
            const vm = this;
            vm.$refs.bookingRequestsView.setDetails(id)
            vm.modalOpen = true
        },
        closeModal() {
            this.modalOpen = false;
        },
        goBack() {
            window.ReactNativeWebView.postMessage("close-screen")
        },
        getParcels() {
            let vm = this
            window.axios.get('parcel/manage', {
                params: {
                    mobile_number: vm.mobileNumber,
                }
            }).then(response => {
                if (response.data.response.status.error_code === 0) {
                    vm.list.parcels = []
                    vm.list.parcels = response.data.response.data.parcels
                }
            })
        },
        deleteParcel(parcelId) {
            let vm = this
            window.axios.delete(`parcel/delete/${parcelId}`, {
                params: {
                    mobile_number: vm.mobileNumber,
                }
            }).then(response => {
                if (response.data.response.status.error_code === 0) {
                    vm.getParcels()
                }
            })
        },
        getParcelType(parcel) {
            let text = ''
            if (parcel.material === 1) {
                text += 'Material'
            }
            if (parcel.material === 1 && parcel.document === 1) {
                text += ' & '
            }
            if (parcel.document === 1) {
                text += 'Document'
            }
            return text
        },
        addNewParcel() {
            this.$router.replace({name: 'publish-parcel'})
        },
        checkUserServices() {
            const vm = this
            window.axios.get('user/get/services/offered/' + vm.mobileNumber)
                .then(resp => {
                    if (resp.data.response.status.error_code === 0) {
                        const user = resp.data.response.data.user
                        if (user.register_for_parcel === 0) {
                            swal({
                                title: "Not Registered",
                                text: "You are not registered for providing this service, Please register first.",
                                icon: "error",
                            }).then(() => {
                                vm.$router.replace({
                                    name: 'ride-register-individual',
                                    params: {
                                        mobileNumber: vm.mobileNumber
                                    }
                                })
                            });
                        } else {
                            if (user.is_verified_by_admin === 0) {
                                swal({
                                    title: "Pending KYC",
                                    text: "You KYC is still in process and pending approval, Once done you would be able to use this service",
                                    icon: "error",
                                }).then(() => {
                                    vm.$router.replace({
                                        name: 'ride-register-individual',
                                        params: {
                                            mobileNumber: vm.mobileNumber
                                        }
                                    })
                                });
                            }else{
                                vm.getParcels()
                            }
                        }
                    }
                })
        }
    },
    created() {
        let vm = this
        vm.mobileNumber = this.$route.params.mobileNumber
        vm.checkUserServices()
    }
}

</script>

<style scoped>

label {
    margin: 0;
    padding: 0;
}

p {
    font-size: 14px;
}

</style>
