<template>

    <div style="overflow-x: clip;">
        <div class="row sticky-top bg-gray-100 shadow" style="padding-top: 10px;">
            <div class="col-sm-12">
                <h2>
                    <button style="border: none; background: none;" @click="goBack"><i
                        class="p-2 fas fa-arrow-left"></i></button>
                    Manage Travels
                </h2>
            </div>
        </div>

        <div class="container-fluid">
            <br>
            <div class="row">
                <div class="col-sm-12" v-for="(travel, index) in travels" :key="travel.id">
                    <div class="card" :id="index">
                        <div class="card-body">
                            <h5>{{ travel.title }}</h5>
                            <hr>

                            <div class="row">
                                <div class="col-6">
                                    <label>Frequency</label>
                                    <p>{{ travel.frequency }}</p>
                                </div>
                                <div class="col-6" style="text-align: right;">
                                    <label>Other Frequency</label>
                                    <p>{{ travel.frequency_other }}</p>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-6">
                                    <label>Date</label>
                                    <p>{{ travel.date }}</p>
                                </div>
                                <div class="col-6" style="text-align: right;">
                                    <label>Time</label>
                                    <p>{{ travel.time }}</p>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-6">
                                    <label>Max Persons</label>
                                    <p>{{ travel.max_persons }}</p>
                                </div>
                                <div class="col-6" style="text-align: right;">
                                    <label>Price/Per person</label>
                                    <p>{{ stringToINR(travel.price_per_person) }}</p>
                                </div>
                            </div>


                            <label>Booking Requests</label>
                            <p>{{ travel.booking_requests_count }}</p>
                            <br>
                            <div class="row">
                                <div class="col-6">
                                    <div style="text-align: end; font-weight: bold;">
                                        <soft-button full-width color="dark" @click="viewBookingRequests(travel.id)">
                                            View Requests
                                        </soft-button>
                                    </div>
                                </div>

                                <div class="col-6">
                                    <div style="text-align: end; font-weight: bold;">
                                        <soft-button full-width color="danger" @click="deleteTravel(travel.id)">Delete
                                        </soft-button>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <br>
                                    <SoftSwitch :checked="travel.is_booked==1" :id="travel.id" :name="travel.id"
                                                @change="setBookingFull($event,travel)">Booking Full
                                    </SoftSwitch>
                                </div>
                            </div>

                        </div>
                    </div>
                    <br>
                </div>
            </div>
            <br>
        </div>

        <soft-button @click="addNew" style="position: fixed; bottom: 20px; right: 20px; padding: 5px 20px;"
                     class="text-2xl">+
        </soft-button>

        <Modal
            ref="modal"
            v-show="modalOpen"
            @close="closeModal">
            <template v-slot:body>
                <ViewBookingRequests ref="bookingRequestsView" :modal="$refs.modal" :mobile-number="mobileNumber"
                                     view-for="travels"></ViewBookingRequests>
            </template>
        </Modal>

    </div>

</template>

<script>

import swal from "sweetalert"
import SoftSwitch from "../../components/SoftSwitch";
import SoftButton from "../../components/SoftButton";
import Modal from "../../components/Modal";
import ViewBookingRequests from "../../components/modals/ViewBookingRequests";

export default {
    components: {ViewBookingRequests, Modal, SoftButton, SoftSwitch},
    data() {
        return {
            modalOpen: false,

            mobileNumber: null,

            travels: [],

        }
    },
    methods: {
        addNew() {
            this.$router.replace({name: 'publish-travels'})
        },
        deleteTravel(travelId) {
            let vm = this
            window.axios.delete('/travels/delete/' + travelId)
                .then(resp => {
                    if (resp.data.response.status.error_code === 0) {
                        vm.getAllTravels()
                    } else {
                        swal({
                            title: resp.data.response.status.error_msg,
                            icon: "warning",
                            dangerMode: false,
                        })
                    }
                })
                .catch(e => {
                    console.log(e)
                })
        },
        viewBookingRequests(id) {
            const vm = this;
            vm.$refs.bookingRequestsView.setDetails(id)
            vm.modalOpen = true
        },
        closeModal() {
            this.modalOpen = false;
        },
        goBack() {
            swal({
                title: "Are you sure you want to go back?",
                text: "Once back, you will not be able to recover this filled form!",
                icon: "warning",
                buttons: ['Cancel', 'GoBack'],
                dangerMode: true,
            }).then((goBack) => {
                if (goBack) {
                    window.ReactNativeWebView.postMessage("close-screen")
                }
            });
        },
        getAllTravels() {
            let vm = this
            window.axios.get('/travels/list', {
                params: {
                    'mobile_number': vm.mobileNumber
                }
            })
                .then(resp => {
                    if (resp.data.response.status.error_code === 0) {
                        vm.travels = resp.data.response.data.travels
                    }
                })
                .catch(e => {
                    console.log(e)
                })
        },
        setBookingFull(event, travel) {
            let vm = this
            event.target.disabled = true
            window.axios.post('/travels/set/booking/full/' + travel.id, {
                'mobile_number': vm.mobileNumber
            })
                .then(resp => {
                    event.target.disabled = false
                    if (resp.data.response.status.error_code === 0) {
                        vm.$toast.open({
                            message: event.target.checked ? 'Booking Closed' : 'Booking Open',
                            type: 'success',
                            position: 'top',
                            duration: 2000,
                            dismissible: false,
                        })
                    }
                })
                .catch(e => {
                    event.target.disabled = false

                    console.log(e)
                })
        },
        checkUserServices() {
            const vm = this
            window.axios.get('user/get/services/offered/' + vm.mobileNumber)
                .then(resp => {
                    if (resp.data.response.status.error_code === 0) {
                        const user = resp.data.response.data.user
                        if (user.register_for_travels === 0) {
                            swal({
                                title: "Not Registered",
                                text: "You are not registered for providing this service, Please register first.",
                                icon: "error",
                            }).then(() => {
                                vm.$router.replace({
                                    name: 'ride-register-individual',
                                    params: {
                                        mobileNumber: vm.mobileNumber
                                    }
                                })
                            });
                        } else {
                            if (user.is_verified_by_admin === 0) {
                                swal({
                                    title: "Pending KYC",
                                    text: "You KYC is still in process and pending approval, Once done you would be able to use this service",
                                    icon: "error",
                                }).then(() => {
                                    vm.$router.replace({
                                        name: 'ride-register-individual',
                                        params: {
                                            mobileNumber: vm.mobileNumber
                                        }
                                    })
                                });
                            } else {
                                vm.getRides()
                            }
                        }
                    }
                })
        }
    },
    created() {
        let vm = this
        vm.mobileNumber = this.$route.params.mobileNumber
        vm.checkUserServices()

        vm.getAllTravels()
    }
}

</script>

<style scoped>

label {
    padding: 0;
    margin: 0;
}

</style>
