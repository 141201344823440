<template>

    <div style="overflow-x: clip;">
        <div class="row sticky-top bg-gray-100 shadow" style="padding-top: 10px;">
            <div class="col-sm-12">
                <h2>
                    <button style="border: none; background: none;" @click="goBack"><i
                        class="p-2 fas fa-arrow-left"></i></button>
                    Search SPV
                </h2>
            </div>
        </div>

        <div class="container-fluid">
            <br>

            <form @submit.prevent>
                <div class="row">

                    <div class="col-12">

                        <label>Location</label>
                        <soft-input v-model="spv.from"
                                    @click="openLocationPicker('from-location')"
                                    type="text"
                                    placeholder="Pick a location"></soft-input>
                    </div>

                    <div class="col-12">
                        <label>Select SPV</label>
                        <select v-model="spv.vehicleType" class="custom-select form-control">
                            <option selected value="JCB" name="JCB" id="JCB">JCB</option>
                            <option value="Tractor" name="Tractor" id="Tractor">Tractor</option>
                            <option value="Staff Buss" name="Staff Buss" id="Staff Buss">Staff Buss</option>
                            <option value="School Bus" name="School Bus" id="School Bus">School Bus</option>
                            <option value="Truck" name="Truck" id="Truck">Truck</option>
                            <option value="Hydra" name="Hydra" id="Hydra">Hydra</option>
                            <option value="Forklift" name="Forklift" id="Forklift">Forklift</option>
                            <option value="Road-Roller" name="Road-Roller" id="Road-Roller">Road-Roller</option>
                        </select>
                        <br>
                    </div>

                    <div class="col-sm-12">
                        <br>
                        <soft-button :disabled="spv.vehicleType==null" @click="searchSPV" full-width
                                     color="success" size="lg"
                                     variant="gradient">
                            Search SPV
                        </soft-button>
                    </div>


                </div>
            </form>

            <br>
        </div>

    </div>

</template>

<script>

import SoftButton from "../../components/SoftButton"
import {mapGetters} from "vuex";
import SoftInput from "../../components/SoftInput";


export default {
    components: {
        SoftInput,
        SoftButton,
    },
    data() {
        return {

            mobileNumber: null,

            addingVehicleOrManager: false,

            spv: {
                from: this.getFromLocation().address,
                vehicleType: 'JCB',
                pinCode: '',
            }

        }
    },
    methods: {
        ...mapGetters(['getFromLocation']),
        openLocationPicker(type) {
            if (type == 'from-location') {
                this.$router.push({
                    name: 'map-location-picker', params: {
                        locationType: 'from-location'
                    }
                })
            }
        },
        goBack() {
            this.$store.dispatch('clearAllLocations')
            window.ReactNativeWebView.postMessage("close-screen")
        },
        searchSPV() {
            let vm = this
            vm.$router.replace({
                name: 'spv-search-results', params: {
                    vehicleType: vm.spv.vehicleType,
                }
            })
        },
    },
    computed: {},
    created() {
        let vm = this
        vm.mobileNumber = this.$route.params.mobileNumber

    },
}

</script>

<style scoped>

</style>
