import axios from "axios";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../config/firebase.config";

let coderesult;
class AuthService {
    setUpRecaptcha = () => {
        window.recaptchaVerifier = new RecaptchaVerifier(
            auth,
            "recaptcha-container",
            {
                size: "invisible", // this property is important otherwise the captcha will be displayed on the screen
            }
        );

        window.recaptchaVerifier.verify();
    };

    signInWithPhone(phoneNumber) {
        const appVerifier = window.recaptchaVerifier;
        
        return signInWithPhoneNumber(auth, phoneNumber, appVerifier)
            .then((confirmationResult) => {
                window.confirmationResult = confirmationResult;
                coderesult = confirmationResult;
                return confirmationResult
            })
            .catch((error) => {
                return error;
            })
    }

    verifyOtp(otp) {
        return coderesult.confirm(otp).then((response) => {
            return response;
        }).catch((error) => {
            return error;
        })
    }

    checkPhoneExist(phoneNumber) {
        return axios.get(`/user/check/phone/${phoneNumber}`)
        .then((response) => response.data)
        .catch((error) => error)
    }

    registerUser(payload) {
        return axios.post(`/user/register`, payload)
        .then((response) => response.data)
        .catch((error) => error)
    }

    phoneLogin(payload) {
        return axios.post(`/user/phone/login`, payload)
        .then((response) => response.data)
        .catch((error) => error)
    }

    getUserDetails() {
        return axios.get('/user/details')
        .then((response) => response.data)
        .catch((error) => error)
    }

    logout() {
        return axios.post(`/user/phone/logout`)
        .then((response) => response.data)
        .catch((error) => error)
    }
}

export default new AuthService();