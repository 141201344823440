<template>
    <div class="get-started py-5" style="overflow-x: clip;">
        <div class="container">
            <div class="row">
                <div class="col">
                    <h3 class="d-flex align-items-center text-white">
                        <img height="45" width="45" :src="logo">
                        TRANSALL
                    </h3>
                </div>
            </div>
            <div class="row">
                <div class="col intro-keywords">
                    <p class="text-white">Ride | Parcel | Tours | Travels<br>Rentals | Courier | Transport</p>
                </div>
            </div>

            <div class="text-center my-2">
                <img :src="getStartedLogo">
            </div>

            <div class="text-left my-4">
                <h1 class="text-white font-weight-normal mb-3">We have trusted <br> and verified users.</h1>
                <p class="mb-5 text-white">Streamline your journey with reliable <br>transport solutions, all in one place.</p>
            </div>

            <router-link :to="{ name: 'sign-in' }">
                <soft-button class="btn-primary btn-lg w-100">
                    Get Started
                </soft-button>
            </router-link>
        </div>
    </div>
</template>
<script>


import logo from '../assets/img/TA_LOGO_2.png';
import getStartedLogo from '../assets/img/welcomelogo.png';

import SoftButton from "../components/SoftButton";


export default {

    data() {
        return {
            logo,
            getStartedLogo
        }
    },

    components: {
        SoftButton
    },


    methods: {},

    created() {


    }


}

</script>

<style lang="scss" scoped>
.get-started {
    min-height: 100vh;
    background: linear-gradient(to top, #103783 0%, #9BAFD9 100%);
}
.intro-keywords p{
    margin-bottom: 0;
}
</style>
