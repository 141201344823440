<template>

    <div style="overflow-x: clip;">
        <div class="row sticky-top bg-gray-100 shadow" style="padding-top: 10px;">
            <div class="col-sm-12">
                <h2>
                    <button style="border: none; background: none;" @click="goBack"><i
                        class="p-2 fas fa-arrow-left"></i></button>
                    Search Travels
                </h2>
            </div>
        </div>

        <div class="container-fluid">
            <br>

            <form @submit.prevent>
                <div class="row">

                    <div class="col-sm-12">
                        <label>From Place</label>
                        <SimpleTypeahead
                            class="form-control"
                            id="typeahead_id"
                            placeholder="Enter place name here"
                            :items="places"
                            :itemProjection="itemProjectionFunction"
                            :minInputLength="0"
                            @selectItem="selectItemEventHandler"
                        >
                        </SimpleTypeahead>
                    </div>

                    <div class="col-sm-12">
                        <label>To Place</label>
                        <SimpleTypeahead
                            class="form-control"
                            id="typeahead_id"
                            placeholder="Enter place name here"
                            :items="places"
                            :itemProjection="itemProjectionFunction"
                            :minInputLength="0"
                            @selectItem="selectItemEventHandler2"
                        >
                        </SimpleTypeahead>
                    </div>

                    <div class="col-sm-12">
                        <br>
                        <soft-button btn-type="submit" @click="searchTravels" full-width color="success" size="lg"
                                     variant="gradient">
                            Search Travels
                        </soft-button>
                    </div>


                </div>
            </form>

            <br>
        </div>

    </div>

</template>

<script>

import SoftButton from "../../components/SoftButton"
import SimpleTypeahead from 'vue3-simple-typeahead';

export default {
    components: {
        SoftButton,
        SimpleTypeahead
    },
    data() {
        return {

            form: {
                from: '',
                to: '',
            },

            places: []

        }
    },
    methods: {
        itemProjectionFunction(e){
            console.log(e)
            return e
        },
        selectItemEventHandler(item) {
            this.form.from = item
        },
        selectItemEventHandler2(item) {
            this.form.to = item
        },
        goBack() {
            this.$store.dispatch('clearAllLocations')
            window.ReactNativeWebView.postMessage("close-screen")
        },
        searchTravels() {
            const vm=this
            vm.$router.push({
                name: 'travel-search-results', params: {
                    from: vm.form.from,
                    to: vm.form.to,
                }
            });
        },
        getListOfPlaces() {
            const vm = this
            window.axios.get("/list/travels")
                .then(resp => {
                    if (resp.data.response.status.error_code === 0) {
                        vm.places.push(...resp.data.response.data.titles)
                        //vm.places.push(...resp.data.response.data.places)
                        console.log(vm.places)
                    }
                })
        }
    },
    created() {
        let vm = this
        vm.form.mobileNumber = this.$route.params.mobileNumber
        vm.getListOfPlaces()
    }
}

</script>

<style scoped>

</style>
