<template>

    <div style="overflow-x: clip;">
        <div class="row sticky-top bg-gray-100 shadow" style="padding-top: 10px;">
            <div class="col-sm-12">
                <h2>
                    <button style="border: none; background: none;" @click="goBack"><i
                        class="p-2 fas fa-arrow-left"></i></button>
                    Manage SPV
                </h2>
            </div>
        </div>

        <div class="container-fluid">

            <template v-if="list.spv.length===0">
                <div class="row">
                    <div class="col-12" style="text-align: center; margin-top: 20%">

                        <p>You dont have any APV to mange<br>You can add new SPV request by tapping on <b>"+"</b>
                            button bellow</p>

                    </div>
                </div>
            </template>

            <br>
            <div class="row">
                <div class="col-sm-12" v-for="(spv, index) in list.spv" :key="spv.id">
                    <div class="card" :id="index">
                        <div class="card-body">
                            <div class="row">

                                <div class="col-12">
                                    <label>Vehicle Type</label>
                                    <p>{{ spv.vehicle_type }}</p>
                                </div>

                                <div class="col-6">
                                    <label>Location</label>
                                    <p>{{ spv.address }}</p>
                                </div>


                                <div class="col-6" style="text-align: right;">
                                    <label>PIN Code</label>
                                    <p>{{ spv.pin_code }}</p>
                                </div>


                                <!--                                <div class="col-6">-->
                                <!--                                    <label>Rent per Hour</label>-->
                                <!--                                    <p>{{ stringToINR(spv.rent_per_hour) }}</p>-->
                                <!--                                </div>-->

                                <div class="col-6">
                                    <label>Booking Requests</label>
                                    <p>{{ spv.booking_requests_count }}</p>
                                </div>

                                <div class="col-6" style="text-align: right;">
                                    <label>Rent per Day</label>
                                    <p>{{ stringToINR(spv.rent_per_day) }}</p>
                                </div>


                                <div class="col-6">
                                    <soft-button @click="deleteSpv(spv.id)" size="sm" full-width color="danger"
                                                 style="float: right;">Delete
                                    </soft-button>
                                </div>

                                <div class="col-6">
                                    <soft-button size="sm" full-width color="dark"
                                                 @click="viewBookingRequests(spv.id)"
                                                 style="float: right;">View Requests
                                    </soft-button>

                                </div>

                            </div>

                        </div>

                    </div>
                </div>
                <br>
            </div>
        </div>
        <br>

        <soft-button @click="addNewSpv" style="position: fixed; bottom: 20px; right: 20px; padding: 5px 20px;"
                     class="text-2xl">+
        </soft-button>

        <Modal
            ref="modal"
            v-show="modalOpen"
            @close="closeModal">
            <template v-slot:body>
                <ViewBookingRequests ref="bookingRequestsView" :modal="$refs.modal" :mobile-number="mobileNumber"
                                     view-for="spv"></ViewBookingRequests>
            </template>
        </Modal>

    </div>

</template>

<script>


import SoftButton from "../../components/SoftButton";
import swal from "sweetalert";
import Modal from "../../components/Modal";
import ViewBookingRequests from "../../components/modals/ViewBookingRequests";

export default {
    components: {ViewBookingRequests, Modal, SoftButton},
    data() {
        return {
            modalOpen: false,

            mobileNumber: null,

            list: {
                spv: [],
            }

        }
    },
    methods: {
        addNewSpv() {
            this.$router.replace({name: 'publish-spv'})
        },
        viewBookingRequests(id) {
            const vm = this;
            vm.$refs.bookingRequestsView.setDetails(id)
            vm.modalOpen = true
        },
        closeModal() {
            this.modalOpen = false;
        },
        deleteSpv(id) {
            let vm = this
            window.axios.delete(`spv/delete/${id}`, {
                params: {
                    mobile_number: vm.mobileNumber,
                }
            }).then(response => {
                if (response.data.response.status.error_code == 0) {
                    vm.getSpvList()
                }
            })
        },
        goBack() {
            window.ReactNativeWebView.postMessage("close-screen")
        },
        getSpvList() {
            let vm = this
            window.axios.get('spv/manage', {
                params: {
                    mobile_number: vm.mobileNumber,
                }
            }).then(response => {
                if (response.data.response.status.error_code == 0) {
                    vm.list.spv = []
                    vm.list.spv = response.data.response.data.spv
                }
            })
        },
        checkUserServices() {
            const vm = this
            window.axios.get('user/get/services/offered/' + vm.mobileNumber)
                .then(resp => {
                    if (resp.data.response.status.error_code === 0) {
                        const user = resp.data.response.data.user
                        if (user.register_for_spv === 0) {
                            swal({
                                title: "Not Registered",
                                text: "You are not registered for providing this service, Please register first.",
                                icon: "error",
                            }).then(() => {
                                vm.$router.replace({
                                    name: 'ride-register-individual',
                                    params: {
                                        mobileNumber: vm.mobileNumber
                                    }
                                })
                            });
                        } else {
                            if (user.is_verified_by_admin === 0) {
                                swal({
                                    title: "Pending KYC",
                                    text: "You KYC is still in process and pending approval, Once done you would be able to use this service",
                                    icon: "error",
                                }).then(() => {
                                    vm.$router.replace({
                                        name: 'ride-register-individual',
                                        params: {
                                            mobileNumber: vm.mobileNumber
                                        }
                                    })
                                });
                            } else {
                                vm.getSpvList()
                            }
                        }
                    }
                })
        }
    },
    computed: {},
    created() {
        let vm = this
        vm.mobileNumber = this.$route.params.mobileNumber
        //vm.checkUserServices()
        vm.getSpvList()
    }
}

</script>

<style scoped>

label {
    margin: 0;
    padding: 0;
}

p {
    font-size: 14px;
}

</style>
