<template>

    <div style="overflow-x: clip;">
        <div class="row sticky-top bg-gray-100 shadow" style="padding-top: 10px;">
            <div class="col-sm-12">
                <h2>
                    <button style="border: none; background: none;" @click="goBack"><i
                        class="p-2 fas fa-arrow-left"></i></button>
                    Publish Ride
                </h2>
            </div>
        </div>

        <div class="container-fluid">
            <br>

            <form @submit.prevent>
                <div class="row">

                    <div class="col-12">

                        <label>From</label>
                        <soft-input v-model="ride.from"
                                    @click="openLocationPicker('from-location')"
                                    type="text"
                                    placeholder="Pick from location"></soft-input>
                    </div>

                    <div class="col-12">
                        <label>To</label>
                        <soft-input v-model="ride.to"
                                    @click="openLocationPicker('to-location')"
                                    type="text"
                                    placeholder="Pick destination location"></soft-input>
                    </div>

                    <div class="col-12">
                        <label>Select Vehicle</label>
                        <select v-model="ride.selectedVehicle" class="custom-select form-control">
                            <template v-for="vehicle in vehicles" :key="vehicle.id">
                                <option :value="vehicle.id">
                                    {{ vehicle.model_name + " (" + vehicle.registered_number + ")" }}
                                </option>
                            </template>
                        </select>
                        <br>
                    </div>

                    <hr>

                    <div class="col-6 text-center">
                        <label>Distance</label>
                        <br>
                        <span class="text-2xl text-bold">{{ distance }}</span>
                    </div>

                    <div class="col-6 text-center">
                        <label>Duration</label>
                        <br>
                        <span class="text-2xl text-bold">{{ timing }}</span>
                    </div>

                    <hr>

                    <div class="col-12">
                        <br>
                        <soft-checkbox id="daily-chkb"
                                       @change="isRidingDaily=!isRidingDaily">
                            <slot>
                                Daily Frequency
                            </slot>
                        </soft-checkbox>
                    </div>

                    <div class="col-6" v-if="!isRidingDaily">
                        <label>Date</label>
                        <soft-input v-model="ride.date"
                                    type="date"
                                    placeholder="Enter date"></soft-input>
                    </div>

                    <div class="col-6">
                        <label>Timing</label>
                        <soft-input v-model="ride.time"
                                    type="time"
                                    placeholder="Enter time"></soft-input>
                    </div>

                    <div class="col-sm-12">
                        <label>Maximum Passengers Allowed</label>
                        <soft-input type="number" v-model="ride.maxNoOfPersons"
                                    placeholder="Enter number of passengers allowed"></soft-input>
                    </div>


                    <div class="col-12">
                        <label>Fare per Km/Person</label>
                        <soft-input v-model="ride.fairPerKm"
                                    type="number"
                                    placeholder="Enter your price"></soft-input>
                    </div>

<!--                    <div class="col-6">-->
<!--                        <label>Fix Charge</label>-->
<!--                        <soft-input v-model="ride.fixedPrice"-->
<!--                                    type="number"-->
<!--                                    placeholder="Enter your price"></soft-input>-->
<!--                    </div>-->

                    <div class="col-12">
                        <br>
                        <soft-checkbox id="terms-conditions"
                                       @change="notAcceptedTermsAndConditions=!notAcceptedTermsAndConditions">
                            <slot>
                                I Accept TransAll Terms & Conditions (<a style="color: #0d6efd;"
                                                                         href="https://transall.in/terms_conditions.html"
                                                                         target="_blank">Read More</a>)
                            </slot>
                        </soft-checkbox>
                    </div>


                    <div class="col-sm-12">
                        <br>
                        <soft-button :disabled="notAcceptedTermsAndConditions" btn-type="submit" @click="publishRide"
                                     full-width color="success" size="lg"
                                     variant="gradient">
                            Publish Ride
                        </soft-button>
                    </div>


                </div>
            </form>

            <br>
        </div>

        <Modal
            ref="modal"
            v-show="addingVehicleOrManager"
            @close="closeModal">
            <template v-slot:body>
                <component :modal="$refs.modal" :is="vehicleOrManagerComponent"></component>
            </template>
        </Modal>

    </div>

</template>

<script>

import SoftInput from "../../components/SoftInput"
import SoftButton from "../../components/SoftButton"
import SoftTextarea from "../../components/SoftTextarea"
import SoftCheckbox from "../../components/SoftCheckbox"
import SoftRadio from "../../components/SoftRadio"
import swal from "sweetalert"
import AddSelectTourVehicle from "../../components/modals/AddSelectTourVehicle"
import AddSelectTourManager from "../../components/modals/AddSelectTourManager"
import Modal from "../../components/Modal"
import {mapGetters} from "vuex";


export default {
    components: {
        SoftInput,
        SoftButton,
        SoftCheckbox,
        SoftTextarea,
        SoftRadio,
        AddSelectTourVehicle,
        Modal,
    },
    data() {
        return {

            mobileNumber:null,

            vehicles: null,
            drivers: null,

            isRidingDaily: false,

            distance: 0,
            timing: 0,

            notAcceptedTermsAndConditions: true,

            address: "",
            addingVehicleOrManager: false,
            vehicleOrManagerComponent: AddSelectTourManager,

            ride: {

                selectedVehicle: null,
                selectedDriver: 0,

                from: this.getFromLocation().address,
                fromLatLng: this.getFromLocation().latLng,
                fromPinCode: this.getFromLocation().pinCode,
                fromCity: this.getFromLocation().city,
                fromLocality: this.getFromLocation().locality,

                to: this.getToLocation().address,
                toLatLng: this.getToLocation().latLng,
                toPinCode: this.getToLocation().pinCode,
                toCity: this.getToLocation().city,
                toLocality: this.getToLocation().locality,

                date: '',
                time: '',

                maxNoOfPersons: '',

                fairPerKm: '',
                fixedPrice: '0',
            }

        }
    },
    methods: {
        ...mapGetters(['getFromLocation', 'getToLocation']),
        getDirections() {

            const vm = this

            window.axios.get('/google/get/direction', {
                params: {
                    from_lat_lng: vm.ride.fromLatLng,
                    to_lat_lng: vm.ride.toLatLng
                }
            }).then(resp => {
                console.log(resp)
                vm.distance = resp.data.response.data.distance.text
                vm.timing = resp.data.response.data.duration.text
            })
        },
        goBack() {
            swal({
                title: "Are you sure you want to go back?",
                text: "Once back, you will not be able to recover this filled form!",
                icon: "warning",
                buttons: ['Cancel', 'GoBack'],
                dangerMode: true,
            }).then((goBack) => {
                if (goBack) {
                    window.ReactNativeWebView.postMessage("close-screen")
                }
            });
        },
        openLocationPicker(type) {
            if (type == 'from-location') {
                this.$router.push({
                    name: 'map-location-picker', params: {
                        locationType: 'from-location'
                    }
                })
            } else if (type == 'to-location') {
                this.$router.push({
                    name: 'map-location-picker', params: {
                        locationType: 'to-location'
                    }
                })
            }
        },
        addSelectVehicle() {
            this.addingVehicleOrManager = true
            this.vehicleOrManagerComponent = AddSelectTourVehicle
        },
        addSelectManager() {
            this.addingVehicleOrManager = true
            this.vehicleOrManagerComponent = AddSelectTourManager
        },
        publishRide() {
            let vm = this
            console.log(vm.ride)

            let msg = ''

            if (vm.ride.fixedPrice === '' || vm.ride.fairPerKm === '') {
                msg = 'Please enter price'
            }


            if (vm.isRidingDaily) {
                if (vm.ride.time === '') {
                    msg = 'Please enter time'
                }
            } else {
                if (vm.ride.date === '' || vm.ride.time === '') {
                    msg = 'Please enter date and time'
                }
            }

            if (vm.ride.from === '' || vm.ride.to === '') {
                msg = 'From and To fields are required'
            }

            if (msg !== '') {
                vm.$toast.open({
                    message: msg,
                    type: 'error',
                    position: 'top',
                    duration: 2000,
                    dismissible: false,
                })
                return
            }


            window.axios.post('ride/publish', {
                mobile_number: vm.mobileNumber,
                from: vm.ride.from,
                to: vm.ride.to,
                from_pin: vm.ride.fromPinCode,
                to_pin: vm.ride.toPinCode,
                from_lat_lng: vm.ride.fromLatLng,
                to_lat_lng: vm.ride.toLatLng,
                fair: vm.ride.fixedPrice,
                fair_per_km: vm.ride.fairPerKm,
                scheduled: (vm.ride.date != '' && vm.ride.time != '') ? 1 : 0,
                date: vm.ride.date,
                time: vm.ride.time,
                max_passengers: vm.ride.maxNoOfPersons,
                daily_frequency: vm.isRidingDaily,
                selected_vehicle: vm.ride.selectedVehicle
            }).then(response => {
                if (response.data.response.data.ride_details) {

                    const data = {
                        address: '',
                        latLng: '',
                        pinCode: '',
                        city: '',
                        locality: '',
                    };

                    vm.$store.dispatch('setFromLocation', data)

                    vm.$store.dispatch('setToLocation', data)

                    swal({
                        title: "Ride has been published",
                        icon: "info",
                        dangerMode: false,
                    }).then(() => {
                        window.ReactNativeWebView.postMessage("close-screen")
                    });
                }
            })
        },
        showModal() {
            this.addingVehicleOrManager = true;
        },
        closeModal() {
            this.addingVehicleOrManager = false;
        },
        checkUserServices() {
            const vm = this
            window.axios.get('user/get/services/offered/' + vm.mobileNumber)
                .then(resp => {
                    if (resp.data.response.status.error_code === 0) {
                        const user = resp.data.response.data.user
                        if (user.register_for_ride === 0) {
                            swal({
                                title: "Not Registered",
                                text: "You are not registered for providing this service, Please register first.",
                                icon: "error",
                            }).then(() => {
                                vm.$router.replace({
                                    name: 'ride-register-individual',
                                    params: {
                                        mobileNumber: vm.mobileNumber
                                    }
                                })
                            });
                        } else {
                            if (user.is_verified_by_admin === 0) {
                                swal({
                                    title: "Pending KYC",
                                    text: "You KYC is still in process and pending approval, Once done you would be able to use this service",
                                    icon: "error",
                                }).then(() => {
                                    vm.$router.replace({
                                        name: 'ride-register-individual',
                                        params: {
                                            mobileNumber: vm.mobileNumber
                                        }
                                    })
                                });
                            } else {
                                if (user.is_verified_by_admin === 0) {
                                    swal({
                                        title: "Pending KYC",
                                        text: "You KYC is still in process and pending approval, Once done you would be able to use this service",
                                        icon: "error",
                                    }).then(() => {
                                        vm.$router.replace({
                                            name: 'ride-register-individual',
                                            params: {
                                                mobileNumber: vm.mobileNumber
                                            }
                                        })
                                    });
                                }else{
                                    vm.getVehicleList()
                                    vm.getDriverList()
                                }
                            }
                        }
                    }

                })
        },
        getVehicleList() {
            const vm = this
            window.axios.get('/vehicle/' + vm.mobileNumber + '/list')
                .then(resp => {
                    if (resp.data.response.status.error_code == 0) {
                        vm.vehicles = resp.data.response.data.vehicles
                        if(vm.vehicles?.length===0){

                            swal({
                                title: "No Vehicles found",
                                text: "You need to add a vehicle before you provide ride or parcel service, Please add one now!",
                                icon: "info",
                            }).then(() => {
                                vm.$router.push({
                                    name: 'account-manage-vehicles',
                                    params: {
                                        mobileNumber: vm.mobileNumber
                                    }
                                })
                            });
                        }
                    }
                })
        },
        getDriverList() {
            const vm = this
            window.axios.get('/driver/' + vm.mobileNumber + '/list')
                .then(resp => {
                    if (resp.data.response.status.error_code == 0) {
                        vm.drivers = resp.data.response.data.drivers
                    }
                })
        }
    },
    computed: {},
    created() {
        let vm = this
        vm.mobileNumber = vm.$route.params.mobileNumber
        vm.checkUserServices();
    },
    mounted() {
        const vm = this
        if (vm.ride.fromLatLng != '' && vm.ride.toLatLng != '') {
            vm.getDirections()
        }
    }
}

</script>

<style scoped>

</style>
