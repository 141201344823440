<template>

    <div>

        <div class="container-fluid">

            <div class="row">
                <div class="col-sm-12">
                    <h4>Add/Select Tour Manager</h4>
                    <hr>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <label>Select Manager</label>
                    <select class="custom-select form-control">
                        <option selected>Click here to select</option>
                        <option value="1">Vipul Patel</option>
                        <option value="2">Krunal Patel</option>
                    </select>
                </div>
                <div class="col-sm-12">
                    <br>
                    <soft-button btn-type="submit" @click="addManager" full-width color="success" size="md"
                                 variant="gradient">
                        Confirm
                    </soft-button>
                    <hr>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12">
                    <label>Manager Name</label>
                    <soft-input v-model="form.fromPinCode"
                                placeholder=""></soft-input>
                </div>

                <div class="col-sm-12">
                    <label>Contact Number</label>
                    <soft-input v-model="form.fromPinCode"
                                placeholder=""></soft-input>
                </div>

            </div>

            <div class="row">
                <div class="col-sm-6">
                    <br>
                    <soft-button btn-type="submit" @click="close()"
                                 full-width color="dark" size="md"
                                 variant="gradient">
                        Close
                    </soft-button>
                </div>

                <div class="col-sm-6">
                    <br>
                    <soft-button btn-type="submit" @click="addTour" full-width color="success" size="md"
                                 variant="gradient">
                        Add
                    </soft-button>
                </div>
            </div>

        </div>

    </div>

</template>
<script>

import SoftInput from "../SoftInput";
import SoftButton from "../SoftButton";

export default {

    components: {
        SoftInput,
        SoftButton
    },
    props: {
        modal: {
            type: Object,
        }
    },
    data()
    {
        return {
            form: {
                fromPinCode: ''
            }
        }
    },
    methods: {
        close()
        {
            this.modal.$emit('close');
        },
    }

}

</script>
