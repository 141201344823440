<template>

    <div style="overflow-x: clip;">
        <div class="row sticky-top bg-gray-100 shadow" style="padding-top: 10px;">
            <div class="col-sm-12">
                <h2>
                    <button style="border: none; background: none;" @click="goBack"><i
                        class="p-2 fas fa-arrow-left"></i></button>
                    Rides Nearby
                </h2>
            </div>
        </div>

        <div class="container-fluid">
            <div class="row">
                <div v-if="list.rides.length===0" class="col-12 text-center" style="margin-top: 20%;">
                    <h4>{{ getLabel }}</h4>
                </div>
            </div>
            <br>
            <div class="row" v-if="list.rides.length>0">
                <div class="col-sm-12" v-for="(ride, index) in list.rides" :key="ride.id">
                    <div class="card" :id="index">
                        <div class="card-body" style="margin-top: 0; padding-top: 0;">
                            <br>
                            <div class="row">

                                <div class="col-6">
                                    <div class="row">
                                        <div class="col-12">
                                            <span class="text-bold" style="font-size: 14px;">From</span>
                                        </div>
                                        <div class="col-12">
                                            <span style="font-size: 14px;">{{ ride.from }}</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-6">
                                    <div class="row">
                                        <div class="col-12" style="text-align: right;">
                                            <span class="text-bold" style="font-size: 14px;">To</span>
                                        </div>
                                        <div class="col-12" style="text-align: right;">
                                            <span style="font-size: 14px; width: 100%;">{{ ride.to }}</span>
                                        </div>
                                    </div>
                                </div>

                                <template v-if="ride.service_type==='ride-now'">

                                    <div class="col-6 text-center">
                                        <br>
                                        <span class="text-bold" style="font-size: 14px;">Rate</span>
                                        <br>
                                        <span style="font-size: 14px; width: 100%;">₹{{
                                                parseInt(ride.service_provider_amount) / 100
                                            }}/-</span>
                                    </div>

<!--                                    <div class="col-4 text-center">-->
<!--                                        <br>-->
<!--                                        <span class="text-bold" style="font-size: 14px;">Passenger</span>-->
<!--                                        <br>-->
<!--                                        <span style="font-size: 14px; width: 100%;">N/A</span>-->
<!--                                    </div>-->

                                    <div class="col-6 text-center">
                                        <br>
                                        <span class="text-bold" style="font-size: 14px;">Date & Time</span>
                                        <br>
                                        <span style="font-size: 14px; width: 100%;">{{
                                                covertTimeStamp(ride.updated_at)
                                            }}</span>
                                    </div>

                                </template>
                                <template v-else>
                                    <div class="col-4 text-center">
                                        <br>
                                        <span class="text-bold" style="font-size: 14px;">Rate</span>
                                        <br>
                                        <span style="font-size: 14px; width: 100%;">₹{{
                                                parseInt(ride.service_provider_amount) / 100
                                            }}/-</span>
                                    </div>

                                    <div class="col-4 text-center">
                                        <br>
                                        <span class="text-bold" style="font-size: 14px;">Parcel Request</span>
                                        <br>
<!--                                        <span style="font-size: 14px; width: 100%;">{{ ride.vehicle_type }}</span>-->
                                    </div>

                                    <div class="col-4 text-center">
                                        <br>
                                        <span class="text-bold" style="font-size: 14px;">Date & Time</span>
                                        <br>
                                        <span style="font-size: 14px; width: 100%;">{{
                                                covertTimeStamp(ride.updated_at)
                                            }}</span>
                                    </div>
                                </template>

                                <!--                                <div class="col-4 text-center">-->
                                <!--                                    <br>-->
                                <!--                                    <span class="text-bold" style="font-size: 14px;">Date</span>-->
                                <!--                                    <br>-->
                                <!--                                    <span style="font-size: 14px; width: 100%;">{{-->
                                <!--                                            ride.date !== null ? convertDate(ride.date) : 'Today'-->
                                <!--                                        }}</span>-->
                                <!--                                </div>-->

                                <!--                                <div class="col-4 text-center">-->
                                <!--                                    <br>-->
                                <!--                                    <span class="text-bold" style="font-size: 14px;">Time</span>-->
                                <!--                                    <br>-->
                                <!--                                    <span style="font-size: 14px; width: 100%;">{{-->
                                <!--                                            ride.time !== null ? convertTime(ride.time) : 'Now'-->
                                <!--                                        }}</span>-->
                                <!--                                </div>-->

                                <div class="col-6">
                                    <br>
                                    <soft-button size="sm" color="dark" full-width style="float: right;"
                                                 @click="showOnMap(ride.from_lat,ride.from_lng,ride.to_lat,ride.to_lng,)">
                                        Map
                                    </soft-button>
                                </div>

                                <div class="col-6">
                                    <br>
                                    <soft-button size="sm" color="success" full-width style="float: right;"
                                                 @click="showBookingDialog($event,ride)">Accept
                                    </soft-button>
                                </div>

                            </div>
                        </div>
                    </div>
                    <br>
                </div>
            </div>
            <br>
        </div>

    </div>

</template>

<script>


import SoftButton from "../../components/SoftButton";
import swal from "sweetalert";

export default {
    components: {SoftButton},
    props: {
        lat: {
            type: String,
            required: false,
            default: null
        },
        lng: {
            type: String,
            required: false,
            default: null
        }
    },
    data() {
        return {

            mobileNumber: '',
            list: {
                rides: [],
            },

            searchingRides: true,

            rate: null,

        }
    },
    methods: {
        goBack() {
            window.ReactNativeWebView.postMessage("close-screen")
        },
        showOnMap(fromLat, fromLng, toLat, toLng) {
            window.open(`https://maps.google.com/maps?saddr=${fromLat},${fromLng}&daddr=${toLat},${toLng}`, '_blank')
        },
        searchNearbyRides(lat, lng) {
            let vm = this
            this.searchingRides = true
            window.axios.post('/ride/search/nearby', {
                latLng: lat + ',' + lng,
            }).then(response => {
                this.searchingRides = false
                if (response.data.response.status.error_code === 0) {
                    vm.list.rides = response.data.response.data.rides
                }
            })
        },
        showBookingDialog(event, ride) {

            const button = event.target

            const vm = this;

            if (ride.service_type === 'ride-now' || ride.service_type === 'parcel') {

                const vm = this
                window.axios.post('/ride/accept/customer/ride/now/request/' + vm.mobileNumber + '/' + ride.id)
                    .then(response => {
                        if (response.data.response.status.error_code === 0) {
                            button.disabled = false
                            vm.$toast.open({
                                message: response.data.response.status.msg,
                                type: 'success',
                                position: 'top',
                                duration: 2000,
                                dismissible: false,
                            })
                            location.reload()
                        }else if(response.data.response.status.error_code === 1){
                            button.disabled = false
                            vm.$toast.open({
                                message: response.data.response.status.error_msg,
                                type: 'error',
                                position: 'top',
                                duration: 2000,
                                dismissible: false,
                            })
                        }
                    })

            } else {
                swal("Enter Fix Rate for this ride", {
                    content: "input",
                })
                    .then((value) => {

                        if (!value) {
                            return
                        }

                        try {
                            value = parseFloat(value)
                        } catch {
                            return
                        }

                        if (value < 1 || isNaN(value)) {
                            return
                        }

                        button.disabled = true

                        swal({
                            title: `Are you sure you want to accept this ride giving ₹${value}/- as the final rate?`,
                            text: "On accepting this ride, customer will receive a notification and they may approve or reject you deal",
                            icon: "info",
                            buttons: ['Cancel', 'Send Request'],
                            dangerMode: false,
                        }).then((book) => {
                            if (book) {
                                vm.rate = value
                                vm.sendBookingRequest(button, ride.id)
                            } else {
                                button.disabled = false
                            }
                        });
                    })
            }


        },
        sendBookingRequest(button, requestId) {
            const vm = this
            window.axios.post('/ride/bid/customer/request/' + vm.mobileNumber + '/' + requestId, {
                rate: vm.rate,
            }).then(response => {
                if (response.data.response.status.error_code === 0) {
                    button.disabled = false
                    vm.$toast.open({
                        message: response.data.response.status.msg,
                        type: 'success',
                        position: 'top',
                        duration: 2000,
                        dismissible: false,
                    })
                }
            })
        },
        checkUserServices() {
            const vm = this
            window.axios.get('user/get/services/offered/' + vm.mobileNumber)
                .then(resp => {
                    if (resp.data.response.status.error_code === 0) {
                        const user = resp.data.response.data.user
                        if (user.register_for_ride === 0) {
                            swal({
                                title: "Not Registered",
                                text: "You are not registered for providing this service, Please register first.",
                                icon: "error",
                            }).then(() => {
                                vm.$router.replace({
                                    name: 'ride-register-individual',
                                    params: {
                                        mobileNumber: vm.mobileNumber
                                    }
                                })
                            });
                        } else {
                            if (user.is_verified_by_admin === 0) {
                                swal({
                                    title: "Pending KYC",
                                    text: "You KYC is still in process and pending approval, Once done you would be able to use this service",
                                    icon: "error",
                                }).then(() => {
                                    vm.$router.replace({
                                        name: 'ride-register-individual',
                                        params: {
                                            mobileNumber: vm.mobileNumber
                                        }
                                    })
                                });
                            } else {
                                if (user.is_verified_by_admin === 0) {
                                    swal({
                                        title: "Pending KYC",
                                        text: "You KYC is still in process and pending approval, Once done you would be able to use this service",
                                        icon: "error",
                                    }).then(() => {
                                        vm.$router.replace({
                                            name: 'ride-register-individual',
                                            params: {
                                                mobileNumber: vm.mobileNumber
                                            }
                                        })
                                    });
                                } else {
                                    if (vm.$props.lat === null || vm.$props.lng === null) {
                                        vm.getLocationViaJsAndSearchRides()
                                    } else {
                                        vm.searchNearbyRides(vm.$props.lat, vm.$props.lng)
                                    }
                                }
                            }
                        }
                    }

                })
        },
        getLocationViaJsAndSearchRides() {
            const vm = this
            navigator.geolocation.getCurrentPosition(
                position => {
                    console.log(position.coords.latitude)
                    console.log(position.coords.longitude)

                    vm.searchNearbyRides(position.coords.latitude, position.coords.longitude)

                },
                error => {
                    console.log(error.message);
                },
            )
        }

    },
    computed: {
        getLabel() {
            if (this.searchingRides) {
                return 'Please wait, finding rides nearby you...'
            } else {
                if (this.list.rides.length === 0) {
                    return 'No rides found nearby you.'
                } else {
                    return ':('
                }
            }
        }
    },
    created() {
        let vm = this
        vm.mobileNumber = this.$route.params.mobileNumber
        vm.checkUserServices()
    }
}

</script>

<style scoped>

</style>
