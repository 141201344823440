<template>
    <div>

        <div class="row">

            <div class="col-12 text-center">
                <span class="text-bold text-2xl">{{ travel.title }}</span>
                <br>
            </div>

            <hr>

            <div class="col-12">
                <label>Description</label>
                <p>{{ travel.description }}</p>
            </div>

            <div class="col-6">
                <label>Frequency</label>
                <p>{{ travel.frequency }}</p>
                <template v-if="travel.frequency_other">
                    <label>Other Frequency</label>
                    <p>{{ travel.frequency_other }}</p>
                </template>
            </div>

            <div class="col-6" style="text-align: right;">
                <label>Max Persons Allowed</label>
                <p>{{ travel.max_persons }}</p>
            </div>


            <div class="col-6">
                <label>Date</label>
                <p>{{ convertDate(travel.date) }}</p>
            </div>

            <div class="col-6" style="text-align: right;">
                <label>Time</label>
                <p>{{ convertTime(travel.time) }}</p>
            </div>

            <div class="col-12">
                <label>From</label>
                <p>{{ JSON.parse(travel.places).stops[0].value }}</p>
            </div>

            <div class="col-12">
                <label>Stops</label>
                <div v-for="stop in JSON.parse(travel.places).stops" v-bind:key="stop.value">
                    <p v-if="JSON.parse(travel.places).stops[0].value!==stop.value">{{ stop.value }}</p>
                </div>
            </div>

            <div class="col-12">
                <label>Last Stop</label>
                <p>{{ travel.last_stop }}</p>
            </div>

            <div class="row" v-if="travel.doc_pdf_url!==''">
                <div class="col-6">
                    <br>
                    <span class="btn btn-warning" @click="openLink(travel.doc_pdf_url)">
                        <img class="img-fluid" height="30" width="30"
                             src="https://icones.pro/wp-content/uploads/2021/03/icone-pdf-symbole-png-rouge.png">
                        <a :href="travel.doc_pdf_url" target="_blank"><br>Download</a></span>
                </div>
                <div class="col-6" style="text-align: right">
                    <br>
                    <span class="text-bold text-2xl">{{ stringToINR(travel.price_per_person) }}</span>
                    <p class="text-xs">*per person</p>
                </div>
            </div>


            <div class="col-12">
                <soft-button full-width size="lg" color="success" @click="showBookingDialog($event)">BOOK NOW
                </soft-button>
                <br>
                <br>
                <soft-button full-width size="lg" color="dark" @click="close">CLOSE</soft-button>
            </div>
        </div>

    </div>
</template>

<script>

import SoftButton from "../SoftButton";
import swal from "sweetalert";

export default {
    components: {SoftButton},
    props: {

        modal: {
            type: Object
        },

        mobileNumber: {
            type: String,
            required: true
        }

    },

    data() {
        return {

            travel: {
                title: '',
                description: '',
                frequency: '',
                frequency_other: 'abc',
                max_persons: '',
                date: '',
                time: '',
                doc_pdf_url: '',
                price_per_person: '',
                places: '{"stops":[{"value":""}]}',
            },


        }
    },

    methods: {
        openLink(url) {
            window.location.href = url
        },
        setTravelDetails(travel) {
            console.log(travel)
            this.travel = travel
        },
        close() {
            this.modal.$emit('close');
        },
        showBookingDialog(event) {
            const vm = this;

            const button = event.target

            swal("Enter Number of Persons", {
                content: "input",
            })
                .then((value) => {

                    if (!value) {
                        return
                    }

                    try {
                        value = parseInt(value)
                    } catch {
                        return
                    }

                    if (value < 1 || isNaN(value)) {
                        return
                    }

                    button.disabled = true

                    vm.modalOpen = true
                    swal({
                        title: "Book this travels?",
                        text: "On booking, this travels company will receive a notification and they may approve your request",
                        icon: "info",
                        buttons: ['Cancel', 'Send Request'],
                        dangerMode: false,
                    }).then((book) => {
                        if (book) {
                            window.axios.post('/travels/request/booking/' + vm.$props.mobileNumber + '/' + vm.travel.id, {
                                no_of_persons: value
                            }).then(resp => {
                                console.log(resp)
                                button.disabled = false

                                if (resp.data.response.status.error_code === 0) {
                                    vm.$toast.open({
                                        message: 'Booking Request Sent',
                                        type: 'success',
                                        position: 'top',
                                        duration: 5000,
                                        dismissible: false,
                                    })
                                }
                            }).catch(e => {
                                console.log(e)
                                button.disabled = false

                            })
                        } else {
                            button.disabled = false
                        }
                    })

                }).catch(e => {
                console.log(e)
                button.disabled = false

            })


        },
    }

}

</script>

<style scoped>

label {
    margin: 0;
    padding: 0;
}

</style>
