<template>
    <div style="overflow-x: clip;">
        <div class="row sticky-top bg-gray-100 shadow" style="padding-top: 10px;">
            <div class="col-sm-12">
                <h3>
                    <button style="border: none; background: none;" @click="goBack"><i
                        class="p-2 fas fa-arrow-left"></i></button>
                    {{ getTitle }}
                </h3>
            </div>
        </div>

        <GoogleMap
            ref="mapRef"
            disable-default-ui
            :styles=mapStyle
            api-key="AIzaSyDk4e0NV-K0_uVSlCqJNtCnKHP5qeYWzLY"
            style="position:fixed; top:0; bottom:0; right:0; left:0;"
            :center="center"
            :zoom="15">
            <template v-for="m in markers" v-bind:key="m.name">
                <Marker @click="navigate(m)" :options="m"/>
            </template>
        </GoogleMap>

    </div>
</template>

<script>
import {ref, watch} from 'vue'
import {GoogleMap, Marker} from 'vue3-google-map'


export default {
    components: {
        GoogleMap,
        Marker,
    },

    props: {
        locationType: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            center: {lat: 23.0208366, lng: 72.5086508},
            markerPosition: null,

            markers: [],

            radius: 1500, //meters

            mapStyle: [
                {
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "administrative",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "administrative.land_parcel",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "administrative.neighborhood",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "transit",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                }
            ],

            currentLocation: null,
            nextPageToken: null
        }
    },

    created() {
        let vm = this

        if (vm.$props.locationType == null || vm.$props.locationType == '') {
            vm.$router.go(-1)
        }

        navigator.geolocation.getCurrentPosition(
            position => {
                console.log(position.coords.latitude);
                console.log(position.coords.longitude);
                vm.currentLocation = position.coords.latitude + ',' + position.coords.longitude
                vm.center = {lat: position.coords.latitude, lng: position.coords.longitude}

                vm.getMarkerPoints()
            },
            error => {
                console.log(error.message);
            },
        )
    },
    methods: {
        navigate(marker){
            window.open(`https://maps.google.com/maps?daddr=${marker.position.lat},${marker.position.lng}`, '_blank')
        },
        goBack() {
            this.$router.go(-1)
        },
        getMarkerPoints() {
            const vm = this

            let nextPage = ''
            if (vm.nextPageToken != null) {
                nextPage = '&next_page_token=' + vm.nextPageToken
            } else {
                nextPage = ''
            }

            if (vm.$props.locationType === 'Transportation') {

                window.axios.get('/google/nearby?lat_lng=' + vm.currentLocation +
                    '&keyword=logistics+service&radius=' + vm.radius + nextPage
                ).then(resp => {
                    if (resp.data.response.status.error_code === 0) {
                        vm.nextPageToken = resp.data.response.data.next_page_token
                        const results = resp.data.response.data.results
                        for (let i = 0; i < results.length; i++) {
                            const latLng = results[i].location.split(',')
                            vm.markers.push({
                                position: {lat: parseFloat(latLng[0]), lng: parseFloat(latLng[1])},
                                title: results[i].address,
                                label: results[i].name,
                            })
                        }
                        if (vm.nextPageToken != null) {
                            vm.getMarkerPoints()
                        }
                    }
                })

            } else if (vm.$props.locationType === 'Courier') {
                window.axios.get('/google/nearby?lat_lng=' + vm.currentLocation +
                    '&keyword=courier+service&radius=' + vm.radius + nextPage
                ).then(resp => {
                    if (resp.data.response.status.error_code === 0) {
                        vm.nextPageToken = resp.data.response.data.next_page_token
                        const results = resp.data.response.data.results
                        for (let i = 0; i < results.length; i++) {
                            const latLng = results[i].location.split(',')
                            vm.markers.push({
                                position: {lat: parseFloat(latLng[0]), lng: parseFloat(latLng[1])},
                                title: results[i].address,
                                label: results[i].name,
                            })
                        }
                        if (vm.nextPageToken != null) {
                            vm.getMarkerPoints()
                        }
                    }
                })
            }
        }
    },
    setup() {
        const mapRef = ref(null)

        // Third pattern: watch for "ready" then do something with the API or map instance
        watch(() => mapRef.value?.ready, (ready) => {

            //alert("map ready")

            if (!ready) return

            // do something with the api using `mapRef.value.api`
            // or with the map instance using `mapRef.value.map`
        })

        return {mapRef}
    },
    computed: {
        getTitle() {
            return 'Courier Services'
        },
        getPlaceholderText() {
            if (this.isFetchingLocation) {
                return 'Please wait, fetching location...'
            } else if (this.autocompleteText != '') {
                return this.autocompleteText
            } else {
                return 'Type location name here...'
            }
        },
    }
}
</script>
