<template>
  <div class="form-group">
    <label :for="id">
      <slot />
    </label>
    <textarea
      :id="id"
      class="form-control"
      rows="3"
      :placeholder="placeholder"
      :value="modelValue"
      @input="$emit('update:modelValue',$event.target.value)"
    ></textarea>
  </div>
</template>

<script>
export default {
  name: "SoftTextarea",
  props: {
    id: {
      type: String,
      required: true,
    },
      ['modelValue']:{},
    placeholder: {
      type: String,
      default: "Your text here...",
    },
  },
    emits:[
        'update:modelValue'
    ],
};
</script>
