<template>

    <div>

        <div class="container-fluid">

            <div class="row">
                <div class="col-sm-12">
                    <br>
                    <h4>Add Your Bank Details</h4>
                    <hr>
                </div>
            </div>

            <div class="row">

                <div class="col-sm-12">
                    <label>Bank Name</label>
                    <soft-input v-model="bank_name"
                                placeholder=""></soft-input>
                </div>

                <div class="col-sm-12">
                    <label>Name on Account</label>
                    <soft-input v-model="account_name"
                                placeholder=""></soft-input>
                </div>

                <div class="col-sm-12">
                    <label>Bank Branch Name</label>
                    <soft-input v-model="bank_branch"
                                placeholder=""></soft-input>
                </div>

                <div class="col-sm-12">
                    <label>Bank Branch Address</label>
                    <soft-input v-model="address"
                                placeholder=""></soft-input>
                </div>

                <div class="col-sm-12">
                    <label>IFSC Code</label>
                    <soft-input v-model="ifsc_code"
                                placeholder=""></soft-input>
                </div>

                <div class="col-sm-12">
                    <label>Account Number</label>
                    <soft-input v-model="account_number"
                                type="password"
                                placeholder=""></soft-input>
                </div>

                <div class="col-sm-12">
                    <label>Confirm Account Number</label>
                    <soft-input v-model="confirm_account_number"
                                type="number"
                                placeholder=""></soft-input>
                </div>


            </div>

            <div class="col-12">
                <p style="color: red">*Once you add your bank details you cannot change or update it.</p>
            </div>

            <div class="row">

                <div class="col-sm-6">
                    <br>
                    <soft-button btn-type="submit" @click="close()"
                                 full-width color="dark" size="md"
                                 variant="gradient">
                        Close
                    </soft-button>
                </div>

                <div class="col-sm-6">
                    <br>
                    <soft-button btn-type="submit" full-width color="success" size="md"
                                 @click="saveBankAccountDetails"
                                 variant="gradient">
                        Save
                    </soft-button>
                    <br>
                    <br>
                </div>

            </div>

        </div>

    </div>

</template>
<script>

import SoftInput from "../SoftInput";
import SoftButton from "../SoftButton";

export default {

    components: {
        SoftInput,
        SoftButton,
    },
    props: {
        modal: {
            type: Object,
        },
        mobileNumber: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            account_name: '',
            bank_name: '',
            account_number: '',
            ifsc_code: '',
            address: '',
            confirm_account_number: '',
            bank_branch: '',
        }
    },
    methods: {
        getBankAccountDetails() {
            const vm = this

            window.axios.get('/user/get/bank/details/' + vm.$props.mobileNumber)
                .then(resp => {
                    if (resp.data.response.status.error_code === 0) {
                        if (resp.data.response.data) {
                            const details = resp.data.response.data.bank_details
                            vm.account_name = details.account_name
                            vm.bank_name = details.bank_name
                            vm.account_number = details.account_number
                            vm.ifsc_code = details.ifsc_code
                            vm.address = details.address
                            vm.confirm_account_number = details.confirm_account_number
                            vm.bank_branch = details.bank_branch
                        }
                    }
                })

        },

        saveBankAccountDetails() {
            const vm = this

            if (vm.account_name === '' || vm.bank_name === '' || vm.account_number === '' || vm.ifsc_code === '' || vm.bank_branch === '') {
                vm.$toast.open({
                    message: 'All field are required',
                    type: 'error',
                    position: 'top',
                    duration: 5000,
                    dismissible: false,
                })
                return
            }

            if (vm.account_number === vm.confirm_account_number) {

                window.axios.put('/user/add/bank/details/' + vm.$props.mobileNumber, {
                    account_name: vm.account_name,
                    bank_name: vm.bank_name,
                    bank_branch: vm.bank_branch,
                    ifsc_code: vm.ifsc_code,
                    account_number: vm.account_number,
                    confirm_account_number: vm.confirm_account_number,
                    address: vm.address,
                }).then(resp => {
                    if (resp.data.response.status.error_code === 0) {
                        vm.$toast.open({
                            message: 'Bank Details Saved',
                            type: 'success',
                            position: 'top',
                            duration: 5000,
                            dismissible: false,
                        })
                    }
                })


            } else {
                vm.$toast.open({
                    message: 'Account number mismatch',
                    type: 'error',
                    position: 'top',
                    duration: 5000,
                    dismissible: false,
                })
            }
        },

        close() {
            this.modal.$emit('close');
        },
    },
    created() {
        setTimeout(() => {
            this.getBankAccountDetails()
        }, 2500)

    }

}

</script>

<style>
p {
    padding: 0;
    margin: 0;
    font-size: 14px;
}
</style>
