import { createRouter, createWebHistory } from "vue-router";

import AddNewTourForm from "@/views/tours/AddNewTourForm.vue";
import CompanyRegistrationForm from "../views/registrations/CompanyRegistrationForm.vue";
import PublishRide from "../views/rides/PublishRide";
import ManageTours from "../views/tours/ManageTours";
import SearchTours from "../views/tours/SearchTours";
import ToursSearchResults from "../views/tours/ToursSearchResults";
import AddNewTravelsForm from "../views/travels/AddNewTravelsForm";
import ManageTravels from "../views/travels/ManageTravels";
import SearchTravels from "../views/travels/SearchTravels";
// import SearchRides from "../views/rides/SearchRides";
import PublishPackage from "../views/packages/PublishPackage";
import SearchRideResults from "../views/rides/SearchRideResults";
// import SearchPackages from "../views/packages/SearchPackages";
import AndroidPrivacyPolicy from "../views/AndroidPrivacyPolicy";
import ApplePrivacyPolicy from "../views/ApplePrivacyPolicy";
import CashFreeCheckout from "../views/CashFreeCheckout";
import GetStarted from "../views/GetStarted";
import GoogleMapLocationPicker from "../views/GoogleMapLocationPicker";
import KycApproved from "../views/KycApproved";
import LandingPage from "../views/LandingPage";
import NewRegistration from "../views/NewRegistration";
import Notifications from "../views/Notifications";
import PaymentCheckout from "../views/PaymentCheckout";
import PaymentDetails from "../views/PaymentDetails";
import PaymentSuccess from "../views/PaymentSuccess";
import ProvideServices from "../views/ProvideServices";
import RecentActivities from "../views/RecentActivities";
import RideNow from "../views/RideNow";
import SendParcel from "../views/SendParcel";
import SetDropLocationPage from "../views/SetDropLocationPage";
import Register from "../views/auth/Register";
import SignIn from "../views/auth/SignIn";
import ServiceNavigationMenu from "../views/components/ServiceNavigationMenu";
import ManagePackageRequests from "../views/packages/ManagePackageRequests";
import NearByPackageResults from "../views/packages/NearByPackageResults";
import ParcelDetails from "../views/packages/ParcelDetails";
import ParcelNotificationHandler from "../views/packages/ParcelNotificationHandler";
import SearchParcelResults from "../views/packages/SearchParcelResults";
import PhonePeCheckTransaction from "../views/phonepe/PhonePeCheckTransaction";
import PhonePeCheckout from "../views/phonepe/PhonePeCheckout";
import IndividualRegistrationForm from "../views/registrations/IndividualRegistrationForm";
import ConfirmBookRide from "../views/rides/ConfirmBookRide";
import ManageRideRequests from "../views/rides/ManageRideRequests";
import NearbyRideResults from "../views/rides/NearbyRideResults";
import RideNotificationHandler from "../views/rides/RideNotificationHandler";
import LiveRideNowView from "../views/sharing/LiveRideNowView";
import LiveRideView from "../views/sharing/LiveRideView";
import ManageSPV from "../views/spv/ManageSPV";
import PublishSPV from "../views/spv/PublishSPV";
import SearchSPV from "../views/spv/SearchSPV";
import SpvNotificationHandler from "../views/spv/SpvNotificationHandler";
import SpvSearchResults from "../views/spv/SpvSearchResults";
import TourNotificationHandler from "../views/tours/TourNotificationHandler";
import NearbyLogisticsOnMap from "../views/transport/NearbyLogisticsOnMap";
import NearbyTransportList from "../views/transport/NearbyTransportList";
import PublishTransport from "../views/transport/PublishTransport";
import SearchTransport from "../views/transport/SearchTransport";
import TravelNotificationHandler from "../views/travels/TravelNotificationHandler";
import TravelSearchResults from "../views/travels/TravelSearchResults";
import ManageAccount from "../views/user/ManageAccount";
import ManageDrivers from "../views/user/ManageDrivers";
import ManagePersonalDetails from "../views/user/ManagePersonalDetails";
import ManageVehicles from "../views/user/ManageVehicles";
import TransactionHistory from "../views/user/TransactionHistory";
import UserActivityHandler from "../views/user/UserActivityHandler";
import Wallet from "../views/user/Wallet";

const routes = [
    {
        path: "/home",
        name: "home",
        component: LandingPage,
        meta: {
            isAuthenticated: true,
            showNavbar: true
        },
    },
    {
        path: "/",
        name: "getStarted",
        component: GetStarted,
        meta: {
            guest: true,
            showNavbar: false
        },
    },
    {
        path: "/sign-in",
        name: "sign-in",
        component: SignIn,
        meta: {
            guest: true,
            showNavbar: false
        },
    },
    {
        path: "/register",
        name: "register",
        component: Register,
        meta: {
            guest: true,
            showNavbar: false
        },
    },
    {
        path: "/services",
        name: "services",
        component: ProvideServices,
        meta: {
            isAuthenticated: true,
            showNavbar: true
        }
    },
    {
        path: "/notifications",
        name: "notifications",
        component: Notifications,
        meta: {
            isAuthenticated: true,
            showNavbar: false
        }
    },
    {
        path: "/recent-activities",
        name: "recent-activities",
        component: RecentActivities,
        meta: {
            isAuthenticated: true,
            showNavbar: true
        }
    },

    {
        path: "/travels-add-new/:mobileNumber",
        name: "Travels Add New Form",
        component: AddNewTravelsForm,
        meta: {
            guest: true
        }
    },

    {
        path: '/user/:mobileNumber/cashfree/checkout',
        component: CashFreeCheckout,
        meta: {
            guest: true
        },
    },

    {
        path: '/user/:mobileNumber/manage/account',
        component: ManageAccount,
        name: 'user-manage-account',
        meta: {
            isAuthenticated: true
        },
    },

    {
        path: '/user/:mobileNumber/manage/vehicles',
        component: ManageVehicles,
        name: 'account-manage-vehicles',
        meta: {
            isAuthenticated: true
        },
    },

    {
        path: '/user/:mobileNumber/manage/drivers',
        component: ManageDrivers,
        name: 'account-manage-drivers',
        meta: {
            isAuthenticated: true
        },
    },

    {
        path: '/user/:mobileNumber/manage/personal',
        component: ManagePersonalDetails,
        name: 'account-manage-personal-details',
        meta: {
            isAuthenticated: true
        },
    },

    {
        path: '/user/register/:mobileNumber',
        component: NewRegistration,
        name: 'ride-register',
        meta: {
            isAuthenticated: true
        },
    },

    {
        path: '/user/register/company',
        name: 'ride-register-company',
        props: true,
        component: CompanyRegistrationForm,
        meta: {
            isAuthenticated: true
        },
    },

    {
        path: '/user/register/individual',
        name: 'ride-register-individual',
        props: true,
        component: IndividualRegistrationForm,
        meta: {
            isAuthenticated: true
        },
    },

    {
        path: "/tours/:mobileNumber",
        name: "tours",
        redirect: {name: 'tours-menu'},
        meta: {
            isAuthenticated: true
        },
        children: [
            {
                path: 'menu',
                component: ServiceNavigationMenu,
                name: "tours-menu",
                meta: {
                    isAuthenticated: true
                }
            },
            {
                path: 'register',
                component: IndividualRegistrationForm,
                meta: {
                    isAuthenticated: true
                },
            },
            {
                path: 'publish',
                name: 'publish-tour',
                component: AddNewTourForm,
                meta: {
                    isAuthenticated: true
                },
            },
            {
                path: 'manage',
                name: 'manage-tours',
                component: ManageTours,
                meta: {
                    isAuthenticated: true
                },
            },
            {
                path: 'search',
                name: 'search-tours',
                component: SearchTours,
                meta: {
                    isAuthenticated: true
                },
            },
            {
                path: 'search/results',
                name: 'tour-search-results',
                component: ToursSearchResults,
                props: true,
                meta: {
                    isAuthenticated: true
                },
            },
            {
                path: 'handle/notification/:notificationType/:bookingId',
                name: 'handle-tour-notification',
                component: TourNotificationHandler,
                props: true,
                meta: {
                    isAuthenticated: true
                },
            },
        ],
    },

    {
        path: "/travels/:mobileNumber",
        name: "travels",
        redirect: {name: 'travels-menu'},
        meta: {
            isAuthenticated: true
        },
        children: [
            {
                path: 'menu',
                component: ServiceNavigationMenu,
                name: "travels-menu",
                meta: {
                    isAuthenticated: true
                }
            },
            {
                path: 'register',
                component: IndividualRegistrationForm,
                meta: {
                    isAuthenticated: true
                },
            },
            {
                path: 'publish',
                name: 'publish-travels',
                component: AddNewTravelsForm,
                meta: {
                    isAuthenticated: true
                },
            },
            {
                path: 'manage',
                name: 'manage-travels',
                component: ManageTravels,
                meta: {
                    isAuthenticated: true
                },
            },
            {
                path: 'search',
                component: SearchTravels,
                meta: {
                    isAuthenticated: true
                },
            },
            {
                path: 'search/results',
                name: 'travel-search-results',
                component: TravelSearchResults,
                props: true,
                meta: {
                    isAuthenticated: true
                },
            },
            {
                path: 'handle/notification/:notificationType/:bookingId',
                name: 'handle-travel-notification',
                component: TravelNotificationHandler,
                props: true,
                meta: {
                    isAuthenticated: true
                },
            },
        ],
    },

    {
        path: "/ride/:mobileNumber",
        name: "ride",
        redirect: {name: 'ride-menu'},
        meta: {
            isAuthenticated: true
        },
        children: [
            {
                path: 'menu',
                component: ServiceNavigationMenu,
                name: "ride-menu",
                meta: {
                    isAuthenticated: true
                }
            },
            {
                path: 'register',
                component: IndividualRegistrationForm,
                meta: {
                    isAuthenticated: true
                },
            },
            {
                path: 'publish',
                name: 'publish-ride',
                component: PublishRide,
                meta: {
                    isAuthenticated: true
                },
            },
            {
                path: 'manage',
                name: 'manage-ride',
                component: ManageRideRequests,
                meta: {
                    isAuthenticated: true
                },
            },
            {
                path: 'search',
                name: 'ride-search',
                component: RideNow,
                meta: {
                    isAuthenticated: true
                },
            },
            {
                path: 'drop/location',
                name: 'set-drop-location',
                component: SetDropLocationPage,
                meta: {
                    isAuthenticated: true
                },
            },
            // {
            //     path: 'search',
            //     name: 'ride-search',
            //     component: SearchRides,
            //     meta: {
            //         isAuthenticated: true
            //     },
            // },
            {
                path: 'search/results',
                name: 'ride-search-results',
                component: SearchRideResults,
                props: true,
                meta: {
                    isAuthenticated: true
                },
            },
            {
                path: 'search/nearby',
                name: 'ride-nearby-results',
                component: NearbyRideResults,
                props: route => Object.assign({}, route.query, route.params),
                meta: {
                    isAuthenticated: true
                },
            },
            {
                path: 'confirm/booking',
                name: 'confirm-ride-booking',
                component: ConfirmBookRide,
                props: true,
                meta: {
                    isAuthenticated: true
                },
            },
            {
                path: 'handle/notification/:notificationType/:bookingId',
                name: 'handle-ride-notification',
                component: RideNotificationHandler,
                props: true,
                meta: {
                    isAuthenticated: true
                },
            },
        ],
    },

    {
        path: "/package/:mobileNumber",
        name: "package",
        redirect: {name: 'package-menu'},
        meta: {
            isAuthenticated: true
        },
        children: [
            {
                path: 'menu',
                component: ServiceNavigationMenu,
                name: "package-menu",
                meta: {
                    isAuthenticated: true
                }
            },
            {
                path: 'register',
                component: IndividualRegistrationForm,
                meta: {
                    isAuthenticated: true
                },
            },
            {
                path: 'publish',
                name: 'publish-parcel',
                component: PublishPackage,
                meta: {
                    isAuthenticated: true
                },
            },
            {
                path: 'manage',
                component: ManagePackageRequests,
                name: 'manage-package',
                meta: {
                    isAuthenticated: true
                },
            },
            {
                path: 'search',
                name: 'parcel-search',
                component: SendParcel,
                meta: {
                    isAuthenticated: true
                },
            },
            // {
            //     path: 'search',
            //     name: 'parcel-search',
            //     component: SearchPackages,
            //     meta: {
            //         isAuthenticated: true
            //     },
            // },
            {
                path: 'search/results',
                name: 'parcel-search-results',
                component: SearchParcelResults,
                props: true,
                meta: {
                    isAuthenticated: true
                },
            },
            {
                path: 'details',
                name: 'add-parcel-details',
                component: ParcelDetails,
                props: true,
                meta: {
                    isAuthenticated: true
                },
            },
            {
                path: 'handle/notification/:notificationType/:bookingId',
                name: 'handle-parcel-notification',
                component: ParcelNotificationHandler,
                meta: {
                    isAuthenticated: true
                },
            },
            {
                path: 'search/nearby',
                name: 'package-nearby-results',
                component: NearByPackageResults,
                props: route => Object.assign({}, route.query, route.params),
                meta: {
                    isAuthenticated: true
                },
            },
        ],
    },

    {
        path: "/transport/:mobileNumber",
        name: "transport",
        redirect: {name: 'transport-menu'},
        meta: {
            isAuthenticated: true
        },
        children: [
            {
                path: 'menu',
                component: ServiceNavigationMenu,
                name: "transport-menu",
                meta: {
                    isAuthenticated: true
                }
            },
            {
                path: 'register',
                component: IndividualRegistrationForm,
                meta: {
                    isAuthenticated: true
                },
            },
            {
                path: 'publish',
                component: PublishTransport,
                name: 'publish-transport',
                meta: {
                    isAuthenticated: true
                },
            },
            {
                path: 'manage',
                component: ManagePackageRequests,
                meta: {
                    isAuthenticated: true
                },
            },
            {
                path: 'search',
                component: SearchTransport,
                meta: {
                    isAuthenticated: true
                },
            },
        ],
    },

    {
        path: "/spv/:mobileNumber",
        name: "spv",
        redirect: {name: 'spv-menu'},
        meta: {
            isAuthenticated: true
        },
        children: [
            {
                path: 'menu',
                component: ServiceNavigationMenu,
                name: "spv-menu",
                meta: {
                    isAuthenticated: true
                }
            },
            {
                path: 'register',
                component: IndividualRegistrationForm,
                meta: {
                    isAuthenticated: true
                },
            },
            {
                path: 'publish',
                name: 'publish-spv',
                component: PublishSPV,
                meta: {
                    isAuthenticated: true
                },
            },
            {
                path: 'manage',
                name: 'manage-spv',
                component: ManageSPV,
                meta: {
                    isAuthenticated: true
                },
            },
            {
                path: 'search',
                name: 'search-spv',
                component: SearchSPV,
                meta: {
                    isAuthenticated: true
                },
            },
            {
                path: 'search/results',
                name: 'spv-search-results',
                component: SpvSearchResults,
                props: true,
                meta: {
                    isAuthenticated: true
                },
            },
            {
                path: 'confirm/booking',
                name: 'confirm-spv-booking',
                component: ConfirmBookRide,
                props: true,
                meta: {
                    isAuthenticated: true
                },
            },
            {
                path: 'handle/notification/:notificationType/:bookingId',
                name: 'handle-spv-notification',
                component: SpvNotificationHandler,
                props: true,
                meta: {
                    isAuthenticated: true
                },
            },
        ],
    },


    {
        path: "/location/picker",
        name: "map-location-picker",
        component: GoogleMapLocationPicker,
        props: true,
        meta: {
            isAuthenticated: true
        }
    },

    {
        path: "/nearby/logistics",
        name: "map-nearby-logistics",
        component: NearbyLogisticsOnMap,
        props: true,
        meta: {
            isAuthenticated: true
        }
    },

    {
        path: "/nearby/transportation/list",
        name: "map-nearby-transportations",
        component: NearbyTransportList,
        props: true,
        meta: {
            isAuthenticated: true
        }
    },

    {
        path: "/user/:mobileNumber/wallet",
        name: "user-wallet",
        component: Wallet,
        meta: {
            isAuthenticated: true
        }
    },

    {
        path: "/user/:mobileNumber/transaction/history",
        name: "user-transaction-history",
        component: TransactionHistory,
        meta: {
            isAuthenticated: true
        }
    },

    {
        path: "/user/:mobileNumber/activities/:activityType/:serviceType/:id",
        name: "user-activities",
        component: UserActivityHandler,
        meta: {
            isAuthenticated: true
        }
    },

    {
        path: "/payment/checkout",
        name: "payment-checkout",
        component: PaymentCheckout,
        props: true,
        meta: {
            isAuthenticated: true
        }
    },

    {
        path: "/phonepe/payment/checkout",
        name: "phonepe-payment-checkout",
        component: PhonePeCheckout,
        props: true,
        meta: {
            isAuthenticated: true
        }
    },

    {
        path: "/payment/details",
        name: "payment-details",
        component: PaymentDetails,
        props: true,
        meta: {
            isAuthenticated: true
        }
    },

    {
        path: "/payment/success",
        name: "payment-success",
        component: PaymentSuccess,
        props: true,
        meta: {
            isAuthenticated: true
        }
    },

    {
        path: '/phonepe/check/transaction/:serviceType/:bookingId',
        component: PhonePeCheckTransaction,
        meta: {
            isAuthenticated: true
        }
    },

    {
        path: "/kyc/approved",
        name: "kyc-approved",
        component: KycApproved,
        meta: {
            isAuthenticated: true
        }
    },

    {
        path: "/android/privacy/policy",
        component: AndroidPrivacyPolicy,
        meta: {
            guest: true
        }
    },

    {
        path: "/ios/privacy/policy",
        component: ApplePrivacyPolicy,
        meta: {
            guest: true
        }
    },

    {
        path: "/share/live/status/:serviceType/:broadcastId/:key",
        name: "live-ride-view",
        component: LiveRideView,
        meta: {
            guest: true
        }
    },

    {
        path: "/share/now/status/:serviceType/:broadcastId/:key",
        name: "live-ride-now-view",
        component: LiveRideNowView,
        meta: {
            guest: true
        }
    },

];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    linkActiveClass: "active",
});

export default router;
