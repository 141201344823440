<template>
    <div class="form-check">
        <input
            :id="id"
            class="form-check-input"
            type="checkbox"
            :name="name"
            :checked="checked"
            :value="value"
            :disabled="disabled"
            @change="$emit('update:modelValue',$event.target.value)"
        />
        <label :for="id" class="custom-control-label" :class="$attrs.class">
            <slot/>
        </label>
    </div>
</template>

<script>
export default {
    name: "SoftCheckbox",
    props: {
        name: {
            type: String,
            default: "",
        },
        value: {
            type: String,
            required: true,
        },
        id: {
            type: String,
            default: "",
        },
        checked: {
            type: Boolean,
            default: false,
        },
        disabled:{
            type: Boolean,
            default: false,
        },
        ['modelValue']: {},
    },
    emits: [
        'update:modelValue'
    ],
};
</script>
