<template>
    <div style="overflow-x: clip;">
        <div class="row sticky-top bg-gray-100 shadow" style="padding-top: 10px;">
            <div class="col-sm-12">
                <h3>
                    <button style="border: none; background: none;" @click="goBack"><i
                        class="p-2 fas fa-arrow-left"></i></button>
                    Manage Drivers
                </h3>
            </div>
        </div>
        <div class="container-fluid">
            <br>
            <div class="row">
                <template v-for="driver in drivers" :key="driver.id">
                    <div class="col-12">
                        <div class="card"
                             @click="showDriverDetails(driver)">
                            <div class="card-body">

                                <div class="row">
                                    <div class="col-4">
                                        <img class="img-fluid" style="border-radius: 5px;"
                                             :src="driver.photo_url">
                                    </div>
                                    <div class="col-8">
                                        <div class="text-xs text-bold" style="padding: 0; margin: 0;">Name</div>
                                        <div class="text-xs" style="padding: 0; margin-bottom: 10px;">
                                            {{ driver.name }}
                                        </div>
                                        <div class="text-xs text-bold" style="padding: 0; margin: 0;">Contact No
                                        </div>
                                        <div class="text-xs" style="padding: 0; margin: 0;">
                                            {{ driver.contact_no }}
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                        <br>
                    </div>
                </template>
            </div>
            <soft-button @click="addDriver" style="position: fixed; bottom: 20px; right: 20px; padding: 5px 20px;"
                         class="text-2xl">+
            </soft-button>

        </div>


        <Modal
            ref="modal"
            v-show="addingDriver"
            @close="closeModal">
            <template v-slot:body>
                <AddDriver ref="driverModal" :modal="$refs.modal" :mobile-number="mobileNumber"
                            :is="AddDriverComponent"></AddDriver>
            </template>
        </Modal>


    </div>
</template>
<script>

import Modal from "../../components/Modal"
import SoftButton from "../../components/SoftButton";
import AddDriver from "../../components/modals/AddDriver"

export default {
    components: {SoftButton, AddDriver, Modal},
    data() {
        return {
            addingDriver: false,

            AddDriverComponent: AddDriver,
            mobileNumber: null,
            drivers: []
        }
    },
    methods: {
        goBack() {
            //this.$router.go(-1)
            window.ReactNativeWebView.postMessage("close-screen")
        },
        addDriver() {
            this.addingDriver = true
        },
        closeModal() {
            this.$refs.driverModal.setDriverData(null)
            this.addingDriver = false;
            this.getDriverList()
        },
        showDriverDetails(vehicle) {
            this.$refs.driverModal.setDriverData(vehicle)
            this.addDriver()
        },
        getDriverList() {
            const vm = this
            window.axios.get('/driver/' + vm.mobileNumber + '/list')
                .then(resp => {
                    if (resp.data.response.status.error_code == 0) {
                        vm.drivers = resp.data.response.data.drivers
                    }
                })
        }
    },
    mounted() {
        this.mobileNumber = this.$route.params.mobileNumber
        this.getDriverList()
    }
}

</script>
