<template>
    <div style="overflow-x: clip;">
        <div class="row sticky-top bg-gray-100 shadow" style="padding-top: 10px;">
            <div class="col-sm-12">
                <h3>
                    <button style="border: none; background: none;" @click="goBack"><i
                        class="p-2 fas fa-arrow-left"></i></button>
                    Registration
                </h3>
            </div>
        </div>
        <div class="container-fluid">
            <br>
            <div class="row">
                <div class="col-6">
                    <div class="card" style="background-color: #2f194d; color: white;" @click="individualRegistration">
                        <div class="card-body text-center">
                            <i class="fa fa-solid fa-user fa-4x"></i>
                            <br>
                            <br>
                            <span>Individual</span>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="card" style="background-color: #2f194d; color: white;" @click="companyRegistration">
                        <div class="card-body text-center">
                            <i class="fa fa-solid fa-building fa-4x"></i>
                            <br>
                            <br>
                            <span>Company</span>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>

export default {
    data() {
        return {
            mobileNumber: null
        }
    },
    components: {},
    methods: {
        companyRegistration() {
            this.$router.replace({
                name: 'ride-register-company', params: {
                    mobileNumber: this.mobileNumber
                }
            })
        },
        individualRegistration() {
            this.$router.replace({
                name: 'ride-register-individual', params: {
                    mobileNumber: this.mobileNumber
                }
            })
        },
        goBack() {
            window.ReactNativeWebView.postMessage("close-screen")
        }
    },
    created() {
        this.mobileNumber = this.$route.params.mobileNumber
    }
}

</script>

<style scoped>
</style>