<template>

    <div>

        <div class="container-fluid">

            <div class="row">
                <div class="col-sm-12">
                    <h4>Add/Select Vehicle Details</h4>
                    <hr>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <label>Select Vehicle</label>
                    <select class="custom-select form-control">
                        <template v-for="vehicle in vehicles" :key="vehicle.id">
                            <option :value="vehicle.id">
                                {{ vehicle.model_name + " (" + vehicle.registered_number + ")" }}
                            </option>
                        </template>
                    </select>
                </div>
                <div class="col-sm-12">
                    <br>
                    <soft-button btn-type="submit" @click="addTour" full-width color="success" size="md"
                                 variant="gradient">
                        Confirm
                    </soft-button>
                    <hr>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12">
                    <label>Vehicle Model</label>
                    <soft-input v-model="form.fromPinCode"
                                placeholder=""></soft-input>
                </div>

                <div class="col-sm-12">
                    <label>Vehicle Number</label>
                    <soft-input v-model="form.fromPinCode"
                                placeholder=""></soft-input>
                </div>


                <div class="col-sm-12">
                    <soft-checkbox v-model="form.adventure" id="adventure" name="adventure">All India Permit
                    </soft-checkbox>
                </div>

                <div class="col-sm-12">
                    <soft-checkbox v-model="form.adventure" id="adventure" name="adventure">Air Conditioned (AC)
                    </soft-checkbox>
                </div>

                <div class="row">
                    <label>Vehicle Type</label>
                    <div class="col-12">
                        <soft-radio v-model="form.acNonAc" name="radio" value="AC-Seating" id="radio1">
                            <slot>Personal</slot>
                        </soft-radio>
                    </div>
                    <div class="col-12">
                        <soft-radio v-model="form.acNonAc" value="AC-Sleeper" id="radio4" name="radio">
                            <slot>Rented</slot>
                        </soft-radio>
                    </div>
                    <div class="col-12">
                        <soft-radio v-model="form.acNonAc" value="AC-Sleeper" id="radio4" name="radio">
                            <slot>Company</slot>
                        </soft-radio>
                    </div>
                </div>

                <div class="col-sm-12">
                    <label>Vehicle Passenger Capacity</label>
                    <soft-input v-model="form.fromPinCode"
                                placeholder=""></soft-input>
                </div>

            </div>

            <div class="row">
                <div class="col-12">
                    <label>Front Photo</label>
                    <br>
                    <input accept="image/*" type="file" id="front-photo">
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <label>Back Photo</label>
                    <br>
                    <input accept="image/*" type="file" id="back-photo">
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <label>Right Side Photo</label>
                    <br>
                    <input accept="image/*" type="file" id="right-photo">
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <label>Left Side Photo</label>
                    <br>
                    <input accept="image/*" type="file" id="left-photo">
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <label>RC Book Photo</label>
                    <br>
                    <input accept="image/*" type="file" id="rc-book-photo">
                </div>
            </div>

            <div class="row">
                <div class="col-sm-6">
                    <br>
                    <soft-button btn-type="submit" @click="close()"
                                 full-width color="dark" size="md"
                                 variant="gradient">
                        Close
                    </soft-button>
                </div>

                <div class="col-sm-6">
                    <br>
                    <soft-button btn-type="submit" @click="addTour" full-width color="success" size="md"
                                 variant="gradient">
                        Add
                    </soft-button>
                </div>
            </div>

        </div>

    </div>

</template>
<script>

import SoftInput from "../SoftInput";
import SoftButton from "../SoftButton";
import SoftCheckbox from "../SoftCheckbox";
import SoftRadio from "../SoftRadio";

export default {

    components: {
        SoftRadio,
        SoftCheckbox,
        SoftInput,
        SoftButton
    },
    props: {
        modal: {
            type: Object,
        },
        mobileNumber: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            form: {
                fromPinCode: ''
            },
            vehicles: [],
        }
    },
    methods: {
        close() {
            this.modal.$emit('close');
        },
        getVehicleList() {
            const vm = this
            window.axios.get('/vehicle/' + vm.$props.mobileNumber + '/list')
                .then(resp => {
                    if (resp.data.response.status.error_code == 0) {
                        vm.vehicles = resp.data.response.data.vehicles
                    }
                })
        }
    },
    created() {
        this.getVehicleList()
    }

}

</script>
