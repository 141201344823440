<template>
    <div class="trans-modal-backdrop">
        <div class="trans-modal">

            <section class="trans-modal-body">
                <slot name="body">
                    This is the default body!
                </slot>
            </section>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Modal',
    methods: {
        close() {
            this.$emit('close-modal');
        },
    },
};
</script>
<style>
.trans-modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1030;
}

.trans-modal {
    background-color: white;
    padding-left: 10px;
    padding-right: 10px;
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    outline: 0;
}

.trans-modal-header,
.trans-modal-footer {
    padding: 15px;
    display: flex;
}

.trans-modal-header {
    position: relative;
    border-bottom: 1px solid transparent;
    color: #4AAE9B;
    justify-content: space-between;
    margin-bottom: 50px;
}

.trans-modal-footer {
    border-top: 1px solid #eeeeee;
    flex-direction: column;
    justify-content: flex-end;
}

.trans-modal-body {
    background-color: white;
    position: relative;
    margin: 0 auto;
    border-radius: 0px;
}

.trans-btn-close {
    top: 0;
    right: 0;
    height: 20px;
    width: 20px;
    border:none;
    border-radius: 35px;
    font-size: 20px;
    cursor: pointer;
    font-weight: bold;
    color: white;
    background: red;
}

.trans-btn-green {
    color: white;
    background: #4AAE9B;
    border: 1px solid #4AAE9B;
    border-radius: 2px;
}
</style>
