<template>
    <div style="overflow-x: clip;" class="have-sticky-header">

        <Header></Header>

        <div class="container">

            <div class="row">

                <div class="col-12">
                    <carousel :autoplay="2000" :wrap-around="true">
                        <slide v-for="slide in 10" :key="slide">
                            <div class="carousel__item">{{ slide }}</div>
                        </slide>

                        <template #addons>
                            <pagination/>
                        </template>
                    </carousel>

                </div>

            </div>

            <div class="product-card">
                <a href="/ride/9409210488/search" class="card ride-now">
                    <div class="card-body">
                        <span>Ride Now</span>
                        <img src="../assets/img/landing-icon/01.png" alt="Ride Now">
                    </div>
                </a>
                <a href="/package/9409210488/search" class="card send-parcel">
                    <div class="card-body">
                        <span>Send Parcel</span>
                        <img src="../assets/img/landing-icon/02.png" alt="Send Parcel">
                    </div>
                </a>
                <a class="card book-tour">
                    <div class="card-body">
                        <span>Book Tour</span>
                        <img src="../assets/img/landing-icon/03.png" alt="Book Tour">
                    </div>
                </a>                    
                <a class="card courier-transport">
                    <div class="card-body">
                        <span>Courier & Transport</span>
                        <img src="../assets/img/landing-icon/04.png" alt="Courier & Transport">
                    </div>
                </a>
                <a class="card book-travels">
                    <div class="card-body">
                        <span>Book Travels</span>
                        <img src="../assets/img/landing-icon/05.png" alt="Book Travels">
                    </div>
                </a>
                <a class="card rent-vehicle">
                    <div class="card-body">
                        <span>Rent Vehicle</span>
                        <img src="../assets/img/landing-icon/06.png" alt="Rent Vehicle">
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>
<script>

import { Carousel, Pagination, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
import Header from '../examples/PageLayout/Header.vue'

export default {

    data() {
        return {}
    },

    components: {
        Carousel,
        Pagination,
        Slide,
        Header
    },


    methods: {},

    mounted() {
        const vm = this;
        if (!vm.$store.state.user.id) {
            vm.$store.dispatch('fetchAndSetUserDetails');
        }
    },

    created() { }
}

</script>

<style lang="scss" scoped>
.product-card{
    display: flex;
    flex-flow: row wrap;
    gap: 15px;
    margin-top: 30px;
    padding-bottom: 15px;
    .card{
        flex: 1 1 calc(50% - 8px);
        border-radius: 12px;
        background-color: red;
        &.ride-now{
            background-color: #23C478;
            .card-body{
                span{
                    &::after{
                        color: #23C478;
                    }
                }
            }
        }
        &.send-parcel{
            background-color: #103783;
            .card-body{
                span{
                    &::after{
                        color: #103783;
                    }
                }
            }
        }
        &.book-tour{
            background-color: #D39121;
            .card-body{
                span{
                    &::after{
                        color: #D39121;
                    }
                }
            }
        }
        &.courier-transport{
            background-color: #3B7AFF;
            .card-body{
                span{
                    &::after{
                        color: #3B7AFF;
                    }
                }
            }
        }
        &.book-travels{
            background-color: #008787;
            .card-body{
                span{
                    &::after{
                        color: #008787;
                    }
                }
            }
        }
        &.rent-vehicle{
            background-color: #FF71B5;
            .card-body{
                span{
                    &::after{
                        color: #FF71B5;
                    }
                }
            }
        }
        .card-body{
            padding: 12px 10px 15px 15px;
            display: flex;
            flex-flow: column;
            height: 130px;
            span{
                color: #ffffff;
                font-weight: 700;
                font-size: 14px;
                display: block;
                margin-bottom: 10px;
                &:after{
                    content: "\f061";
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                    color: red;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 12px;
                    width: 20px;
                    height: 20px;
                    background-color: #fff;
                    border-radius: 20px;
                    position: relative;
                    top:3px;
                }
            }
            img{
                max-width: 60px;
                max-height: 60px;
                display: flex;
                align-self: end;
                position: absolute;
                bottom: 15px;
                right: 15px;
            }
        }
    }
}
.carousel__item {
    min-height: 200px;
    width: 100%;
    background-color: var(--vc-clr-primary);
    color: var(--vc-clr-white);
    font-size: 20px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.carousel__slide {
    padding: 10px;
}

.container{
    padding: 0 1rem !important;
}
</style>
