<template>
    <div style="overflow-x: clip;">
        <div class="row sticky-top bg-gray-100 shadow" style="padding-top: 10px;">
            <div class="col-sm-12">
                <h3>
                    <button style="border: none; background: none;" @click="goBack"><i
                        class="p-2 fas fa-arrow-left"></i></button>
                    Personal Details
                </h3>
            </div>
        </div>
        <div class="container-fluid">
            <br>
            <div class="row">

                <div class="col-12">
                    <label>Legal First Name</label>
                    <soft-input v-model="firstName"></soft-input>
                </div>

                <div class="col-12">
                    <label>Legal Last Name</label>
                    <soft-input v-model="lastName"></soft-input>
                </div>

                <div class="col-12">
                    <label>Personal Email Id</label>
                    <soft-input v-model="email"></soft-input>
                </div>

                <div class="col-12">
                    <label>Mobile Number (Used for login, cannot be changed.)</label>
                    <soft-input disabled v-model="mobileNumber"></soft-input>
                </div>

                <div class="col-12">
                    <br>
                    <soft-button full-width color="success" @click="updateUserBasicDetails">Save & Update</soft-button>
                </div>

            </div>
        </div>
    </div>
</template>
<script>

import SoftInput from "../../components/SoftInput";
import SoftButton from "../../components/SoftButton";

export default {
    components: {SoftButton, SoftInput},
    data() {
        return {
            mobileNumber: null,
            firstName: '',
            lastName: '',
            email: '',
        }
    },
    methods: {
        goBack() {
            //this.$router.go(-1)
            window.ReactNativeWebView.postMessage("close-screen")
        },
        getUserBasicDetails() {
            const vm = this
            window.axios.get('/user/get/basic/details/' + vm.mobileNumber)
                .then(resp => {
                    if (resp.data.response.status.error_code === 0) {
                        vm.firstName = resp.data.response.data.user.first_name
                        vm.lastName = resp.data.response.data.user.last_name
                        vm.email = resp.data.response.data.user.email
                    }
                })
        },
        updateUserBasicDetails() {
            const vm = this

            window.axios.post('/user/update/basic/details/' + vm.mobileNumber, {
                first_name: vm.firstName,
                last_name: vm.lastName,
                email: vm.email
            })
                .then(resp => {
                    if (resp.data.response.status.error_code === 0) {
                        vm.$toast.open({
                            message: 'Details Updated',
                            type: 'success',
                            position: 'top',
                            duration: 5000,
                            dismissible: false,
                        })
                    }
                })
        }
    },
    mounted() {
        this.mobileNumber = this.$route.params.mobileNumber
        this.getUserBasicDetails()
    }
}

</script>
