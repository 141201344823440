<template>
    <div class="install-prompt alert text-white font-weight-bold alert-dark" role="alert" v-if="deferredPrompt">
        <soft-button @click="install">Install app</soft-button>
        <span aria-hidden="true" class="text-lg font-weight-bold" @click="dismiss">&times;</span>
    </div>
    <!-- Not in use for now -->
    <!-- <sidenav
        :custom_class="this.$store.state.mcolor"
        :class="[
      this.$store.state.isTransparent,
      this.$store.state.isRTL ? 'fixed-end' : 'fixed-start',
    ]"
        v-if="this.$store.state.showSidenav && this.$store.getters.getJwtToken!==''"
    /> -->
    <main
        class="main-content position-relative max-height-vh-100 h-100 border-radius-lg"
        :style="this.$store.state.isRTL ? 'overflow-x: hidden' : ''"
    >
        <router-view v-slot="{ Component, route }">
            <transition name="slide-fade" mode="out-in">
                <div :key="route.name">
                    <component :is="Component"></component>
                </div>
            </transition>
        </router-view>
        <!-- bottom nav -->
        <navbar v-if="this.$store.state.showNavbar || this.$store.getters.getJwtToken!==''"/>

        <app-footer v-show="this.$store.state.showFooter && this.$store.getters.getJwtToken!==''"/>
        <!-- <configurator v-if="this.$store.getters.getJwtToken!==''"
                      :toggle="toggleConfigurator"
                      :class="[
        this.$store.state.showConfig ? 'show' : '',
        this.$store.state.hideConfigButton ? 'd-none' : '',
      ]"
        /> -->
    </main>
</template>
<script>
import Configurator from "@/examples/Configurator.vue";
import AppFooter from "@/examples/Footer.vue";
import Navbar from "@/examples/Navbars/Navbar.vue";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { mapMutations } from "vuex";
import SoftAlert from "./components/SoftAlert.vue";
import firebaseConfig from "./config/firebase.config";
import Sidenav from "./examples/Sidenav";
import locationService from "./services/location.service";
import notificationService from './services/notification.service';
initializeApp(firebaseConfig);
const messaging = getMessaging();

onMessage(messaging, (payload) => {
  console.log('Message received. ', payload);
});
export default {
    name: "App",
    data() {
        return {
            deferredPrompt: null
        }
    },
    components: {
        Sidenav,
        Configurator,
        Navbar,
        AppFooter,
        SoftAlert
    },
    methods: {
        ...mapMutations(["toggleConfigurator", "navbarMinimize","showAll"]),
        async dismiss() {
            this.deferredPrompt = null;
            document.body.classList.remove('prompt-install-dialog');
        },
        async install() {
            this.deferredPrompt.prompt();
        }
    },
    computed: {
        navClasses() {
            return {
                "position-sticky blur shadow-blur mt-4 left-auto top-1 z-index-sticky": this
                    .$store.state.isNavFixed,
                "position-absolute px-4 mx-0 w-100 z-index-2": this.$store.state
                    .isAbsolute,
                "px-0 mx-4 mt-4": !this.$store.state.isAbsolute,
            };
        },
    },
    beforeMount() {
        if(this.$store.getters.getJwtToken!==''){
            this.showAll()
        }
        this.$store.state.isTransparent = "bg-transparent";
    },
    async created() {
        await notificationService.askBrowserPermission();
        window.addEventListener("beforeinstallprompt", e => {
            e.preventDefault();
            // Stash the event so it can be triggered later.
            this.deferredPrompt = e;
            console.log("🚀 ~ created ~ e:", e)
            document.body.classList.add('prompt-install-dialog');
        });
        window.addEventListener("appinstalled", () => {
            this.deferredPrompt = null;
        });

        locationService.requestGeolocation();
        locationService.watchGeolocationPosition();

        getToken(messaging, { vapidKey: process.env.VUE_APP_PUBLIC_VAPID_KEY }).then((currentToken) => {
        if (currentToken) {
            // Send the token to your server and update the UI if necessary
            console.log("Token is:",currentToken);
            // ...
        } else {
            // Show permission request UI
            console.log('No registration token available. Request permission to generate one.');
            // ...
        }
        }).catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
        // ...
        });
    }
};
</script>

<style>
.slide-fade-enter-active {
    transition: all .1s ease;
}

.slide-fade-leave-active {
    transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */
{
    transform: translateX(10px);
    opacity: 0;
}

.scale-slide-enter-active,
.scale-slide-leave-active {
    position: absolute;
    transition: all 0.85s ease;
}
.scale-slide-enter-from {
    left: -100%;
}
.scale-slide-enter-to {
    left: 0%;
}
.scale-slide-leave-from {
    transform: scale(1);
}
.scale-slide-leave-to {
    transform: scale(0.8);
}

.slide-enter-active,
.slide-leave-active {
    transition: all 0.75s ease-out;
}
.slide-enter-to {
    position: absolute;
    right: 0;
}
.slide-enter-from {
    position: absolute;
    right: -100%;
}
.slide-leave-to {
    position: absolute;
    left: -100%;
}
.slide-leave-from {
    position: absolute;
    left: 0;
}

.install-prompt {
    display: flex;
    justify-content: space-between;
}
</style>
