<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
            </div>
        </div>
    </div>
</template>
<script>

export default {

    data() {
        return {
            script: "https://sdk.cashfree.com/js/ui/2.0.0/cashfree.sandbox.js",
        }
    },

    methods: {
        async loadRazorPay() {
            return new Promise(resolve => {
                const script = document.createElement('script')
                script.src = this.script
                script.onload = () => {
                    resolve(true)
                }
                script.onerror = () => {
                    resolve(false)
                }
                document.body.appendChild(script)
            })
        }
    },

    async created() {

        const result = await this.loadRazorPay()
        if (!result) {
            alert('Failed to load razorpay script')
            return
        }

        const paymentSessionId = "session_KenVyI__eYeZrlKUcnfqXucL12MIcEscHAMPhbQMZVTJH83uOwa9SSajX-mKBUpB7O2J23O_B2A0EMDegU8YuzQm7foSGxcxqy5xvr248kuj";
        const cashfree = new window.Cashfree(paymentSessionId);

        cashfree.redirect();

    }

}

</script>
