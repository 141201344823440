<template>
    <div class="container-fluid" style="padding: 0; margin: 0;">

        <div class="row">
            <template v-if="socketConnected">
                <div class="col-12" v-if="center">
                    <GoogleMap
                        ref="mapRef"
                        disable-default-ui
                        :styles=mapStyle
                        api-key="AIzaSyDk4e0NV-K0_uVSlCqJNtCnKHP5qeYWzLY"
                        style="height: 500px; width: 100%;"
                        :center="center"
                        :zoom="15">

                        <Marker :options="{
                                position: center,
                            }">
                            <InfoWindow :options="{ position: center }">Rider Location <br> {{ locationTimestamp }}
                            </InfoWindow>
                        </Marker>

                    </GoogleMap>
                    <div style="width: 100%;">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">

                                    <div class="col-12">
                                        <p>Live Location of {{ ride.customerName }}'s Ride on TransAll App</p>
                                        <hr>
                                    </div>

                                    <div class="col-6">
                                        <label>From</label>
                                        <p>{{ ride.to }}</p>
                                    </div>
                                    <div class="col-6" style="text-align: right">
                                        <label>To</label>
                                        <p>{{ ride.from }}</p>
                                    </div>

                                    <div class="col-6">
                                        <label>Rider Name</label>
                                        <p>{{ ride.riderName }}</p>
                                    </div>
                                    <div class="col-6" style="text-align: right">
                                        <label>Rider Contact</label>
                                        <p>{{ ride.riderContact }}</p>
                                    </div>



                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="col-12"
                         style="background-image: linear-gradient(310deg, rgba(104,32,197,0.96) 0%, rgba(171,134,218,0.96) 100%);">
                        <p style="margin: 0; color: white; font-weight: bold; text-transform: uppercase;"
                           class="text-center blink-text">
                            Waiting for rider location...</p>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="col-12"
                     style="background-image: linear-gradient(310deg, rgba(104,32,197,0.96) 0%, rgba(171,134,218,0.96) 100%);">
                    <p style="margin: 0; color: white; font-weight: bold; text-transform: uppercase;"
                       class="text-center blink-text">
                        Connecting to TransAll Server...</p>
                </div>
            </template>

        </div>

    </div>
</template>

<script>

import {GoogleMap, InfoWindow, Marker} from 'vue3-google-map'
import {io} from "socket.io-client";

export default {

    components: {
        GoogleMap,
        Marker,
        InfoWindow,
    },

    data() {
        return {

            serviceType: null,

            broadcastId: null,

            key: null,

            center: null,

            socketConnected: false,

            ride: {
                customerName: '',
                from: '',
                to: '',
                riderName: '',
                riderContact: '',
            },

            mapStyle: [
                {
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "administrative",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "administrative.land_parcel",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "administrative.neighborhood",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "transit",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                }
            ],


        }
    },

    methods: {

        receiveLocationUpdates() {
            const vm = this

            console.log('receiving location')

            vm.socket = io('https://socket.transall.in', {
                query: "broadcast_id=" + vm.broadcastId + '-sharing',
                transports: ['websocket']
            });

            vm.socket.on("connect_error", (err) => {
                console.log(`connect_error due to ${err.message}`);
            });

            vm.socket.on('disconnect', () => {
                console.log('socket disconnected')
                vm.socketConnected = false
            });

            // Listen for connection events
            vm.socket.on('connect', () => {

                console.log('customer socket connected')

                vm.socketConnected = true

                vm.socket.on('receive-location-updates', (data) => {
                    console.log(data)

                    vm.locationTimestamp = window.moment(data.timestamp).format("hh:mm a");

                    const latLng = data.message.split(',')

                    vm.center = {lat: parseFloat(latLng[0]), lng: parseFloat(latLng[1])}

                })

            });

        }

    },

    created() {

        const vm = this

        vm.serviceType = vm.$route.params.serviceType
        vm.broadcastId = vm.$route.params.broadcastId
        vm.key = vm.$route.params.key

        window.axios.get(`/ride/sharing/details/${vm.key}`)
            .then(resp => {
                if (resp.data.response.status.error_code === 0) {

                    const data = resp.data.response.data.booking_details

                    vm.ride.from = data.from
                    vm.ride.to = data.to

                    vm.ride.customerName = data.user.first_name + ' ' + data.user.last_name

                    vm.ride.riderName = data.ride.rider.first_name + ' ' + data.ride.rider.last_name

                    vm.ride.riderContact=data.ride.rider.mobile_number

                    vm.receiveLocationUpdates()
                }
            })


    }

}

</script>

<style scoped>

label {
    padding: 0;
    margin: 0;
}

p {
    padding: 0;
    margin: 0;
}

</style>

