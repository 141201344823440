<template>
    <div style="overflow-x: clip;">

        <div class="row sticky-top bg-gray-100 shadow" style="padding-top: 10px;">
            <div class="col-sm-12">
                <h3>
                    <button style="border: none; background: none;" @click="goBack"><i
                        class="p-2 fas fa-arrow-left"></i></button>
                    Ride Now
                </h3>
            </div>
        </div>

        <div style="position:fixed; top:50px; bottom:0; right:0; left:0;">

            <GoogleMap
                @click="placeMarker"
                @change="placeMarker"
                ref="mapRef"
                :zoom-control="false"
                :street-view-control="false"
                :fullscreen-control="false"
                :map-type-control="false"
                api-key="AIzaSyDk4e0NV-K0_uVSlCqJNtCnKHP5qeYWzLY"
                style="height: 100%"
                :center="center"
                :zoom="15">
                <Marker :options="markerPosition" :draggable="false"/>
                <Marker :options="destinationPosition" :draggable="false"/>
                <Polyline :options="tripPath"/>
            </GoogleMap>

        </div>

        <div class="row text-center"
             style="z-index: 10000000; position: fixed; right: 0; bottom: 0; left: 0; background-color: white; padding: 10px;">

            <div class="col-12">

                <div>
                    <soft-input
                        style="border-radius: 0;"
                        v-model="dropAddress"
                        @click="openDropLocationPicker"
                        class="shadow"
                        placeholder="Enter Drop Location"></soft-input>

                    <div v-if="dropAddress===''">
                        <img class="img-fluid" :src="dropLocationClipArt" style="height: 100px">

                        <p>
                            Book ride now by searching for your drop location
                        </p>
                    </div>

                    <div v-if="dropAddress!=='' && !isFetchingLocationMatrix && !searchingForRiders"
                         class="mt-3">
                        <!-- Ride Options -->
                        <table width="100%">

                            <tr>
                                <td style="text-align: left">Ride Distance</td>
                                <td style="text-align: right">{{ distance + " Km" }}</td>
                            </tr>

                            <tr>
                                <td style="text-align: left">Estimated Travel Time</td>
                                <td style="text-align: right">{{ travelTime + " mins" }}</td>
                            </tr>

                        </table>

                        <div class="list-group">
                            <!-- Bike Option -->
                            <a href="#" @click="setSelectedVehicle(' A Bike',1)"
                               class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                                <div class="d-flex align-items-center">
                                    <img height="20" :src="twoWheelerClipArt" alt="Bike"
                                         style="margin-right: 0.3em">
                                    <!-- Replace with your bike icon -->
                                    Bike
                                </div>
                                <div>
                                    {{ "₹" + bikeFare }}
                                </div>
                            </a>

                            <a href="#" @click="setSelectedVehicle(' An Auto',2)"
                               class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                                <div class="d-flex align-items-center">
                                    <img height="20" :src="threeWheelerClipArt" alt="Bike"
                                         style="margin-right: 0.3em">
                                    <!-- Replace with your bike icon -->
                                    Auto
                                </div>
                                <div>
                                    {{ "₹" + autoFare }}
                                </div>
                            </a>

                            <a href="#" @click="setSelectedVehicle(' A Hatchback Car',3)"
                               class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                                <div class="d-flex align-items-center">
                                    <img height="20" :src="car" alt="Bike" style="margin-right: 0.3em">
                                    <!-- Replace with your bike icon -->
                                    Hatchback Car
                                </div>
                                <div>
                                    {{ "₹" + carFare }}
                                </div>
                            </a>

                            <a href="#" @click="setSelectedVehicle(' A Sedan Car',4)"
                               class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                                <div class="d-flex align-items-center">
                                    <img height="20" :src="car" alt="Bike" style="margin-right: 0.3em">
                                    <!-- Replace with your bike icon -->
                                    Sedan Car
                                </div>
                                <div>
                                    {{ "₹" + sedanFare }}
                                </div>
                            </a>

                            <a href="#" @click="setSelectedVehicle(' A SUV Car',5)"
                               class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                                <div class="d-flex align-items-center">
                                    <img height="20" :src="car" alt="Bike" style="margin-right: 0.3em">
                                    <!-- Replace with your bike icon -->
                                    SUV Car
                                </div>
                                <div>
                                    {{ "₹" + suvFare }}
                                </div>
                            </a>

                        </div>

                        <div class="col-12">
                            <br>
                            <soft-button @click="searchNearByVehicles()" full-width color="success">Book
                                {{ getSelectedBookingVehicle }}
                            </soft-button>
                        </div>

                    </div>

                    <div v-if="searchingForRiders" class="mt-3">
                        <!-- Ride Options -->

                        <div class="col-12">

                            <p>Please wait, a rider near you will soon accept your ride request...</p>

                            <Vue3Lottie :animationData="animationJson" height="100px"></Vue3Lottie>

                            <br>
                            <soft-button @click="cancelRide()" full-width color="danger">
                                CANCEL RIDE
                            </soft-button>
                        </div>

                    </div>
                </div>

            </div>


        </div>


        <div class="row">
            <div class="col-12">
                <vue-google-autocomplete
                    ref="googleAutocomplete"
                    :disabled="isFetchingLocation"
                    enable-geolocation
                    types=""
                    style="position:fixed; top:80px; right: 30px; left: 30px; padding: 5px; border-radius: 10px;"
                    id="map-to"
                    country="in"
                    :placeholder="getPlaceholderText"
                    v-on:placechanged="getAddressData">
                </vue-google-autocomplete>

            </div>
        </div>

    </div>
</template>

<script>
import {ref, watch} from 'vue'
import {GoogleMap, Marker, Polyline} from 'vue3-google-map'
import VueGoogleAutocomplete from "vue-google-autocomplete"
import SoftInput from "../components/SoftInput";
import dropLocationClipArt from "../assets/img/drop_location_clipart.jpg"
import twoWheelerClipArt from "../assets/img/two-wheeler.png"
import threeWheelerClipArt from "../assets/img/three-wheeler.png"
import car from "../assets/img/car.jpg"
import {mapGetters} from "vuex"
import SoftButton from "../components/SoftButton";
import {Vue3Lottie} from 'vue3-lottie'
import animationJson from "../assets/anim/searching-rides.json"


export default {
    components: {
        SoftButton,
        SoftInput,
        GoogleMap,
        Marker,
        Polyline,
        VueGoogleAutocomplete,
        Vue3Lottie
    },

    props: {
        locationType: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            animationJson,
            rideSearchTimer: null,
            dropLocationClipArt: dropLocationClipArt,
            twoWheelerClipArt: twoWheelerClipArt,
            threeWheelerClipArt: threeWheelerClipArt,
            car: car,

            center: this.getSelectedPickupLocation().latLng,
            markerPosition: null,
            destinationPosition: null,
            autocompleteText: this.getSelectedPickupLocation().address,
            pinCode: this.getSelectedPickupLocation().pinCode,
            city: this.getSelectedPickupLocation().city,
            locality: this.getSelectedPickupLocation().locality,
            isFetchingLocation: false,
            address: this.getSelectedPickupLocation().address,
            isFetchingLocationCount: 0,


            dropAddress: this.getSelectedDropLocation().address,
            dropLatLng: this.getSelectedDropLocation().latLng,
            dropPinCode: this.getSelectedDropLocation().pinCode,
            dropCity: this.getSelectedDropLocation().city,
            dropLocality: this.getSelectedDropLocation().locality,

            pickupAddress: this.getSelectedPickupLocation().address,
            pickupLatLng: this.getSelectedPickupLocation().latLng,
            pickupPinCode: this.getSelectedPickupLocation().pinCode,
            pickupCity: this.getSelectedPickupLocation().city,
            pickupLocality: this.getSelectedPickupLocation().locality,

            distance: '',
            travelTime: '',
            price: '',

            carFare: 0,
            sedanFare: 0,
            suvFare: 0,
            autoFare: 0,
            bikeFare: 0,

            isFetchingLocationMatrix: true,

            tripPath: {
                path: [],
                geodesic: true,
                strokeColor: "#FF0000",
                strokeOpacity: 1.0,
                strokeWeight: 2,
            },

            selectedVehicle: ' Now',

            searchingForRiders: false,

            mobileNumber: null,

            amountInPaisa: 0,

            generatedRideRequestId: null,

            modeOfTransport: null

        }
    },

    created() {
        let vm = this

        vm.mobileNumber = this.$route.params.mobileNumber

        vm.isFetchingLocation = true
        navigator.geolocation.getCurrentPosition(
            position => {
                vm.isFetchingLocation = false
                console.log(position.coords.latitude);
                console.log(position.coords.longitude);

                if (vm.center === '') {
                    vm.center = {lat: position.coords.latitude, lng: position.coords.longitude}
                    vm.markerPosition = {
                        position: {lat: position.coords.latitude, lng: position.coords.longitude},
                    }
                } else {
                    vm.markerPosition = {
                        position: {lat: vm.center.lat, lng: vm.center.lng},
                    }
                    vm.isFetchingLocationCount=1
                }


                vm.reverseGeoencode()
            },
            error => {
                vm.isFetchingLocation = false
                console.log(error.message);
            },
        )


    },
    methods: {
        ...mapGetters(['getSelectedDropLocation', 'getSelectedPickupLocation']),
        openDropLocationPicker() {

            this.$router.push({
                name: 'map-location-picker', params: {
                    locationType: 'select-drop-location'
                }
            })

        },

        cancelRide() {

            clearInterval(this.rideSearchTimer);
            this.searchingForRiders = false

            window.axios.delete('ride/customer/delete/ride/now/request/' + this.generatedRideRequestId, {
                params: {
                    mobile_number: this.mobileNumber
                }
            })
                .then(resp => {
                    if (resp.data.response.status.error_code === 0) {
                        this.$toast.open({
                            message: 'Ride Cancelled',
                            type: "error",
                            position: 'top',
                            duration: 2000,
                            dismissible: false,
                        })
                    }
                })

        },

        setSelectedVehicle(vehicleName, type) {

            const vm = this

            vm.selectedVehicle = vehicleName

            switch (type) {

                //Bike
                case 1:
                    vm.amountInPaisa = parseInt(vm.bikeFare) * 100
                    vm.modeOfTransport = 'bike'
                    break

                //Auto
                case 2:
                    vm.amountInPaisa = parseInt(vm.autoFare) * 100
                    vm.modeOfTransport = 'auto'
                    break

                //Hatch back
                case 3:
                    vm.amountInPaisa = parseInt(vm.carFare) * 100
                    vm.modeOfTransport = 'car'
                    break

                //Sedan
                case 4:
                    vm.amountInPaisa = parseInt(vm.sedanFare) * 100
                    vm.modeOfTransport = 'sedan'
                    break

                //SUV
                case 5:
                    vm.amountInPaisa = parseInt(vm.suvFare) * 100
                    vm.modeOfTransport = 'suv'
                    break
            }

        },

        searchNearByVehicles() {

            const vm = this

            if (this.selectedVehicle == ' Now') {
                this.$toast.open({
                    message: 'Please select mode of transport',
                    type: "error",
                    position: 'top',
                    duration: 2000,
                    dismissible: false,
                })

                return
            }

            vm.rideSearchTimer = setInterval(function () {

                //TODO: check if request accepted every 10 seconds

                window.axios.get('/ride/check/ride/now/request/status/' + vm.generatedRideRequestId)
                    .then(response => {
                        if (response.data.response.status.error_code === 0) {


                            if (response.data.response.data.accepted === 1) {
                                vm.$toast.open({
                                    message: response.data.response.status.msg,
                                    type: 'success',
                                    position: 'top',
                                    duration: 2000,
                                    dismissible: false,
                                })


                                clearInterval(vm.rideSearchTimer);

                                //redirect/replace to

                                vm.$router.replace({
                                    path: "/user/" + vm.mobileNumber + "/activities/customer_request/ride-now/" + vm.generatedRideRequestId
                                })

                            } else {
                                // vm.cancelRide()
                                //
                                // swal({
                                //     title: "No Riders Found",
                                //     text: "Looks like all our riders are busy with their rides and could not accept your request at the moment, you can try again after sometime.",
                                //     icon: "error",
                                // }).then(() => {
                                //     vm.searchingForRiders = false
                                // });
                            }


                        }
                    })


            }, 1000 * 10 * 1) // milliseconds * seconds * mins | 1000 * 60 * 1 = 1 min

            vm.searchingForRiders = true

            //call API here

            let destinationLatLng = vm.getSelectedDropLocation().latLng.split(',')

            window.axios.post('/ride/customer/request/ride/now', {
                mobile_number: vm.mobileNumber,

                no_of_passengers: 0,

                from: vm.autocompleteText,
                to: vm.dropAddress,

                from_lat_lng: vm.markerPosition.position.lat + ',' + vm.markerPosition.position.lng,
                to_lat_lng: destinationLatLng[0] + ',' + destinationLatLng[1],

                mode_of_transport: vm.modeOfTransport,

                car_fare: vm.carFare,
                sedan_fare: vm.sedanFare,
                suv_fare: vm.suvFare,
                auto_fare: vm.autoFare,
                bike_fare: vm.bikeFare,

            }).then(response => {
                if (response.data.response.status.error_code === 0) {
                    vm.$toast.open({
                        message: response.data.response.status.msg,
                        type: 'success',
                        position: 'top',
                        duration: 2000,
                        dismissible: false,
                    })

                    vm.generatedRideRequestId = response.data.response.data.id

                    //clearInterval(vm.rideSearchTimer);

                    //vm.makePaymentRequest(response.data.response.data.id)

                }
            })


        },

        getLocationMatrix() {

            const vm = this

            vm.isFetchingLocationMatrix = true

            let destinationLatLng = vm.getSelectedDropLocation().latLng.split(',')

            window.axios.post(`/google/get/location/matrix`, {

                origin_lat: vm.markerPosition.position.lat,
                origin_lng: vm.markerPosition.position.lng,

                destination_lat: destinationLatLng[0],
                destination_lng: destinationLatLng[1],

            }).then(response => {

                console.log(response)

                if (response.data.response.status.error_code === 0) {

                    vm.isFetchingLocationMatrix = false

                    vm.destinationPosition = {
                        position: {lat: parseFloat(destinationLatLng[0]), lng: parseFloat(destinationLatLng[1])},
                    }

                    vm.distance = (response.data.response.data.result.routes[0].distanceMeters / 1000).toFixed(1);
                    vm.travelTime = response.data.response.data.result.routes[0].duration.split('s')[0];
                    vm.travelTime = (parseInt(vm.travelTime) / 60).toFixed(0)

                    vm.carFare = response.data.response.data.car_fare
                    vm.sedanFare = response.data.response.data.sedan_fare
                    vm.suvFare = response.data.response.data.suv_fare
                    vm.autoFare = response.data.response.data.auto_fare
                    vm.bikeFare = response.data.response.data.two_wheeler_fare

                    const coordinates = [];

                    for (let i = 0; i < response.data.response.data.points.length; i++) {
                        coordinates.push({
                            lat: response.data.response.data.points[i][0],
                            lng: response.data.response.data.points[i][1]
                        })
                    }

                    vm.tripPath = {
                        path: coordinates,
                        geodesic: true,
                        strokeColor: "#000000",
                        strokeOpacity: 1.0,
                        strokeWeight: 2,
                    };


                    let bounds = new vm.$refs.mapRef.api.LatLngBounds();
                    for (var i = 0; i < coordinates.length; i++) {
                        bounds.extend(coordinates[i]);
                    }

                    bounds.getCenter();

                    vm.$refs.mapRef.map.fitBounds(bounds);

                }

            })

        },

        reverseGeoencode() {
            const vm = this
            vm.isFetchingLocation = true
            vm.isFetchingLocationCount++
            window.axios.get('/google/reverse/geoencode', {
                params: {
                    lat_lng: vm.markerPosition.position.lat + ',' + vm.markerPosition.position.lng
                }
            }).then(resp => {
                vm.isFetchingLocation = false
                vm.$refs.googleAutocomplete.autocompleteText = ''
                vm.autocompleteText = resp.data.response.data.formatted_address
                vm.city = resp.data.response.data.city
                vm.state = resp.data.response.data.state
                vm.locality = resp.data.response.data.locality
                vm.pinCode = resp.data.response.data.pin_code

                const data = {
                    address: vm.autocompleteText,
                    latLng: {lat: vm.markerPosition.position.lat, lng: vm.markerPosition.position.lng},
                    pinCode: vm.pinCode,
                    city: vm.city,
                    locality: vm.locality,
                }

                vm.$store.dispatch('setSelectedPickupLocation', data)


                vm.getLocationMatrix()

            }).catch(error => {
                vm.isFetchingLocation = false
                console.log(error)
            })
        },

        getAddressData: function (addressData) {
            this.address = addressData;
            console.log(addressData)

            this.city = addressData.administrative_area_level_2
            this.locality = addressData.locality
            this.pinCode = addressData.postal_code

            this.center = {lat: addressData.latitude, lng: addressData.longitude}

            this.markerPosition = null
        },

        placeMarker(e) {
            let vm = this
            if (vm.isFetchingLocation) {
                return
            }
            console.log(e.latLng.lat())
            vm.center.lat = e.latLng.lat()
            vm.center.lng = e.latLng.lng()
            vm.markerPosition = {
                position: {lat: e.latLng.lat(), lng: e.latLng.lng()},
            }
            vm.reverseGeoencode()
        },

        goBack() {

            this.$store.dispatch('clearAllLocations')

            window.ReactNativeWebView.postMessage("close-screen")


            // this.$router.back()
        },

        // makePaymentRequest(id) {
        //
        //     const vm = this
        //
        //     window.axios.post('/payment/create/ride/now/order/' + id, {
        //         contact: vm.mobileNumber,
        //         requestType: 'ride-now',
        //         customer_amount: vm.amountInPaisa
        //     }).then(response => {
        //         if (response.data.response.status.error_code === 0) {
        //
        //             //let url = response.data.response.data.data.instrumentResponse.redirectInfo.url
        //
        //             let orderId = response.data.response.data.id
        //             let amount = vm.amountInPaisa
        //
        //             this.$router.push({
        //                 name: "payment-checkout", params: {
        //                     orderId: orderId,
        //                     amount: amount,
        //                     typeOfService: 'ride-now',
        //                     name: 'Rutvik',
        //                     email: 'Rutvik106@live.com',
        //                     mobileNumber: vm.mobileNumber,
        //                     description: 'Ride Now',
        //                     bookingId: '9'
        //                 }
        //             })
        //         }
        //     }).catch(e => console.log(e))
        // },

    },
    setup() {
        const mapRef = ref(null)

        // Third pattern: watch for "ready" then do something with the API or map instance
        watch(() => mapRef.value?.ready, (ready) => {

            //alert("map ready")

            if (!ready) return

            // do something with the api using `mapRef.value.api`
            // or with the map instance using `mapRef.value.map`
        })

        return {mapRef}
    },
    computed: {
        getPlaceholderText() {
            if (this.isFetchingLocation) {
                return 'Please wait, fetching location...'
            } else if (this.autocompleteText != '') {
                if (this.isFetchingLocationCount > 1) {
                    return this.autocompleteText
                }
                return 'Your Current Location'
            } else {
                return 'Type location name here...'
            }
        },
        getSelectedBookingVehicle() {
            return this.selectedVehicle
        }
    },
}
</script>

<style scoped>

.bottom-sheet {
    position: fixed;
    bottom: -100%;
    left: 0;
    width: 100%;
    padding: 1rem;
    background-color: #fff;
    box-shadow: 0px -1px 5px rgba(0, 0, 0, 0.1);
    transition: bottom 0.3s ease-out;
}

.bottom-sheet.show {
    bottom: 0;
}

</style>
