import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// Import the functions you need from the SDKs you need

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDk4e0NV-K0_uVSlCqJNtCnKHP5qeYWzLY",
    authDomain: "trans-all.firebaseapp.com",
    projectId: "trans-all",
    storageBucket: "trans-all.appspot.com",
    messagingSenderId: "116277308282",
    appId: "1:116277308282:web:40410702730961489ec4c3",
    measurementId: "G-3CEPZ8H32P"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default firebaseConfig;
