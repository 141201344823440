<template>
    <div>
        <div class="sticky-top inner-header sticky-header bg-gray-100 shadow py-2">
            <div class="col-sm-12">
                <h3>
                    <button style="border: none; background: none;" @click="goBack()">
                        <i class="p-2 fas fa-arrow-left"></i>
                    </button>
                    {{ selectedRoute.title }}
                </h3>
            </div>
        </div>
        <div class="page-wrap have-sticky-header container">
            <div class="row" v-for="(menu, index) of selectedRoute.menu" :key="index">
                <div class="col-6 card-col" v-for="row of menu.rowOne" :key="row.title">
                    <router-link :to="{ name: row.routeName }" class="card">
                        <div class="card-body">
                            <i class="fa fa-2x pb-3" :class="'fa-'+ row.icon"></i>
                            {{ row.title }}
                        </div>
                    </router-link>
                </div>
                <div class="col-6 card-col" v-for="row of menu.rowTwo" :key="row.title">
                    <router-link :to="{ name: row.routeName }" class="card">
                        <div class="card-body">
                            <i class="fa fa-2x pb-3" :class="'fa-'+ row.icon"></i>
                            {{ row.title }}
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: 'serviceNavigationMenu',
    data() {
        return {
            navigationMenu: {
                'ride-menu' : {
                    title: 'Publish Rides',
                    menu: [
                    {
                        rowOne: [
                            { title: 'Add New', icon: 'plus', routeName: 'publish-ride' },
                            { title: 'Manage', icon: 'edit', routeName: 'manage-ride' }
                        ]
                    },
                    { rowTwo: [{ title: 'Nearby', icon: 'map-marker', routeName: 'ride-nearby-results' }]}]
                },
                'package-menu' : {
                    title: 'Publish Parcels',
                    menu: [
                    {
                        rowOne: [
                            { title: 'Add New', icon: 'plus', routeName: 'publish-parcel' },
                            { title: 'Manage', icon: 'edit', routeName: 'manage-package' }
                        ]
                    },
                    { rowTwo: [{ title: 'Nearby', icon: 'map-marker', routeName: 'package-nearby-results' }]}]
                },
                'tours-menu' : {
                    title: 'Publish Tours',
                    menu: [
                    {
                        rowOne: [
                            { title: 'Add New', icon: 'plus', routeName: 'publish-tour' },
                            { title: 'Manage', icon: 'edit', routeName: 'manage-tours' }
                        ]
                    }]
                },
                'travels-menu' : {
                    title: 'Publish Travels',
                    menu: [
                    {
                        rowOne: [
                            { title: 'Add New', icon: 'plus', routeName: 'publish-travels' },
                            { title: 'Manage', icon: 'edit', routeName: 'manage-travels' }
                        ]
                    }]
                },
                'transport-menu' : {
                    title: 'Publish Transport',
                    menu: [
                    {
                        rowOne: [
                            { title: 'Add New', icon: 'plus', routeName: 'publish-transport' }
                        ]
                    }]
                },
                'spv-menu' : {
                    title: 'Publish SPV',
                    menu: [
                    {
                        rowOne: [
                            { title: 'Add New', icon: 'plus', routeName: 'publish-spv' },
                            { title: 'Manage', icon: 'edit', routeName: 'manage-spv' }
                        ]
                    }]
                }
            },
            selectedRoute: ''
        }
    },
    methods: {
        goBack() {
            this.$router.back()
        }
    },
    mounted() {
        this.selectedRoute = this.navigationMenu[this.$router.currentRoute._rawValue.name]
    }
}

</script>

<style lang="scss" scoped>
.page-wrap{
    padding: 10px 0;
}
.container{
    padding: 0 1rem;
    .row{
        margin: 0;
        gap: 14px;
    
        .card-col{
            padding: 0;
            margin-bottom: 14px;
            &.col-4{
                width: calc(33.333% - 10px);
            }
            &.col-6{
                width: calc(50% - 10px);
            }
            &.col-12{
                .card-body{
                    flex-flow: row;
                }
            }
        }
    }
}
.card{
    background-color: #26143E;
    height: 100%;
    padding: 1.5rem;
    .card-body{
        color: var(--bs-white);
        padding: 0;
        display: flex;
        flex-flow: column;
        justify-content:center;
        align-items: center;
        text-align: center;
    }
}
</style>
