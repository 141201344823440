<template>
    <div style="overflow-x: clip;">
        <div class="row sticky-top bg-gray-100 shadow" style="padding-top: 10px;">
            <div class="col-sm-12">
                <h3>
                    <button style="border: none; background: none;" @click="goBack"><i
                        class="p-2 fas fa-arrow-left"></i></button>
                    Payment Details
                </h3>
            </div>
        </div>
        <div class="container-fluid">

            <div class="row">
                <div class="col-12">
                    <br>
                    <table style="width: 100%;">
                        <tr>
                            <td>Ride Fare</td>
                            <td style="text-align: right;">{{ stringToINR(amount) }}</td>
                        </tr>
                        <!--                        <tr>-->
                        <!--                            <td>Commission</td>-->
                        <!--                            <td style="text-align: right;">₹{{ ride_commission }}/-</td>-->
                        <!--                        </tr>-->
                        <tr>
                            <td>Platform Fees</td>
                            <td style="text-align: right;">₹{{ convenience_fees }}/-</td>
                        </tr>
                        <tr>
                            <td>GST</td>
                            <td style="text-align: right;">{{ stringToINR(other_taxes) }}</td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <hr>
                            </td>
                        </tr>
                        <tr>
                            <td><b>Total Payable Amount</b></td>
                            <td class="text-lg" style="text-align: right;"><b>{{ stringToINR(finalAmount) }}</b></td>
                        </tr>
                    </table>
                    <br>
                    <span style="font-size: 11px;">* Terms and Conditions apply (<a style="color: #0d6efd;"
                                                                                    href="https://transall.in/terms_conditions.html"
                                                                                    target="_blank">Read More</a>)</span>

                </div>

                <div class="col-12">
                    <br>
                    <soft-button full-width size="lg" variant="gradient" color="success" @click="makePaymentRequest">
                        PAY NOW
                    </soft-button>
                </div>

            </div>

        </div>
    </div>
</template>
<script>

import SoftButton from "../components/SoftButton";

export default {
    components: {SoftButton},
    props: {
        name: {
            type: String,
            required: true,
        },
        email: {
            type: String,
            required: true,
        },
        amount: {
            type: String,
            required: true,
        },
        mobileNumber: {
            type: String,
            required: true,
        },
        requestType: {
            type: String,
            required: true,
        },
        customerRequestId: {
            type: Number,
            required: true,
        },
        bidId: {
            type: Number,
            required: true,
        },
        from: {
            type: String,
        },
        to: {
            type: String,
        },
    },
    data() {
        return {
            finalAmount: 0,
            convenience_fees: 0,
            other_taxes: 0,
            ride_commission: 0,
        }
    },
    methods: {
        goBack() {
            this.$router.go(-1)
        },
        makePaymentRequest() {
            const vm = this

            if (vm.$props.requestType == 'ride') {
                window.axios.post('/payment/create/customer/ride/order/' + vm.$props.mobileNumber + '/' + vm.$props.customerRequestId + '/' + vm.$props.bidId)
                    .then(resp => {
                        if (resp.data.response.status.error_code === 0) {
                            let orderId = resp.data.response.data.id
                            let amount = resp.data.response.data.amount
                            vm.$router.push({
                                name: "payment-checkout", params: {
                                    orderId: orderId,
                                    amount: amount,
                                    typeOfService: 'customer_ride_request',
                                    name: vm.$props.name,
                                    email: vm.$props.email,
                                    mobileNumber: vm.$props.mobileNumber,
                                    description: 'Ride Booking',
                                    bookingId: vm.$props.bidId
                                }
                            })
                        }
                    }).catch(e => console.log(e))
            } else if (vm.$props.requestType == 'ride-now' || vm.$props.requestType == 'parcel') {

                const vm = this

                let amount = vm.finalAmount * 100

                window.axios.post('/payment/create/ride/now/order/' + vm.$props.customerRequestId, {
                    contact: vm.mobileNumber,
                    requestType: 'ride-now',
                    customer_amount: amount
                }).then(response => {
                    if (response.data.response.status.error_code === 0) {

                        //let url = response.data.response.data.data.instrumentResponse.redirectInfo.url

                        let orderId = response.data.response.data.id

                        this.$router.push({
                            name: "payment-checkout", params: {
                                orderId: orderId,
                                amount: amount,
                                typeOfService: 'ride-now',
                                name: vm.$props.name,
                                email: vm.$props.email,
                                mobileNumber: vm.mobileNumber,
                                description: 'Ride Now',
                                bookingId: vm.$props.bidId,
                                customerRequestId: vm.$props.customerRequestId
                            }
                        })
                    }
                }).catch(e => console.log(e))

            }


        },
        getApplicationProperties() {
            const vm = this
            window.axios.get('/app/get/properties')
                .then(response => {
                    if (response.data.response.status.error_code == 0) {
                        const appProperties = response.data.response.data

                        for (let i = 0; i < appProperties.length; i++) {
                            const appProp = appProperties[i]

                            if (vm.$props.requestType === 'ride') {
                                if (appProp.property_key === 'ride_commission') {
                                    if (appProp.property_type === 'Percentage') {
                                        vm.ride_commission = parseFloat(vm.finalAmount) * parseFloat(appProp.property_value) / 100
                                        vm.finalAmount = parseFloat(vm.finalAmount) + parseFloat(vm.ride_commission)
                                    }
                                }
                            }

                            if (appProp.property_key === 'convenience_fees') {
                                if (appProp.property_type === 'Rupees') {
                                    vm.convenience_fees = appProp.property_value
                                    vm.finalAmount = parseFloat(vm.finalAmount) + parseFloat(vm.convenience_fees)
                                }
                                if (appProp.property_type === 'Percentage') {
                                    vm.convenience_fees = parseFloat(vm.finalAmount) * parseFloat(appProp.property_value) / 100
                                    vm.finalAmount = parseFloat(vm.finalAmount) + parseFloat(vm.convenience_fees)
                                }
                                vm.convenience_fees = Number(vm.convenience_fees.toFixed(2))
                            }

                            if (appProp.property_key === 'other_taxes') {
                                if (appProp.property_type === 'Rupees') {
                                    vm.other_taxes = appProp.property_value
                                    vm.finalAmount = parseFloat(vm.finalAmount) + parseFloat(vm.other_taxes)
                                }
                                if (appProp.property_type === 'Percentage') {
                                    vm.other_taxes = parseFloat(vm.finalAmount) * parseFloat(appProp.property_value) / 100
                                    vm.finalAmount = parseFloat(vm.finalAmount) + parseFloat(vm.other_taxes)
                                }
                                vm.other_taxes = Number(vm.other_taxes.toFixed(2))
                            }

                        }

                        vm.finalAmount = Math.round(vm.finalAmount)

                    }
                })
                .catch(error => {
                    console.log(error)
                })
        },

    },
    computed: {

        getLabel() {
            if (this.$props.requestType == 'ride') {
                return 'Ride'
            }
            return 'Service'
        }

    },
    created() {

        const vm = this
        vm.getApplicationProperties()
        if (isNaN(parseFloat(vm.$props.amount))) {
            vm.$router.go(-1)
        }
        vm.finalAmount = parseFloat(vm.$props.amount)
    }

}

</script>
