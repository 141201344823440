<template>

    <div style="overflow-x: clip;">
        <div class="row sticky-top bg-gray-100 shadow" style="padding-top: 10px;">
            <div class="col-sm-12">
                <h2>
                    <button style="border: none; background: none;" @click="goBack"><i
                        class="p-2 fas fa-arrow-left"></i></button>
                    Publish Parcel
                </h2>
            </div>
        </div>

        <div class="container-fluid">
            <br>

            <form @submit.prevent>
                <div class="row">

                    <div class="col-12">

                        <label>From</label>
                        <soft-input v-model="parcel.from"
                                    @click="openLocationPicker('from-location')"
                                    type="text"
                                    placeholder="Pick from location"></soft-input>
                    </div>

                    <div class="col-12">
                        <label>Destination</label>
                        <soft-input v-model="parcel.to"
                                    @click="openLocationPicker('to-location')"
                                    type="text"
                                    placeholder="Pick destination location"></soft-input>
                    </div>

                    <div class="col-12">
                        <label>Select Vehicle</label>
                        <select v-model="parcel.selectedVehicle" class="custom-select form-control">
                            <template v-for="vehicle in vehicles" :key="vehicle.id">
                                <option :value="vehicle.id">
                                    {{ vehicle.model_name + " (" + vehicle.registered_number + ")" }}
                                </option>
                            </template>
                        </select>
                        <br>
                    </div>

                    <div class="col-12">
                        <br>
                        <soft-checkbox id="daily-chkb"
                                       @change="isRidingDaily=!isRidingDaily">
                            <slot>
                                Daily Frequency
                            </slot>
                        </soft-checkbox>
                    </div>

                    <div class="col-6" v-if="!isRidingDaily">
                        <label>Date</label>
                        <soft-input v-model="parcel.date"
                                    type="date"
                                    placeholder="Enter date"></soft-input>
                    </div>

                    <div class="col-6">
                        <label>Timing</label>
                        <soft-input v-model="parcel.time"
                                    type="time"
                                    placeholder="Enter time"></soft-input>
                    </div>

                    <label>Type of Parcel</label>
                    <div class="col-6">
                        <soft-checkbox @change="parcel.document=!parcel.document" value="document" id="document"
                                       name="checkbox">
                            <slot>Document</slot>
                        </soft-checkbox>
                    </div>
                    <div class="col-6">
                        <soft-checkbox @change="parcel.material=!parcel.material" value="material" id="material"
                                       name="checkbox">
                            <slot>Material</slot>
                        </soft-checkbox>
                    </div>


                    <div class="col-6">
                        <label>Price per Km</label>
                        <soft-input v-model="parcel.farePerKm"
                                    type="number"
                                    placeholder="Enter your price"></soft-input>
                    </div>

                    <div class="col-6">
                        <label>Price per Kg</label>
                        <soft-input v-model="parcel.farePerKg"
                                    type="number"
                                    placeholder="Enter your price"></soft-input>
                    </div>

                    <div class="col-12">
                        <br>
                        <soft-checkbox id="terms-conditions"
                                       @change="notAcceptedTermsAndConditions=!notAcceptedTermsAndConditions">
                            <slot>
                                I Accept TransAll Terms & Conditions (<a style="color: #0d6efd;"
                                                                         href="https://transall.in/terms_conditions.html"
                                                                         target="_blank">Read More</a>)
                            </slot>
                        </soft-checkbox>
                    </div>

                    <div class="col-sm-12">
                        <br>
                        <soft-button :disabled="notAcceptedTermsAndConditions || isApiCallRunning" btn-type="submit"
                                     @click="publishParcel"
                                     full-width color="success" size="lg"
                                     variant="gradient">
                            Publish Parcel
                        </soft-button>
                    </div>


                </div>
            </form>

            <br>
        </div>

    </div>

</template>

<script>

import SoftInput from "../../components/SoftInput"
import SoftButton from "../../components/SoftButton"
import SoftCheckbox from "../../components/SoftCheckbox"
import swal from "sweetalert"
import AddSelectTourManager from "../../components/modals/AddSelectTourManager"
import {mapGetters} from "vuex";


export default {
    components: {
        SoftInput,
        SoftButton,
        SoftCheckbox,
    },
    data() {
        return {

            isRidingDaily: false,

            isApiCallRunning: false,

            vehicles: null,

            notAcceptedTermsAndConditions: true,

            addingVehicleOrManager: false,
            vehicleOrManagerComponent: AddSelectTourManager,

            mobileNumber: null,

            parcel: {

                date: null,
                time: null,

                selectedVehicle: null,
                parcelType: '',

                from: this.getFromLocation().address,
                fromLatLng: this.getFromLocation().latLng,
                fromPinCode: this.getFromLocation().pinCode,
                fromCity: this.getFromLocation().city,
                fromLocality: this.getFromLocation().locality,

                to: this.getToLocation().address,
                toLatLng: this.getToLocation().latLng,
                toPinCode: this.getToLocation().pinCode,
                toCity: this.getToLocation().city,
                toLocality: this.getToLocation().locality,

                material: false,
                document: false,

                farePerKm: '',
                farePerKg: '',

            }


        }
    },
    methods: {
        ...mapGetters(['getFromLocation', 'getToLocation']),
        openLocationPicker(type) {
            if (type == 'from-location') {
                this.$router.push({
                    name: 'map-location-picker', params: {
                        locationType: 'from-location'
                    }
                })
            } else if (type == 'to-location') {
                this.$router.push({
                    name: 'map-location-picker', params: {
                        locationType: 'to-location'
                    }
                })
            }
        },
        clearFormData() {
            const vm = this
            const data = {
                address: '',
                latLng: '',
                pinCode: '',
                city: '',
                locality: '',
            }

            vm.$store.dispatch('setFromLocation', data)

            vm.$store.dispatch('setToLocation', data)
        },
        goBack() {
            this.clearFormData()
            swal({
                title: "Are you sure you want to go back?",
                text: "Once back, you will not be able to recover this filled form!",
                icon: "warning",
                buttons: ['Cancel', 'GoBack'],
                dangerMode: true,
            }).then((goBack) => {
                if (goBack) {
                    window.ReactNativeWebView.postMessage("close-screen")
                }
            });
        },
        publishParcel() {
            const vm = this

            if (vm.parcel.selectedVehicle == null) {
                vm.$toast.open({
                    message: 'Select Vehicle',
                    type: 'error',
                    position: 'top',
                    duration: 5000,
                    dismissible: false,
                })
                return
            }

            if (!vm.parcel.document && !vm.parcel.material) {
                vm.$toast.open({
                    message: 'Select Parcel Type',
                    type: 'error',
                    position: 'top',
                    duration: 5000,
                    dismissible: false,
                })
                return
            }

            vm.isApiCallRunning = true

            window.axios.post('parcel/publish', {
                mobile_number: vm.mobileNumber,

                from: vm.parcel.from,
                to: vm.parcel.to,

                from_pin: vm.parcel.fromPinCode,
                to_pin: vm.parcel.toPinCode,

                from_lat_lng: vm.parcel.fromLatLng,
                to_lat_lng: vm.parcel.toLatLng,

                fare_per_Kg: vm.parcel.farePerKg,
                fare_per_km: vm.parcel.farePerKm,

                material: vm.parcel.material ? 1 : 0,
                document: vm.parcel.document ? 1 : 0,

                selected_vehicle: vm.parcel.selectedVehicle,

                date: vm.parcel.date,
                time: vm.parcel.time
            }).then(response => {
                vm.isApiCallRunning = false

                if (response.data.response.data.parcel_details) {

                    vm.clearFormData()

                    swal({
                        title: "Parcel has been published",
                        icon: "info",
                        dangerMode: false,
                    }).then(() => {
                        vm.$store.dispatch('clearAllLocations')
                        window.ReactNativeWebView.postMessage("close-screen")
                    });
                }
            })
        },
        checkUserServices() {
            const vm = this
            window.axios.get('user/get/services/offered/' + vm.mobileNumber)
                .then(resp => {
                    if (resp.data.response.status.error_code === 0) {
                        const user = resp.data.response.data.user
                        if (user.register_for_parcel === 0) {
                            swal({
                                title: "Not Registered",
                                text: "You are not registered for providing this service, Please register first.",
                                icon: "error",
                            }).then(() => {
                                vm.$router.push({
                                    name: 'ride-register-individual',
                                    params: {
                                        mobileNumber: vm.mobileNumber
                                    }
                                })
                            });
                        } else {
                            if (user.is_verified_by_admin === 0) {
                                swal({
                                    title: "Pending KYC",
                                    text: "You KYC is still in process and pending approval, Once done you would be able to use this service",
                                    icon: "info",
                                }).then(() => {
                                    vm.$router.replace({
                                        name: 'ride-register-individual',
                                        params: {
                                            mobileNumber: vm.mobileNumber
                                        }
                                    })
                                });
                            } else {
                                vm.getVehicleList()
                            }
                        }
                    }

                })
        },
        getVehicleList() {
            const vm = this
            window.axios.get('/vehicle/' + vm.mobileNumber + '/list')
                .then(resp => {
                    if (resp.data.response.status.error_code == 0) {
                        vm.vehicles = resp.data.response.data.vehicles
                        if(vm.vehicles?.length===0){

                            swal({
                                title: "No Vehicles found",
                                text: "You need to add a vehicle before you provide ride or parcel service, Please add one now!",
                                icon: "info",
                            }).then(() => {
                                vm.$router.push({
                                    name: 'account-manage-vehicles',
                                    params: {
                                        mobileNumber: vm.mobileNumber
                                    }
                                })
                            });
                        }
                    }
                })
        },
        showModal() {
            this.addingVehicleOrManager = true;
        },
        closeModal() {
            this.addingVehicleOrManager = false;
        }
    },
    computed: {},
    created() {
        let vm = this
        vm.mobileNumber = vm.$route.params.mobileNumber
        vm.checkUserServices();
    },
}

</script>

<style scoped>

</style>
