<template>
    <div style="overflow-x: clip;">
        <div class="row sticky-top bg-gray-100 shadow" style="padding-top: 10px;">
            <div class="col-sm-12">
                <h3>
                    <button style="border: none; background: none;" @click="goBack"><i
                        class="p-2 fas fa-arrow-left"></i></button>
                    Map
                    <!--                    {{locationType === 'search-to-location'?"Pick Drop Location":"Pick Location"}}-->
                </h3>
            </div>
        </div>

        <GoogleMap
            @click="placeMarker"
            ref="mapRef"
            api-key="AIzaSyDk4e0NV-K0_uVSlCqJNtCnKHP5qeYWzLY"
            style="position:fixed; top:0; bottom:0; right:0; left:0;"
            :center="center"
            :zoom="15">
            <Marker :options="markerPosition"/>
        </GoogleMap>

        <div class="row">
            <div class="col-12">
                <vue-google-autocomplete
                    ref="googleAutocomplete"
                    :disabled="isFetchingLocation"
                    enable-geolocation
                    types=""
                    style="position:fixed; top:80px; right: 30px; left: 30px; padding: 5px; border-radius: 10px;"
                    id="map-to"
                    country="in"
                    :placeholder="getPlaceholderText"
                    v-on:placechanged="getAddressData">
                </vue-google-autocomplete>
            </div>
        </div>

        <div class="row position-fixed bottom-0 bg-white p-3" style="left: 0; right: 0;">
            <div class="col-12">

                <h6 class="text-center">Select
                    {{ locationType === 'search-to-location' || 'select-drop-location' ? "Drop" : "Pick Up" }}
                    Location</h6>

                <hr>
                <template v-if="locationType === 'parcel-drop-location'">
                    <label>Your parcel will be dropped here</label>
                </template>
                <p>{{ getPlaceholderText }}</p>
                <template v-if="saveAddress">
                    <div class="row">
                        <div class="col-12">
                            <label>House/Apartment No.</label>
                            <soft-input v-model="houseNo"/>
                        </div>
                        <div class="col-12">
                            <label>Address Type</label>
                        </div>
                        <div class="col-3">
                            <soft-button @click="setAddressType('Home')" variant="outline" color="primary"
                                         :active="addressType=='Home'" full-width>Home
                            </soft-button>
                        </div>
                        <div class="col-3">
                            <soft-button @click="setAddressType('Shop')" variant="outline" color="primary"
                                         :active="addressType=='Shop'" full-width>Shop
                            </soft-button>
                        </div>
                        <div class="col-3">
                            <soft-button @click="setAddressType('Office')" variant="outline" color="primary"
                                         :active="addressType=='Office'" full-width>Office
                            </soft-button>
                        </div>
                        <div class="col-3">
                            <soft-button @click="setAddressType('Other')" variant="outline" color="primary"
                                         :active="addressType=='Other'" full-width>Other
                            </soft-button>
                        </div>
                    </div>
                </template>
                <br>
                <soft-checkbox v-if="markerPosition" @change="saveAddress=!saveAddress" name="save-address"
                               value="save-address"
                               id="save-address">
                    Save Address
                </soft-checkbox>
                <br>
                <soft-button full-width size="lg" color="dark"
                             :disabled="isFetchingLocation"
                             @click="confirmLocation">
                    Confirm
                </soft-button>
            </div>
        </div>
    </div>
</template>

<script>
import {ref, watch} from 'vue'
import {GoogleMap, Marker} from 'vue3-google-map'
import SoftButton from "../components/SoftButton";
import VueGoogleAutocomplete from "vue-google-autocomplete"
import swal from "sweetalert";
import SoftCheckbox from "../components/SoftCheckbox";
import SoftInput from "../components/SoftInput";


export default {
    components: {
        SoftInput,
        SoftCheckbox,
        SoftButton,
        GoogleMap,
        Marker,
        VueGoogleAutocomplete,
    },

    props: {
        locationType: {
            type: String,
            required: true
        },
        mobileNumber: {
            type: String,
            required: false,
            default: ''
        }
    },

    data() {
        return {
            center: {lat: 23.0208366, lng: 72.5086508},
            markerPosition: null,
            autocompleteText: '',
            pinCode: '',
            city: '',
            locality: '',
            isFetchingLocation: false,
            address: null,
            saveAddress: false,

            houseNo: '',
            addressType: ''
        }
    },

    created() {
        let vm = this
        vm.isFetchingLocation = false
        // navigator.geolocation.getCurrentPosition(
        //     position => {
        //         vm.isFetchingLocation = false
        //         console.log(position.coords.latitude);
        //         console.log(position.coords.longitude);
        //         vm.center = {lat: position.coords.latitude, lng: position.coords.longitude}
        //         vm.markerPosition = {
        //             position: {lat: position.coords.latitude, lng: position.coords.longitude},
        //         }
        //         vm.reverseGeoencode()
        //     },
        //     error => {
        //         vm.isFetchingLocation = false
        //         console.log(error.message);
        //     },
        // )
    },
    methods: {
        setAddressType(addressType) {
            this.addressType = addressType
        },
        async confirmLocation() {
            const vm = this

            if (vm.markerPosition === null) {
                swal({
                    title: "Pick a location",
                    text: "Tap on the map to place a RED marker exactly where you want to set the location point",
                    icon: "error",
                    buttons: ['Ok'],
                    dangerMode: true,
                })
                return
            }

            if (vm.saveAddress) {
                if (vm.addressType == '') {
                    alert("Select Address Type")
                    return;
                }
                if (vm.houseNo == '') {
                    alert("Enter House No.")
                    return;
                }

                const response = await window.axios.post(`/user/add/address/${vm.$props.mobileNumber}`, {
                    lat_lng: vm.markerPosition.position.lat + ',' + vm.markerPosition.position.lng,
                    lat: vm.markerPosition.position.lat,
                    lng: vm.markerPosition.position.lng,
                    address: vm.autocompleteText,
                    pincode: vm.pinCode,
                    city: vm.city,
                    locality: vm.locality,
                    address_type: vm.addressType,
                    house_number: vm.houseNo,
                })

                if (response.data.response.status.error_code === 0) {
                    console.log("address saved")
                }
            }

            const data = {
                address: vm.autocompleteText,
                latLng: vm.markerPosition.position.lat + ',' + vm.markerPosition.position.lng,
                pinCode: vm.pinCode,
                city: vm.city,
                locality: vm.locality,
            }

            if (vm.$props.locationType === 'from-location') {
                console.log(data)
                vm.$store.dispatch('setFromLocation', data)
                vm.$router.go(-1)
            } else if (vm.$props.locationType === 'to-location') {
                vm.$store.dispatch('setToLocation', data)
                vm.$router.go(-1)
            } else if (vm.$props.locationType === 'search-from-location') {
                vm.$store.dispatch('setSearchFromLocation', data)
                vm.$router.go(-1)
            } else if (vm.$props.locationType === 'search-to-location') {
                vm.$store.dispatch('setSearchToLocation', data)
                vm.$router.go(-1)
            } else if (vm.$props.locationType === 'select-drop-location') {
                vm.$store.dispatch('setSelectedDropLocation', data)
                vm.$router.go(-1)
            } else if (vm.$props.locationType === 'parcel-drop-location') {
                vm.$store.dispatch('setSelectedDropLocation', data)
                vm.$router.go(-1)
            }
            //vm.$router.back()
        },
        reverseGeoencode() {
            const vm = this
            vm.isFetchingLocation = true
            window.axios.get('/google/reverse/geoencode', {
                params: {
                    lat_lng: vm.markerPosition.position.lat + ',' + vm.markerPosition.position.lng
                }
            }).then(resp => {
                vm.isFetchingLocation = false
                vm.$refs.googleAutocomplete.autocompleteText = ''
                vm.autocompleteText = resp.data.response.data.formatted_address
                vm.city = resp.data.response.data.city
                vm.state = resp.data.response.data.state
                vm.locality = resp.data.response.data.locality
                vm.pinCode = resp.data.response.data.pin_code
            }).catch(error => {
                vm.isFetchingLocation = false
                console.log(error)
            })
        },
        getAddressData: function (addressData) {
            this.address = addressData;
            console.log(addressData)

            this.city = addressData.administrative_area_level_2
            this.locality = addressData.locality
            this.pinCode = addressData.postal_code

            this.center = {lat: addressData.latitude, lng: addressData.longitude}

            this.markerPosition = {
                position: {lat: addressData.latitude, lng: addressData.longitude},
            }
            this.reverseGeoencode()
            // this.markerPosition = null
        },
        placeMarker(e) {
            let vm = this
            if (vm.isFetchingLocation) {
                return
            }
            console.log(e.latLng.lat())
            vm.center.lat = e.latLng.lat()
            vm.center.lng = e.latLng.lng()
            vm.markerPosition = {
                position: {lat: e.latLng.lat(), lng: e.latLng.lng()},
            }
            vm.reverseGeoencode()
        },
        goBack() {
            this.$router.back()
        }
    },
    setup() {
        const mapRef = ref(null)

        // Third pattern: watch for "ready" then do something with the API or map instance
        watch(() => mapRef.value?.ready, (ready) => {

            //alert("map ready")

            if (!ready) return

            // do something with the api using `mapRef.value.api`
            // or with the map instance using `mapRef.value.map`
        })

        return {mapRef}
    },
    computed: {
        getPlaceholderText() {
            if (this.isFetchingLocation) {
                return 'Please wait, fetching location...'
            } else if (this.autocompleteText != '') {
                return this.autocompleteText
            } else {
                return 'Type location name here...'
            }
        },
    }
}
</script>
