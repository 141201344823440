<template>

    <div style="overflow-x: clip;">
        <div class="row sticky-top bg-gray-100 shadow" style="padding-top: 10px;">
            <div class="col-sm-12">
                <h3>
                    <button style="border: none; background: none;" @click="goBack"><i
                        class="p-2 fas fa-arrow-left"></i></button>
                    Company Registration
                </h3>
            </div>
        </div>

        <div class="container-fluid">
            <br>
            <form @submit.prevent>
                <div class="row">

                    <div class="col-sm-12">
                        <label>Registered Company Name</label>
                        <soft-input is-required v-model="form.companyName"
                                    placeholder="Enter your company name here"></soft-input>
                    </div>

                    <div class="col-sm-12">
                        <label>Registered Address of Company</label>
                        <soft-textarea v-model="form.officeAddress"
                                       placeholder="Enter your office address here"></soft-textarea>
                    </div>

                    <div class="col-sm-12">
                        <label>Registered Office PIN Code</label>
                        <soft-input v-model="form.officeAddress" placeholder="Enter your PIN code here"></soft-input>
                    </div>

                    <div class="col-sm-12">
                        <label>Registration Type</label>
                        <soft-input is-required v-model="form.gstNumber"
                                    placeholder="Type of company"></soft-input>
                    </div>

                    <div class="col-sm-12">
                        <label>GST Number</label>
                        <soft-input is-required v-model="form.gstNumber"
                                    placeholder="Enter your GSTIN here"></soft-input>
                    </div>

                    <div class="col-sm-12">
                        <label>Office Number</label>
                        <soft-input v-model="form.officeNumber"
                                    placeholder="Enter your office number here"></soft-input>
                    </div>

                    <div class="col-sm-12">
                        <label>Email Address</label>
                        <soft-input v-model="form.emailAddress"
                                    placeholder="Enter your email address here"></soft-input>
                    </div>

                    <div class="col-sm-12">
                        <label>Select Type of Services you Provide</label>
                        <soft-checkbox
                            id="flexCheckDefault1"
                            name="flexCheckDefault"
                            class="font-weight-light">
                            Ride/Taxi Service
                        </soft-checkbox>
                        <soft-checkbox
                            id="flexCheckDefault2"
                            name="flexCheckDefault"
                            class="font-weight-light">
                            Courier/Package/Parcel Services
                        </soft-checkbox>
                        <soft-checkbox
                            id="flexCheckDefault3"
                            name="flexCheckDefault"
                            class="font-weight-light">
                            Tours Services
                        </soft-checkbox>
                        <soft-checkbox
                            id="flexCheckDefault4"
                            name="flexCheckDefault"
                            class="font-weight-light">
                            Travels Services
                        </soft-checkbox>

                        <soft-checkbox
                            id="flexCheckDefault5"
                            name="flexCheckDefault"
                            class="font-weight-light">
                            Transport/Logistics Services
                        </soft-checkbox>

                    </div>


                    <div class="col-sm-12">
                        <br>
                        <soft-button @click="showModal" full-width color="dark" size="md"
                                     variant="gradient">
                            Branch Details
                        </soft-button>

                    </div>

                    <div class="col-12">
                        <br>
                        <soft-checkbox id="terms-conditions"
                                       @change="notAcceptedTermsAndConditions=!notAcceptedTermsAndConditions">
                            <slot>
                                I Accept TransAll Terms & Conditions (<a style="color: #0d6efd;"
                                                                         href="https://transall.in/terms_conditions.html"
                                                                         target="_blank">Read More</a>)
                            </slot>
                        </soft-checkbox>
                    </div>

                    <div class="col-sm-12">
                        <br>
                        <soft-button :disabled="notAcceptedTermsAndConditions" btn-type="submit" @click="register"
                                     full-width color="success" size="lg"
                                     variant="gradient">
                            Register
                        </soft-button>
                    </div>

                </div>
            </form>

            <br>
        </div>

        <Modal
            ref="modal"
            v-show="addingBranchDetails"
            @close="closeModal"
        >
            <template v-slot:body>
                <component :modal="$refs.modal" :is="branchDetailsComponent"></component>
            </template>
        </Modal>

    </div>
</template>

<script>

import SoftInput from "../../components/SoftInput";
import SoftButton from "../../components/SoftButton";
import SoftTextarea from "../../components/SoftTextarea";
import SoftCheckbox from "../../components/SoftCheckbox";
import swal from "sweetalert";
import AddBranchDetails from "../../components/modals/AddBranchDetails";
import Modal from "../../components/Modal"

export default {
    components: {
        SoftInput,
        SoftButton,
        SoftTextarea,
        SoftCheckbox,
        Modal
    },
    props: {
        mobileNumber: {
            type: String,
            required: true
        }
    },
    data() {
        return {

            notAcceptedTermsAndConditions: true,

            addingBranchDetails: false,
            branchDetailsComponent: AddBranchDetails,

            form: {
                companyName: '',
                officeAddress: '',
                branchAddress: '',
                gstNumber: '',
                mobileNumber: '',
                officeNumber: '',
                emailAddress: '',
            }

        }
    },
    methods: {
        goBack() {
            swal({
                title: "Are you sure you want to go back?",
                text: "Once back, you will not be able to recover this filled form!",
                icon: "warning",
                buttons: ['Cancel', 'GoBack'],
                dangerMode: true,
            }).then((goBack) => {
                if (goBack) {
                    this.$router.replace({name: 'ride-register'})
                }
            });
        },
        register() {
            let vm = this
            console.log(vm.form)
        },
        showModal() {
            this.addingBranchDetails = true;
        },
        closeModal() {
            this.addingBranchDetails = false;
        }
    },
    created() {
        //let vm = this
        //vm.form.mobileNumber = this.$route.params.mobileNumber

    }
}

</script>

<style scoped>

</style>
