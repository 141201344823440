<template>

    <div style="overflow-x: clip;">
        <div class="row sticky-top bg-gray-100 shadow" style="padding-top: 10px;">
            <div class="col-sm-12">
                <h3>
                    <button style="border: none; background: none;" @click="goBack"><i
                        class="p-2 fas fa-arrow-left"></i></button>
                    Individual Registration
                </h3>
            </div>
        </div>

        <div v-if="!isFreshRegistration && !isEditingForm" class="container-fluid">
            <br>
            <form @submit.prevent>
                <div class="row">

                    <div class="col-12" v-if="!kycVerified">
                        <div class="card bg-dark text-white text-center">
                            <div class="card-body">
                                <p>Your <b>KYC</b> is still in process, Once it is verified by <b>Transall</b> you would
                                    be able to
                                    use all the services.</p>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12">
                            <label>Personal Photo</label>
                            <br>
                            <img class="img-fluid card" :src="form.photoUrl">
                        </div>
                    </div>

                    <div class="col-sm-12">
                        <br>
                        <label>First Name</label>
                        <br>
                        <span>{{ form.firstName }}</span>
                    </div>

                    <div class="col-sm-12">
                        <label>Last Name</label>
                        <br>
                        <span>{{ form.lastName }}</span>
                    </div>

                    <div class="col-sm-12">
                        <label>Registered Address</label>
                        <br>
                        <span>{{ form.address }}</span>
                    </div>

                    <div class="col-sm-12">
                        <label>PIN Code</label>
                        <br>
                        <span>{{ form.pinCode }}</span>
                    </div>

                    <div class="col-sm-12">
                        <label>Personal Email Address</label>
                        <br>
                        <span>{{ form.email }}</span>
                    </div>

                    <div class="col-sm-12">
                        <label>Aadhar Card Number</label>
                        <br>
                        <span>{{ form.aadharCardNo }}</span>
                    </div>

                    <!--                    <div class="col-sm-12">-->
                    <!--                        <label>PAN Card Number</label>-->
                    <!--                        <br>-->
                    <!--                        <span>{{ form.panCardNumber }}</span>-->
                    <!--                    </div>-->


                    <div v-if="company.name">

                        <br>

                        <div class="col-sm-12">
                            <label>Registered Company Name</label>
                            <soft-input is-required v-model="company.name"
                                        placeholder="Enter your company name"></soft-input>
                        </div>

                        <div class="col-sm-12">
                            <label>Registered Address of Company</label>
                            <soft-textarea v-model="company.address"
                                           placeholder="Enter your office address here"></soft-textarea>
                        </div>

                        <div class="col-sm-12">
                            <label>Registered Office PIN Code</label>
                            <soft-input v-model="company.pin"
                                        placeholder="Enter your office PIN code"></soft-input>
                        </div>

                        <div class="col-sm-12">
                            <label>Registration Type</label>
                            <soft-input is-required v-model="company.type"
                                        placeholder="Type of company"></soft-input>
                        </div>

                        <div class="col-sm-12">
                            <label>GST Number</label>
                            <soft-input is-required v-model="company.gstin"
                                        placeholder="Enter your GSTIN"></soft-input>
                        </div>

                        <div class="col-sm-12">
                            <label>Office Contact Number</label>
                            <soft-input v-model="company.contact"
                                        placeholder="Enter your office contact number"></soft-input>
                        </div>

                        <div class="col-sm-12">
                            <label>Company Email Address</label>
                            <soft-input v-model="company.email"
                                        placeholder="Enter your company email address"></soft-input>
                        </div>

                    </div>

                    <div class="col-sm-12">
                        <br>
                        <label>Type of Services you are Providing</label>
                        <soft-checkbox
                            disabled
                            :checked="form.rideService==1"
                            value="1"
                            v-model="form.rideService"
                            id="ride"
                            name="ride">
                            Ride Service
                        </soft-checkbox>
                        <soft-checkbox disabled
                                       :checked="form.parcelService==1"
                                       value="1"
                                       v-model="form.parcelService"
                                       id="parcel"
                                       name="parcel">
                            Package & Parcel Services
                        </soft-checkbox>
                        <soft-checkbox disabled
                                       :checked="form.toursService==1"
                                       value="1"
                                       v-model="form.toursService"
                                       id="tours"
                                       name="tours">
                            Tours Services
                        </soft-checkbox>
                        <soft-checkbox disabled
                                       :checked="form.travelsTaxiService==1"
                                       value="1"
                                       v-model="form.travelsTaxiService"
                                       id="travels"
                                       name="travels">
                            Travels & Taxi Services
                        </soft-checkbox>
                        <soft-checkbox disabled
                                       :checked="form.spvService==1"
                                       value="1"
                                       v-model="form.spvService"
                                       id="spv"
                                       name="spv">
                            Special Purpose Vehicle Service (SPV)
                        </soft-checkbox>

                        <!--                        <soft-checkbox disabled-->
                        <!--                                       :checked="form.transportCourierService==1"-->
                        <!--                                       value="1"-->
                        <!--                                       v-model="form.transportCourierService"-->
                        <!--                                       id="transport"-->
                        <!--                                       name="transport">-->
                        <!--                            Transport & Courier Services-->
                        <!--                        </soft-checkbox>-->

                    </div>

                    <div style="display: none;" class="col-12" v-if="form.parcelService==1 || form.rideService==1">
                        <br>
                        <label>Vehicle Model</label>
                        <p>{{ vehicle.model }}</p>
                        <label>Vehicle Number</label>
                        <p>{{ vehicle.number }}</p>
                    </div>

                    <div class="col-12">
                        <br>
                        <soft-checkbox disabled
                                       :checked="partnerWithTransAll==1"
                                       v-model="partnerWithTransAll"
                                       value="1"
                                       id="travels"
                                       name="travels">
                            Partner With Transall
                        </soft-checkbox>
                    </div>

                    <div class="col-sm-12" v-if="kycVerified">
                        <br>
                        <soft-button btn-type="submit"
                                     @click="editRegistrationForm"
                                     full-width color="success" size="lg"
                                     variant="gradient">
                            Edit
                        </soft-button>
                    </div>

                </div>
            </form>

            <br>
        </div>

        <div v-if="isEditingForm || isFreshRegistration" class="container-fluid">
            <br>
            <form @submit.prevent>
                <div class="row">

                    <div class="col-sm-12">
                        <label>First Name</label>
                        <soft-input is-required v-model="form.firstName"
                                    placeholder="Enter your legal first name"></soft-input>
                    </div>

                    <div class="col-sm-12">
                        <label>Last Name</label>
                        <soft-input is-required v-model="form.lastName"
                                    placeholder="Enter your legal last name"></soft-input>
                    </div>

                    <div class="col-sm-12">
                        <label>Registered Address</label>
                        <soft-textarea v-model="form.address"
                                       placeholder="Enter your permanent address"></soft-textarea>
                    </div>

                    <div class="col-sm-12">
                        <label>PIN Code</label>
                        <soft-input v-model="form.pinCode" type="number"
                                    placeholder="Enter your area PIN code"></soft-input>
                    </div>

                    <div class="col-sm-12">
                        <label>Personal Email Address</label>
                        <soft-input v-model="form.email"
                                    type="email"
                                    placeholder="Enter your valid email address"></soft-input>
                    </div>

                    <div class="col-sm-12">
                        <label>Aadhar Card Number</label>
                        <soft-input v-model="form.aadharCardNo"
                                    type="number"
                                    placeholder="Enter your aadhar card number"></soft-input>
                    </div>

                    <!--                    <div class="col-sm-12">-->
                    <!--                        <label>PAN Card Number</label>-->
                    <!--                        <soft-input v-model="form.panCardNumber"-->
                    <!--                                    placeholder="Enter your pan card number"></soft-input>-->
                    <!--                    </div>-->

                    <div class="row">
                        <div class="col-sm-12">
                            <label>Upload Your Photo (Passport size)</label>
                            <br>
                            <input accept="image/*" type="file" name="personal-photo" id="personal-photo">
                        </div>
                    </div>

                    <div class="col-12">
                        <br>
                        <soft-checkbox id="register-company"
                                       @change="registerCompany=!registerCompany">
                            <slot>
                                Register My Company
                            </slot>
                        </soft-checkbox>
                    </div>


                    <div v-if="registerCompany">

                        <br>

                        <div class="col-sm-12">
                            <label>Registered Company Name</label>
                            <soft-input is-required v-model="company.name"
                                        placeholder="Enter your company name"></soft-input>
                        </div>

                        <div class="col-sm-12">
                            <label>Registered Address of Company</label>
                            <soft-textarea v-model="company.address"
                                           placeholder="Enter your office address here"></soft-textarea>
                        </div>

                        <div class="col-sm-12">
                            <label>Registered Office PIN Code</label>
                            <soft-input v-model="company.pin"
                                        placeholder="Enter your office PIN code"></soft-input>
                        </div>

                        <div class="col-sm-12">
                            <label>Registration Type</label>
                            <soft-input is-required v-model="company.type"
                                        placeholder="Type of company"></soft-input>
                        </div>

                        <div class="col-sm-12">
                            <label>GST Number</label>
                            <soft-input is-required v-model="company.gstin"
                                        placeholder="Enter your GSTIN"></soft-input>
                        </div>

                        <div class="col-sm-12">
                            <label>Office Contact Number</label>
                            <soft-input v-model="company.contact"
                                        placeholder="Enter your office contact number"></soft-input>
                        </div>

                        <div class="col-sm-12">
                            <label>Company Email Address</label>
                            <soft-input v-model="company.email"
                                        placeholder="Enter your company email address"></soft-input>
                        </div>

                    </div>


                    <div class="col-12">
                        <br>
                        <label>Select Type of Services you will Provide</label>
                        <soft-checkbox
                            :checked="form.rideService==1"
                            value="1"
                            v-model="form.rideService"
                            id="ride"
                            @click="selectedRideService=!selectedRideService"
                            name="ride">
                            <p class="form-label" @click="selectedRideService=!selectedRideService">
                                Ride Service
                            </p>
                        </soft-checkbox>

                        <soft-checkbox
                            :checked="form.parcelService==1"
                            value="1"
                            v-model="form.parcelService"
                            id="parcel"
                            @click="selectedParcelService=!selectedParcelService"
                            name="parcel">
                            <p class="form-label" @click="selectedParcelService=!selectedParcelService">
                                Package & Parcel Services
                            </p>
                        </soft-checkbox>
                        <soft-checkbox
                            :checked="form.toursService==1"
                            value="1"
                            v-model="form.toursService"
                            id="tours"
                            name="tours">
                            <p class="form-label">
                                Tours Services
                            </p>
                        </soft-checkbox>
                        <soft-checkbox
                            :checked="form.travelsTaxiService==1"
                            value="1"
                            v-model="form.travelsTaxiService"
                            id="travels"
                            name="travels">
                            <p class="form-label">
                                Travels & Taxi Services
                            </p>
                        </soft-checkbox>

                        <soft-checkbox
                            :checked="form.spvService==1"
                            value="1"
                            v-model="form.spvService"
                            id="spv"
                            name="spv">
                            <p class="form-label">
                                Special Purpose Vehicle Service (SPV)
                            </p>
                        </soft-checkbox>

                        <!--                        <soft-checkbox-->
                        <!--                            :checked="form.transportCourierService==1"-->
                        <!--                            value="1"-->
                        <!--                            v-model="form.transportCourierService"-->
                        <!--                            id="transport"-->
                        <!--                            name="transport">-->
                        <!--                            Transport & Courier Services-->
                        <!--                        </soft-checkbox>-->

                        <div class="row" style="display: none">//v-if="selectedRideService || selectedParcelService">
                            <div class="col-12">

                                <div class="row">
                                    <div class="col-sm-12">
                                        <label>Vehicle Model</label>
                                        <soft-input v-model="vehicle.model"
                                                    placeholder=""></soft-input>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-12">
                                        <label>Vehicle Number</label>
                                        <soft-input custom-style="text-transform:uppercase" v-model="vehicle.number"
                                                    placeholder=""></soft-input>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12">
                                        <label>Vehicle Front Photo</label>
                                        <br>
                                        <input accept="image/*" type="file" id="front-photo">
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12">
                                        <label>Vehicle Back Photo</label>
                                        <br>
                                        <input accept="image/*" type="file" id="back-photo">
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12">
                                        <label>RC Book Photo</label>
                                        <br>
                                        <input accept="image/*" type="file" id="rc-book-photo">
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12">
                                        <label>Licence Photo</label>
                                        <br>
                                        <input accept="image/*" type="file" id="licence-photo">
                                    </div>
                                </div>

                                <br>
                            </div>
                        </div>

                    </div>

                    <div class="col-12">
                        <br>
                        <soft-checkbox id="terms-conditions-2"
                                       :checked="partnerWithTransAll==1"
                                       @change="partnerWithTransAll=!partnerWithTransAll">
                            <slot>
                                Partner with TransAll (Get more customers with verification badge and optimised search
                                results. <a style="color: #0d6efd;"
                                            href="https://transall.in/partner_with_us.html"
                                            target="_blank">Read More</a>)
                            </slot>
                        </soft-checkbox>
                    </div>

                    <div class="col-12">
                        <br>
                        <soft-checkbox id="terms-conditions"
                                       @change="notAcceptedTermsAndConditions=!notAcceptedTermsAndConditions">
                            <slot>
                                I Accept TransAll Terms & Conditions (<a style="color: #0d6efd;"
                                                                         href="https://transall.in/terms_conditions.html"
                                                                         target="_blank">Read More</a>)
                            </slot>
                        </soft-checkbox>
                    </div>

                    <div class="col-sm-12">
                        <br>
                        <soft-button :disabled="notAcceptedTermsAndConditions || isApiCalling" btn-type="submit"
                                     @click="register"
                                     full-width color="success" size="lg"
                                     variant="gradient">
                            {{ buttonText }}
                        </soft-button>
                    </div>

                    <div class="col-sm-12" v-if="!isFreshRegistration">
                        <br>
                        <soft-button btn-type="submit"
                                     @click="cancelFormEditing"
                                     full-width color="dark" size="lg"
                                     variant="gradient">
                            CANCEL
                        </soft-button>
                    </div>

                </div>
            </form>

            <br>
        </div>

    </div>
</template>

<script>

import SoftInput from "../../components/SoftInput";
import SoftButton from "../../components/SoftButton";
import SoftTextarea from "../../components/SoftTextarea";
import SoftCheckbox from "../../components/SoftCheckbox";
import swal from "sweetalert";

export default {
    components: {
        SoftInput,
        SoftButton,
        SoftTextarea,
        SoftCheckbox,
    },
    data() {
        return {

            kycVerified: false,

            selectedRideService: false,
            selectedParcelService: false,

            isFreshRegistration: true,

            isEditingForm: false,

            isApiCalling: false,

            mobileNumber: null,

            registerCompany: false,

            notAcceptedTermsAndConditions: true,
            partnerWithTransAll: false,

            addingBranchDetails: false,

            form: {
                firstName: '',
                lastName: '',
                gender: '',
                address: '',
                pinCode: '',
                email: '',
                aadharCardNo: '',
                panCardNumber: '',
                photoUrl: '',

                transportCourierService: '',
                travelsTaxiService: '',
                spvService: '',
                toursService: '',
                parcelService: '',
                rideService: '',
            },

            vehicle: {
                model: '',
                number: '',
                frontPhotoUrl: '',
                backPhotoUrl: '',
                rcBookPhotoUrl: '',
                licencePhotoUrl: '',
            },

            company: {
                name: '',
                address: '',
                pin: '',
                type: '',
                gstin: '',
                contact: '',
                email: '',
            },

            images: {
                personalPhotoUrl: '',
            }

        }
    },
    methods: {
        cancelFormEditing() {
            this.isEditingForm = false
        },
        editRegistrationForm() {
            this.isEditingForm = true
        },
        goBack() {
            swal({
                title: "Are you sure you want to go back?",
                text: "Once back, you will not be able to recover this filled form!",
                icon: "warning",
                buttons: ['Cancel', 'GoBack'],
                dangerMode: true,
            }).then((goBack) => {
                if (goBack) {
                    window.ReactNativeWebView.postMessage("close-screen")
                }
            });
        },
        uploadPersonalPhoto() {

            const vm = this

            return new Promise((resolve) => {

                let formData = new FormData()
                let imagefile = document.getElementById('personal-photo')
                if (imagefile.files[0]) {
                    formData.append("image", imagefile.files[0])
                    window.axios.post('/do/upload/image/personal', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(resp => {
                        if (resp.data.response.status.error_code === 0) {
                            vm.images.personalPhotoUrl = resp.data.response.data.image_url
                        }
                        resolve()
                    }).catch(() => resolve())
                } else {
                    resolve()
                }

            })


        },
        uploadFrontPhoto() {

            const vm = this

            return new Promise((resolve) => {

                let formData = new FormData()
                let imagefile = document.getElementById('front-photo')
                if (imagefile.files[0]) {
                    formData.append("image", imagefile.files[0])
                    window.axios.post('/do/upload/image/vehicle/front', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(resp => {
                        if (resp.data.response.status.error_code === 0) {
                            vm.vehicle.frontPhotoUrl = resp.data.response.data.image_url
                        }
                        resolve()
                    }).catch(() => resolve())
                } else {
                    resolve()
                }

            })


        },
        uploadBackPhoto() {

            const vm = this

            return new Promise((resolve) => {

                let formData = new FormData()
                let imagefile = document.getElementById('back-photo')
                if (imagefile.files[0]) {
                    formData.append("image", imagefile.files[0])
                    window.axios.post('/do/upload/image/vehicle/back', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(resp => {
                        if (resp.data.response.status.error_code === 0) {
                            vm.vehicle.backPhotoUrl = resp.data.response.data.image_url
                        }
                        resolve()
                    }).catch(() => resolve())
                } else {
                    resolve()
                }

            })


        },
        uploadRcBookPhoto() {

            const vm = this

            return new Promise((resolve) => {

                let formData = new FormData()
                let imagefile = document.getElementById('rc-book-photo')
                if (imagefile.files[0]) {
                    formData.append("image", imagefile.files[0])
                    window.axios.post('/do/upload/image/vehicle/rc/book', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(resp => {
                        if (resp.data.response.status.error_code === 0) {
                            vm.vehicle.rcBookPhotoUrl = resp.data.response.data.image_url
                        }
                        resolve()
                    }).catch(() => resolve())
                } else {
                    resolve()
                }

            })


        },
        uploadLicencePhoto() {

            const vm = this

            return new Promise((resolve) => {

                let formData = new FormData()
                let imagefile = document.getElementById('licence-photo')
                if (imagefile.files[0]) {
                    formData.append("image", imagefile.files[0])
                    window.axios.post('/do/upload/image/driving/licence', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(resp => {
                        if (resp.data.response.status.error_code === 0) {
                            vm.vehicle.licencePhotoUrl = resp.data.response.data.image_url
                        }
                        resolve()
                    }).catch(() => resolve())
                } else {
                    resolve()
                }

            })


        },
        async uploadAllImages() {
            const vm = this

            await vm.uploadPersonalPhoto()

            // if (vm.selectedRideService || vm.selectedParcelService) {
            //     await vm.uploadFrontPhoto()
            //     await vm.uploadBackPhoto()
            //     await vm.uploadRcBookPhoto()
            //     await vm.uploadLicencePhoto()
            // }

        },
        async register() {
            let vm = this

            if (vm.form.firstName == '' || vm.form.lastName == '' || vm.form.aadharCardNo == '' || vm.form.email == '' || vm.form.address == '' || vm.form.pinCode == '') {
                vm.$toast.open({
                    message: 'All Details are Required',
                    type: 'error',
                    position: 'top',
                    duration: 2000,
                    dismissible: false,
                })
                return
            }

            vm.isApiCalling = true

            console.log(vm.form)

            await vm.uploadAllImages()

            if (vm.images.personalPhotoUrl == '') {
                vm.$toast.open({
                    message: 'Upload your photo',
                    type: 'error',
                    position: 'top',
                    duration: 2000,
                    dismissible: false,
                })
                // eslint-disable-next-line require-atomic-updates
                vm.isApiCalling = false
                return
            }

            // if (vm.form.rideService == 1 || vm.form.parcelService == 1) {
            //     if (vm.vehicle.number == '' || vm.vehicle.model == '') {
            //         vm.$toast.open({
            //             message: 'Required Vehicle No & Model',
            //             type: 'error',
            //             position: 'top',
            //             duration: 2000,
            //             dismissible: false,
            //         })
            //         // eslint-disable-next-line require-atomic-updates
            //         vm.isApiCalling = false
            //         return
            //     }
            //
            //     if (vm.vehicle.frontPhotoUrl == '' || vm.vehicle.backPhotoUrl == '') {
            //         vm.$toast.open({
            //             message: 'Vehicle photos are required',
            //             type: 'error',
            //             position: 'top',
            //             duration: 2000,
            //             dismissible: false,
            //         })
            //         // eslint-disable-next-line require-atomic-updates
            //         vm.isApiCalling = false
            //         return
            //     }
            //
            //     if (vm.vehicle.licencePhotoUrl == '' || vm.vehicle.rcBookPhotoUrl == '') {
            //         vm.$toast.open({
            //             message: 'Vehicle documents are required',
            //             type: 'error',
            //             position: 'top',
            //             duration: 2000,
            //             dismissible: false,
            //         })
            //         // eslint-disable-next-line require-atomic-updates
            //         vm.isApiCalling = false
            //         return
            //     }
            //
            // }

            window.axios.post('/user/register/for/service/' + vm.mobileNumber, {

                partner_with_transall: vm.partnerWithTransAll,

                first_name: vm.form.firstName,
                last_name: vm.form.lastName,

                personal_picture_image_url: vm.images.personalPhotoUrl,

                email: vm.form.email,
                address: vm.form.address,
                aadhar_card_number: vm.form.aadharCardNo,
                pan_card_number: vm.form.panCardNumber,
                pin_code: vm.form.pinCode,

                transport: vm.form.transportCourierService,
                travels: vm.form.travelsTaxiService,
                spv: vm.form.spvService,
                tours: vm.form.toursService,
                parcel: vm.form.parcelService,
                ride: vm.form.rideService,

                // vehicle_front_image_url: vm.vehicle.frontPhotoUrl,
                // vehicle_back_image_url: vm.vehicle.backPhotoUrl,
                // driving_licence_image_url: vm.vehicle.licencePhotoUrl,
                // vehicle_rc_book_image_url: vm.vehicle.rcBookPhotoUrl,

                vehicle_number: vm.vehicle.number,
                vehicle_model: vm.vehicle.model,


            }).then(response => {
                vm.isApiCalling = false
                if (response.data.response.status.error_code === 0) {
                    swal({
                        title: "Success",
                        text: "Your registration is done successfully, you can provide selected services once your KYC is approved.",
                        icon: "success",
                    }).then(() => {
                        window.ReactNativeWebView.postMessage("close-screen")
                    });
                } else {
                    vm.isApiCalling = false
                }
            }).catch(() => {
                vm.isApiCalling = false
            })

        },
        showModal() {
            this.addingBranchDetails = true;
        },
        closeModal() {
            this.addingBranchDetails = false;
        },
        getRegistrationDetails() {
            const vm = this
            window.axios.get('/user/get/service/registration/details/' + vm.mobileNumber)
                .then(resp => {
                    if (resp.data.response.status.error_code === 0) {

                        console.log(resp.data.response.data)
                        const user = resp.data.response.data.user

                        vm.form.firstName = user.first_name
                        vm.form.lastName = user.last_name
                        vm.form.address = user.address
                        vm.form.pinCode = user.pin_code
                        vm.form.email = user.email
                        vm.form.aadharCardNo = user.aadhar_card_number

                        vm.form.photoUrl = user.personal_picture_image_url

                        vm.form.transportCourierService = user.register_for_transport
                        vm.form.travelsTaxiService = user.register_for_travels
                        vm.form.spvService = user.register_for_spv
                        vm.form.toursService = user.register_for_tours
                        vm.form.parcelService = user.register_for_parcel
                        vm.form.rideService = user.register_for_ride

                        vm.selectedRideService = user.register_for_ride == 1
                        vm.selectedParcelService = user.register_for_parcel == 1

                        vm.partnerWithTransAll = user.partner_with_transall == 1

                        vm.vehicle.model = user.vehicle_model
                        vm.vehicle.number = user.vehicle_number

                        vm.partnerWithTransAll = user.partner_with_transall == 1

                        vm.kycVerified = user.is_verified_by_admin == 1

                        if (vm.form.transportCourierService === 1 || vm.form.travelsTaxiService === 1 || vm.form.toursService === 1 || vm.form.parcelService === 1 || vm.form.rideService === 1) {
                            vm.isFreshRegistration = false
                        }

                    }
                })
        }
    },
    computed: {
        buttonText() {
            if (this.isApiCalling) {
                return 'LOADING...';
            }
            if (this.isEditingForm && !this.isFreshRegistration) {
                return 'SAVE';
            }
            return 'REGISTER';
        }
    },
    created() {
        this.mobileNumber = this.$route.params.mobileNumber
        this.getRegistrationDetails();
    }
}

</script>

<style scoped>

label {
    margin: 0;
    padding: 0;
}

p {
    margin: 0;
    padding: 0;
    font-size: 14px;
}

.form-label {
    font-size: 0.75rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
    color: #344767;
    margin-left: 0.25rem;
}

</style>
