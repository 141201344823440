<template>
    <div style="overflow-x: clip;">

        <div class="row sticky-top bg-gray-100 shadow" style="padding-top: 10px;">
            <div class="col-sm-12">
                <h2>
                    <button style="border: none; background: none;" @click="goBack"><i
                        class="p-2 fas fa-arrow-left"></i></button>
                    {{ getTitle }}
                </h2>
            </div>
        </div>

        <div class="container-fluid">
            <br>

            <!--            CUSTOMER FLOW
            -->
            <template v-if="activityType==='customer_request' && (serviceType==='ride'||'ride-now'||'parcel')">

                <div class="row">

                    <div class="col-12">

                        <ul class="timeline">

                            <li class="timeline-block">
                                <div class="card">
                                    <div class="card-body">
                                        <span style="text-align: right;">{{ ride.requestTime }}</span>

                                        <template v-if="serviceType==='parcel'">
                                            <h4>Your Parcel Request</h4>
                                        </template>
                                        <template v-else>
                                            <h4>You Requested a Ride</h4>
                                        </template>

                                        <label>From</label>
                                        <p>{{ ride.from }}</p>
                                        <label>To</label>
                                        <p>{{ ride.to }}</p>

                                        <template v-if="serviceType==='ride'">
                                            <label>No of Passengers</label>
                                            <p>{{ ride.noOfPassengers }}</p>
                                        </template>

                                        <soft-button color="danger" full-width v-if="ride.status==0"
                                                     @click="deleteRideRequest">CANCEL
                                        </soft-button>

                                        <template v-if="serviceType==='parcel'">
                                            <button style="float: right; width: 100%;" class="btn btn-primary"
                                                    @click="showParcelDetailsModal">
                                                <i style="margin-right: 10px;" class="fa fa-map fa-xl"
                                                   aria-hidden="true"></i>View
                                                Parcel Details
                                            </button>
                                        </template>

                                    </div>
                                </div>
                                <br>
                            </li>


                            <li class="timeline-block" v-if="ride.status==0">
                                <div class="card">
                                    <div class="card-body">
                                        <template v-if="serviceType==='parcel'">
                                            <h6 class="text-muted blink_me">Waiting for a vehicle...</h6>
                                        </template>
                                        <template v-else>
                                            <h6 class="text-muted blink_me">Waiting for rider...</h6>
                                        </template>
                                    </div>
                                </div>
                                <br>
                            </li>

                            <li class="timeline-block" v-if="ride.status>=1">
                                <div class="card">
                                    <div class="card-body">
                                        <span style="text-align: right;">{{ ride.riderAcceptedTime }}</span>

                                        <template v-if="serviceType==='parcel'">
                                            <h4>{{ ride.riderName }} Accepted your parcel request</h4>
                                        </template>
                                        <template v-else>
                                            <h4>{{ ride.riderName }} Accepted your ride request</h4>
                                        </template>

                                        <br>
                                        <label>Vehicle</label>
                                        <h5>{{ ride.vehicle }}</h5>


                                        <template v-if="serviceType==='parcel'">
                                            <label>Transportation Fare</label>
                                        </template>
                                        <template v-else>
                                            <label>Ride Fare</label>
                                        </template>

                                        <h5>{{ stringToINR(ride.rate) }}</h5>
                                        <br>
                                        <div class="row" v-if="ride.status==1">
                                            <div class="col-6">
                                                <soft-button color="danger" full-width @click="rejectBidRequest">REJECT
                                                </soft-button>
                                            </div>
                                            <div class="col-6">
                                                <soft-button color="success" full-width @click="acceptBidRequest">ACCEPT
                                                    &
                                                    PAY
                                                </soft-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br>
                            </li>


                            <template v-if="ride.status>=2">

                                <li class="timeline-block">
                                    <div class="card">
                                        <div class="card-body">
                                            <h6 class="text-muted">You made the payment of <b>₹{{ ride.rate }}/-</b>
                                            </h6>
                                        </div>
                                    </div>
                                    <br>
                                </li>


                                <li class="timeline-block" v-if="ride.status<=3">
                                    <div class="card">
                                        <div class="card-body">
                                            <template v-if="serviceType==='parcel'">
                                                <label>Driver Name</label>
                                            </template>
                                            <template v-else>
                                                <label>Rider Name</label>
                                            </template>
                                            <br>
                                            <span>{{ ride.riderName }}</span>
                                            <br>
                                            <img class="img-fluid card" :src="ride.riderPhotoUrl">
                                            <br>
                                            <label>Vehicle</label>
                                            <br>
                                            <span>{{ ride.vehicle }}</span>
                                            <br>
                                            <label>Vehicle No.</label>
                                            <br>
                                            <span>{{ ride.vehicleNumber }}</span>
                                            <br>
                                            <br>
                                            <a :href="callRider"
                                               style="display: block; text-align: center; color: white; font-weight: bold; background-color: olivedrab; border-radius: 10px; width: 100%; padding: 10px;"
                                               target="_blank"
                                               v-if="ride.status<=3">CALL
                                                {{ serviceType === 'parcel' ? 'DRIVER' : 'RIDER' }}
                                            </a>
                                        </div>
                                    </div>
                                    <br>
                                </li>


                                <li class="timeline-block" v-if="(ride.status==2 && socketConnected)">
                                    <div class="card"
                                         style="background-image: linear-gradient(310deg, rgba(104,32,197,0.96) 0%, rgba(171,134,218,0.96) 100%);">
                                        <div class="card-body">

                                            <div class="row">
                                                <div class="col-12" v-if="locationTimestamp!==''">

                                                    <template v-if="serviceType==='parcel'">
                                                        <h5 class="text-white">Drivers Live Location</h5>
                                                    </template>
                                                    <template v-else>
                                                        <h5 class="text-white">Riders Live Location</h5>
                                                    </template>

                                                    <GoogleMap
                                                        ref="mapRef"
                                                        disable-default-ui
                                                        :styles=mapStyle
                                                        api-key="AIzaSyDk4e0NV-K0_uVSlCqJNtCnKHP5qeYWzLY"
                                                        style="height: 200px; width: 100%; border-radius: 10px;"
                                                        :center="ridersLocationPoint"
                                                        :zoom="15">

                                                        <Marker :options="{
                                position: ridersLocationPoint,
                            }">
                                                            <InfoWindow :options="{ position: ridersLocationPoint }">
                                                                {{ serviceType === 'parcel' ? 'Driver' : 'Rider' }}
                                                                Location <br>
                                                                {{ locationTimestamp }}
                                                            </InfoWindow>
                                                        </Marker>

                                                    </GoogleMap>
                                                </div>
                                                <div v-else>
                                                    <div class="col-12">
                                                        <template v-if="serviceType==='parcel'">
                                                            <p style="margin: 0; color: white; font-weight: bold; text-transform: uppercase;"
                                                               class="text-center blink-text">
                                                                Waiting for Drivers location...</p>
                                                        </template>
                                                        <template v-else>
                                                            <p style="margin: 0; color: white; font-weight: bold; text-transform: uppercase;"
                                                               class="text-center blink-text">
                                                                Waiting for rider location...</p>
                                                        </template>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <br>
                                </li>


                                <li class="timeline-block" v-if="ride.status==2">
                                    <div class="card">
                                        <div class="card-body">
                                            <template v-if="serviceType==='parcel'">
                                                <h4>Secure PIN</h4>
                                            </template>
                                            <template v-else>
                                                <h4>Ride PIN</h4>
                                            </template>

                                            <span class="text-bold"
                                                  style="border-radius:10px; text-align: center; font-size: 28px; letter-spacing: 10px; background-color: black; color: white; padding: 5px;">{{
                                                    ride.ridePin
                                                }}</span>
                                            <p>
                                                <br>
                                                <template v-if="serviceType==='parcel'">
                                                    <i>*Share this Secure PIN when rider arrives at your location</i>
                                                </template>
                                                <template v-else>
                                                    <i>*Share this Ride PIN when rider arrives at your location</i>
                                                </template>
                                            </p>
                                        </div>
                                    </div>
                                    <br>
                                </li>


                            </template>


                            <li class="timeline-block" v-if="ride.status==3">
                                <div class="card">
                                    <div class="card-body">
                                        <h6 class="text-muted">Ride started</h6>
                                        <!--                                        <p>{{ getRideSharingDetails }}</p>-->
                                        <a :href="getRideSharingDetails" target="_blank">
                                            <img height="100"
                                                 src="https://cdn.iconscout.com/icon/free/png-256/free-whatsapp-share-button-3289864-2758562.png">
                                        </a>
                                    </div>
                                </div>
                                <br>
                            </li>


                            <li class="timeline-block" v-if="ride.status>=4">
                                <div class="card">
                                    <div class="card-body">
                                        <h6 class="text-muted">Ride ended</h6>
                                    </div>
                                </div>
                                <br>
                            </li>

                            <li class="timeline-block" v-if="ride.status>=4">
                                <div class="card">
                                    <div class="card-body">

                                        <div class="row">
                                            <div class="col-12">
                                                <label>{{
                                                        givenRiderRating != null ? `You provided ${givenRiderRating} star rating` : 'Please provide your rating'
                                                    }}</label>
                                                <star-rating star-size="30" text-class="star-rating-text"
                                                             rounded-corners
                                                             v-model:rating="rating"></star-rating>
                                                <br>
                                            </div>
                                        </div>

                                        <template v-if="!isRatingSubmitted && givenRiderRating==null">

                                            <div class="row">
                                                <div class="col-12">
                                                    <soft-button @click="submitRating('rider')" full-width color="dark">
                                                        Submit Rating
                                                    </soft-button>
                                                </div>
                                            </div>

                                        </template>

                                    </div>
                                </div>
                            </li>


                        </ul>

                    </div>

                </div>
            </template>

            <!--            RIDER FLOW
            -->
            <template
                v-if="activityType==='allocated_customer_request' && (serviceType==='ride'||'ride-now'||'parcel')">

                <div class="row">

                    <div class="col-12">

                        <br>

                        <ul class="timeline">

                            <li class="timeline-block">
                                <div class="card">
                                    <div class="card-body">
                                        <span style="text-align: right;">{{ ride.requestTime }}</span>

                                        <h4>Your ride info</h4>
                                        <div class="row">
                                            <div class="col-12">
                                                <label>From (Pick-up point)</label>
                                                <p>{{ ride.from }}</p>
                                            </div>
                                            <div class="col-12">
                                                <button class="btn btn-warning" size="sm" style="float: right;"
                                                        @click="navigate(ride.from_lat,ride.from_lng)">
                                                    <i style="margin-right: 10px;" class="fa fa-location-arrow fa-xl"
                                                       aria-hidden="true"></i>Navigate
                                                </button>
                                            </div>
                                        </div>


                                        <div class="row">
                                            <div class="col-12">
                                                <label>To (Drop point)</label>
                                                <p>{{ ride.to }}</p>
                                            </div>
                                            <div class="col-12">
                                                <button class="btn btn-warning" size="sm" style="float: right;"
                                                        @click="navigate(ride.to_lat,ride.to_lng)">
                                                    <i style="margin-right: 10px;" class="fa fa-location-arrow fa-xl"
                                                       aria-hidden="true"></i>Navigate
                                                </button>
                                            </div>
                                        </div>


                                        <!--                                        <label>No of Passengers</label>-->
                                        <!--                                        <p>{{ ride.noOfPassengers }}</p>-->
                                        <button style="float: right; width: 100%;" class="btn btn-info"
                                                @click="showOnMap(ride.from_lat,ride.from_lng,ride.to_lat,ride.to_lng)">
                                            <i style="margin-right: 10px;" class="fa fa-map fa-xl"
                                               aria-hidden="true"></i>View
                                            Trip On Map
                                        </button>

                                        <template v-if="serviceType==='parcel'">
                                            <button style="float: right; width: 100%;" class="btn btn-primary"
                                                    @click="showParcelDetailsModal">
                                                <i style="margin-right: 10px;" class="fa fa-map fa-xl"
                                                   aria-hidden="true"></i>View
                                                Parcel Details
                                            </button>
                                        </template>

                                    </div>
                                </div>
                                <br>
                            </li>

                            <li class="timeline-block" v-if="ride.status>=1">
                                <div class="card">
                                    <div class="card-body">

                                        <span style="text-align: right;">{{ ride.riderAcceptedTime }}</span>

                                        <template v-if="serviceType==='parcel'">
                                            <h4>{{ customer.name }} need to send Parcel</h4>
                                        </template>
                                        <template v-else>
                                            <h4>{{ customer.name }} Accepted your request</h4>
                                        </template>

                                    </div>
                                </div>
                                <br>
                            </li>


                            <template v-if="ride.status>=2">

                                <li class="timeline-block">
                                    <div class="card">
                                        <div class="card-body">
                                            <h6 class="text-muted">{{ customer.name }} made the payment of <b>₹{{
                                                    ride.rate
                                                }}/-</b>
                                            </h6>
                                        </div>
                                    </div>
                                    <br>
                                </li>

                                <li class="timeline-block">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-12">
                                                    <label>Receivers Name</label>
                                                    <h6>Rutvik</h6>
                                                </div>
                                                <div class="col-12">
                                                    <a :href="callRider"
                                                       style="display: block; text-align: center; color: white; font-weight: bold; background-color: olivedrab; border-radius: 10px; width: 100%; padding: 10px;"
                                                       target="_blank"
                                                       v-if="ride.status<=3">CALL RECEIVER
                                                    </a>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <br>
                                </li>

                                <li class="timeline-block" v-if="(ride.status<=2 && socketConnected)">
                                    <div class="card"
                                         style="background-image: linear-gradient(310deg, #17ad37ee 0%, #98ec2dee 100%);">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-12">
                                                    <p style="margin: 0; color: white; font-weight: bold; text-transform: uppercase;"
                                                       class="text-center blink-text">
                                                        Sharing your live location with Customer</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br>
                                </li>

                                <li class="timeline-block" v-if="ride.status<=2">
                                    <div class="card">
                                        <div class="card-body">
                                            <h4>Enter Ride PIN</h4>
                                            <soft-input type="number" v-model="ride.enteredPin"></soft-input>
                                            <p>
                                                <i>*Ask your customer to share the ride pin with you</i></p>
                                            <soft-button @click="startRide" full-width color="success"
                                                         variant="gradient">
                                                Start Rider
                                            </soft-button>
                                        </div>
                                    </div>
                                    <br>
                                </li>


                            </template>


                            <li class="timeline-block" v-if="ride.status==3">
                                <div class="card">
                                    <div class="card-body">
                                        <h6 class="text-muted">Ride started</h6>
                                        <br>
                                        <soft-button @click="endRide" full-width color="danger" variant="gradient">
                                            End Ride
                                        </soft-button>
                                    </div>
                                </div>
                                <br>
                            </li>


                            <li class="timeline-block" v-if="ride.status>=4">
                                <div class="card">
                                    <div class="card-body">
                                        <h6 class="text-muted">Ride ended</h6>
                                    </div>
                                </div>
                                <br>
                            </li>


                        </ul>

                    </div>

                </div>
            </template>

        </div>

        <template v-if="serviceType==='parcel'">
            <Modal
                ref="modal"
                v-show="showParcelDetails"
                @close="closeModal">
                <template v-slot:body>
                    <AddParcelDetailsModal :modal="$refs.modal" parcel_id="read-only"></AddParcelDetailsModal>
                </template>
            </Modal>
        </template>

    </div>
</template>

<script>

import SoftButton from "../../components/SoftButton";
import SoftInput from "../../components/SoftInput";

import {io} from "socket.io-client";

import {GoogleMap, InfoWindow, Marker} from 'vue3-google-map'
import StarRating from "vue-star-rating";
import Modal from "../../components/Modal";
import AddParcelDetailsModal from "../../components/modals/AddParcelDetailsModal";

export default {

    components: {
        AddParcelDetailsModal,
        Modal,
        StarRating,
        SoftInput,
        SoftButton,
        GoogleMap,
        Marker,
        InfoWindow,
    },
    data() {
        return {
            rideInfo: 'Hi, I am riding using TransAll. And you can track my rides live location via this link https://admin.transall.in/share/now/status/ride/',

            ride_sharing_key: '',

            locationTimestamp: '',

            givenRiderRating: null,
            rating: 0,
            isRatingSubmitted: false,


            mapStyle: [
                {
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "administrative",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "administrative.land_parcel",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "administrative.neighborhood",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "transit",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                }
            ],

            socket: null,
            socketConnected: false,

            ridersLocationPoint: {lat: 23.0208366, lng: 72.5086508},

            currentLocation: '',


            activityType: null,
            serviceType: null,
            mobileNumber: null,
            customerRequestId: null,

            showParcelDetails: false,


            ride: {
                enteredPin: '',
                from_lat: '',
                from_lng: '',
                to_lat: '',
                to_lng: '',
                requestTime: '',
                riderAcceptedTime: '',
                status: 1,
                from: '',
                to: '',
                noOfPassengers: '',
                riderName: '',
                vehicle: '',
                rate: '',
                vehicleNumber: '',
                riderContactNo: '',
                riderPhotoUrl: '',
                ridePin: '',
                bidId: null,
            },

            customer: {
                name: '',
                email: '',
                contactNo: ''
            }


        }
    },
    methods: {
        closeModal() {
            this.showParcelDetails = false;
        },
        deleteRideRequest() {
            window.axios.delete('/ride/customer/delete/request/' + this.customerRequestId)
                .then(resp => {
                    if (resp.data.response.status.error_code === 0) {
                        window.ReactNativeWebView.postMessage("close-screen")
                    }
                })
        },
        goBack() {
            window.ReactNativeWebView.postMessage("close-screen")
        },
        showParcelDetailsModal() {
            this.$store.dispatch('setParcelDetails', this.ride.parcelDetails)
            this.showParcelDetails = true
        },
        showOnMap(fromLat, fromLng, toLat, toLng) {
            window.open(`https://maps.google.com/maps?saddr=${fromLat},${fromLng}&daddr=${toLat},${toLng}`, '_blank')
        },
        navigate(toLat, toLng) {
            window.open(`https://maps.google.com/maps?daddr=${toLat},${toLng}`, '_blank')
        },
        endRide() {
            const vm = this
            window.axios.post('ride/end/customer/request/' + vm.mobileNumber + '/' + vm.customerRequestId)
                .then(resp => {
                    if (resp.data.response.status.error_code === 0) {
                        vm.getActivityDetails()
                    }
                })
                .catch(error => {
                    console.log(error)
                })
        },
        startRide() {
            const vm = this
            if (vm.ride.enteredPin == vm.ride.ridePin) {
                window.axios.post('ride/start/customer/request/' + vm.mobileNumber + '/' + vm.customerRequestId)
                    .then(resp => {
                        if (resp.data.response.status.error_code === 0) {
                            vm.getActivityDetails()
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
            }
        },
        getActivityDetails() {
            const vm = this
            window.axios.get('ride/customer/request/details/' + vm.customerRequestId)
                .then(resp => {
                    if (resp.data.response.status.error_code === 0) {
                        const ride = resp.data.response.data.ride

                        if (vm.serviceType === 'parcel') {
                            vm.ride.parcelDetails = JSON.parse(ride.parcel_details)
                        }

                        //CUSTOMER
                        if (vm.activityType === 'customer_request') {

                            vm.givenRiderRating = ride.rider_rating
                            if (vm.givenRiderRating != null) {
                                vm.rating = vm.givenRiderRating
                            }
                        }

                        //RIDER
                        if (vm.activityType === 'allocated_customer_request') {
                            console.log("set customers rating.")
                        }


                        vm.customer.name = ride.customer_info.first_name + " " + ride.customer_info.last_name
                        vm.customer.email = ride.customer_info.email
                        vm.customer.contactNo = ride.customer_info.mobile_number

                        vm.ride.from = ride.from
                        vm.ride.from_lat = ride.from_lat
                        vm.ride.from_lng = ride.from_lng

                        vm.ride.to = ride.to
                        vm.ride.to_lat = ride.to_lat
                        vm.ride.to_lng = ride.to_lng


                        vm.ride.noOfPassengers = ride.no_of_passengers
                        vm.ride.requestTime = ride.created_at
                        vm.ride.status = ride.status
                        if (ride.status >= 1) {
                            vm.ride.bidId = ride.bid_info[0].id
                            vm.ride.riderAcceptedTime = ride.bid_info[0].created_at
                            vm.ride.riderName = ride.bid_info[0].by_user.first_name + " " + ride.bid_info[0].by_user.last_name
                            vm.ride.rate = JSON.parse(ride.bid_info[0].data).bid_rate
                            vm.ride.riderContactNo = ride.bid_info[0].by_user.mobile_number
                            vm.ride.vehicle = ride.bid_info[0].by_user.vehicle_model
                        }
                        if (ride.status >= 2) {
                            vm.ride.riderPhotoUrl = ride.rider_info.personal_picture_image_url
                            vm.ride.vehicleNumber = ride.rider_info.vehicle_number
                            vm.ride.vehicle = ride.rider_info.vehicle_model
                            vm.ride.ridePin = ride.pin


                            if (vm.activityType === 'customer_request') {
                                vm.receiveLocationUpdates()
                                if (ride.ride_sharing_key) {
                                    vm.ride_sharing_key = ride.ride_sharing_key
                                }
                            }

                            if (vm.activityType === 'allocated_customer_request') {

                                vm.sendLocationUpdates()

                                setInterval(() => {
                                    vm.getCurrentLocation()
                                }, 2000)
                            }


                        }
                    }
                })
                .catch(error => {
                    console.log(error)
                })
        },
        getCurrentLocation() {
            const vm = this
            navigator.geolocation.getCurrentPosition(
                position => {
                    console.log(position.coords.latitude)
                    console.log(position.coords.longitude)

                    vm.currentLocation = position.coords.latitude + ',' + position.coords.longitude

                },
                error => {
                    console.log(error.message);
                },
            )
        },
        informRiderForRideAccepted() {
            const vm = this
            window.axios.post('/ride/inform/rider/for/acceptance/' + vm.customerRequestId)
                .then(response => {
                    console.log(response)
                })
                .catch(error => {
                    console.log(error)
                })

        },
        acceptBidRequest() {
            const vm = this
            vm.informRiderForRideAccepted()
            vm.$router.push({
                name: "payment-details", params: {
                    name: vm.customer.name,
                    email: vm.customer.email,
                    amount: vm.ride.rate,
                    mobileNumber: vm.mobileNumber,
                    requestType: vm.serviceType,
                    customerRequestId: vm.customerRequestId,
                    bidId: vm.ride.bidId,
                }
            })
        },
        rejectBidRequest() {
            const vm = this
            if (vm.ride.bidId == null) {
                return
            }
            window.axios.post('ride/reject/rider/bid/request/' + vm.mobileNumber + "/" + vm.ride.bidId)
                .then(resp => {
                    if (resp.data.response.status.error_code === 0) {
                        vm.getActivityDetails()
                    }
                })
                .catch(error => {
                    console.log(error)
                })
        },
        //for rider
        sendLocationUpdates() {
            const vm = this

            const broadcastId = vm.mobileNumber + '-' + vm.customerRequestId

            console.log('sending location')

            vm.socket = io('https://socket.transall.in', {
                query: "broadcast_id=" + broadcastId,
                transports: ['websocket']
            });

            vm.socket.on("connect_error", (err) => {
                vm.socketErrorMsg = `connect_error due to ${err.message}`
                console.log(`connect_error due to ${err.message}`);
            });

            // Listen for connection events
            vm.socket.on('connect', () => {

                console.log('socket connected')

                vm.socketConnected = true

            });

            vm.socket.on('disconnect', () => {
                console.log('socket disconnected')
                vm.socketConnected = false

            });

            setInterval(() => {

                if (vm.currentLocation != '') {
                    console.log('sending location... Socket Broadcast Id:' + vm.customer.contactNo + '-' + vm.customerRequestId)
                    vm.socket.emit('send-location-updates', {
                        targetUserId: vm.customer.contactNo + '-' + vm.customerRequestId,
                        message: vm.currentLocation,
                        timestamp: Date.now()
                    });
                }

            }, 4000)
        },


        //for customer
        receiveLocationUpdates() {
            const vm = this

            const broadcastId = vm.mobileNumber + '-' + vm.customerRequestId

            console.log('receiving location')

            vm.socket = io('https://socket.transall.in', {
                query: "broadcast_id=" + broadcastId,
                transports: ['websocket']
            });

            vm.socket.on("connect_error", (err) => {
                vm.socketErrorMsg = `connect_error due to ${err.message}`
                console.log(`connect_error due to ${err.message}`);
            });

            // Listen for connection events
            vm.socket.on('connect', () => {

                console.log('customer socket connected')

                vm.socketConnected = true

                vm.socket.emit('message', {
                    rideId: vm.customerRequestId
                });

                vm.socket.on('receive-location-updates', (data) => {
                    console.log(data)

                    //vm.locationTimestamp = window.moment(data.timestamp).format("dddd, MMMM Do YYYY, h:mm:ss a");
                    vm.locationTimestamp = window.moment(data.timestamp).format("hh:mm a");

                    const latLng = data.message.split(',')

                    vm.ridersLocationPoint = {lat: parseFloat(latLng[0]), lng: parseFloat(latLng[1])}

                })

            });

            vm.socket.on('disconnect', () => {

                console.log('socket disconnected')

                vm.socketConnected = false

            });

        },

        submitRating(entity) {
            const vm = this

            if (vm.rating === 0) {
                return
            }

            window.axios.put(`/ride/now/rate/${entity}/${vm.customerRequestId}`, {
                rating: vm.rating
            })
                .then(resp => {
                    if (resp.data.response.status.error_code === 0) {
                        vm.isRatingSubmitted = true
                        vm.$toast.open({
                            message: resp.data.response.status.msg,
                            type: 'success',
                            position: 'top',
                            duration: 5000,
                            dismissible: false,
                        })
                    }
                }).catch(e => console.log(e))
        },

    },
    computed: {
        getTitle() {
            if (this.serviceType === 'ride') {
                return 'Ride Details'
            } else if (this.serviceType === 'parcel') {
                return 'Parcel Details'
            } else if (this.serviceType === 'ride-now') {
                return 'Ride Now'
            }
            return 'Activity Details'
        },
        callRider() {
            return "tel:" + this.ride.riderContactNo
        },
        callParcelReceiver() {
            return "tel:" + this.ride.riderContactNo
        },
        getRideSharingDetails() {
            return 'https://wa.me/?text=' + this.rideInfo + this.customer.contactNo + '-' + this.customerRequestId + '/' + this.ride_sharing_key
        }
    },
    created() {
        const vm = this
        vm.mobileNumber = vm.$route.params.mobileNumber
        vm.activityType = vm.$route.params.activityType
        vm.serviceType = vm.$route.params.serviceType
        vm.customerRequestId = vm.$route.params.id

        vm.getActivityDetails()

    },
    mounted() {

    }

}

</script>

<style scoped>
.blink_me {
    animation: blinker 2s linear infinite;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

label {
    margin: 0;
    padding: 0;
}

</style>
