<template>
    <div style="overflow-x: clip;">
        <div class="row sticky-top bg-gray-100 shadow" style="padding-top: 10px;">
            <div class="col-sm-12">
                <h3>
                    <button style="border: none; background: none;" @click="goBack"><i
                        class="p-2 fas fa-arrow-left"></i></button>
                    {{ getTitle }}
                </h3>
            </div>
            <hr>


            <div class="col-12">
                <div class="row">
                    <div class="col-6" style="padding: 0">
                        <soft-button @click="currentViewFrom" full-width
                                     style="padding-top: 5px; padding-bottom: 5px; border-radius: 0;"
                                     :color="fromBtnClr" size="lg">
                            From <br> {{ $props.fromPinCode }}
                        </soft-button>
                    </div>
                    <div class="col-6" style="padding: 0">
                        <soft-button @click="currentViewTo" full-width
                                     style="padding-top: 5px; padding-bottom: 5px; border-radius: 0;"
                                     :color="toBtnClr" size="lg">
                            To <br> {{ $props.toPinCode }}
                        </soft-button>
                    </div>
                </div>
            </div>

        </div>


        <div class="row" v-if="currentView==='from'">
            <div v-if="callingFromApi" class="col-12">
                <h5 style="margin-top: 40%; text-align: center">Please wait loading...</h5>
            </div>
            <div class="col-12">
                <ul v-if="results.length>0">
                    <template v-for="result in results" v-bind:key="result.name">
                        <li @click="openLocation(result.position)">
                            <h6>{{ result.label }}</h6>
                            <p>{{ result.title }}</p>
                        </li>
                        <hr>
                    </template>
                </ul>
            </div>
        </div>

        <div class="row" v-if="currentView==='to'">
            <div v-if="callingToApi" class="col-12">
                <h5 style="margin-top: 40%; text-align: center">Please wait loading...</h5>
            </div>
            <div class="col-12">
                <ul v-if="results2.length>0">
                    <template v-for="result in results2" v-bind:key="result.name">
                        <li @click="openLocation(result.position)">
                            <h6>{{ result.label }}</h6>
                            <p>{{ result.title }}</p>
                        </li>
                        <hr>
                    </template>
                </ul>
            </div>
        </div>

        <Vue3Lottie v-if="callingFromApi || callingToApi" :animationData="animationJson"></Vue3Lottie>


    </div>
</template>

<script>

import SoftButton from "../../components/SoftButton";
import {Vue3Lottie} from "vue3-lottie"
import animationJson from "../../assets/anim/transport-anim-2.json"

export default {
    components: {
        SoftButton,
        Vue3Lottie
    },

    props: {
        fromPinCode: {
            type: String,
            required: true
        },
        toPinCode: {
            type: String,
            required: true
        },
        serviceType: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            animationJson,
            callingFromApi: true,
            callingToApi: true,

            nextPageToken: null,
            radius: 1500, //meters,

            results: [],
            results2: [],

            fromBtnClr: 'success',
            toBtnClr: 'normal',

            currentView: 'from',

        }
    },

    created() {
        let vm = this

        if (vm.$props.fromPinCode == null || vm.$props.fromPinCode == '') {
            vm.$router.go(-1)
        }

        vm.getDataFromPin()
        vm.getDataToPin()

    },
    methods: {
        openLocation(position) {
            window.open(`https://maps.google.com/maps?daddr=${position.lat},${position.lng}`, '_blank')
        },
        currentViewFrom() {
            this.currentView = 'from'
            this.fromBtnClr = 'success'
            this.toBtnClr = 'normal'
        },
        currentViewTo() {
            this.currentView = 'to'
            this.fromBtnClr = 'normal'
            this.toBtnClr = 'success'
        },
        goBack() {
            this.$router.go(-1)
        },
        getDataFromPin() {

            const vm = this

            let nextPage = ''
            if (vm.nextPageToken != null) {
                nextPage = '&next_page_token=' + vm.nextPageToken
            } else {
                nextPage = ''
            }

            let keyword = ''
            if (vm.$props.serviceType === 'Transportation') {
                keyword = 'logistics+service'
            } else if (vm.$props.serviceType === 'Courier') {
                keyword = 'courier+service'
            } else {
                alert('Invalid service passed')
                this.goBack()
                return
            }

            window.axios.get('google/nearby/transport/from/pin?from_pincode=' + vm.$props.fromPinCode + '&keyword=' + keyword + '&radius=' + vm.radius + nextPage
            ).then(resp => {
                if (resp.data.response.status.error_code === 0) {
                    vm.callingFromApi = false
                    vm.nextPageToken = resp.data.response.data.next_page_token
                    const results = resp.data.response.data.results
                    console.log(results)
                    for (let i = 0; i < results.length; i++) {
                        const latLng = results[i].location.split(',')
                        vm.results.push({
                            position: {lat: parseFloat(latLng[0]), lng: parseFloat(latLng[1])},
                            title: results[i].address,
                            label: results[i].name,
                        })
                    }
                    if (vm.nextPageToken != null) {
                        vm.getDataFromPin()
                    }
                }
            })

        },
        getDataToPin() {

            const vm = this

            let nextPage = ''
            if (vm.nextPageToken != null) {
                nextPage = '&next_page_token=' + vm.nextPageToken
            } else {
                nextPage = ''
            }

            let keyword = ''
            if (vm.$props.serviceType === 'Transportation') {
                keyword = 'logistics+service'
            } else if (vm.$props.serviceType === 'Courier') {
                keyword = 'courier+service'
            } else {
                alert('Invalid service passed')
                this.goBack()
                return
            }

            window.axios.get('google/nearby/transport/to/pin?to_pincode=' + vm.$props.toPinCode + '&keyword=' + keyword +
                '&radius=' + vm.radius + nextPage
            ).then(resp => {
                if (resp.data.response.status.error_code === 0) {
                    vm.callingToApi = false
                    vm.nextPageToken = resp.data.response.data.next_page_token
                    const results = resp.data.response.data.results
                    console.log(results)
                    for (let i = 0; i < results.length; i++) {
                        const latLng = results[i].location.split(',')
                        vm.results2.push({
                            position: {lat: parseFloat(latLng[0]), lng: parseFloat(latLng[1])},
                            title: results[i].address,
                            label: results[i].name,
                        })
                    }
                    if (vm.nextPageToken != null) {
                        vm.getDataToPin()
                    }
                }
            })

        }
    },
    computed: {
        getTitle() {
            if (this.$props.serviceType === 'Transportation') {
                return 'Transport Services'
            } else {
                return 'Courier Services'
            }
        },
        getPlaceholderText() {
            if (this.isFetchingLocation) {
                return 'Please wait, fetching location...'
            } else if (this.autocompleteText != '') {
                return this.autocompleteText
            } else {
                return 'Type location name here...'
            }
        },
    }
}
</script>

<style scoped>

ul {
    list-style: none;
    padding: 0;
}

li {
    padding: 10px;
}

li:hover {
    background-color: #e1e1e1;
}

p {
    font-size: 12px;
    margin: 0;
}

hr {
    margin: 0;
}

</style>
