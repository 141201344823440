<template>
    <div class="form-check">
        <input
                :id="id"
                class="form-check-input"
                type="radio"
                :name="name"
                :checked="checked"
                :value="value"
                @change="$emit('update:modelValue',$event.target.value)"
        />
        <label class="custom-control-label" :for="id">
            <slot/>
        </label>
    </div>
</template>

<script>
export default {
    name: "SoftRadio",
    props: {
        name: {
            type: String,
            required: true,
        },
        id: {
            type: String,
            required: true,
        },
        value: {
            type: String,
            required: true,
        },
        checked: {
            type: Boolean,
            default: false,
        },
        ['modelValue']:{},
    },
    emits:[
        'update:modelValue'
    ],
};
</script>
