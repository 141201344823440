<template>
    <div class="sticky-top inner-header sticky-header bg-gray-100 shadow py-2">
        <div class="col-sm-12">
            <h3>
                <router-link :to="{name: 'home'}">
                    <button style="border: none; background: none;">
                        <i class="p-2 fas fa-arrow-left"></i>
                    </button>
                </router-link>
                Notifications
            </h3>
        </div>
    </div>
    <div class="container-fluid p-3 have-sticky-header">
        <div class="text-center pt-5" v-if="isLoading">
            <span class="spinner-border" role="status" aria-hidden="true"></span>
        </div>
        <div class=" p-0" v-if="!isLoading">
            <p v-if="errorMessage">{{ errorMessage }}</p>
            <div class="notification-card card mb-2" v-for="notification of notifications" v-bind:key="notification.id">
                <div class="card-body p-3" @click="redirectUser(notification.data.url)">
                    <h5 class="card-title ">{{ notification.title }}</h5>
                    <p class="p-0 mb-1">{{ notification.body }}</p>
                    <p class="text-end p-0 m-0"> {{ notification.time_elapsed }} </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import apiService from "../services/api.service";
export default {
    name: "Notifications",
    data() {
        return {
            mobileNumber: this.getUserMobileNumber(),
            notifications: [],
            isLoading: true,
            errorMessage: ''
        }
    },
    components: {
    },
    methods: {
        ...mapGetters(['getUserMobileNumber']),
        async getNotification() {
            this.isLoading = true;
            apiService.getNotifications(this.mobileNumber)
            .then((apiResponse) => {
                this.isLoading = false;
                if (apiResponse && !apiResponse.response.status.error_code) {
                    this.notifications = apiResponse.response.data.notifications;
                    this.errorMessage = this.notifications.length ? '' : 'No notification founds';
                } else {
                    this.errorMessage = apiResponse && apiResponse.response.status.error_code ? apiResponse.response.status.error_msg : 'No notification founds';
                }
            })
            .catch(() => {
                this.errorMessage = 'No notification founds'
            });
        },

        redirectUser (url) {
            url = new URL(url).pathname;
            this.$router.push(url);
        }
    },
    created() {
        this.getNotification();
    }
};
</script>

<style scoped lang="scss">

.notification-card {
    box-shadow: 1px 4px 16px 0 rgba(0, 0, 0, 0.05);
    margin-bottom: 1rem;
    h5{
        font-size: 18px;
        color: var(--bs-gray-900);
    }
    p{
        font-size: 15px;
        line-height: 1.4;
        color: var(--bs-gray-900);
    }
}
</style>