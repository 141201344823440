import bootstrap from "bootstrap/dist/js/bootstrap.min.js";
import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

import authService from "../services/auth.service";

export default createStore({
    state: {

        showReferAndEarnDialog: true,

        jwtToken: '',
        hideConfigButton: false,
        isPinned: true,
        showConfig: false,
        isTransparent: "",
        isRTL: false,
        color: "",
        isNavFixed: false,
        isAbsolute: false,
        showNavs: true,
        showSidenav: true,
        showNavbar: true,
        showFooter: true,
        showMain: true,
        navbarFixed:
            "position-sticky blur shadow-blur left-auto top-1 z-index-sticky px-0 mx-4",
        absolute: "position-absolute px-4 mx-0 w-100 z-index-2",
        bootstrap,
        pickedFromLocation: {
            address: '',
            latLng: '',
            city: '',
            locality: '',
            pinCode: '',
        },
        pickedToLocation: {
            address: '',
            latLng: '',
            city: '',
            locality: '',
            pinCode: '',
        },
        pickedSearchFromLocation: {
            address: '',
            latLng: '',
            city: '',
            locality: '',
            pinCode: '',
        },
        pickedSearchToLocation: {
            address: '',
            latLng: '',
            city: '',
            locality: '',
            pinCode: '',
        },
        selectedDropLocation: {
            address: '',
            latLng: '',
            city: '',
            locality: '',
            pinCode: '',
        },
        selectedPickupLocation: {
            address: '',
            latLng: '',
            city: '',
            locality: '',
            pinCode: '',
        },
        parcel: {

            receiversMobileNumber:'',
            receiversName:'',
            itemName:'',

            type: '',

            itemDetails: {

                name: '',

                plantAndMachinery: 0,
                furnitureAndHousehold: 0,
                electricItem: 0,
                chemicalOrLiquid: 0,
                rawMaterial: 0,
                electricalItem: 0,
                gasItem: 0,


                specialCareRequired: '',
                fragileItem: '',
                hazardousItem: '',
                inflamableItem: '',
                packageTypes: [],
                price: '',

                box: {
                    length: 0,
                    breath: 0,
                    height: 0,
                    weight: 0,
                    totalCount: 0,
                },

                pallet: {
                    length: 0,
                    breath: 0,
                    height: 0,
                    weight: 0,
                    totalCount: 0,
                },

                item: {
                    length: 0,
                    breath: 0,
                    height: 0,
                    weight: 0,
                    totalCount: 0,
                },

                other: {
                    length: 0,
                    breath: 0,
                    height: 0,
                    weight: 0,
                    totalCount: 0,
                }
            }
        },
        user: {
            mobileNumber: '0',
            fId: ''
        }

    },
    mutations: {
        toggleConfigurator(state) {
            state.showConfig = !state.showConfig;
        },
        navbarMinimize(state) {
            const sidenav_show = document.querySelector(".g-sidenav-show");
            if (sidenav_show.classList.contains("g-sidenav-hidden")) {
                sidenav_show.classList.remove("g-sidenav-hidden");
                sidenav_show.classList.add("g-sidenav-pinned");
                state.isPinned = true;
            } else {
                sidenav_show.classList.add("g-sidenav-hidden");
                sidenav_show.classList.remove("g-sidenav-pinned");
                state.isPinned = false;
            }
        },
        sidebarType(state, payload) {
            state.isTransparent = payload;
        },
        cardBackground(state, payload) {
            state.color = payload;
        },
        setNewJwtToken(state, payload) {
            state.jwtToken = payload;
        },
        setFromLocation(state, payload) {
            state.pickedFromLocation = payload;
        },
        setToLocation(state, payload) {
            state.pickedToLocation = payload;
        },
        setSearchFromLocation(state, payload) {
            state.pickedSearchFromLocation = payload;
        },
        setSearchToLocation(state, payload) {
            state.pickedSearchToLocation = payload;
        },
        setSelectedDropLocation(state, payload) {
            state.selectedDropLocation = payload;
        },
        setSelectedPickupLocation(state, payload) {
            state.selectedPickupLocation = payload;
        },
        setParcelDetails(state, payload) {
            state.parcel = payload;
        },
        navbarFixed(state) {
            if (state.isNavFixed === false) {
                state.isNavFixed = true;
            } else {
                state.isNavFixed = false;
            }
        },
        toggleEveryDisplay(state) {
            state.showNavbar = !state.showNavbar;
            state.showSidenav = !state.showSidenav;
            state.showFooter = !state.showFooter;
        },
        showAll(state) {
            state.showNavbar = true;
            state.showSidenav = true;
            state.showFooter = true;
        },
        toggleHideConfig(state) {
            state.hideConfigButton = !state.hideConfigButton;
        },
        hideReferAndEarnDialog(state) {
            state.showReferAndEarnDialog = false
        },
        keepReferAndEarnDialog(state) {
            state.showReferAndEarnDialog = true
        },
        setUserMobileNumber(state, payload) {
            state.user.mobileNumber = payload;
        },
        showNavbar(state, payload) {
            state.showNavbar = payload;
        },
        setUserFirebaseId(state, payload) {
            state.user.fId = payload;
        },
        setUserDetails(state, payload) {
            state.user = {
                ...state.user,
                ...payload
            }
        },
        fetchUserDetails() {

        }
    },
    actions: {
        dontShowReferAndEranDialog({commit}) {
            commit("hideReferAndEarnDialog");
        },
        showReferAndEranDialog({commit}) {
            commit("keepReferAndEarnDialog");
        },
        toggleSidebarColor({commit}, payload) {
            commit("sidebarType", payload);
        },
        setCardBackground({commit}, payload) {
            commit("cardBackground", payload);
        },
        setJwtToken({commit}, payload) {
            commit("setNewJwtToken", payload);
        },
        Logout({commit}) {
            return authService.logout()
            .then(() => {
                commit("setNewJwtToken", '');
            });
        },
        setFromLocation({commit}, payload) {
            commit("setFromLocation", payload);
        },
        setToLocation({commit}, payload) {
            commit("setToLocation", payload);
        },
        setSearchFromLocation({commit}, payload) {
            commit("setSearchFromLocation", payload);
        },
        setSearchToLocation({commit}, payload) {
            commit("setSearchToLocation", payload);
        },
        setSelectedDropLocation({commit}, payload) {
            commit("setSelectedDropLocation", payload);
        },
        setSelectedPickupLocation({commit}, payload) {
            commit("setSelectedPickupLocation", payload);
        },
        clearAllLocations({commit}) {
            commit("setFromLocation", {
                address: '',
                latLng: '',
                city: '',
                locality: '',
                pinCode: '',
            });
            commit("setToLocation", {
                address: '',
                latLng: '',
                city: '',
                locality: '',
                pinCode: '',
            });
            commit("setSearchFromLocation", {
                address: '',
                latLng: '',
                city: '',
                locality: '',
                pinCode: '',
            });
            commit("setSearchToLocation", {
                address: '',
                latLng: '',
                city: '',
                locality: '',
                pinCode: '',
            });
            commit("setSelectedDropLocation", {
                address: '',
                latLng: '',
                city: '',
                locality: '',
                pinCode: '',
            });
            commit("setSelectedPickupLocation", {
                address: '',
                latLng: '',
                city: '',
                locality: '',
                pinCode: '',
            });
        },
        setParcelDetails({commit}, payload) {
            commit("setParcelDetails", payload);
        },
        clearParcelDetails({commit}) {
            commit("setParcelDetails", {
                type: '',

                itemDetails: {
                    name: '',
                    specialCareRequired: 'no',
                    fragileItem: 'no',
                    hazardousItem: 'no',
                    inflamableItem: 'no',
                    packageTypes: [],
                    price: '',

                    box: {
                        length: 0,
                        breath: 0,
                        height: 0,
                        weight: 0,
                        totalCount: 0,
                    },

                    pallet: {
                        length: 0,
                        breath: 0,
                        height: 0,
                        weight: 0,
                        totalCount: 0,
                    },

                    item: {
                        length: 0,
                        breath: 0,
                        height: 0,
                        weight: 0,
                        totalCount: 0,
                    },

                    other: {
                        length: 0,
                        breath: 0,
                        height: 0,
                        weight: 0,
                        totalCount: 0,
                    }
                }
            });
        },
        setUserMobileNumber({commit}, payload) {
            commit("setUserMobileNumber", payload);
        },
        showNavbar({commit}, payload) {
            commit("showNavbar", payload);
        },
        setUserFirebaseId({commit}, payload) {
            commit("setUserFirebaseId", payload);
        },
        setUserDetails({commit}, payload) {
            commit("setUserDetails", payload);
        },
        fetchAndSetUserDetails({commit}) {
            return authService.getUserDetails()
            .then((apiResponse) => {
                if (apiResponse && !apiResponse.response.status.error_code) {
                    commit("setUserDetails", apiResponse.response.data.user_details);
                }
                return apiResponse;
            });
        }
    },
    getters: {
        isAuthenticated: state => state.jwtToken !== '',
        getJwtToken: state => state.jwtToken,
        getFromLocation: state => state.pickedFromLocation,
        getToLocation: state => state.pickedToLocation,
        getSearchFromLocation: state => state.pickedSearchFromLocation,
        getSearchToLocation: state => state.pickedSearchToLocation,
        getSelectedDropLocation: state => state.selectedDropLocation,
        getSelectedPickupLocation: state => state.selectedPickupLocation,
        showNavbar: state => state.showNavbar,
        showSidenav: state => state.showSidenav,
        getParcelDetails: state => state.parcel,
        showReferAndEarnDialog: state => state.showReferAndEarnDialog,
        getUserMobileNumber: state => state.user.mobileNumber,
        getUser: state => state.user
    },
    plugins: [createPersistedState()],
});
