<template>
  <div
    class="position-fixed w-100 bottom-0"
    v-bind="$attrs"
  >
    <ul class="nav nav-pills nav-fill p-1" role="tablist">
      <li class="nav-item text-2xl" v-for="nav in navs" v-bind:key="nav.icon">
        <router-link
          class="nav-link mb-0 px-0 py-1"
          :to="{name: nav.routeName, params: nav.routeParams}"
          activeClass="active"
          exact-active-class="exact-active"
          v-bind="$attrs"
          :title="nav.title"
        >
           <i class="fa" :class="nav.icon"></i>
        </router-link>
      </li>
    </ul>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "navbar",
  data() {
    return {
      navs: [{
        title: 'Home',
        icon: 'fa-home',
        routeName: 'home'
      },{
        title: 'Services',
        icon: 'fa-bus',
        routeName: 'services',
        routeParams: {
          mobileNumber: this.getUserMobileNumber()
        }
      },{
        title: 'Recents',
        icon: 'fa-history',
        routeName: 'recent-activities',
        routeParams: {
          mobileNumber: this.getUserMobileNumber()
        }
      },{
        title: 'Profile',
        icon: 'fa-user',
        routeName: 'user-manage-account',
        routeParams: {
          mobileNumber: this.getUserMobileNumber()
        }
      }]
    };
  },
  props: ["minNav", "textWhite"],
  created() {
    this.minNav;
  },
  methods: {
    ...mapGetters(['getUserMobileNumber']),
  },
  components: {
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  }
};
</script>
<style lang="scss" scoped>
.nav-pills {
  background-color: #fff !important;
}
.nav-link {
  color: grey !important;
}
.active {
  color: #2f194d !important
}
</style>