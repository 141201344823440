<template>


    <div style="overflow-x: clip;">
        <div class="row sticky-top bg-gray-100 shadow" style="padding-top: 10px;">
            <div class="col-sm-12">
                <h3>
                    <button style="border: none; background: none;" @click="goBack"><i
                        class="p-2 fas fa-arrow-left"></i></button>
                    Wallet
                </h3>
            </div>
        </div>
        <div class="container-fluid">
            <br>
            <div class="row">

                <div class="col-12" style="text-align: right">
                    <soft-button @click="refer" variant="gradient" color="primary">Refer & Earn</soft-button>
                </div>

                <div class="col-12">
                    <br>
                    <div class="card gradient-box">
                        <div class="card-body">
                            <span class="text-bold">Available Balance</span>
                            <br>
                            <span class="text-5xl">₹{{ (walletBalance).toLocaleString('en-IN') }}</span>
                            <br>
                            <br>
                            <div class="row">
                                <div class="col-12">
                                    <soft-button color="dark" class="float-end" @click="requestWithdrawal" size="sm">
                                        Withdraw
                                    </soft-button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p><br>You have been rewarded <b>₹100/-</b> as an initial wallet balance which will be valid till 3
                        months.</p>
                </div>
                <!--                <div class="col-12">-->
                <!--                    <br>-->
                <!--                    <soft-button color="success" variant="gradient" full-width>-->
                <!--                        Load Wallet Balance-->
                <!--                    </soft-button>-->
                <!--                </div>-->
                <div class="col-12">
                    <br>
                    <p class="text-center">
                        <a href="#" @click="$router.push({name:'user-transaction-history'})" style="color: #0d6efd;"><u>Transaction
                            History</u></a>
                    </p>
                </div>
                <div class="col-12">
                    <p class="text-center">
                        <a href="#" @click="isAddingBankDetails=true" style="color: #0d6efd;"><u>Add Bank
                            Details</u></a>
                    </p>
                </div>
            </div>

        </div>

        <Modal
            ref="modal"
            v-show="isAddingBankDetails"
            @close="closeModal">
            <template v-slot:body>
                <AddBankAccountDetails :modal="$refs.modal" :mobile-number="mobileNumber"></AddBankAccountDetails>
            </template>
        </Modal>

    </div>

    <template v-if="displayReferAndEarnDialog">
        <FusionDialog @fusion-dialog-check-box-clicked="dontShowDialogAgain"
                      @fusion-dialog-positive-button-clicked="refer" @close-refer-earn-dialog="closeDialog"
                      style="position:fixed; top:0; bottom: 0; left: 0; right: 0; overflow-y: auto;"></FusionDialog>
    </template>


</template>
<script>

import SoftButton from "../../components/SoftButton";
import swal from "sweetalert";
import Modal from "../../components/Modal"
import AddBankAccountDetails from "../../components/modals/AddBankAccountDetails";
import FusionDialog from "../../components/FusionDialog";
import {mapGetters} from "vuex";


export default {
    components: {FusionDialog, AddBankAccountDetails, SoftButton, Modal},
    data() {
        return {
            isAddingBankDetails: false,
            mobileNumber: null,
            walletBalance: 0.0,
            haveBankDetails: false,
            displayReferAndEarnDialog: this.showReferAndEarnDialog()
        }
    },
    methods: {
        ...mapGetters(['showReferAndEarnDialog']),

        dontShowDialogAgain(value) {

            if (value) {
                this.$store.dispatch('dontShowReferAndEranDialog')
            } else {
                this.$store.dispatch('showReferAndEranDialog')
            }

        },

        closeDialog() {
            this.displayReferAndEarnDialog = false
        },
        closeModal() {
            this.isAddingBankDetails = false;
        },
        goBack() {
            window.ReactNativeWebView.postMessage("close-screen")
        },
        getUserWalletBalance() {
            const vm = this
            window.axios.get('/user/wallet/balance/' + vm.mobileNumber)
                .then(resp => {
                    if (resp.data.response.status.error_code === 0) {
                        vm.walletBalance = (resp.data.response.data.wallet_balance / 100)
                    }
                })
                .catch(e => console.log(e))
        },
        requestWithdrawal() {
            const vm = this

            if (!vm.haveBankDetails) {
                swal({
                    title: "Please add your bank details first, to enable withdrawal facility.",
                    icon: "info",
                    dangerMode: false,
                })
                vm.isAddingBankDetails = true
                return
            }

            swal("Enter Amount", {
                content: "input",
                button:"WITHDRAW"
            })
                .then((value) => {
                    let val = parseFloat(value)
                    let currentBalance = parseFloat(vm.walletBalance)

                    if (isNaN(val)) {
                        return
                    }
                    if (currentBalance - val < 100) {
                        vm.$toast.open({
                            message: 'Value exceeds min balance',
                            type: 'error',
                            position: 'top',
                            duration: 2000,
                            dismissible: false,
                        })
                        return
                    }
                    if (val > currentBalance) {
                        vm.$toast.open({
                            message: 'Value exceeds current balance',
                            type: 'error',
                            position: 'top',
                            duration: 2000,
                            dismissible: false,
                        })
                        return
                    }

                    vm.$toast.open({
                        message: "Request sent",
                        type: 'success',
                        position: 'top',
                        duration: 5000,
                        dismissible: false,
                    })

                });
        },
        refer() {

            const vm = this

            vm.closeDialog()
            swal("Enter your friend's Contact Number to Refer", {
                content: "input",
                buttons: "Invite"
            })
                .then((value) => {

                    if (!value) {
                        return
                    }

                    if (value.length !== 10) {
                        vm.$toast.open({
                            message: "Number must be 10 digits",
                            type: 'error',
                            position: 'top',
                            duration: 2000,
                            dismissible: false,
                        })
                        return;
                    }

                    window.axios.post('/apply/offer/referandearn', {
                        mobile_number: vm.mobileNumber,
                        ref_contact_number: value,
                    }).then(resp => {
                        if (resp.data.response.status.error_code === 0) {
                            let appInfo = 'Hi, I have invited you on TransAll Application it is a great app for ride sharing service. Download it here Android: https://play.google.com/store/apps/details?id=in.fusionbit.transall iOS: https://apps.apple.com/us/app/transall/id6449186201'

                            swal({
                                title: `Successfully Invited`,
                                text: "Please share the App Installation Link with your friend. You will get your reference balance once this user will signup using this same number on TransAll App. Note: Reward amount is only valid till 2 months and cannot be Withdrawn into your bank account",
                                icon: "success",
                                buttons: "Share Download Link",
                                closeOnClickOutside: false,
                            }).then((share) => {
                                if (share) {
                                    let a = document.createElement('a');
                                    a.target = '_blank';
                                    a.href = 'https://api.whatsapp.com/send/?text=' + appInfo;
                                    a.click();
                                    //window.open('https://api.whatsapp.com/send/?text=' + appInfo, '_blank')
                                }
                            });
                        }else{
                            swal({
                                title: 'User already registered',
                                text: resp.data.response.status.error_msg,
                                icon: "error",
                                button:"OK"
                            })
                        }
                    })


                })
        }
    },
    mounted() {
        const vm = this
        vm.mobileNumber = vm.$route.params.mobileNumber
        vm.getUserWalletBalance()
        window.axios.get('/user/get/bank/details/' + vm.mobileNumber)
            .then(resp => {
                vm.haveBankDetails = resp.data.response.status.error_code === 0
            })
    }
}

</script>

<style scoped>

.gradient-box {
    border: solid 3px transparent;
    border-radius: 1em;
}

.gradient-box:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -5px;
    /* !importanté */
    border-radius: inherit;
    /* !importanté */
    background: linear-gradient(to right, #17ad37, #98ec2d);
}

</style>
