<template>
    <div class="container-fluid">
        <template v-if="typeOfService==='ride-now'">
            <img class="img-fluid" src="https://momentumacademy.net/wp-content/uploads/2020/05/Paymentsuccessful21.png">
        </template>
        <template v-else>
            <div class="row">
                <div class="col-12" style="margin-top: 5%">
                    <h5>Service Confirmed!</h5>
                    <p>
                        Your service is Confirmed! Please make the payment to the service provider by <b>Cash</b> or any
                        <b>UPI</b>
                        app.
                    </p>
                    <img class="img-fluid" src="https://www.pay10.com/img/upi/illustration.png">
                </div>
            </div>
        </template>
    </div>
</template>
<script>

export default {

    props: {

        mobileNumber: {
            type: String,
            required: true,
        },
        typeOfService: {
            type: String,
            required: true,
        },
        paymentId: {
            type: String,
            required: true,
        },
        orderId: {
            type: String,
            required: true,
        },
        signature: {
            type: String,
            required: true,
        },
        bookingId: {
            type: String,
        },
        customerRequestId: {
            type: String,
        },
        amount: {
            type: String,
        }
    },

    data() {
        return {
            timeOut: 5000,
        }
    },

    methods: {
        updatePaymentDetails() {
            const vm = this

            if (vm.$props.typeOfService === 'customer_ride_request') {
                window.axios.post('/payment/insert/customer/transaction/details/' + vm.$props.mobileNumber, {
                    type_of_service: vm.$props.typeOfService,
                    payment_id: vm.$props.paymentId,
                    order_id: vm.$props.orderId,
                    signature: vm.$props.signature,
                    booking_id: vm.$props.bookingId,
                    amount: vm.$props.amount,
                }).then(resp => {

                    if (resp.data.response.status.error_code === 0) {
                        vm.$toast.open({
                            message: 'Payment Completed',
                            type: 'success',
                            position: 'top',
                            duration: 5000,
                            dismissible: false,
                        })

                        window.ReactNativeWebView.postMessage("close-screen")

                    } else {
                        vm.$toast.open({
                            message: 'Something went wrong',
                            type: 'error',
                            position: 'top',
                            duration: 5000,
                            dismissible: false,
                        })
                    }

                }).catch(e => console.log(e))

            } else if (vm.$props.typeOfService === 'ride-now') {
                window.axios.post('/payment/insert/ride/now/transaction/details/' + vm.$props.mobileNumber, {
                    type_of_service: vm.$props.typeOfService,
                    payment_id: vm.$props.paymentId,
                    order_id: vm.$props.orderId,
                    signature: vm.$props.signature,
                    booking_id: vm.$props.bookingId,
                    customer_request_id: vm.$props.customerRequestId,
                    amount: vm.$props.amount,
                }).then(resp => {

                    if (resp.data.response.status.error_code === 0) {
                        vm.$toast.open({
                            message: 'Payment Completed',
                            type: 'success',
                            position: 'top',
                            duration: 5000,
                            dismissible: false,
                        })

                        window.ReactNativeWebView.postMessage("close-screen")

                    } else {
                        vm.$toast.open({
                            message: 'Something went wrong',
                            type: 'error',
                            position: 'top',
                            duration: 5000,
                            dismissible: false,
                        })
                    }

                }).catch(e => console.log(e))

            } else if (vm.$props.typeOfService === 'parcel') {
                window.axios.post('/payment/insert/parcel/transaction/details/' + vm.$props.mobileNumber, {
                    type_of_service: vm.$props.typeOfService,
                    payment_id: vm.$props.paymentId,
                    order_id: vm.$props.orderId,
                    signature: vm.$props.signature,
                    booking_id: vm.$props.bookingId,
                    amount: vm.$props.amount,
                }).then(resp => {

                    if (resp.data.response.status.error_code === 0) {
                        vm.$toast.open({
                            message: 'Payment Completed',
                            type: 'success',
                            position: 'top',
                            duration: 5000,
                            dismissible: false,
                        })

                        setTimeout(() => {
                            vm.$router.go(-2)
                        }, vm.timeOut)

                    } else {
                        vm.$toast.open({
                            message: 'Something went wrong',
                            type: 'error',
                            position: 'top',
                            duration: 5000,
                            dismissible: false,
                        })
                    }

                }).catch(e => console.log(e))
            } else if (vm.$props.typeOfService === 'ride') {
                window.axios.post('/payment/insert/transaction/details/' + vm.$props.mobileNumber, {
                    type_of_service: vm.$props.typeOfService,
                    payment_id: vm.$props.paymentId,
                    order_id: vm.$props.orderId,
                    signature: vm.$props.signature,
                    booking_id: vm.$props.bookingId,
                    amount: vm.$props.amount,
                }).then(resp => {

                    if (resp.data.response.status.error_code === 0) {
                        vm.$toast.open({
                            message: 'Payment Completed',
                            type: 'success',
                            position: 'top',
                            duration: 2000,
                            dismissible: false,
                        })

                        setTimeout(() => {
                            vm.$router.go(-2)
                        }, vm.timeOut)

                    } else {
                        vm.$toast.open({
                            message: 'Something went wrong',
                            type: 'error',
                            position: 'top',
                            duration: 5000,
                            dismissible: false,
                        })
                    }

                }).catch(e => console.log(e))
            } else if (vm.$props.typeOfService === 'tour') {
                window.axios.post('/payment/insert/tour/transaction/details/' + vm.$props.mobileNumber, {
                    type_of_service: vm.$props.typeOfService,
                    payment_id: vm.$props.paymentId,
                    order_id: vm.$props.orderId,
                    signature: vm.$props.signature,
                    booking_id: vm.$props.bookingId,
                    amount: vm.$props.amount,
                }).then(resp => {

                    if (resp.data.response.status.error_code === 0) {
                        vm.$toast.open({
                            message: 'Payment Completed',
                            type: 'success',
                            position: 'top',
                            duration: 5000,
                            dismissible: false,
                        })

                        setTimeout(() => {
                            vm.$router.go(-2)
                        }, vm.timeOut)

                    } else {
                        vm.$toast.open({
                            message: 'Something went wrong',
                            type: 'error',
                            position: 'top',
                            duration: 5000,
                            dismissible: false,
                        })
                    }

                }).catch(e => console.log(e))
            } else if (vm.$props.typeOfService === 'travels') {
                window.axios.post('/payment/insert/travels/transaction/details/' + vm.$props.mobileNumber, {
                    type_of_service: vm.$props.typeOfService,
                    payment_id: vm.$props.paymentId,
                    order_id: vm.$props.orderId,
                    signature: vm.$props.signature,
                    booking_id: vm.$props.bookingId,
                    amount: vm.$props.amount,
                }).then(resp => {

                    if (resp.data.response.status.error_code === 0) {
                        vm.$toast.open({
                            message: 'Payment Completed',
                            type: 'success',
                            position: 'top',
                            duration: 5000,
                            dismissible: false,
                        })

                        setTimeout(() => {
                            vm.$router.go(-2)
                        }, vm.timeOut)

                    } else {
                        vm.$toast.open({
                            message: 'Something went wrong',
                            type: 'error',
                            position: 'top',
                            duration: 5000,
                            dismissible: false,
                        })
                    }

                }).catch(e => console.log(e))
            } else if (vm.$props.typeOfService === 'spv') {
                window.axios.post('/payment/insert/spv/transaction/details/' + vm.$props.mobileNumber, {
                    type_of_service: 'spv',
                    payment_id: vm.$props.paymentId,
                    order_id: vm.$props.orderId,
                    signature: vm.$props.signature,
                    booking_id: vm.$props.bookingId,
                    amount: vm.$props.amount,
                }).then(resp => {

                    if (resp.data.response.status.error_code === 0) {
                        vm.$toast.open({
                            message: 'Payment Completed',
                            type: 'success',
                            position: 'top',
                            duration: 5000,
                            dismissible: false,
                        })

                        setTimeout(() => {
                            vm.$router.go(-2)
                        }, vm.timeOut)

                    } else {
                        vm.$toast.open({
                            message: 'Something went wrong',
                            type: 'error',
                            position: 'top',
                            duration: 5000,
                            dismissible: false,
                        })
                    }

                }).catch(e => console.log(e))
            }


        }
    },

    created() {

        this.updatePaymentDetails()

    }

}

</script>
