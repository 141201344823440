/**
 =========================================================
 * Vue Soft UI Dashboard - v3.0.0
 =========================================================

 * Product Page: https://creative-tim.com/product/vue-soft-ui-dashboard
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { createApp } from "vue";
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import App from "./App.vue";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import router from "./router";
import SoftUIDashboard from "./soft-ui-dashboard";
import store from "./store";

window.API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

window.axios = require('axios');
window.axios.defaults.baseURL = window.API_BASE_URL;

window.moment = require('moment-timezone');

window.axios.interceptors.response.use(undefined, async function (error) {
    if (error) {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            //await store.dispatch('SetAuthError', "Authentication missing")
            //await store.dispatch('LogOut')
            store.dispatch('Logout')
            return router.push('/sign-in')
        } else
            if (error.response.status === 400 && !originalRequest._retry) {
                originalRequest._retry = true;
                try {
                    //await store.dispatch('SetAuthError', error.response.data.response.error.error_msg)
                    //window.Bus.$emit('api_error_422', error.response.data.response.error.error_msg)
                } catch (e) {
                    if (error.response.data.error === 'Provided token is expired.') {
                        //await store.dispatch('SetAuthError', "Your login has expired, please login again")
                        //await store.dispatch('LogOut')
                        return router.push('/sign-in')
                    }
                }

            } else
                if ((error.response.status === 422) && !originalRequest._retry) {
                    if (error.response.data.response.error.error_msg == 'validation failed') {
                        let errorMsg = '';
                        let values = Object.values(error.response.data.response.data);
                        console.log(values);
                        for (let i = 0; i < values.length; i++) {
                            console.log(values[i][0]);
                            errorMsg = errorMsg + values[i][0] + ' ';
                        }
                        alert(errorMsg);
                        //window.Bus.$emit('api_error_422', errorMsg)
                    } else {
                        alert(error.response.data.response.status.error_msg);
                        //window.Bus.$emit('api_error_422', error.response.data.response.status.error_msg)
                    }
                } else
                    if (error.response.status === 500) {
                        alert("Internal Server Error.");
                        //window.Bus.$emit('api_error_422', "Internal Server Error.")
                    }
    }
});

window.axios.interceptors.request.use(req => {
    const token = store.getters.getJwtToken;
    // Important: request interceptors *must* return the request.
    if (token) {
      req.headers.Authorization = 'Bearer ' + token;
    }
    return req;
});

router.beforeEach((to, from, next) => {
    store.dispatch("showNavbar", to.meta.showNavbar !== undefined ? to.meta.showNavbar : true);
    
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isAuthenticated) {
            window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters.getJwtToken;
            next()
            return
        }
        next('/sign-in')
    } else {
        next()
    }
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.guest)) {
        if (store.getters.isAuthenticated) {
            window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters.getJwtToken;
            next("/home");
            return;
        }
        next();
    } else {
        next();
    }
});

import GlobalMixin from './mixins/GlobalMixin';
import './registerServiceWorker';

const appInstance = createApp(App);
appInstance.use(store);
appInstance.mixin(GlobalMixin)
appInstance.use(router);
appInstance.use(SoftUIDashboard);
appInstance.use(ToastPlugin);
appInstance.mount("#app");
