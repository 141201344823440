<template>

    <template v-if="showLocationPicker">
        <LocationPickerV2 :stop-place-index="stopPlaceIndex" @close-picker="closePicker"
                          @location-picked="locationPicked"></LocationPickerV2>
    </template>

    <template v-else>
        <div style="overflow-x: clip;">
            <div class="row sticky-top bg-gray-100 shadow" style="padding-top: 10px;">
                <div class="col-sm-12">
                    <h2>
                        <button style="border: none; background: none;" @click="goBack"><i
                            class="p-2 fas fa-arrow-left"></i></button>
                        Publish Travels
                    </h2>
                </div>
            </div>

            <div class="container-fluid">
                <br>

                <form @submit.prevent>
                    <div class="row">

                        <div class="col-sm-12">
                            <label>Route Name*</label>
                            <soft-input v-model="travels.title"
                                        placeholder="Enter travels route here"></soft-input>
                        </div>

                        <div class="col-sm-12">
                            <label>Select Vehicle</label>
                            <select v-model="travels.selectedVehicle" class="custom-select form-control">
                                <template v-for="vehicle in vehicles" :key="vehicle.id">
                                    <option :value="vehicle.id">
                                        {{ vehicle.model_name + " (" + vehicle.registered_number + ")" }}
                                    </option>
                                </template>
                            </select>
                            <br>
                        </div>

                        <div class="col-sm-12">
                            <label>Select Driver</label>
                            <select v-model="travels.selectedDriver" class="custom-select form-control">
                                <option selected value="0">
                                    Self
                                </option>
                                <template v-for="driver in drivers" :key="driver.id">
                                    <option :value="driver.id">
                                        {{ driver.name + " (" + driver.contact_no + ")" }}
                                    </option>
                                </template>
                            </select>
                            <br>
                        </div>

                        <div class="col-sm-12">
                            <label>From Place</label>
                            <soft-input v-model="travels.from"
                                        placeholder="Enter from place name here"></soft-input>
                        </div>

                        <template v-for="(stop,index) in travels.places.stops" :key="index">
                            <div class="col-sm-12">
                                <label>Intermediate Place</label>
                                <soft-input @click="openLocationPicker(index)" placeholder="Enter place name here"
                                            v-model="stop.value"></soft-input>
                            </div>
                        </template>

                        <div class="col-6" style="text-align: left">
                            <soft-button v-if="travels.places.stops.length>0" @click="removeStop" color="danger">Remove
                            </soft-button>
                        </div>

                        <div class="col-6" style="text-align: right">
                            <soft-button @click="addStop">Add Stop</soft-button>
                        </div>

                        <div class="col-sm-12">
                            <br>
                            <label>To Place</label>
                            <soft-input v-model="travels.to"
                                        placeholder="Enter destination place name here"></soft-input>
                        </div>

                        <div class="col-sm-12">
                            <br>
                            <label>Travels Detailed Description</label>
                            <soft-textarea id="tour-description" v-model="travels.description"
                                           placeholder="Enter tour details and description here"></soft-textarea>
                        </div>

                        <div class="col-sm-12">
                            <label>Maximum Passengers Allowed</label>
                            <soft-input type="number" v-model="travels.maxNoOfPersons"
                                        placeholder="Enter max number of allowed persons in this tour"></soft-input>
                        </div>

                        <div class="row">
                            <label>Travel Frequency</label>
                            <div class="col-sm-4">
                                <soft-radio v-model="travels.frequency" value="once" id="zero" name="radio">
                                    <slot>Once</slot>
                                </soft-radio>
                            </div>
                            <div class="col-sm-4">
                                <soft-radio v-model="travels.frequency" value="daily" id="one" name="radio">
                                    <slot>Daily</slot>
                                </soft-radio>
                            </div>
                            <div class="col-sm-4">
                                <soft-radio v-model="travels.frequency" value="weekly" id="two"
                                            name="radio">
                                    <slot>Weekly</slot>
                                </soft-radio>
                            </div>
                            <div class="col-sm-4">
                                <soft-radio v-model="travels.frequency" value="monthly" id="three"
                                            name="radio">
                                    <slot>Monthly</slot>
                                </soft-radio>
                            </div>
                            <div class="col-sm-4">
                                <soft-radio v-model="travels.frequency" value="yearly" id="four" name="radio">
                                    <slot>Yearly</slot>
                                </soft-radio>
                            </div>

                            <div class="col-sm-12">
                                <label>Other Frequency</label>
                                <soft-input v-model="travels.otherFrequency"
                                            placeholder="Eg: Every 15 Days."></soft-input>
                            </div>

                            <div class="col-6" v-if="travels.frequency==='once'">
                                <label>Date</label>
                                <soft-input v-model="travels.date"
                                            type="date"
                                            placeholder="Enter date"></soft-input>
                            </div>

                            <div class="col-6">
                                <label>Timing</label>
                                <soft-input v-model="travels.time"
                                            type="time"
                                            placeholder="Enter time"></soft-input>
                            </div>


                        </div>


                        <div class="row">
                            <div class="col-sm-12">
                                <label>Upload Travels Catalogue PDF</label>
                                <br>
                                <input type="file" name="tour-pdf" id="tour-pdf">
                            </div>
                        </div>


                        <div class="col-sm-12">
                            <br>
                            <label>Price/Person</label>
                            <soft-input v-model="travels.price_per_person"
                                        type="number"
                                        placeholder="Enter price"></soft-input>
                        </div>


                        <div class="col-12">
                            <br>
                            <soft-checkbox id="terms-conditions"
                                           @change="notAcceptedTermsAndConditions=!notAcceptedTermsAndConditions">
                                <slot>
                                    I Accept TransAll Terms & Conditions (<a style="color: #0d6efd;"
                                                                             href="https://transall.in/terms_conditions.html"
                                                                             target="_blank">Read More</a>)
                                </slot>
                            </soft-checkbox>
                        </div>


                        <div class="col-sm-12">
                            <br>
                            <soft-button :disabled="notAcceptedTermsAndConditions || isCallingApi" btn-type="submit"
                                         @click="addTravels"
                                         full-width color="success" size="lg"
                                         variant="gradient">
                                Publish Travels
                            </soft-button>
                        </div>


                    </div>
                </form>

                <br>
            </div>

        </div>
    </template>


</template>

<script>

import SoftInput from "../../components/SoftInput"
import SoftButton from "../../components/SoftButton"
import SoftTextarea from "../../components/SoftTextarea"
import SoftCheckbox from "../../components/SoftCheckbox"
import SoftRadio from "../../components/SoftRadio"
import swal from "sweetalert"
import AddSelectTourVehicle from "../../components/modals/AddSelectTourVehicle"
import AddSelectTourManager from "../../components/modals/AddSelectTourManager"
import LocationPickerV2 from "../LocationPickerV2";


export default {
    components: {
        LocationPickerV2,
        SoftInput,
        SoftButton,
        SoftCheckbox,
        SoftTextarea,
        SoftRadio,

    },
    data() {
        return {

            stopPlaceIndex: null,

            isCallingApi: false,

            mobileNumber: null,

            vehicles: null,
            drivers: null,

            notAcceptedTermsAndConditions: true,


            addingVehicleOrManager: false,
            vehicleOrManagerComponent: AddSelectTourManager,

            travels: {

                places: {
                    stops: []
                },

                selectedVehicle: null,
                selectedDriver: 0,

                title: '',

                from: '',
                to: '',

                description: '',

                maxNoOfPersons: '',


                frequency: '',
                otherFrequency: '',

                date: '',
                time: '',

                travelPdfUrl: '',

                price_per_person: '',

            },
            showLocationPicker: false,

        }
    },
    methods: {
        closePicker() {
            const vm = this
            vm.showLocationPicker = false
        },
        locationPicked(data) {
            const vm = this
            console.log(data.stopPlaceIndex)
            vm.travels.places.stops[data.stopPlaceIndex].value = data.address
            vm.showLocationPicker = false
        },
        openLocationPicker(index) {
            const vm = this
            vm.stopPlaceIndex = index
            vm.showLocationPicker = true
        },
        removeStop() {
            const vm = this
            vm.travels.places.stops.pop()
        },
        addStop() {
            const vm = this
            vm.travels.places.stops.push({
                value: ''
            })
        },
        checkUserServices() {
            const vm = this
            window.axios.get('user/get/services/offered/' + vm.mobileNumber)
                .then(resp => {
                    if (resp.data.response.status.error_code === 0) {
                        const user = resp.data.response.data.user
                        if (user.register_for_travels === 0) {
                            swal({
                                title: "Not Registered",
                                text: "You are not registered/allowed for providing this service, Please register first or contact customer support",
                                icon: "error",
                            }).then(() => {
                                vm.$router.replace({
                                    name: 'ride-register-individual',
                                    params: {
                                        mobileNumber: vm.mobileNumber
                                    }
                                })
                            });
                        } else {
                            if (user.is_verified_by_admin === 0) {
                                swal({
                                    title: "Pending KYC",
                                    text: "You KYC is still in process and pending approval, Once done you would be able to use this service",
                                    icon: "error",
                                }).then(() => {
                                    vm.$router.replace({
                                        name: 'ride-register-individual',
                                        params: {
                                            mobileNumber: vm.mobileNumber
                                        }
                                    })
                                });
                            } else {
                                vm.getVehicleList()
                                vm.getDriverList()
                            }
                        }
                    }

                })
        },
        getVehicleList() {
            const vm = this
            window.axios.get('/vehicle/' + vm.mobileNumber + '/list')
                .then(resp => {
                    if (resp.data.response.status.error_code == 0) {
                        vm.vehicles = resp.data.response.data.vehicles
                    }
                })
        },
        getDriverList() {
            const vm = this
            window.axios.get('/driver/' + vm.mobileNumber + '/list')
                .then(resp => {
                    if (resp.data.response.status.error_code == 0) {
                        vm.drivers = resp.data.response.data.drivers
                    }
                })
        },
        addSelectVehicle() {
            this.addingVehicleOrManager = true
            this.vehicleOrManagerComponent = AddSelectTourVehicle
        },
        addSelectManager() {
            this.addingVehicleOrManager = true
            this.vehicleOrManagerComponent = AddSelectTourManager
        },
        goBack() {
            const vm = this
            swal({
                title: "Are you sure you want to go back?",
                text: "Once back, you will not be able to recover this filled form!",
                icon: "warning",
                buttons: ['Cancel', 'GoBack'],
                dangerMode: true,
            }).then((goBack) => {
                if (goBack) {
                    vm.$store.dispatch('clearAllLocations')
                    window.ReactNativeWebView.postMessage("close-screen")
                }
            });
        },
        async uploadAllImages() {
            const vm = this

            await vm.uploadTourPdf()

        },
        uploadTourPdf() {

            const vm = this

            return new Promise((resolve) => {

                let formData = new FormData()
                let imagefile = document.getElementById('tour-pdf')
                if (imagefile.files[0]) {
                    formData.append("image", imagefile.files[0])
                    window.axios.post('/do/upload/document/travels/pdf', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(resp => {
                        if (resp.data.response.status.error_code === 0) {
                            vm.travels.travelPdfUrl = resp.data.response.data.image_url
                        }
                        resolve()
                    }).catch(() => resolve())
                } else {
                    resolve()
                }

            })


        },
        async addTravels() {
            let vm = this

            vm.isCallingApi = true

            await vm.uploadAllImages()

            window.axios.post('/travels/add/new', {
                title: vm.travels.title,
                vehicle_id: vm.travels.selectedVehicle,
                driver_id: vm.travels.selectedDriver,
                places: JSON.stringify(vm.travels.places),
                description: vm.travels.description,
                frequency: vm.travels.frequency,
                frequency_other: vm.travels.otherFrequency,
                date: vm.travels.date,
                time: vm.travels.time,
                doc_pdf_url: vm.travels.travelPdfUrl,
                max_persons: vm.travels.maxNoOfPersons,
                price_per_person: vm.travels.price_per_person,
                last_stop: vm.travels.to,
                mobile_number: vm.mobileNumber,
            }).then(resp => {
                if (resp.data.response.status.error_code === 0) {
                    swal({
                        title: "Success",
                        text: "Travels published successfully!",
                        icon: "info",
                    }).then(() => {
                        window.ReactNativeWebView.postMessage("close-screen")
                    });
                } else {
                    vm.isCallingApi = false

                    vm.$toast.open({
                        message: "Failed to add",
                        type: 'error',
                        position: 'top',
                        duration: 5000,
                        dismissible: false,
                    })
                }
            }).catch(e => {
                vm.isCallingApi = false
                console.log(e)
                vm.$toast.open({
                    message: "Something went wrong",
                    type: 'error',
                    position: 'top',
                    duration: 5000,
                    dismissible: false,
                })
            })

        },
        showModal() {
            this.addingVehicleOrManager = true;
        },
        closeModal() {
            this.addingVehicleOrManager = false;
        }
    },
    computed: {},
    created() {
        let vm = this
        vm.mobileNumber = this.$route.params.mobileNumber
        vm.checkUserServices();
    }
}

</script>

<style scoped>

</style>
