<template>

    <div style="overflow-x: clip;">
        <div class="row sticky-top bg-gray-100 shadow" style="padding-top: 10px;">
            <div class="col-sm-12">
                <h2>
                    <button style="border: none; background: none;" @click="goBack"><i
                        class="p-2 fas fa-arrow-left"></i></button>
                    Publish SPV
                </h2>
            </div>
        </div>

        <div class="container-fluid">
            <br>

            <form @submit.prevent>
                <div class="row">

                    <div class="col-12">

                        <label>Location</label>
                        <soft-input v-model="spv.from"
                                    @click="openLocationPicker('from-location')"
                                    type="text"
                                    placeholder="Pick location"></soft-input>
                    </div>

                    <div class="col-12">
                        <label>Select Vehicle</label>
                        <select v-model="spv.selectedVehicleId" class="custom-select form-control">
                            <template v-if="list.spv.length>0">


                                <template v-for="spv in list.spv" :key="spv.id">
                                    <option :value="spv.id">
                                        {{
                                            spv.vehicle_type + ' ' + spv.model_name + " (" + spv.registered_number + ")"
                                        }}
                                    </option>
                                </template>

                            </template>
                            <template v-else>
                                <option disabled selected value="">No Special Purpose Vehicle Found</option>
                            </template>
                        </select>

                    </div>
                    <div class="col-12" style="text-align: right;">
                        <br>
                        <soft-button size="sm" color="success" variant="outline" @click="showManageVehicleScreen">+ Add
                            Vehicle
                        </soft-button>
                        <br>
                        <br>
                    </div>

                    <div class="col-6">
                        <label>Vehicle Rent</label>
                        <soft-input v-model="spv.rent"
                                    type="number"
                                    placeholder="Enter your rent"></soft-input>
                    </div>

                    <div class="col-6">
                        <label>Rent Per</label>
                        <select v-model="spv.rentType" class="custom-select form-control">
                            <option value="Km" name="Km" id="Km">Km</option>
                            <option value="Hour" name="Hour" id="Hour">Hour</option>
                            <option value="Day" name="Day" id="Day">Day</option>
                        </select>
                    </div>

                    <!--                    <div class="col-12">-->
                    <!--                        <label>Rent per Hour</label>-->
                    <!--                        <soft-input v-model="spv.rentPerHour"-->
                    <!--                                    type="number"-->
                    <!--                                    placeholder="Enter your rate"></soft-input>-->
                    <!--                    </div>-->

                    <!--                    <div class="col-12">-->
                    <!--                        <label>Rent per Day</label>-->
                    <!--                        <soft-input v-model="spv.rentPerDay"-->
                    <!--                                    type="number"-->
                    <!--                                    placeholder="Enter your rate"></soft-input>-->
                    <!--                    </div>-->

                    <!--                    <div class="col-12">-->
                    <!--                        <label>Rent per Km</label>-->
                    <!--                        <soft-input v-model="spv.rentPerKm"-->
                    <!--                                    type="number"-->
                    <!--                                    placeholder="Enter your rate"></soft-input>-->
                    <!--                    </div>-->

                    <div class="col-12">
                        <br>
                        <soft-checkbox id="terms-conditions"
                                       @change="notAcceptedTermsAndConditions=!notAcceptedTermsAndConditions">
                            <slot>
                                I Accept TransAll Terms & Conditions (<a style="color: #0d6efd;"
                                                                         href="https://transall.in/terms_conditions.html"
                                                                         target="_blank">Read More</a>)
                            </slot>
                        </soft-checkbox>
                    </div>


                    <div class="col-sm-12">
                        <br>
                        <soft-button :disabled="notAcceptedTermsAndConditions" btn-type="submit" @click="publishSpv"
                                     full-width color="success" size="lg"
                                     variant="gradient">
                            Publish SPV
                        </soft-button>
                    </div>


                </div>
            </form>

            <br>
        </div>

    </div>

</template>

<script>

import SoftInput from "../../components/SoftInput"
import SoftButton from "../../components/SoftButton"
import SoftCheckbox from "../../components/SoftCheckbox"
import swal from "sweetalert"
import {mapGetters} from "vuex";


export default {
    components: {
        SoftInput,
        SoftButton,
        SoftCheckbox,
    },
    data() {
        return {

            mobileNumber: null,

            notAcceptedTermsAndConditions: true,

            list: {
                spv: [],
            },

            spv: {

                from: this.getFromLocation().address,
                fromLatLng: this.getFromLocation().latLng,
                fromPinCode: this.getFromLocation().pinCode,
                fromCity: this.getFromLocation().city,
                fromLocality: this.getFromLocation().locality,

                date: '',
                time: '',

                rentPerHour: null,
                rentPerDay: null,
                rentPerKm: null,

                rent: null,
                rentType: "Km",

                vehicleType: null,

                selectedVehicleId: '',
            }

        }
    },
    methods: {
        ...mapGetters(['getFromLocation']),

        showManageVehicleScreen() {

            const vm = this
            this.$router.push({
                name: "account-manage-vehicles", params: {
                    mobileNumber: vm.mobileNumber
                }
            })

        },

        publishSpv() {
            const vm = this

            if (vm.rentType === 'Km') {
                vm.spv.rentPerKm = vm.rent
            } else if (vm.rentType === 'Hour') {
                vm.spv.rentPerHour = vm.rent
            } else if (vm.rentType === 'Day') {
                vm.spv.rentPerDay = vm.rent
            } else {
                return
            }

            window.axios.post('/spv/publish', {
                mobile_number: vm.mobileNumber,
                vehicle_type: vm.spv.vehicleType,
                location: vm.spv.fromLatLng,
                rent_per_hour: vm.spv.rentPerHour,
                rent_per_day: vm.spv.rentPerDay,
                rent_per_km: vm.spv.rentPerKm,
                address: vm.spv.from,
                pin_code: vm.spv.fromPinCode,
            }).then(resp => {
                if (resp.data.response.status.error_code === 0) {
                    swal({
                        title: "SPV Published",
                        text: "Your SPV Request has been published!",
                        icon: "info",
                        dangerMode: false,
                    }).then(() => {
                        window.ReactNativeWebView.postMessage("close-screen")
                    });
                }
            })
        },
        goBack() {
            swal({
                title: "Are you sure you want to go back?",
                text: "Once back, you will not be able to recover this filled form!",
                icon: "warning",
                buttons: ['Cancel', 'GoBack'],
                dangerMode: true,
            }).then((goBack) => {
                if (goBack) {
                    window.ReactNativeWebView.postMessage("close-screen")
                }
            });
        },
        openLocationPicker(type) {
            if (type == 'from-location') {
                this.$router.push({
                    name: 'map-location-picker', params: {
                        locationType: 'from-location'
                    }
                })
            }
        },
        getVehicleList() {
            const vm = this
            window.axios.get('/vehicle/' + vm.mobileNumber + '/list/spv')
                .then(resp => {
                    if (resp.data.response.status.error_code == 0) {
                        vm.list.spv = resp.data.response.data.vehicles
                        if (vm.list.spv?.length === 0) {

                            swal({
                                title: "No Vehicles found",
                                text: "You need to add a vehicle before you provide SPV service, Please add one now!",
                                icon: "info",
                            }).then(() => {
                                vm.$router.push({
                                    name: 'account-manage-vehicles',
                                    params: {
                                        mobileNumber: vm.mobileNumber
                                    }
                                })
                            });
                        }
                    }
                })
        },
        checkUserServices() {
            const vm = this
            window.axios.get('user/get/services/offered/' + vm.mobileNumber)
                .then(resp => {
                    if (resp.data.response.status.error_code === 0) {
                        const user = resp.data.response.data.user
                        if (user.register_for_spv === 0) {
                            swal({
                                title: "Not Registered",
                                text: "You are not registered for providing this service, Please register first.",
                                icon: "error",
                            }).then(() => {
                                vm.$router.replace({
                                    name: 'ride-register-individual',
                                    params: {
                                        mobileNumber: vm.mobileNumber
                                    }
                                })
                            });
                        } else {
                            if (user.is_verified_by_admin === 0) {
                                swal({
                                    title: "Pending KYC",
                                    text: "You KYC is still in process and pending approval, Once done you would be able to use this service",
                                    icon: "error",
                                }).then(() => {
                                    vm.$router.replace({
                                        name: 'ride-register-individual',
                                        params: {
                                            mobileNumber: vm.mobileNumber
                                        }
                                    })
                                });
                            } else {
                                if (user.is_verified_by_admin === 0) {
                                    swal({
                                        title: "Pending KYC",
                                        text: "You KYC is still in process and pending approval, Once done you would be able to use this service",
                                        icon: "error",
                                    }).then(() => {
                                        vm.$router.replace({
                                            name: 'ride-register-individual',
                                            params: {
                                                mobileNumber: vm.mobileNumber
                                            }
                                        })
                                    });
                                } else {
                                    //TODO: Logic goes here
                                    vm.getVehicleList()
                                }
                            }
                        }
                    }

                })
        },
    },
    computed: {},
    created() {
        let vm = this
        vm.mobileNumber = vm.$route.params.mobileNumber
        vm.checkUserServices();
    },
}

</script>

<style scoped>

</style>
