<template>
    <div style="overflow-x: clip;">

        <div class="row sticky-top bg-gray-100 shadow" style="padding-top: 10px;">
            <div class="col-sm-12">
                <h2>
                    <button style="border: none; background: none;" @click="goBack"><i
                        class="p-2 fas fa-arrow-left"></i></button>
                    {{ getTitle }}
                </h2>
            </div>
        </div>

        <div class="container-fluid">

            <br>

            <div v-if="notificationType==='TRAVEL_BOOKING_ACCEPTED'">

                <div class="row">
                    <div class="col-12">
                        <h5>{{ travel.title }}</h5>
                    </div>
                </div>

                <hr>

                <div class="row">
                    <div class="col-6">
                        <span class="text-bold">Package Price</span>
                    </div>
                    <div class="col-6" style="text-align: right;">
                        <span>{{ stringToINR(travel.package_price) }}</span>
                    </div>

                </div>

                <br>

                <div class="row">
                    <div class="col-6">
                        <span class="text-bold">Persons</span>
                    </div>
                    <div class="col-6" style="text-align: right;">
                        <span>{{ travel.no_of_persons }}</span>
                    </div>

                </div>

                <br>

                <div class="row">
                    <div class="col-6">
                        <span class="text-bold">Convenience Fees</span>
                    </div>
                    <div class="col-6" style="text-align: right;">
                        <span>7%</span>
                    </div>

                </div>

                <br>

                <div class="row">
                    <div class="col-6">
                        <h4 class="text-bold">You Pay</h4>
                    </div>
                    <div class="col-6" style="text-align: right;">
                        <h4>{{ stringToINR(travel.customer_amount) }}</h4>
                    </div>
                    <span class="font-italic">* Terms and Conditions apply</span>
                </div>

                <br>

                <div class="row">

                    <div class="col-4">
                        <soft-button :disabled="isLoading" full-width color="danger">Cancel
                        </soft-button>
                    </div>

                    <div class="col-8">
                        <soft-button :disabled="isLoading" @click="makePaymentRequest" full-width
                                     color="success">
                            Pay Now
                        </soft-button>
                    </div>
                </div>

                <br>
            </div>

            <div v-if="notificationType==='TRAVEL_BOOKING_CONFIRMED_CUSTOMER'">

                <div class="row">
                    <div class="col-12">
                        <h5>{{ travel.title }}</h5>
                    </div>
                    <div class="col-12">
                        <label>Service Provider</label>
                        <p>{{ travel.serviceProvider.name }}</p>
                    </div>
                    <div class="col-12">
                        <label>Contact</label>
                        <p>{{ travel.serviceProvider.contact }}</p>
                    </div>
                </div>

                <hr>

                <div class="row">
                    <div class="col-6">
                        <span class="text-bold">Package Price</span>
                    </div>
                    <div class="col-6" style="text-align: right;">
                        <span>{{ stringToINR(travel.package_price) }}</span>
                    </div>

                </div>

                <br>

                <div class="row">
                    <div class="col-6">
                        <span class="text-bold">Persons</span>
                    </div>
                    <div class="col-6" style="text-align: right;">
                        <span>{{ travel.no_of_persons }}</span>
                    </div>

                </div>

                <br>

                <div class="row">
                    <div class="col-6">
                        <span class="text-bold">Convenience Fees</span>
                    </div>
                    <div class="col-6" style="text-align: right;">
                        <span>7%</span>
                    </div>

                </div>

                <br>

                <div class="row">
                    <div class="col-6">
                        <h4 class="text-bold">You Paid</h4>
                    </div>
                    <div class="col-6" style="text-align: right;">
                        <h4>{{ stringToINR(travel.customer_amount) }}</h4>
                    </div>
                    <span class="font-italic">* Terms and Conditions apply</span>
                </div>

                <br>

            </div>

        </div>

    </div>

</template>

<script>

import SoftButton from "../../components/SoftButton"

export default {
    components: {SoftButton},
    props: {

        viewer: {
            default: ''
        },

    },
    data() {
        return {

            travel: {
                title: '',
                customer_amount: 0,
                no_of_persons: 0,
                package_price: 0,
                serviceProvider: {
                    name: '',
                    contact: '',
                }
            },

            isLoading: true,

            mobileNumber: null,

            notificationType: null,

            bookingId: null,

            payee: {
                name: '',
                email: '',
            },

            rideFair: {
                isLoading: true,
                fair: '0.0',
                perKmCharges: '0.0',
                convFees: '0.0',
                total: '0.0'
            }

        }
    },
    methods: {

        goBack() {
            if (window.history.length > 2) {
                this.$router.go(-1)
            } else {
                window.ReactNativeWebView.postMessage("close-screen")
            }
        },

        getTravelBookingDetails() {

            const vm = this

            vm.isLoading = true

            window.axios.get('/travels/get/booking/details/' + vm.bookingId)
                .then(resp => {
                    if (resp.data.response.status.error_code === 0) {

                        vm.isLoading = false

                        const bookingDetails = resp.data.response.data.booking_details

                        vm.travel.title = bookingDetails.travel.title
                        vm.travel.customer_amount = bookingDetails.customer_amount / 100
                        vm.travel.no_of_persons = bookingDetails.no_of_persons
                        vm.travel.package_price = bookingDetails.travel.price_per_person
                        vm.payee.name = bookingDetails.user.first_name + " " + bookingDetails.user.last_name
                        vm.payee.name = bookingDetails.user.email

                        if (bookingDetails.status === 2) {
                            vm.notificationType = 'TRAVEL_BOOKING_CONFIRMED_CUSTOMER'
                            vm.travel.serviceProvider.name = bookingDetails.travel.publisher.first_name + " " + bookingDetails.travel.publisher.last_name
                            vm.travel.serviceProvider.contact = bookingDetails.travel.publisher.mobile_number
                        }

                    }
                })
                .catch(error => {
                    vm.isLoading = false
                    console.log(error)
                })

        },

        makePaymentRequest() {
            const vm = this
            vm.rideFair.isLoading = true
            window.axios.post('/payment/create/travels/order/' + vm.bookingId, {
                contact: vm.mobileNumber,
                requestId: vm.bookingId,
                requestType: 'travels',
            }).then(response => {
                if (response.data.response.status.error_code === 0) {

                    let url = response.data.response.data.data.instrumentResponse.redirectInfo.url

                    this.$router.push({
                        name: "phonepe-payment-checkout", params: {
                            webCheckoutURL: url,
                            typeOfService: 'travels',
                            name: vm.payee.name,
                            email: vm.payee.email,
                            mobileNumber: vm.mobileNumber,
                            description: 'Travels Booking',
                            bookingId: vm.customerBookingId
                        }
                    })



                    // let orderId = response.data.response.data.id
                    // let amount = response.data.response.data.amount
                    // this.$router.push({
                    //     name: "payment-checkout", params: {
                    //         orderId: orderId,
                    //         amount: amount,
                    //         typeOfService: 'travels',
                    //         name: vm.payee.name,
                    //         email: vm.payee.email,
                    //         mobileNumber: vm.mobileNumber,
                    //         description: 'Travels Booking',
                    //         bookingId: vm.bookingId
                    //     }
                    // })
                }
            }).catch(e => console.log(e))
        },


    },
    mounted() {

        if (this.notificationType === '') {
            window.ReactNativeWebView.postMessage("close-screen")
        }

    },
    computed: {
        getTitle() {
            if (this.notificationType === 'TRAVEL_BOOKING_REQUEST') {
                return 'Travel Booking'
            } else if (this.notificationType === 'TRAVEL_BOOKING_ACCEPTED') {
                return 'Travel Accepted'
            } else if (this.notificationType === 'TRAVEL_BOOKING_REJECTED') {
                return 'Travel Rejected'
            } else if (this.notificationType === 'TRAVEL_BOOKING_CONFIRMED_CUSTOMER' || this.notificationType === 'TRAVEL_BOOKING_CONFIRMED_RIDER') {
                return 'Travel Confirmed'
            }
            return 'Notification'
        },
    },
    created() {
        let vm = this
        vm.mobileNumber = vm.$route.params.mobileNumber
        vm.notificationType = vm.$route.params.notificationType
        vm.bookingId = vm.$route.params.bookingId

        vm.getTravelBookingDetails()

    }

}

</script>

<style scoped>

label {
    margin: 0;
    padding: 0;
}

p {
    font-size: 14px;
}

</style>
