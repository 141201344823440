<template>

    <div v-if="isApiCalling" class="row"
         style="position: fixed; top:0; bottom: 0; left: 0; right: 0; z-index: 1; background-image: linear-gradient(310deg, #17ad37ee 0%, #98ec2dee 100%);">
        <div class="col-12 text-center" style="margin-top: 40vh;">
            <img :src="loader" class="img-fluid" width="50">
            <h2 class="text-white text-center">Please wait adding vehicle...</h2>
        </div>
    </div>

    <div>

        <div class="container-fluid">

            <div class="row">
                <div class="col-sm-12">
                    <br>
                    <h4>{{ getTitle }}</h4>
                    <hr>
                </div>
            </div>

            <div class="row">

                <div class="col-6">
                    <soft-radio :checked="isNormalVehicle" @click="setNormalVehicle()" value="normal" id="normal"
                                name="normal">Normal Vehicle
                    </soft-radio>
                </div>

                <div class="col-6">
                    <soft-radio :checked="isSpvVehicle" @click="setSpvVehicle()" value="spv" id="spv" name="spv">Special
                        Purpose Vehicle
                    </soft-radio>
                </div>

                <br>
                <br>

                <template v-if="isNormalVehicle">

                    <div class="col-12">
                        <label>Select Vehicle Type</label>
                        <select class="form-control" v-model="vehicle.vehicleType">
                            <option value="Bike">Bike</option>
                            <option value="Auto">Auto</option>
                            <option value="Hatchback">Hatchback</option>
                            <option value="Sedan">Sedan</option>
                            <option value="SUV">SUV</option>
                            <option value="Traveller">Traveller</option>
                            <option value="Bus">Bus</option>
                            <option value="Mini-Bus">Mini-Bus</option>
                            <option value="Loading Tempo">Loading Tempo</option>
                            <option value="Loading Truck">Loading Truck</option>
                            <option value="Loading Container">Loading Container</option>
                        </select>
                        <br>
                    </div>

                    <div class="col-sm-12">
                        <label>Vehicle Model</label>
                        <soft-input v-model="vehicle.model"
                                    placeholder=""></soft-input>
                    </div>

                    <div class="col-sm-12">
                        <label>Vehicle Number</label>
                        <soft-input custom-style="text-transform:uppercase" v-model="vehicle.number"
                                    placeholder=""></soft-input>
                    </div>


                    <div class="col-sm-12">
                        <soft-checkbox :checked="vehicle.allIndiaPermit"
                                       @change="vehicle.allIndiaPermit=!vehicle.allIndiaPermit" id="allIndiaPermit"
                                       name="allIndiaPermit">All India
                            Permit
                        </soft-checkbox>
                    </div>

                    <div class="col-sm-12">
                        <soft-checkbox :checked="vehicle.haveAc" @change="vehicle.haveAc=!vehicle.haveAc" id="haveAc"
                                       name="haveAc">Air Conditioned (AC)
                        </soft-checkbox>
                    </div>

                    <div class="row">
                        <label>Registration Type</label>
                        <div class="col-12">
                            <soft-radio v-model="vehicle.registrationType" value="Personal" id="radio1" name="radio">
                                <slot>Personal</slot>
                            </soft-radio>
                        </div>
                        <div class="col-12">
                            <soft-radio v-model="vehicle.registrationType" value="Rented" id="radio4" name="radio">
                                <slot>Rented</slot>
                            </soft-radio>
                        </div>
                        <div class="col-12">
                            <soft-radio v-model="vehicle.registrationType" value="Company" id="radio4" name="radio">
                                <slot>Company</slot>
                            </soft-radio>
                        </div>
                    </div>

                    <div class="row">
                        <label>Type</label>
                        <div class="col-12">
                            <soft-radio checked v-model="vehicle.loadingType" value="Passenger" id="Passenger"
                                        name="radio-p">
                                <slot>Passenger</slot>
                            </soft-radio>
                        </div>
                        <div class="col-12">
                            <soft-radio v-model="vehicle.loadingType" value="Commercial" id="Commercial" name="radio-p">
                                <slot>Commercial</slot>
                            </soft-radio>
                        </div>
                    </div>

                    <div class="col-sm-12" v-if="vehicle.loadingType==='Passenger'">
                        <label>Passenger Capacity</label>
                        <soft-input v-model="vehicle.passengerCapacity"
                                    placeholder=""></soft-input>
                    </div>

                    <div class="col-sm-12" v-if="vehicle.loadingType==='Commercial'">
                        <label>Loading Capacity in Kg</label>
                        <soft-input v-model="vehicle.loadingCapacity"
                                    placeholder=""></soft-input>
                    </div>

                </template>

                <template v-if="isSpvVehicle">
                    <div class="col-12">
                        <label>Select SPV Vehicle</label>
                        <select v-model="vehicle.vehicleType" class="custom-select form-control">
                            <option value="JCB" name="JCB" id="JCB">JCB</option>
                            <option value="Tractor" name="Tractor" id="Tractor">Tractor</option>
                            <option value="Staff Buss" name="Staff Buss" id="Staff Buss">Staff Buss</option>
                            <option value="School Bus" name="School Bus" id="School Bus">School Bus</option>
                            <option value="Truck" name="Truck" id="Truck">Truck</option>
                            <option value="Hydra" name="Hydra" id="Hydra">Hydra</option>
                            <option value="Forklift" name="Forklift" id="Forklift">Forklift</option>
                            <option value="Road-Roller" name="Road-Roller" id="Road-Roller">Road-Roller</option>
                            <option value="Ambulance" name="Ambulance" id="Ambulance">Ambulance</option>
                            <option value="Towing Van" name="Towing Van" id="Towing Van">Towing Van</option>
                        </select>
                        <br>
                    </div>

                    <div class="col-sm-12">
                        <label>Vehicle Model/Brand</label>
                        <soft-input v-model="vehicle.model"
                                    placeholder=""></soft-input>
                    </div>

                    <div class="col-sm-12">
                        <label>Vehicle Number</label>
                        <soft-input custom-style="text-transform:uppercase" v-model="vehicle.number"
                                    placeholder=""></soft-input>
                    </div>


                    <div class="col-sm-12">
                        <soft-checkbox :checked="vehicle.allIndiaPermit"
                                       @change="vehicle.allIndiaPermit=!vehicle.allIndiaPermit" id="allIndiaPermit"
                                       name="allIndiaPermit">All India
                            Permit
                        </soft-checkbox>
                    </div>

                </template>

                <div class="col-sm-12">
                    <label>Owner Name (As per RC Book)</label>
                    <soft-input v-model="vehicle.ownerName"
                                placeholder=""></soft-input>
                </div>

            </div>
            <div v-if="vehicleData!=null" class="row">
                <div class="col">
                    <img class="img-fluid" :src="vehicle.rcBookPhotoUrl"/>
                </div>
                <div class="col">
                    <img class="img-fluid" :src="vehicle.frontPhotoUrl"/>
                </div>
                <div class="col">
                    <img class="img-fluid" :src="vehicle.backPhotoUrl"/>
                </div>
                <div class="col">
                    <img class="img-fluid" :src="vehicle.rightPhotoUrl"/>
                </div>
                <div class="col">
                    <img class="img-fluid" :src="vehicle.leftPhotoUrl"/>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <label>RC Book Photo</label>
                    <br>
                    <input accept="image/*" type="file" id="rc-book-photo">
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <label>Front Photo</label>
                    <br>
                    <input accept="image/*" type="file" id="front-photo">
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <label>Back Photo</label>
                    <br>
                    <input accept="image/*" type="file" id="back-photo">
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <label>Right Side Photo</label>
                    <br>
                    <input accept="image/*" type="file" id="right-photo">
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <label>Left Side Photo</label>
                    <br>
                    <input accept="image/*" type="file" id="left-photo">
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <br>
                    <soft-button btn-type="submit" @click="close()"
                                 full-width color="dark" size="md"
                                 variant="gradient">
                        Close
                    </soft-button>
                </div>

                <div class="col-12">
                    <br>
                    <soft-button btn-type="submit" @click="addVehicle" full-width color="success" size="md"
                                 variant="gradient">
                        {{ getButtonLabel }}
                    </soft-button>
                    <br>
                    <br>
                </div>

            </div>

            <div class="row" v-if="vehicleData">

                <div class="col-12">

                    <soft-button btn-type="submit" @click="deleteVehicle" full-width color="danger" size="md"
                                 variant="gradient">
                        Delete
                    </soft-button>
                    <br>
                    <br>
                </div>

            </div>

        </div>

    </div>

</template>
<script>

import SoftInput from "../SoftInput";
import SoftButton from "../SoftButton";
import SoftCheckbox from "../SoftCheckbox";
import SoftRadio from "../SoftRadio";
import loader from "@/assets/img/loader.gif";
import swal from "sweetalert";

export default {

    components: {
        SoftRadio,
        SoftCheckbox,
        SoftInput,
        SoftButton,
    },
    props: {
        modal: {
            type: Object,
        },
        mobileNumber: {
            type: String,
            required: true
        }
    },
    data() {
        return {

            isNormalVehicle: true,

            isSpvVehicle: false,

            loader: loader,

            vehicleData: null,

            isApiCalling: false,

            vehicle: {
                id: null,
                model: '',
                number: '',
                frontPhotoUrl: '',
                backPhotoUrl: '',
                leftPhotoUrl: '',
                rightPhotoUrl: '',
                rcBookPhotoUrl: '',
                passengerCapacity: null,
                loadingCapacity: '',
                vehicleType: '',
                registrationType: '',
                loadingType: 'Passenger',
                allIndiaPermit: false,
                haveAc: false,
                ownerName: '',
            },
        }
    },
    methods: {
        close() {
            this.modal.$emit('close');
        },

        setSpvVehicle() {
            this.isSpvVehicle = true
            this.isNormalVehicle = false
        },

        setNormalVehicle() {
            this.isSpvVehicle = false
            this.isNormalVehicle = true
        },

        setVehicleData(selectedVehicle) {
            this.vehicleData = selectedVehicle
            if (this.vehicleData != null) {
                this.vehicle.id = this.vehicleData.id
                this.vehicle.number = this.vehicleData.registered_number
                this.vehicle.model = this.vehicleData.model_name

                this.vehicle.frontPhotoUrl = this.vehicleData.front_image
                this.vehicle.backPhotoUrl = this.vehicleData.back_image
                this.vehicle.rightPhotoUrl = this.vehicleData.right_image
                this.vehicle.leftPhotoUrl = this.vehicleData.left_image
                this.vehicle.rcBookPhotoUrl = this.vehicleData.rc_book_image

                this.vehicle.passengerCapacity = this.vehicleData.passenger_capacity
                this.vehicle.registrationType = this.vehicleData.registration_type
                this.vehicle.haveAc = this.vehicleData.ac_non_ac
                this.vehicle.allIndiaPermit = this.vehicleData.all_india_permit
                this.vehicle.ownerName = this.vehicleData.owner_name
                this.vehicle.loadingCapacity = this.vehicleData.loading_capacity
                this.vehicle.vehicleType = this.vehicleData.vehicle_type
            } else {
                this.vehicle.model = ''
                this.vehicle.number = ''
                this.vehicle.frontPhotoUrl = ''
                this.vehicle.backPhotoUrl = ''
                this.vehicle.rightPhotoUrl = ''
                this.vehicle.leftPhotoUrl = ''
                this.vehicle.rcBookPhotoUrl = ''

                this.vehicle.passengerCapacity = null
                this.vehicle.registrationType = null
                this.vehicle.haveAc = false
                this.vehicle.allIndiaPermit = false
            }
        },
        deleteVehicle() {
            const vm = this
            window.axios.delete('/vehicle/' + vm.$props.mobileNumber + '/delete/' + vm.vehicleData.id)
                .then(resp => {
                    if (resp.data.response.status.error_code === 0) {
                        vm.close()
                    }
                })
        },

        compressImage(file) {
            return new Promise((resolve) => {
                const reader = new FileReader();
                reader.onload = async (event) => {
                    const img = new Image();
                    img.src = event.target.result;

                    img.onload = () => {
                        const canvas = document.createElement('canvas');
                        const maxDimension = 1024; // You can adjust this value as needed
                        let width = img.width;
                        let height = img.height;

                        if (width > height) {
                            if (width > maxDimension) {
                                height *= maxDimension / width;
                                width = maxDimension;
                            }
                        } else {
                            if (height > maxDimension) {
                                width *= maxDimension / height;
                                height = maxDimension;
                            }
                        }

                        canvas.width = width;
                        canvas.height = height;

                        const ctx = canvas.getContext('2d');
                        ctx.drawImage(img, 0, 0, width, height);

                        canvas.toBlob((blob) => {
                            resolve(new File([blob], file.name, { type: 'image/jpeg' }));
                        }, 'image/jpeg', 0.7); // You can adjust the compression quality (0.7 means 70%)
                    };
                };

                reader.readAsDataURL(file);
            });
        },

        uploadFrontPhoto(compressedImage) {

            const vm = this

            return new Promise((resolve) => {

                let formData = new FormData()
                let imagefile = document.getElementById('front-photo')
                if (imagefile.files[0]) {

                    formData.append("image", compressedImage)
                    window.axios.post('/do/upload/image/vehicle/front', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(resp => {
                        if (resp.data.response.status.error_code === 0) {
                            vm.vehicle.frontPhotoUrl = resp.data.response.data.image_url
                        }
                        resolve()
                    }).catch(() => resolve())
                } else {
                    resolve()
                }

            })


        },
        uploadBackPhoto(compressedImage) {

            const vm = this

            return new Promise((resolve) => {

                let formData = new FormData()
                let imagefile = document.getElementById('back-photo')
                if (imagefile.files[0]) {

                    formData.append("image", compressedImage)
                    window.axios.post('/do/upload/image/vehicle/back', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(resp => {
                        if (resp.data.response.status.error_code === 0) {
                            vm.vehicle.backPhotoUrl = resp.data.response.data.image_url
                        }
                        resolve()
                    }).catch(() => resolve())
                } else {
                    resolve()
                }

            })


        },
        uploadRightPhoto(compressedImage) {

            const vm = this

            return new Promise((resolve) => {

                let formData = new FormData()
                let imagefile = document.getElementById('right-photo')
                if (imagefile.files[0]) {

                    formData.append("image", compressedImage)
                    window.axios.post('/do/upload/image/vehicle/right', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(resp => {
                        if (resp.data.response.status.error_code === 0) {
                            vm.vehicle.rightPhotoUrl = resp.data.response.data.image_url
                        }
                        resolve()
                    }).catch(() => resolve())
                } else {
                    resolve()
                }

            })


        },
        uploadLeftPhoto(compressedImage) {

            const vm = this

            return new Promise((resolve) => {

                let formData = new FormData()
                let imagefile = document.getElementById('left-photo')
                if (imagefile.files[0]) {

                    formData.append("image", compressedImage)
                    window.axios.post('/do/upload/image/vehicle/left', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(resp => {
                        if (resp.data.response.status.error_code === 0) {
                            vm.vehicle.leftPhotoUrl = resp.data.response.data.image_url
                        }
                        resolve()
                    }).catch(() => resolve())
                } else {
                    resolve()
                }

            })


        },
        uploadRcBookPhoto(compressedImage) {

            const vm = this

            return new Promise((resolve) => {

                let formData = new FormData()
                let imagefile = document.getElementById('rc-book-photo')
                if (imagefile.files[0]) {

                    formData.append("image", compressedImage)
                    window.axios.post('/do/upload/image/vehicle/rc/book', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(resp => {
                        if (resp.data.response.status.error_code === 0) {
                            vm.vehicle.rcBookPhotoUrl = resp.data.response.data.image_url
                        }
                        resolve()
                    }).catch(() => resolve())
                } else {
                    resolve()
                }

            })


        },
        async uploadAllImages() {
            const vm = this

            let imagefile1 = document.getElementById('front-photo')
            if (imagefile1.files[0]) {
                const compressedImage = await vm.compressImage(imagefile1.files[0]);
                await vm.uploadFrontPhoto(compressedImage)

            }

            let imagefile2 = document.getElementById('back-photo')
            if (imagefile2.files[0]) {
                const compressedImage = await vm.compressImage(imagefile2.files[0]);
                await vm.uploadBackPhoto(compressedImage)

            }

            let imagefile3 = document.getElementById('right-photo')
            if (imagefile3.files[0]) {
                const compressedImage = await vm.compressImage(imagefile3.files[0]);
                await vm.uploadRightPhoto(compressedImage)

            }

            let imagefile4 = document.getElementById('left-photo')
            if (imagefile4.files[0]) {
                const compressedImage = await vm.compressImage(imagefile4.files[0]);
                await vm.uploadLeftPhoto(compressedImage)

            }

            let imagefile5 = document.getElementById('rc-book-photo')
            if (imagefile5.files[0]) {
                const compressedImage = await vm.compressImage(imagefile5.files[0]);
                await vm.uploadRcBookPhoto(compressedImage)

            }


        },

        async addVehicle() {
            const vm = this

            let imagefile1 = document.getElementById('rc-book-photo')
            if (imagefile1.files[0] == null && vm.vehicle.rcBookPhotoUrl === '') {
                vm.$toast.open({
                    message: 'RC Book photo is required',
                    type: 'error',
                    position: 'top',
                    duration: 2000,
                    dismissible: false,
                })
                return
            }

            let imagefile2 = document.getElementById('front-photo')
            if (imagefile2.files[0] == null && vm.vehicle.frontPhotoUrl === '') {
                vm.$toast.open({
                    message: 'Front side photo is required',
                    type: 'error',
                    position: 'top',
                    duration: 2000,
                    dismissible: false,
                })
                return
            }
            let imagefile3 = document.getElementById('back-photo')
            if (imagefile3.files[0] == null && vm.vehicle.backPhotoUrl === '') {
                vm.$toast.open({
                    message: 'Back side photo is required',
                    type: 'error',
                    position: 'top',
                    duration: 2000,
                    dismissible: false,
                })
                return
            }
            let imagefile4 = document.getElementById('right-photo')
            if (imagefile4.files[0] == null && vm.vehicle.rightPhotoUrl === '') {
                vm.$toast.open({
                    message: 'Right side photo is required',
                    type: 'error',
                    position: 'top',
                    duration: 2000,
                    dismissible: false,
                })
                return
            }
            let imagefile5 = document.getElementById('left-photo')
            if (imagefile5.files[0] == null && vm.vehicle.leftPhotoUrl === '') {
                vm.$toast.open({
                    message: 'Left side photo is required',
                    type: 'error',
                    position: 'top',
                    duration: 2000,
                    dismissible: false,
                })
                return
            }

            vm.isApiCalling = true

            await vm.uploadAllImages()

            window.axios.post('/vehicle/' + vm.$props.mobileNumber + '/add', {
                vehicle_id: vm.vehicle.id,
                model_name: vm.vehicle.model,
                registered_number: vm.vehicle.number,
                owner_name: vm.vehicle.ownerName,
                passenger_capacity: vm.vehicle.passengerCapacity,
                loading_capacity: vm.vehicle.loadingCapacity,
                vehicle_type: vm.vehicle.vehicleType,
                loading_type: vm.vehicle.loadingType,
                registration_type: vm.vehicle.registrationType,
                ac_non_ac: vm.vehicle.haveAc,
                all_india_permit: vm.vehicle.allIndiaPermit,

                front_image: vm.vehicle.frontPhotoUrl,
                back_image: vm.vehicle.backPhotoUrl,
                right_image: vm.vehicle.rightPhotoUrl,
                left_image: vm.vehicle.leftPhotoUrl,
                rc_book_image: vm.vehicle.rcBookPhotoUrl,

                is_spv: vm.isSpvVehicle ? 1 : 0,
            })
                .then(resp => {
                    if (resp.data.response.status.error_code == 0) {
                        vm.isApiCalling = false
                        vm.close()
                    }else{
                      vm.isApiCalling = false
                      swal({
                        title: "Cannot Add Vehicle",
                        text: resp.data.response.status.error_msg,
                        icon: "error",
                      });
                    }
                })
        }
    },
    computed: {
        getTitle() {
            if (this.vehicleData != null) {
                return 'Vehicle Details'
            } else {
                return 'Add Vehicle'

            }
        },
        getButtonLabel() {
            if (this.vehicleData != null) {
                return 'Save'
            } else {
                return 'Add'

            }
        }
    }

}

</script>
