<template>
    <div style="overflow-x: clip;">

        <div class="row sticky-top bg-gray-100 shadow" style="padding-top: 10px;">
            <div class="col-sm-12">
                <h2 v-if="!ride.ended && !ride.onGoing">
                    <button style="border: none; background: none;" @click="goBack"><i
                        class="p-2 fas fa-arrow-left"></i></button>
                    {{ getTitle }}
                </h2>
                <h2 v-if="ride.onGoing">
                    <button style="border: none; background: none;" @click="goBack"><i
                        class="p-2 fas fa-arrow-left"></i></button>
                    Ongoing Parcel
                </h2>
                <h2 v-if="ride.ended">
                    <button style="border: none; background: none;" @click="goBack"><i
                        class="p-2 fas fa-arrow-left"></i></button>
                    Parcel Ended
                </h2>

            </div>
        </div>

        <div class="container-fluid" v-if="!ride.ended">

            <br>

            <!--            This will be shown to RIDER who published the ride-->
            <div v-if="notificationType==='PARCEL_BOOKING_REQUEST' && isRider">

                <div class="row">
                    <div class="col-12">
                        <h5>Parcel No. (#{{ ride.rideNumber }})</h5>
                    </div>
                </div>

                <br>

                <div class="row" v-if="isRider">
                    <div class="col-6">
                        <label>Customer Name</label>
                        <p>{{ ride.customer_name }}</p>
                    </div>
                    <div class="col-6" style="text-align: right;">
                        <label>Parcel Type</label>
                        <p>{{ ride.is_material === 1 ? 'Material' : 'Document' }}</p>
                    </div>
                </div>

                <br>

                <div class="row">
                    <div class="col-6">
                        <label>Date</label>
                        <p>{{ ride.date }}</p>
                    </div>
                    <div class="col-6" style="text-align: right;">
                        <label>Time</label>
                        <p>{{ ride.time }}</p>
                    </div>
                </div>

                <br>

                <div class="row">
                    <div class="col-10">
                        <label>From Location</label>
                        <p>{{ ride.from_location }}</p>
                    </div>
                    <div class="col-2" style="text-align: right">
                        <a :href="fromLocationPoint" target="_blank">
                            <img width="30" class="img-fluid" :src="navIcon">
                        </a>
                    </div>
                </div>

                <br>

                <div class="row">
                    <div class="col-10">
                        <label>To Location</label>
                        <p>{{ ride.to_location }}</p>
                    </div>
                    <div class="col-2" style="text-align: right">
                        <a :href="toLocationPoint" target="_blank">
                            <img width="30" class="img-fluid" :src="navIcon">
                        </a>
                    </div>
                </div>

                <div class="row" v-if="ride.is_material===1">
                    <div class="col-12">
                        <soft-button @click="viewMaterialDetails" full-width color="dark">
                            View Material Details
                        </soft-button>
                    </div>
                </div>
                <div class="row" v-if="ride.is_document===1">
                    <div class="col-12">
                        <label>Document</label>
                        <!--                        <p>{{ride.parcelDetails.itemDetails.name}}</p>-->
                    </div>
                </div>

                <hr>

                <template v-if="isRider">
                    <div class="row">
                        <div class="col-6">
                            <span class="text-bold">Per Km Charges</span>
                        </div>
                        <div class="col-6" style="text-align: right;">
                            <span>₹{{ rideFair.perKmCharges }}/-</span>
                        </div>

                    </div>

                    <br>

                    <div class="row">
                        <div class="col-6">
                            <span class="text-bold">Distance</span>
                        </div>
                        <div class="col-6" style="text-align: right;">
                            <span>{{ ride.distance }}</span>
                        </div>

                    </div>

                    <br>

                    <div class="row">
                        <div class="col-6">
                            <span class="text-bold">Convenience Fees</span>
                        </div>
                        <div class="col-6" style="text-align: right;">
                            <span>₹{{ rideFair.convFees }}/-</span>
                        </div>

                    </div>

                    <br>

                    <div class="row">
                        <div class="col-6">
                            <h4 class="text-bold">You Get</h4>
                        </div>
                        <div class="col-6" style="text-align: right;">
                            <h4>₹{{ rideFair.total }}/-</h4>
                        </div>
                        <span class="font-italic">* Terms and Conditions apply</span>
                    </div>


                    <br>

                    <div class="row">

                        <div class="col-4">
                            <soft-button :disabled="isLoading" @click="rejectRideRequest" full-width color="danger">
                                Reject
                            </soft-button>
                        </div>

                        <div class="col-8">
                            <soft-button v-if="!rideAlreadyAcceptedByRider"
                                         :disabled="isLoading" @click="acceptRideRequest"
                                         full-width color="success">
                                Accept
                            </soft-button>
                            <soft-button v-else :disabled="rideAlreadyAcceptedByRider"
                                         @click="acceptRideRequest" full-width color="success">
                                Already Accepted
                            </soft-button>
                        </div>

                        <p v-if="rideAlreadyAcceptedByRider">
                            <br>
                            Waiting for Customer to make Payment...
                        </p>

                        <br>
                        <br>
                        <br>

                    </div>

                </template>

            </div>

            <!--            This will be shown to CUSTOMER who requested for ride-->
            <div v-if="notificationType==='PARCEL_BOOKING_ACCEPTED' && isCustomer">

                <div class="row">
                    <div class="col-12">
                        <h5>Parcel No. (#{{ ride.rideNumber }})</h5>
                    </div>
                </div>

                <br>

                <div class="row">
                    <div class="col-6">
                        <span style="position:relative; z-index: 1;">

                            <img class="img-fluid card"
                                 :src="ride.riderImage">

                            <img class="img-fluid"
                                 style="position:absolute; top: -10px; left: -10px; height: 35px; width: 35px;"
                                 src="https://www.pngkey.com/png/full/624-6246436_verified-account-icon-selo-verificao-instagram-png.png">
                            </span>
                    </div>
                    <div class="col-6">
                        <div class="row">
                            <div class="col-12">
                                <span class="text-bold">Rider Name</span>
                                <br>
                                <span>{{ ride.riderName }}</span>
                                <br>
                            </div>
                            <div class="col-12">
                                <br>
                                <span class="text-bold">Rider Rating</span>
                                <br>
                                <span><img class="img-fluid" width="20"
                                           style="padding: 0; margin-right: 5px; margin-top: -5px;"
                                           src="https://iconsplace.com/wp-content/uploads/_icons/ffe500/256/png/rating-star-icon-19-256.png"></span>
                                <span>{{ ride.riderRating }}</span>
                                <br>
                                <br>
                            </div>
                            <div class="col-12">
                                <span class="text-bold">Vehicle Model</span>
                                <br>
                                <span>{{ ride.vehicle.model_name }}</span>
                                <br>
                            </div>

                            <!--                            <div class="col-12">-->
                            <!--                                <span class="text-bold">Number</span>-->
                            <!--                                <br>-->
                            <!--                                <span>{{ ride.vehicle.registered_number }}</span>-->
                            <!--                            </div>-->

                        </div>


                    </div>
                </div>

                <div class="row">
                    <!--                    <div class="col-6">-->
                    <!--                        <br>-->
                    <!--                        <span class="text-bold">Rider Rating</span>-->
                    <!--                        <br>-->
                    <!--                        <span><img class="img-fluid" width="20"-->
                    <!--                                   style="padding: 0; margin-right: 5px; margin-top: -5px;"-->
                    <!--                                   src="https://iconsplace.com/wp-content/uploads/_icons/ffe500/256/png/rating-star-icon-19-256.png"></span>-->
                    <!--                        <span>{{ ride.riderRating }}</span>-->
                    <!--                    </div>-->

                    <!--                    <div class="col-6" style="text-align: right;">-->
                    <!--                        <br>-->
                    <!--                        <a :href="riderNumber" target="_blank">-->
                    <!--                            <img width="35" class="img-fluid" :src="callBtn">-->
                    <!--                        </a>-->
                    <!--                    </div>-->
                </div>

                <hr>

                <div class="row">
                    <div class="col-10">
                        <span class="text-bold">From Location</span>
                        <br>
                        <span>{{ ride.from_location }}</span>
                    </div>
                    <div class="col-2" style="text-align: right">
                        <a :href="fromLocationPoint" target="_blank">
                            <img width="30" class="img-fluid" :src="navIcon">
                        </a>
                    </div>
                </div>

                <br>

                <div class="row">
                    <div class="col-10">
                        <span class="text-bold">To Location</span>
                        <br>
                        <span>{{ ride.to_location }}</span>
                    </div>
                    <div class="col-2" style="text-align: right">
                        <a :href="toLocationPoint" target="_blank">
                            <img width="30" class="img-fluid" :src="navIcon">
                        </a>
                    </div>
                </div>

                <hr>

                <div class="row">
                    <div class="col-6">
                        <span class="text-bold">Per Km Charges</span>
                    </div>
                    <div class="col-6" style="text-align: right;">
                        <span>₹{{ rideFair.perKmCharges }}/-</span>
                    </div>

                </div>

                <br>

                <div class="row">
                    <div class="col-6">
                        <span class="text-bold">Distance</span>
                    </div>
                    <div class="col-6" style="text-align: right;">
                        <span>{{ ride.distance }}</span>
                    </div>

                </div>

                <br>

                <div class="row">
                    <div class="col-6">
                        <span class="text-bold">Convenience Fees</span>
                    </div>
                    <div class="col-6" style="text-align: right;">
                        <span>₹{{ rideFair.convFees }}/-</span>
                    </div>

                </div>

                <br>

                <div class="row">
                    <div class="col-6">
                        <h4 class="text-bold">You Pay</h4>
                    </div>
                    <div class="col-6" style="text-align: right;">
                        <h4>₹{{ rideFair.total }}/-</h4>
                    </div>
                    <span class="font-italic">* Terms and Conditions apply</span>
                </div>

                <br>

                <div class="row">

                    <div class="col-4">
                        <soft-button :disabled="isLoading" @click="rejectRideRequest" full-width color="danger">Reject
                        </soft-button>
                    </div>

                    <div class="col-8">
                        <soft-button :disabled="rideFair.isLoading" @click="makePaymentRequest" full-width
                                     color="success">
                            Accept & Pay
                        </soft-button>
                    </div>
                </div>

                <br>
            </div>

            <!--            This will be shown to CUSTOMER once the payment is done and ride is confirmed-->
            <div v-if="notificationType==='PARCEL_BOOKING_CONFIRMED_CUSTOMER' && isCustomer">

                <div class="row">
                    <div class="col-12">
                        <h5>Parcel No. (#{{ ride.rideNumber }})</h5>
                    </div>
                </div>

                <br>

                <div class="row">
                    <div class="col-6">
                        <span style="position:relative; z-index: 1;">

                            <img class="img-fluid card"
                                 :src="ride.riderImage">

                            <img class="img-fluid"
                                 style="position:absolute; top: -10px; left: -10px; height: 35px; width: 35px;"
                                 src="https://www.pngkey.com/png/full/624-6246436_verified-account-icon-selo-verificao-instagram-png.png">
                            </span>
                    </div>
                    <div class="col-6">
                        <div class="row">

                            <div class="col-12">
                                <label>Rider Name</label>
                                <p>{{ ride.riderName }}</p>
                            </div>

                            <div class="col-12">
                                <label>Vehicle Model</label>
                                <p>{{ ride.vehicle.model_name }}</p>
                            </div>

                            <div class="col-12">
                                <label>Rider Rating</label>
                                <br>
                                <span><img class="img-fluid" width="20"
                                           style="padding: 0; margin-right: 5px; margin-top: -5px;"
                                           src="https://iconsplace.com/wp-content/uploads/_icons/ffe500/256/png/rating-star-icon-19-256.png"></span>
                                <span>{{ ride.riderRating }}</span>
                            </div>

                            <div class="col-12">
                                <label>Payment Made</label>
                                <h5>{{ stringToINR(ride.customerPaid) }}</h5>
                            </div>

                        </div>


                    </div>
                </div>

                <hr>

                <div class="row">
                    <div class="col-12">
                        <label>From</label>
                        <p>{{ ride.from_location }}</p>
                    </div>
                </div>

                <br>

                <div class="row">
                    <div class="col-12">
                        <label>To</label>
                        <p>{{ ride.to_location }}</p>
                    </div>
                </div>

                <br>

                <div class="row">
                    <div class="col-12">
                        <label>Vehicle Photos</label>
                        <br>
                        <br>
                    </div>
                    <div class="col">
                        <img class="img-fluid" :src="ride.vehicle.front_image"/>
                    </div>
                    <div class="col">
                        <img class="img-fluid" :src="ride.vehicle.back_image"/>
                    </div>
                    <div class="col">
                        <img class="img-fluid" :src="ride.vehicle.right_image"/>
                    </div>
                    <div class="col">
                        <img class="img-fluid" :src="ride.vehicle.left_image"/>
                    </div>
                </div>

                <br>

                <div class="row">
                    <div class="col-12">
                        <label>Vehicle Number</label>
                        <p>{{ ride.vehicle.registered_number }}</p>
                    </div>
                </div>

                <br>

                <div class="row">
                    <div class="col-10">
                        <label>Rider Contact</label>
                        <p>{{ ride.riderContact }}</p>
                    </div>
                    <div class="col-2" style="text-align: right">
                        <a :href="riderNumber" target="_blank">
                            <img width="30" class="img-fluid" :src="callBtn">
                        </a>
                    </div>
                </div>

                <br>

                <div class="row">
                    <div v-if="!ride.onGoing" class="col-12">
                        <span class="text-bold text-2xl">Parcel Pickup PIN</span>
                        <br>
                        <span class="text-bold"
                              style="border-radius:10px; text-align: center; font-size: 28px; letter-spacing: 10px; background-color: black; color: white; padding: 5px;">{{
                                ride.ridePin
                            }}</span>
                        <br>
                        <span class="font-italic">* Parcel Pick-up PIN. Share this PIN when rider comes to pick-up you parcel</span>
                    </div>
                    <div v-else>
                        <h5>Parcel is ongoing...</h5>
                        <br>
                        <span class="text-bold text-2xl">Parcel Delivery PIN</span>
                        <br>
                        <span class="text-bold"
                              style="border-radius:10px; text-align: center; font-size: 28px; letter-spacing: 10px; background-color: black; color: white; padding: 5px;">{{
                                ride.deliveryPin
                            }}</span>
                        <br>
                        <span class="font-italic">* Parcel Delivery PIN. Share this PIN after rider delivers your parcel</span>
                    </div>
                </div>

                <br>


            </div>

            <!--            This will be shown to RIDER once the payment is done by customer-->
            <div v-if="notificationType==='PARCEL_BOOKING_CONFIRMED_RIDER' && isRider">

                <div class="row">
                    <div class="col-12">
                        <h5>Parcel No. (#{{ ride.rideNumber }})</h5>
                    </div>
                </div>

                <br>

                <div class="row">
                    <div class="col-6">
                        <span style="position:relative; z-index: 1;">

                            <img class="img-fluid card"
                                 :src="ride.customerImage">

                            <img class="img-fluid"
                                 style="position:absolute; top: -10px; left: -10px; height: 35px; width: 35px;"
                                 src="https://www.pngkey.com/png/full/624-6246436_verified-account-icon-selo-verificao-instagram-png.png">
                            </span>
                    </div>
                    <div class="col-6">
                        <div class="row">
                            <div class="col-12">
                                <label>Customer Name</label>
                                <p>{{ ride.customer_name }}</p>
                            </div>
                            <div class="col-12">
                                <label>No. of Passengers</label>
                                <p>{{ ride.noOfPassengers }}</p>
                            </div>
                            <div class="col-12">
                                <label>Customer Rating</label>
                                <br>
                                <span><img class="img-fluid" width="20"
                                           style="padding: 0; margin-right: 5px; margin-top: -5px;"
                                           src="https://iconsplace.com/wp-content/uploads/_icons/ffe500/256/png/rating-star-icon-19-256.png"></span>
                                <span>{{ ride.riderRating }}</span>
                            </div>
                            <div class="col-12">
                                <label>Payment Received</label>
                                <h5>{{ stringToINR(ride.riderEarned) }}</h5>
                            </div>
                        </div>
                    </div>
                </div>

                <hr>

                <div class="row">
                    <div class="col-10">
                        <label>Pickup Location</label>
                        <p>{{ ride.from_location }}</p>
                    </div>
                    <div class="col-2" style="text-align: right">
                        <a :href="fromLocationPoint" target="_blank">
                            <img width="30" class="img-fluid" :src="navIcon">
                        </a>
                    </div>
                </div>

                <br>

                <div class="row">
                    <div class="col-10">
                        <label>Drop Location</label>
                        <p>{{ ride.to_location }}</p>
                    </div>
                    <div class="col-2" style="text-align: right">
                        <a :href="toLocationPoint" target="_blank">
                            <img width="30" class="img-fluid" :src="navIcon">
                        </a>
                    </div>
                </div>

                <br>

                <div class="row">
                    <div class="col-10">
                        <label>Customer Contact</label>
                        <p>{{ ride.customerContact }}</p>
                    </div>
                    <div class="col-2" style="text-align: right">
                        <br>
                        <a :href="customerNumber" target="_blank">
                            <img width="30" class="img-fluid" :src="callBtn">
                        </a>
                    </div>
                </div>

                <br>


                <div class="row">
                    <div v-if="!ride.onGoing" class="col-12">
                        <soft-button @click="startRide" full-width color="success">
                            Start Ride & Pickup Parcel
                        </soft-button>
                    </div>

                    <div v-else class="col-12">
                        <soft-button @click="endRide" full-width color="danger">
                            End Ride & Deliver Parcel
                        </soft-button>
                    </div>

                    <br>
                    <br>
                    <br>

                </div>


            </div>


        </div>

        <div class="container-fluid" v-if="ride.ended && isRider">

            <br>

            <div class="row">
                <div class="col-12">
                    <h5>Parcel No. (#{{ ride.rideNumber }})</h5>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <label>Customer Name</label>
                    <p>{{ ride.customer_name }}</p>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <label>From Location</label>
                    <p>{{ ride.from_location }}</p>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <label>To Location</label>
                    <p>{{ ride.to_location }}</p>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <label>{{
                            givenCustomerRating != null ? `You provided ${givenCustomerRating} star rating` : 'Please provide your rating'
                        }}</label>
                    <star-rating star-size="30" text-class="star-rating-text" rounded-corners
                                 v-model:rating="rating"></star-rating>
                    <br>
                </div>
            </div>

            <template v-if="!isRatingSubmitted && givenCustomerRating==null">

                <div class="row">
                    <div class="col-12">
                        <soft-button @click="submitRating" full-width color="dark">
                            Submit Rating
                        </soft-button>
                    </div>
                </div>

            </template>

        </div>

        <div class="container-fluid" v-if="ride.ended && isCustomer">

            <br>

            <div class="row">
                <div class="col-12">
                    <h5>Parcel No. (#{{ ride.rideNumber }})</h5>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <label>Rider Name</label>
                    <p>{{ ride.riderName }}</p>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <label>From Location</label>
                    <p>{{ ride.from_location }}</p>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <label>To Location</label>
                    <p>{{ ride.to_location }}</p>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <label>{{
                            givenRiderRating != null ? `You provided ${givenRiderRating} star rating` : 'Please provide your rating'
                        }}</label>
                    <star-rating star-size="30" text-class="star-rating-text" rounded-corners
                                 v-model:rating="rating"></star-rating>
                    <br>
                </div>
            </div>

            <template v-if="!isRatingSubmitted && givenRiderRating==null">

                <div class="row">
                    <div class="col-12">
                        <soft-button @click="submitRating" full-width color="dark">
                            Submit Rating
                        </soft-button>
                    </div>
                </div>

            </template>

        </div>


    </div>

</template>

<script>

import callBtn from "@/assets/img/call_btn.png"
import navIcon from "@/assets/img/navigate_icon.png"
import SoftButton from "../../components/SoftButton"
import StarRating from 'vue-star-rating'

import swal from "sweetalert";

export default {
    components: {SoftButton, StarRating},
    data() {
        return {

            isCustomer: false,
            isRider: false,

            givenCustomerRating: null,
            givenRiderRating: null,

            rating: 0,
            isRatingSubmitted: false,

            isLoading: true,

            callBtn,

            navIcon,

            mobileNumber: null,

            notificationType: null,

            bookingId: null,

            customerBookingId: null,

            ride: {

                rideAlreadyAcceptedByRider: false,
                onGoing: false,
                ended: false,
                rated: false,

                customerContact: '',
                riderContact: '',

                distance: '',
                distanceValue: 0,
                duration: '',
                id: '',
                rideNumber: '',
                customer_name: '',
                riderName: '',
                from_location: '',
                to_location: '',
                date: '',
                time: '',
                fair: '',
                from_lat: '',
                from_lng: '',
                to_lat: '',
                to_lng: '',
                fairPerKm: '',

                is_material: 0,
                is_document: 0,

                parcelDetails: null,

                riderEarned: 0,
                customerPaid: 0,

                noOfPassengers: 0,

                riderRating: '4.2',

                personImage: 'https://www.ccd.edu/sites/default/files/PhotoAvatar.jpg',

                riderImage: 'https://www.ccd.edu/sites/default/files/PhotoAvatar.jpg',
                customerImage: 'https://www.ccd.edu/sites/default/files/PhotoAvatar.jpg',

                ridePin: '',
                deliveryPin: '',

                status: 0,

                vehicle: {
                    registered_number: '',
                    model_name: '',
                    owner_name: '',
                    front_image: '',
                    back_image: '',
                    right_image: '',
                    left_image: '',
                },


            },

            payee: {
                name: '',
                email: '',
            },

            rideFair: {
                isLoading: true,
                fair: '0.0',
                perKmCharges: '0.0',
                convFees: '0.0',
                total: '0.0'
            }

        }
    },
    methods: {

        goBack() {
            if (window.history.length > 2) {
                this.$router.go(-1)
            } else {
                window.ReactNativeWebView.postMessage("close-screen")
            }
        },

        getParcelBookingDetails() {

            const vm = this

            vm.isLoading = true

            window.axios.get('/parcel/get/booking/details/' + vm.bookingId)
                .then(resp => {
                    if (resp.data.response.status.error_code === 0) {

                        vm.isLoading = false

                        const bookingDetails = resp.data.response.data.booking_details

                        vm.ride.is_material = bookingDetails.is_material
                        vm.ride.is_document = bookingDetails.is_document
                        vm.ride.parcelDetails = JSON.parse(bookingDetails.material_details_json)

                        vm.ride.customerContact = bookingDetails.user.mobile_number
                        vm.ride.riderContact = bookingDetails.ride.rider.mobile_number

                        /*
                            public const STATUS_BOOKING_REQUESTED = 0;
                            public const STATUS_BOOKING_ACCEPTED = 1;
                            public const STATUS_BOOKING_CONFIRMED = 2;
                            public const STATUS_PARCEL_STARTED = 3;
                            public const STATUS_PARCEL_ENDED = 4;
                            public const STATUS_PARCEL_RATED = 5;
                        */
                        vm.ride.status = bookingDetails.status

                        //check if customer or rider
                        if (vm.mobileNumber == vm.ride.customerContact) {
                            vm.isCustomer = true
                            vm.isRider = false
                            //Customer
                            if (vm.ride.status === 1) {
                                vm.notificationType = 'PARCEL_BOOKING_ACCEPTED'
                            } else if (vm.ride.status === 2 || vm.ride.status === 3) {
                                vm.notificationType = 'PARCEL_BOOKING_CONFIRMED_CUSTOMER'
                            }


                        } else if (vm.mobileNumber == vm.ride.riderContact) {

                            vm.isRider = true
                            vm.isCustomer = false
                            //Rider
                            if (vm.ride.status === 0) {
                                vm.notificationType = 'PARCEL_BOOKING_REQUEST'
                            } else if (vm.ride.status === 1) {
                                vm.rideAlreadyAcceptedByRider = true
                            } else if (vm.ride.status === 2 || vm.ride.status === 3) {

                                vm.notificationType = 'PARCEL_BOOKING_CONFIRMED_RIDER'
                            }
                        }

                        if (bookingDetails.status === 3) {
                            vm.ride.onGoing = true
                        }

                        if (bookingDetails.status === 4) {
                            vm.ride.ended = true
                        }

                        if (bookingDetails.status === 5) {
                            vm.ride.ended = true
                            vm.ride.rated = true

                            if (vm.isRider) {
                                vm.givenCustomerRating = bookingDetails.customer_rating
                                if (vm.givenCustomerRating != null) {
                                    vm.rating = vm.givenCustomerRating
                                }
                            }

                            if (vm.isCustomer) {
                                vm.givenRiderRating = bookingDetails.rider_rating
                                if (vm.givenRiderRating != null) {
                                    vm.rating = vm.givenRiderRating
                                }
                            }

                        }

                        vm.customerBookingId = vm.bookingId

                        vm.ride.rideNumber = bookingDetails.ride.ride_number

                        vm.ride.customer_name = bookingDetails.user.first_name + " " + bookingDetails.user.last_name
                        vm.ride.noOfPassengers = bookingDetails.no_of_passengers

                        vm.payee.name = bookingDetails.user.first_name + ' ' + bookingDetails.user.last_name
                        vm.payee.email = bookingDetails.user.email

                        vm.ride.date = bookingDetails.ride.date ? vm.convertDate(bookingDetails.ride.date) : 'Daily'
                        vm.ride.time = vm.convertTime(bookingDetails.ride.time)

                        vm.ride.from_location = bookingDetails.from
                        vm.ride.to_location = bookingDetails.to

                        vm.rideFair.perKmCharges = bookingDetails.ride.fair_per_km

                        vm.ride.distance = bookingDetails.distance

                        vm.ride.from_lat = bookingDetails.from_lat
                        vm.ride.from_lng = bookingDetails.from_lng

                        vm.ride.to_lat = bookingDetails.to_lat
                        vm.ride.to_lng = bookingDetails.to_lng

                        vm.ride.riderName = bookingDetails.ride.rider.first_name + " " + bookingDetails.ride.rider.last_name
                        vm.ride.vehicle.model_name = bookingDetails.ride.vehicle.model_name
                        vm.ride.vehicle.registered_number = bookingDetails.ride.vehicle.registered_number

                        vm.ride.riderEarned = (bookingDetails.service_provider_amount / 100)

                        vm.ride.customerPaid = (bookingDetails.customer_amount / 100)

                        vm.ride.ridePin = bookingDetails.ride_pin
                        vm.ride.deliveryPin = bookingDetails.delivery_pin

                        vm.ride.riderImage = bookingDetails.ride.rider.personal_picture_image_url ? bookingDetails.ride.rider.personal_picture_image_url : "https://www.ccd.edu/sites/default/files/PhotoAvatar.jpg"

                        vm.ride.customerImage = bookingDetails.user.personal_picture_image_url ? bookingDetails.user.personal_picture_image_url : "https://www.ccd.edu/sites/default/files/PhotoAvatar.jpg"

                        vm.ride.vehicle.front_image = bookingDetails.ride.vehicle.front_image
                        vm.ride.vehicle.back_image = bookingDetails.ride.vehicle.back_image
                        vm.ride.vehicle.left_image = bookingDetails.ride.vehicle.left_image
                        vm.ride.vehicle.right_image = bookingDetails.ride.vehicle.right_image

                        if (vm.notificationType === 'PARCEL_BOOKING_ACCEPTED') {
                            vm.getRideFairDetailsForCustomer()
                        } else if (vm.notificationType === 'PARCEL_BOOKING_REQUEST') {
                            vm.getRideFairDetailsForServiceProvider()
                        }

                    }
                })
                .catch(error => {
                    vm.isLoading = false
                    console.log(error)
                })

        },

        acceptRideRequest() {
            const vm = this
            vm.isLoading = true
            window.axios.post('/parcel/accept/booking/' + vm.bookingId, {
                mobile_number: vm.mobileNumber,
            }).then(response => {
                if (response.data.response.status.error_code === 0) {
                    vm.goBack()
                }
            }).catch(e => console.log(e))
        },

        makePaymentRequest() {
            const vm = this
            vm.rideFair.isLoading = true
            window.axios.post('/payment/create/parcel/order/' + vm.customerBookingId, {
                contact: vm.mobileNumber,
                requestId: vm.customerBookingId,
                requestType: 'parcel',
            }).then(response => {
                if (response.data.response.status.error_code === 0) {

                    let url = response.data.response.data.data.instrumentResponse.redirectInfo.url

                    this.$router.push({
                        name: "phonepe-payment-checkout", params: {
                            webCheckoutURL: url,
                            typeOfService: 'parcel',
                            name: vm.payee.name,
                            email: vm.payee.email,
                            mobileNumber: vm.mobileNumber,
                            description: 'Parcel Booking',
                            bookingId: vm.customerBookingId
                        }
                    })

                    //OLD CODE
                    // let orderId = response.data.response.data.id
                    // let amount = response.data.response.data.amount
                    // this.$router.push({
                    //     name: "payment-checkout", params: {
                    //         orderId: orderId,
                    //         amount: amount,
                    //         typeOfService: 'parcel',
                    //         name: vm.payee.name,
                    //         email: vm.payee.email,
                    //         mobileNumber: vm.mobileNumber,
                    //         description: 'Parcel Booking',
                    //         bookingId: vm.customerBookingId
                    //     }
                    // })
                }
            }).catch(e => console.log(e))
        },

        startRide() {
            const vm = this
            swal("Enter Parcel Pickup PIN", {
                content: "input",
            })
                .then((value) => {
                    window.axios.post('/parcel/start/' + vm.bookingId, {
                        ride_pin: value
                    }).then(resp => {
                        if (resp.data.response.status.error_code === 0) {
                            vm.ride.onGoing = true
                            vm.getRideBookingDetails()
                            vm.$toast.open({
                                message: resp.data.response.status.msg,
                                type: 'success',
                                position: 'top',
                                duration: 5000,
                                dismissible: false,
                            })
                        } else {
                            vm.$toast.open({
                                message: resp.data.response.status.error_msg,
                                type: 'error',
                                position: 'top',
                                duration: 2000,
                                dismissible: false,
                            })
                        }
                    })
                });
        },

        endRide() {
            const vm = this

            swal("Enter Parcel Delivery PIN", {
                content: "input",
            })
                .then((value) => {
                    window.axios.post('/parcel/end/' + vm.bookingId,{
                        delivery_pin: value
                    })
                        .then(resp => {
                            if (resp.data.response.status.error_code === 0) {
                                vm.ride.onGoing = false
                                vm.ride.ended = true
                                vm.getRideBookingDetails()
                                vm.$toast.open({
                                    message: resp.data.response.status.msg,
                                    type: 'success',
                                    position: 'top',
                                    duration: 5000,
                                    dismissible: false,
                                })
                            } else {
                                vm.$toast.open({
                                    message: resp.data.response.status.error_msg,
                                    type: 'error',
                                    position: 'top',
                                    duration: 2000,
                                    dismissible: false,
                                })
                            }
                        })
                });


        },

        submitRating() {
            const vm = this

            if (vm.rating === 0) {
                return
            }

            let entity = ''
            if (vm.isRider) {
                entity = 'customer'
            }
            if (vm.isCustomer) {
                entity = 'rider'
            }
            window.axios.put(`/parcel/rate/${entity}/${vm.bookingId}`, {
                rating: vm.rating
            })
                .then(resp => {
                    if (resp.data.response.status.error_code === 0) {
                        vm.isRatingSubmitted = true
                        vm.$toast.open({
                            message: resp.data.response.status.msg,
                            type: 'success',
                            position: 'top',
                            duration: 5000,
                            dismissible: false,
                        })
                    }
                }).catch(e => console.log(e))
        },

        rejectRideRequest() {
            alert('parcel request rejected')
            window.ReactNativeWebView.postMessage("close-screen")
        },

        getRideFairDetailsForCustomer() {
            const vm = this
            window.axios.get('parcel/calculate/fare/customer/' + vm.customerBookingId)
                .then(resp => {
                    if (resp.data.response.status.error_code === 0) {
                        vm.isLoading = false
                        vm.rideFair.isLoading = false
                        vm.rideFair.fair = resp.data.response.data.fair_details.fair
                        vm.rideFair.perKmCharges = resp.data.response.data.fair_details.fair_per_km
                        vm.rideFair.convFees = resp.data.response.data.fair_details.conv_fees
                        vm.rideFair.total = resp.data.response.data.fair_details.total_fair
                    }
                }).catch(e => console.log(e))
        },

        getRideFairDetailsForServiceProvider() {
            const vm = this
            window.axios.get('parcel/calculate/fare/service/provider/' + vm.customerBookingId)
                .then(resp => {
                    if (resp.data.response.status.error_code === 0) {
                        vm.rideFair.isLoading = false
                        vm.rideFair.fair = resp.data.response.data.fair_details.fair
                        vm.rideFair.perKmCharges = resp.data.response.data.fair_details.fair_per_km
                        vm.rideFair.convFees = resp.data.response.data.fair_details.conv_fees
                        vm.rideFair.total = resp.data.response.data.fair_details.total_fair
                    }
                }).catch(e => console.log(e))
        },

        viewMaterialDetails() {
            const vm = this

            vm.$store.dispatch('setParcelDetails', vm.ride.parcelDetails)

            vm.$router.push({
                name: "add-parcel-details", params: {
                    parcel_id: 'read-only',
                    from: vm.$props.from,
                    to: vm.$props.to,
                    from_lat_lng: vm.$props.from_lat_lng,
                    to_lat_lng: vm.$props.to_lat_lng,
                }
            })
        }

    },
    mounted() {

        if (this.notificationType === '') {
            window.ReactNativeWebView.postMessage("close-screen")
        }

    },
    computed: {
        getTitle() {
            if (this.notificationType === 'PARCEL_BOOKING_REQUEST') {
                return 'Parcel Booking'
            } else if (this.notificationType === 'PARCEL_BOOKING_ACCEPTED') {
                return 'Parcel Accepted'
            } else if (this.notificationType === 'PARCEL_BOOKING_REJECTED') {
                return 'Parcel Rejected'
            } else if (this.notificationType === 'PARCEL_BOOKING_CONFIRMED_CUSTOMER' || this.notificationType === 'PARCEL_BOOKING_CONFIRMED_RIDER') {
                return 'Parcel Confirmed'
            }
            return 'Notification'
        },
        toLocationPoint() {
            return 'geo:' + this.ride.to_lat + ',' + this.ride.to_lng
        },
        fromLocationPoint() {
            return 'geo:' + this.ride.from_lat + ',' + this.ride.from_lng
        },
        riderNumber() {
            return 'tel:' + this.ride.riderContact
        },
        customerNumber() {
            return 'tel:' + this.ride.customerContact
        }
    },
    created() {
        let vm = this
        vm.mobileNumber = vm.$route.params.mobileNumber
        vm.notificationType = vm.$route.params.notificationType
        vm.bookingId = vm.$route.params.bookingId

        vm.getParcelBookingDetails()

    }

}

</script>

<style>
.star-rating-text {
    color: transparent;
}
</style>

<style scoped>

label {
    margin: 0;
    padding: 0;
}

</style>
