<template>
    <div>
        <Header></Header>
        <div class="page-wrap have-sticky-header">
            <div class="container">
                <h3 class="page-titele">Provide Services</h3>
                <div class="row">
                    <div class="col-4 card-col">
                        <router-link :to="{name: 'ride', params: { mobileNumber }}" class="card">
                            <div class="card-body">
                                <img :src="car" width="60" height="60" />
                                Ride
                            </div>
                        </router-link>
                    </div>
                    <div class="col-4 card-col">
                        <router-link :to="{name: 'package', params: { mobileNumber }}" class="card">                            
                            <div class="card-body">
                                <img :src="parcel" width="60" height="60" />
                                Parcels
                            </div>
                        </router-link>
                    </div>
                    <div class="col-4 card-col">
                        <router-link :to="{name: 'tours', params: { mobileNumber }}" class="card">
                            <div class="card-body">
                                <img :src="tours" width="60" height="60" />
                                Tours
                            </div>
                        </router-link>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 card-col">
                        <router-link :to="{name: 'travels', params: { mobileNumber }}" class="card">
                            <div class="card-body">
                                <img :src="taxi" width="60" height="60" />
                                Travels
                            </div>
                        </router-link>
                    </div>
                    <div class="col-6 card-col">
                        <router-link :to="{name: 'transport', params: { mobileNumber }}" class="card">
                            <div class="card-body">
                                <img :src="truck" width="60" height="60" />
                                Transport & Courier
                            </div>
                        </router-link>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 card-col">
                        <router-link :to="{name: 'spv', params: { mobileNumber }}" class="card">
                            <div class="card-body">
                                <img :src="rent_vehicles" width="100" height="100" />
                                <span>
                                    Rent Vehicles
                                    <small>Book or Publish Special Purpose Vehicles (SPV)</small>
                                </span>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import 'vue3-carousel/dist/carousel.css';
import { mapGetters } from "vuex";
import car from '../assets/img/services/car.png';
import parcel from '../assets/img/services/parcel.png';
import rent_vehicles from '../assets/img/services/rent_vehicles.png';
import taxi from '../assets/img/services/taxi.png';
import tours from '../assets/img/services/tours.png';
import truck from '../assets/img/services/truck.png';
import Header from '../examples/PageLayout/Header.vue';

export default {

    data() {
        return {
            car,
            parcel,
            tours,
            taxi,
            truck,
            rent_vehicles,
            mobileNumber: this.getUserMobileNumber()
        }
    },

    components: {
        Header
    },


    methods: {
        ...mapGetters(['getUserMobileNumber']),
    },

    created() { }
}

</script>

<style lang="scss" scoped>
.page-wrap{
    padding: 10px 0;
    .page-titele{
        font-size: 1.2rem;
        font-weight:700;
        border-bottom: 2px solid var(--bs-gray-900);
        color: var(--bs-gray-900);
        padding-bottom: 0.6rem;
        margin-bottom: 1rem
    }
    .container{
        padding: 0 1rem;
        .row{
            margin: 0;
            gap: 14px;
        
            .card-col{
                padding: 0;
                margin-bottom: 14px;
                &.col-4{
                    width: calc(33.333% - 10px);
                }
                &.col-6{
                    width: calc(50% - 10px);
                }
                &.col-12{
                    .card-body{
                        flex-flow: row;
                        img{
                            max-width: 80px;
                            margin-bottom: 0;
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }
    .card{
        background-color: #26143E;
        height: 100%;
        padding: 1.5rem;
        .card-body{
            color: var(--bs-white);
            padding: 0;
            display: flex;
            flex-flow: column;
            justify-content:center;
            align-items: center;
            text-align: center;
            img{
                filter: invert(1);
                max-width: 50px;
                height: auto;
                margin-bottom: 10px;
            }
            span{
                text-align: left;
                padding-left: 12px;
                font-weight: 700;
                small{
                    font-weight: 400;
                    display: flex;
                    color: var(--bs-gray-500);
                }
            }
        }
    }
}
</style>
