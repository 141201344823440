<template>
    <div style="overflow-x: clip;">
        <div class="row sticky-top bg-gray-100 shadow" style="padding-top: 10px;">
            <div class="col-sm-12">
                <h3>
                    <button style="border: none; background: none;" @click="goBack"><i
                        class="p-2 fas fa-arrow-left"></i></button>
                    Select Stop Point
                </h3>
            </div>
        </div>

        <GoogleMap
            @click="placeMarker"
            ref="mapRef"
            api-key="AIzaSyDk4e0NV-K0_uVSlCqJNtCnKHP5qeYWzLY"
            style="position:fixed; top:0; bottom:0; right:0; left:0;"
            :center="center"
            :zoom="15">
            <Marker :options="markerPosition"/>
        </GoogleMap>

        <div class="row">
            <div class="col-12">
                <vue-google-autocomplete
                    ref="googleAutocomplete"
                    :disabled="isFetchingLocation"
                    enable-geolocation
                    types=""
                    style="position:fixed; top:80px; right: 30px; left: 30px; padding: 5px; border-radius: 10px;"
                    id="map-to"
                    country="in"
                    :placeholder="getPlaceholderText"
                    v-on:placechanged="getAddressData">
                </vue-google-autocomplete>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <soft-button style="position:fixed; bottom:25px; right: 60px; left: 60px;" size="lg" color="dark"
                             :disabled="isFetchingLocation"
                             @click="confirmLocation">
                    Select
                </soft-button>
            </div>
        </div>
    </div>
</template>

<script>
import {ref, watch} from 'vue'
import {GoogleMap, Marker} from 'vue3-google-map'
import SoftButton from "../components/SoftButton";
import VueGoogleAutocomplete from "vue-google-autocomplete"


export default {
    components: {
        SoftButton,
        GoogleMap,
        Marker,
        VueGoogleAutocomplete,
    },

    props: {

        stopPlaceIndex: {
            required: true,
            type: Number,
        }

    },

    data() {
        return {
            center: {lat: 23.0208366, lng: 72.5086508},
            markerPosition: null,
            autocompleteText: '',
            pinCode: '',
            city: '',
            locality: '',
            isFetchingLocation: false,
            address: null
        }
    },

    created() {
        let vm = this
        vm.isFetchingLocation = true
        navigator.geolocation.getCurrentPosition(
            position => {
                vm.isFetchingLocation = false
                console.log(position.coords.latitude);
                console.log(position.coords.longitude);
                vm.center = {lat: position.coords.latitude, lng: position.coords.longitude}
                vm.markerPosition = {
                    position: {lat: position.coords.latitude, lng: position.coords.longitude},
                }
                vm.reverseGeoencode()
            },
            error => {
                vm.isFetchingLocation = false
                console.log(error.message);
            },
        )
    },
    methods: {
        confirmLocation() {
            const vm = this
            const data = {
                stopPlaceIndex: vm.$props.stopPlaceIndex,
                address: vm.autocompleteText,
                latLng: vm.markerPosition.position.lat + ',' + vm.markerPosition.position.lng,
                pinCode: vm.pinCode,
                city: vm.city,
                locality: vm.locality,
            };

            this.$emit('location-picked', data)

            //console.log(data)
        },
        reverseGeoencode() {
            const vm = this
            vm.isFetchingLocation = true
            window.axios.get('/google/reverse/geoencode', {
                params: {
                    lat_lng: vm.markerPosition.position.lat + ',' + vm.markerPosition.position.lng
                }
            }).then(resp => {
                vm.isFetchingLocation = false
                vm.$refs.googleAutocomplete.autocompleteText = ''
                vm.autocompleteText = resp.data.response.data.formatted_address
                vm.city = resp.data.response.data.city
                vm.state = resp.data.response.data.state
                vm.locality = resp.data.response.data.locality
                vm.pinCode = resp.data.response.data.pin_code
            }).catch(error => {
                vm.isFetchingLocation = false
                console.log(error)
            })
        },
        getAddressData: function (addressData) {
            this.address = addressData;
            console.log(addressData)

            this.city = addressData.administrative_area_level_2
            this.locality = addressData.locality
            this.pinCode = addressData.postal_code

            this.center = {lat: addressData.latitude, lng: addressData.longitude}
        },
        placeMarker(e) {
            let vm = this
            if (vm.isFetchingLocation) {
                return
            }
            console.log(e.latLng.lat())
            vm.center.lat = e.latLng.lat()
            vm.center.lng = e.latLng.lng()
            vm.markerPosition = {
                position: {lat: e.latLng.lat(), lng: e.latLng.lng()},
            }
            vm.reverseGeoencode()
        },
        goBack() {
            this.$emit('close-picker')
        }
    },
    setup() {
        const mapRef = ref(null)

        watch(() => mapRef.value?.ready, (ready) => {


            if (!ready) return

        })

        return {mapRef}
    },
    computed: {
        getPlaceholderText() {
            if (this.isFetchingLocation) {
                return 'Please wait, fetching location...'
            } else if (this.autocompleteText != '') {
                return this.autocompleteText
            } else {
                return 'Type location name here...'
            }
        },
    }
}
</script>
