<template>
    <div class="sign-in pt-5" style="overflow-x: clip;">
        <div class="row">
            <div class="col-sm-12 ms-2">
                <h3 class="d-flex align-items-center text-white">
                    <img height="45" width="45" :src="logo">
                    TRANSALL
                </h3>
            </div>
        </div>
        <div v-if="!show.otpCard" class="text-end mt-4">
            <img class="group-image" :src="group">
        </div>
        <div v-if="!show.otpCard" class="card phone-card auth-card mt-4">
            <div class="label-text-stlye card-header p-0 m-3 mt-4 position-relative z-index-1 text-center">
                <h4>Enter Phone Number</h4>
                <small>An OTP will be sent to this number</small>
            </div>

            <div class="card-body pt-2">
                <form @submit.prevent class="mb-6">
                    <div class="form-group">
                        <soft-input is-required v-model="form.phoneNumber" type="number" placeholder="Enter Phone Number"></soft-input>
                    </div>
                </form>
                <div id="recaptcha-container"></div>
                <soft-button v-if="!isLoading" class="btn-primary btn-lg w-100" id="send-otp" @click="sendOTP">
                    Send OTP
                </soft-button>
                
                <div class="text-center" v-if="isLoading">
                    <span class="spinner-border" role="status" aria-hidden="true"></span>
                </div>
            </div>
        </div>
        <!-- OTP Card -->
        <div v-if="show.otpCard" class="card auth-card otp-card mt-5">
            <div class="label-text-stlye card-header p-0 mx-3 mt-4 mb-5 position-relative z-index-1 text-center">
                <h4>Enter OTP</h4>
                <small>OTP sent to your mobile number</small>
            </div>

            <div class="card-body pt-2">
                <form @submit.prevent class="mb-1">
                    <div class="otp-field mb-5">
                        <input type="text" maxlength="1" />
                        <input type="text" maxlength="1" />
                        <input type="text" maxlength="1" />
                        <input type="text" maxlength="1" />
                        <input type="text" maxlength="1" />
                        <input type="text" maxlength="1" />
                    </div>
                </form>
                <!-- <div v-if="show.otpExpired || show.otpTimer.show" class="mb-8">
                    <span v-if="!show.resendLoading && show.otpExpired">OTP has been expired. <b @click="sendOTP"> Resend code</b>.</span>
                    <span v-if="!show.resendLoading && show.otpTimer.show">
                        <span>
                            Didn't receive OTP?
                            <span v-if="show.otpTimer.time">Resend in 00:{{ show.otpTimer.time }} sec</span>
                            <b v-if="!show.otpTimer.time" @click="sendOTP"> Resend</b>
                        </span>
                    </span>
                    <div class="text-center" v-if="show.resendLoading">
                        <span class="spinner-border" role="status" aria-hidden="true"></span>
                    </div>
                </div> -->
                <soft-button v-if="!isLoading" class="btn-primary btn-lg w-100" @click="submitOtp">
                    Verify
                </soft-button>
                
                <div class="text-center" v-if="isLoading">
                    <span class="spinner-border" role="status" aria-hidden="true"></span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";

import logo from '../../assets/img/TA_LOGO_2.png';
import group from '../../assets/img/group.png';

import SoftButton from "../../components/SoftButton";
import SoftInput from "../../components/SoftInput";

import authService from "../../services/auth.service";

export default {

    data() {
        return {
            logo,
            group,
            form: {
                phoneNumber: ''
            },
            otpInputs: '',
            isLoading: false,
            show: {
                otpCard: false,
                otpExpired: false,
                otpTimer: {
                    time: 30,
                    show: false
                }
            },
            user: this.getUser() 
        }
    },

    components: {
        SoftInput,
        SoftButton
    },


    methods: {
        ...mapGetters(['getUser']),
        sendOTP() {
            const vm = this

            if (!vm.form.phoneNumber) {
                vm.showToast({message: 'Enter phone number', type: 'error'});
                return;
            }

            vm.isLoading = true;

            authService.signInWithPhone(`+91${vm.form.phoneNumber}`)
            .then((response) => {
                vm.isLoading = false;
                if (response && response.verificationId) {
                    vm.showToast({message: 'OPT sent to your phone number', type: 'success'});
                    vm.$store.dispatch('setUserMobileNumber', vm.form.phoneNumber);

                    if (!vm.show.otpCard) {
                        vm.show.otpCard = true;
                        setTimeout(() => {
                            vm.setOtpInputs(); 
                            // vm.otpTimer();
                        });
                    }
                } else if (response.code === 'auth/too-many-requests') {
                    vm.showToast({message: 'Too many requests, try after sometime.', type: 'error'});
                } else {
                    vm.showToast({message: 'Something went wrong, try after sometime.', type: 'error'});
                }
            })
            .catch(() => {
                vm.isLoading = false;
                vm.showToast({message: 'Enter valid phone number', type: 'error'});
            });
        },
        handleOtp(e) {
            /**
             * <input type="text" 👉 maxlength="1" />
             * 👉 NOTE: On mobile devices `maxlength` property isn't supported,
             * So we to write our own logic to make it work. 🙂
             */
            const vm = this;
            const input = e.target;
            let value = input.value;
            let isValidInput = value.match(/[0-9]/gi);
            input.value = "";
            input.value = isValidInput ? value[0] : "";
            let fieldIndex = input.dataset.index;
            if (fieldIndex < vm.otpInputs.length - 1 && isValidInput) {
                input.nextElementSibling.focus();
            }
            if (e.key === "Backspace" && fieldIndex > 0) {
                input.previousElementSibling.focus();
            }
            if (fieldIndex == vm.otpInputs.length - 1 && isValidInput) {
                this.submitOtp();
            }
        },
        handleOnPasteOtp(e) {
            const vm = this;
            const data = e.clipboardData.getData("text");
            const value = data.split("");
            if (value.length === vm.otpInputs.length) {
                vm.otpInputs.forEach((input, index) => (input.value = value[index]));
                this.submitOtp();
            }
        },
        submitOtp() {
            const vm = this;
            let otp = "";

            vm.otpInputs.forEach((input) => {
                otp += input.value;
            });
            if (!otp) {
                vm.showToast({message: 'Enter OTP', type: 'error'});
                return;
            }

            this.verifyOTP();
        },
        async verifyOTP() {
            const vm = this
            const otp = vm.enableDisableOTPClearInput(true);

            vm.isLoading = true;

            authService.verifyOtp(otp).then((response) => {
                vm.isLoading = false;
                if (response.user) {
                    vm.$store.dispatch('setUserFirebaseId', response.user.uid);
                    vm.checkIfPhonExist()
                } else if (response.code === 'auth/invalid-verification-code') {
                    vm.show.otpExpired = true;
                    vm.showToast({message: 'Invalid OTP code', type: 'error'});
                    vm.enableDisableOTPClearInput(false);
                } else if (response.code === 'auth/code-expired') {
                    vm.show.otpExpired = true;
                    vm.showToast({message: 'OTP code is expired', type: 'error'});
                    vm.enableDisableOTPClearInput(false);
                } else {
                    vm.showToast({message: 'Please try again after sometime', type: 'error'});
                    vm.show.otpCard = false;
                }
            })
            .catch(() => {
                vm.isLoading = false; 
            });
        },
        enableDisableOTPClearInput(disabled) {
            let otp = '';
            this.otpInputs.forEach((input) => {
                otp += input.value;
                input.disabled = disabled;
                if (disabled) {
                    input.classList.add("disabled");
                } else {
                    input.value = '';
                    input.classList.remove("disabled");
                }
            });
            if (disabled) {
                return otp;
            }
        },
        setOtpInputs() {
            this.otpInputs = document.querySelectorAll(".otp-field input");
            this.otpInputs.forEach((input, index) => {
                input.dataset.index = index;
                input.addEventListener("keyup", this.handleOtp);
                input.addEventListener("paste", this.handleOnPasteOtp);
            });
        },
        showToast(toast) {
            this.$toast.open({
                message: toast.message,
                type: toast.type,
                position: 'top',
                duration: 3000,
                dismissible: true,
            });
        },
        checkIfPhonExist() {
            const vm = this;
            vm.isLoading = true;
            authService.checkPhoneExist(this.form.phoneNumber)
            .then((apiResponse) => {
                vm.isLoading = false;
                vm.showToast({message: 'OTP Verified', type: 'success'});
                if (apiResponse && [0,1].includes(apiResponse.response.status.error_code)) {
                    if (apiResponse.response.status.error_code) {
                        vm.$router.push({
                            name: 'register'
                        });
                    } else {
                        vm.login();
                    }
                } else {
                    vm.showToast({message: apiResponse.response.status.error_msg || 'Please try again after sometime', type: 'error'});
                }
            })
            .catch(() => {
                vm.isLoading = false;
                vm.showToast({message: 'Please try again after sometime', type: 'error'});
            });
        },
        login() {
            const vm = this;
            vm.isLoading = true;
            const payload = {
                mobile_number: vm.user.mobileNumber,
                uid: vm.user.fId
            };
            authService.phoneLogin(payload)
            .then((apiResponse) => {
                vm.isLoading = false;
                if (apiResponse && !apiResponse.response.status.error_code) {
                    vm.$store.dispatch('setJwtToken', apiResponse.response.data.token);
                    vm.$store.dispatch('fetchAndSetUserDetails').then(() => {
                        vm.$router.push({
                            name: 'home'
                        });
                    })
                } else {
                    vm.showToast({message: 'Please try again after sometime', type: 'error'});
                }
            })
            .catch(() => {
                vm.isLoading = false;
                vm.showToast({message: 'Please try again after sometime', type: 'error'});
            })
        },
        otpTimer() {
            const vm = this;
            vm.show.otpTimer.show = true;
            console.log("🚀 ~ otpTimer ~ vm.show.otpTimer.show:", vm.show.otpTimer.show)
            const interval = setInterval(() => {
            if (this.show.otpTimer.time > 0) {
                this.show.otpTimer.time--;
            } else {
                vm.show.otpTimer.show = true;
                clearInterval(interval);
            }
            }, 1000);
        }
    },
    mounted() {
        authService.setUpRecaptcha();
    }
}

</script>

<style lang="scss" scoped>

.sign-in {
    height: 100vh;
    background: linear-gradient(to top, #103783 0%, #9BAFD9 100%);
}

.group-image {
    width: 70%;
}
.label-text-stlye h4{
    color: #4EA692;
}
.label-text-stlye small{
    color: #495057;
}
.phone-card{
    height: calc(100% - 41%);
    min-height: 290px;
}

.otp-field {
  display: flex;
  justify-content: space-between;
}
.otp-field input {
  width: 45px;
  font-size: 32px;
  text-align: center;
  border-radius: 5px;
  margin: 2px;
  border: 2px solid #9BAFD9;
  outline: none;
  transition: all 0.1s;
}
.otp-field input:focus {
  box-shadow: 0 0 2px 2px #9BAFD9;
}
.disabled {
  opacity: 0.5;
}
</style>
