<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12" style="margin-top: 5%">
                <template v-if="transactionSuccess!=null">
                    <img v-if="transactionSuccess" alt="Payment Success" class="img-fluid" :src="successImage">
                    <img v-else alt="Payment Failed" class="img-fluid" :src="failedImage">
                </template>
            </div>
        </div>
    </div>
</template>
<script>

import successImage from '../../assets/img/payment_success.png'
import failedImage from '../../assets/img/payment_failed.png'

export default {

    data() {
        return {
            transactionSuccess: null,
            successImage,
            failedImage,
            timeOut: 5000,
            serviceType: '',
            bookingId: ''
        }
    },

    methods: {
        updatePaymentDetails() {
            const vm = this

            let url = null

            if (vm.serviceType === 'ride') {
                url = '/payment/insert/transaction/details/phonepe/' + vm.bookingId
            } else if (vm.serviceType === 'parcel') {
                url = '/payment/insert/parcel/transaction/details/' + vm.bookingId
            } else if (vm.serviceType === 'tour') {
                url = '/payment/insert/tour/transaction/details/' + vm.bookingId
            } else if (vm.serviceType === 'travels') {
                url = '/payment/insert/travels/transaction/details/' + vm.bookingId
            } else if (vm.serviceType === 'spv') {
                url = '/payment/insert/spv/transaction/details/' + vm.bookingId
            }


            window.axios.post(url, {
                type_of_service: vm.serviceType,
            }).then(resp => {

                if (resp.data.response.status.error_code === 0) {
                    vm.transactionSuccess = true
                    vm.$toast.open({
                        message: 'Payment Completed',
                        type: 'success',
                        position: 'top',
                        duration: 2000,
                        dismissible: false,
                    })

                    setTimeout(() => {
                        vm.$router.go(-2)
                    }, vm.timeOut)

                } else {
                    vm.transactionSuccess = false
                    vm.$toast.open({
                        message: 'Something went wrong',
                        type: 'error',
                        position: 'top',
                        duration: 5000,
                        dismissible: false,
                    })
                }

            }).catch(e => console.log(e))


        }
    },

    created() {

        const vm = this

        vm.serviceType = vm.$route.params.serviceType
        vm.bookingId = vm.$route.params.bookingId

        vm.updatePaymentDetails()

    }

}

</script>
