<template>

    <div style="overflow-x: clip;">
        <div class="row sticky-top bg-gray-100 shadow" style="padding-top: 10px;">
            <div class="col-sm-12">
                <h2>
                    <button style="border: none; background: none;" @click="goBack"><i
                        class="p-2 fas fa-arrow-left"></i></button>
                    Search Results
                </h2>
            </div>
        </div>

        <Vue3Lottie v-if="searchingParcels" :animationData="animationJson" style="position: fixed; bottom: 0;"></Vue3Lottie>

        <div class="container-fluid">
            <div class="row">
                <div v-if="list.parcels.length===0" class="col-12 text-center" style="margin-top: 20%;">
                    <h4>{{ getLabel }}</h4>
                    <div v-if="getLabel==='No parcels found near you...'">
                        <soft-button color="dark" @click="publishThisParcel">Request Parcel Quote</soft-button>
                    </div>
                </div>
            </div>
            <br>
            <div class="row" v-if="list.parcels.length>0">
                <div class="col-sm-12" v-for="(parcel, index) in list.parcels" :key="parcel.id">
                    <div class="card" :id="index">
                        <div class="card-body" style="margin-top: 0; padding-top: 0;">
                            <br>
                            <div class="row">
                                <span class=" text-bolder"
                                      style="font-size: 12px;">{{ getParcelType(parcel) }}</span>
                                <div class="col-6">
                                    <label>From</label>
                                    <p>{{ parcel.from }}</p>
                                </div>

                                <div class="col-6" style="text-align: right">
                                    <label>To</label>
                                    <p>{{ parcel.to }}</p>
                                </div>

                                <div class="col-6">
                                    <label>{{
                                            (parcel.date == null ? 'Daily' : convertDate(parcel.date)) + " " + convertTime(parcel.time)
                                        }}</label>
                                    <p>{{ stringToINR(parcel.fare_per_km) }} per Km
                                        <br>
                                        {{ parcel.material === 1 ? stringToINR(parcel.fare_per_kg) + " per Kg" : '' }}
                                    </p>
                                </div>
                                <div class="col-6">
                                    <br>
                                    <br>
                                    <soft-button size="sm" color="dark" full-width style="float: right;"
                                                 @click="showBookingDialog($event,parcel.id)">Request Quote
                                    </soft-button>
                                </div>

                            </div>
                        </div>

                    </div>

                    <br>
                </div>
                <div class="col-12">
                    <div>
                        <p>Dont find a suitable/appropriate vehicle for parcel?</p>
                        <soft-button color="dark" full-width @click="publishThisParcel">Request Parcel Quote
                        </soft-button>
                    </div>
                </div>
            </div>
            <br>
        </div>

    </div>

</template>

<script>


import SoftButton from "../../components/SoftButton";
import swal from "sweetalert";
import {mapGetters} from "vuex";
import {Vue3Lottie} from 'vue3-lottie'
import animationJson from '../../assets/anim/parcel-anim.json'

export default {
    components: {SoftButton,Vue3Lottie},
    props: {
        parcel_type: {
            type: String,
            required: true
        },
    },
    data() {
        return {
            animationJson,
            from: this.getFromLocation().address,
            fromPinCode: this.getFromLocation().pinCode,
            fromLatLng: this.getFromLocation().latLng,

            to: this.getToLocation().address,
            toPinCode: this.getToLocation().pinCode,
            toLatLng: this.getToLocation().latLng,


            parcel: this.getParcelDetails(),

            isParcelPublished: false,

            mobileNumber: '',
            list: {
                parcels: [],
            },

            searchingParcels: true,


        }
    },
    methods: {
        ...mapGetters(['getFromLocation', 'getToLocation','getParcelDetails']),

        goBack() {
            this.$router.go(-1)
        },

        getParcelType(parcel) {
            if (parcel.material === 1 && parcel.document === 1) {
                return 'Material / Document'
            }
            if (parcel.document === 1) {
                return 'Document'
            }
            if (parcel.material === 1) {
                return 'Material'
            }
        },

        searchParcels() {
            let vm = this
            this.searchingParcels = true
            window.axios.post('/parcel/search', {
                from: vm.from,
                to: vm.to,
                from_pin: vm.from_pin,
                to_pin: vm.to_pin,
            }).then(response => {
                this.searchingParcels = false
                if (response.data.response.status.error_code === 0) {
                    vm.list.parcels = response.data.response.data.parcels
                }
            })
        },

        showBookingDialog(event, parcelId) {

            const button = event.target

            const vm = this;

            if (vm.$props.parcel_type === 'material') {
                vm.$router.push({
                    name: "add-parcel-details", params: {
                        parcel_id: parcelId,
                        from: vm.from,
                        to: vm.to,
                        from_lat_lng: vm.fromLatLng,
                        to_lat_lng: vm.toLatLng,
                    }
                })
            } else {

                button.disabled = true

                swal({
                    title: `Are you sure you want to book this vehicle for a Document?`,
                    text: "On booking this vehicle, service provider will receive a notification and they may approve or reject you request",
                    icon: "info",
                    buttons: ['Cancel', 'Send Request'],
                    dangerMode: false,
                }).then((book) => {
                    if (book) {
                        vm.sendBookingRequest(button, parcelId, 'Document')
                    } else {
                        button.disabled = false
                    }
                });

            }

        },

        sendBookingRequest(button, parcelId, itemDesc) {
            const vm = this
            vm.parcel.itemDetails.name = itemDesc
            window.axios.post('/parcel/request/booking', {
                mobile_number: this.mobileNumber,
                is_document: 1,
                is_material: 0,
                material_details_json: JSON.stringify(vm.parcel),
                parcel_id: parcelId,
                from: vm.from,
                to: vm.to,
                from_lat_lng: vm.fromLatLng,
                to_lat_lng: vm.toLatLng,
            }).then(response => {
                if (response.data.response.status.error_code === 0) {
                    button.disabled = false
                    vm.$toast.open({
                        message: response.data.response.status.msg,
                        type: 'success',
                        position: 'top',
                        duration: 2000,
                        dismissible: false,
                    })
                }
            })
        },

        publishThisParcel(event) {
            const button = event.target

            const vm = this;

            if (vm.$props.parcel_type === 'material') {
                vm.$router.push({
                    name: "add-parcel-details", params: {
                        parcel_id: 'customer-request',
                        from: vm.from,
                        to: vm.to,
                        from_lat_lng: vm.fromLatLng,
                        to_lat_lng: vm.toLatLng,
                    }
                })
            } else {

                button.disabled = true

                swal({
                    title: `Are you sure you want to book this parcel for a Document?`,
                    text: "On booking this parcel, rider will receive a notification and they may approve or reject you request",
                    icon: "info",
                    buttons: ['Cancel', 'Send Booking Request'],
                    dangerMode: false,
                }).then((book) => {
                    if (book) {
                        window.axios.post('/parcel/customer/publish/request', {
                            mobile_number: this.mobileNumber,
                            is_document: 1,
                            is_material: 0,
                            material_details_json: JSON.stringify(vm.parcel),
                            parcel_id: 'customer-request',
                            from: vm.from,
                            to: vm.to,
                            from_lat_lng: vm.fromLatLng,
                            to_lat_lng: vm.toLatLng,
                        }).then(response => {
                            if (response.data.response.status.error_code === 0) {
                                vm.isParcelPublished = true
                                vm.$toast.open({
                                    message: response.data.response.status.msg,
                                    type: 'success',
                                    position: 'top',
                                    duration: 2000,
                                    dismissible: false,
                                })
                            }
                        })
                    } else {
                        button.disabled = false
                    }
                });

                //})
            }
        },
    },
    computed: {
        getLabel() {
            if (this.isParcelPublished) {
                return 'Your parcel request has been published. You will be notified when a parcel rider nearby will accept your request.'
            } else {
                if (this.searchingParcels) {
                    return 'Please wait, finding parcels near you...'
                } else {
                    if (this.list.parcels.length === 0) {
                        return 'No parcels found near you...'
                    } else {
                        return ':('
                    }
                }
            }

        }
    },
    created() {
        let vm = this
        vm.mobileNumber = this.$route.params.mobileNumber
        vm.searchParcels()
    }
}

</script>

<style scoped>

label {
    margin: 0;
    padding: 0;
}

p {
    font-size: 14px;
}

</style>
