function askBrowserPermission() {
    console.log("ask");
    return new Promise(function (resolve, reject) {
        if ("Notification" in window) {
            const permissionResult = Notification.requestPermission(function (result) {
                resolve(result);
            });

            if (permissionResult) {
                permissionResult.then(resolve, reject);
            }
        } else {
            throw new Error("This browser does not support push notifications");
        }
    });
}

export default {
    askBrowserPermission
}
